import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import cx from "clsx";
import Loader from "../../../../Components/Common/Loader.js";
import {
  searchPhoneNumber,
  confirmNumber,
} from "../../../../Actions/Inbox/inboxAction.js";
import {
  numberFormat,
  positionFooterCorrectly,
} from "../../../../Utils/services.js";
import classes from "../sass/Setup.module.scss";
import { dispatch } from "../../../../store/store.js";
import { privacyPolicyPopup } from "../../../../store/privacyPolicyPopup/index.js";

class InboxSetup extends Component {
  static propTypes = {
    searchPhoneNumber: PropTypes.func,
    confirmNumber: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      addNewCard: false,
      country_list: [],
      type: "local",
      cardData: "",
      country: "US",
      phone_number_match: "",
      subscriptionData: {},
      showCardSection: false,
      showSearchNumber: true,
      showUsageSection: false,
      refill_sms_status: false,
      refill_sms_limit: "250",
      selectedSMSPack: numberFormat("250", "currency"),
      refill_sms_value: "50",
      refill_email_status: false,
      refill_email_limit: "500",
      refill_email_value: "50",
      refill_data_status: false,
      refill_data_limit: "1",
      refill_data_value: "1",
      add_on_sms: "250",
      selectedNumber: "",
      match_to: "",
      phoneNumbers: [],
      friendly_name: "",
      totalAmount: numberFormat(260, "currency"),
      ifSearchButtonClicked: false,
      loggedUserType: userData && userData.user_type ? userData.user_type : "",
      country_code: "US",
      country_name: "United States",
      countryList: [],
      isDataLoaded: false,
      term: "",
    };
  }

  searchPhone = (data) => {
    this.props
      .searchPhoneNumber(data)
      .catch((res) => {
        toast.error(
          this.state.globalLang[res.message] ||
            "An Error Occurred While Searching Phone",
        );
      })
      .finally(() => {
        this.setState({
          showLoader: false,
        });
      });
  };

  handleInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    let name = target.name;
    let returnState = {};
    switch (target.type) {
      case "checkbox": {
        value = target.checked;
        break;
      }
      default:
        break;
    }
    returnState[name] = value;
    if (name === "country_code") {
      const country = this.state.countryList.find(
        (x) => x.country_code == value,
      );
      let country_name = this.state.country_name;
      if (country) {
        country_name = country.country_name;
      }
      this.setState({
        showLoader: true,
        ifSearchButtonClicked: true,
        selectedNumber: "",
        friendly_name: "",
        country_name: country_name,
      });
      this.searchPhone({ country_code: value });
    } else if (name === "add_on_sms") {
      returnState.selectedSMSPack = numberFormat(value, "currency");
      returnState.totalAmount = numberFormat(parseInt(value) + 10, "currency");
    }
    this.setState(returnState);
  };

  componentDidMount() {
    this.setState({
      showLoader: true,
      ifSearchButtonClicked: true,
      selectedNumber: "",
    });
    this.searchPhone();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (
      nextProps.phoneNumbers != undefined &&
      nextProps.phoneNumbersTime != prevState.phoneNumbersTime
    ) {
      returnState.phoneNumbers = nextProps.phoneNumbers.number_list
        ? nextProps.phoneNumbers.number_list
        : [];
      returnState.countryList = nextProps.phoneNumbers.countries
        ? nextProps.phoneNumbers.countries
        : [];
      returnState.phoneNumbersTime = nextProps.phoneNumbersTime;
      returnState.showLoader = false;
      returnState.isDataLoaded = true;
    }

    if (
      nextProps.phoneConfirmTime != prevState.phoneConfirmTime &&
      nextProps.redirect == true
    ) {
      returnState.showSearchNumber = true;
      returnState.friendly_name = "";
      returnState.selectedNumber = "";
      returnState.showUsageSection = false;
      returnState.showLoader = false;
      nextProps.history.push("/inbox/sms/all");
    }
    positionFooterCorrectly();
    return returnState;
  }

  searchNumber = () => {
    let formData = {};
    this.setState({
      phone_number_match_error: false,
      match_to_error: false,
      country_error: false,
    });
    if (this.state.country == "") {
      this.setState({ country_error: true });
      return;
    }
    if (this.state.phone_number_match == "") {
      this.setState({ phone_number_match_error: true });
      return;
    }
    if (this.state.match_to == "") {
      this.setState({ match_to_error: true });
      return;
    }
    formData.country = this.state.country;
    formData.phone_number_match = this.state.phone_number_match;
    formData.match_to = this.state.match_to;
    formData.type = this.state.type;
    formData.sms_enabled = true;
    formData.voice_enabled = true;
    this.setState({
      showLoader: true,
      ifSearchButtonClicked: true,
      selectedNumber: "",
    });
    this.searchPhone(formData);
  };

  submitNumber = () => {
    this.setState({ showLoader: true });
    let formData = {
      twilio_number: this.state.selectedNumber,
    };
    this.props
      .confirmNumber(formData)
      .then((res) => {
        toast.success(
          this.state.globalLang[res.message] ||
            "SMS Inbox Enabled Successfully",
        );
        dispatch(privacyPolicyPopup.actions.open());
      })
      .catch((res) => {
        toast.error(
          this.state.globalLang[res.message] || "Unable To Configure SMS Inbox",
        );
      })
      .finally(() => {
        this.setState({
          showLoader: false,
        });
      });
  };

  saveNumberData = (obj) => {
    this.setState({
      friendly_name: obj.friendly_name,
      selectedNumber: obj.phone_number,
    });
  };

  goBackToPrevSection = () => {
    this.setState({
      showSearchNumber: true,
      friendly_name: "",
      showUsageSection: false,
      showLoader: true,
      ifSearchButtonClicked: true,
      selectedNumber: "",
    });
    this.searchPhone();
  };

  goToNextScreen = () => {
    this.setState({ showSearchNumber: false, showUsageSection: true });
  };

  changeCountry = (obj) => {
    if (obj) {
      const country = this.state.countryList.find(
        (x) => x.country_code == obj.country_code,
      );
      let country_name = this.state.country_name;
      if (country) {
        country_name = country.country_name;
      }
      this.setState({
        showLoader: true,
        ifSearchButtonClicked: true,
        selectedNumber: "",
        friendly_name: "",
        country_name: country_name,
        country_code: obj.country_code,
      });
      this.searchPhone({ country_code: obj.country_code });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ showLoader: true, ifSearchButtonClicked: true });
    this.searchPhone({
      country_code: this.state.country_code,
      area_code: this.state.term,
    });
  };

  render() {
    let setupMessage = this.state.globalLang.SETUP_MESSAGE;
    setupMessage = setupMessage.replace(
      /{amount}/g,
      `${process.env.REACT_APP_SETUP_PRICE}`,
    );

    return (
      <div className={cx("memberWalletOuter business-section", classes.root)}>
        <div className={this.state.showSearchNumber ? "m-b-15" : "no-display"}>
          <div
            className={cx("mainSectionTitle buyNumberTitle", classes.titleWrap)}
          >
            <span className="membership-title no-padding">
              {this.state.globalLang.buy_number}
            </span>
            {this.state.countryList && this.state.countryList.length > 0 && (
              <>
                <div className="filter-type inboxSelectCountry">
                  <span className="search-text">
                    {this.state.globalLang.signup_select_country}:
                  </span>
                  <div className="header-select">
                    <select
                      name="country_code"
                      value={this.state.country_code}
                      onChange={this.handleInputChange}
                    >
                      {this.state.countryList.map((obj, idx) => {
                        return (
                          <option
                            key={"countryList-" + idx}
                            value={obj.country_code}
                          >{`${obj.country_name} (${obj.country_code})`}</option>
                        );
                      })}
                    </select>
                    <i className="fa fa-angle-down"></i>
                  </div>
                </div>
                {this.state.country_code &&
                  this.state.country_code === "US" &&
                  this.state.countryList &&
                  this.state.countryList.length > 0 && (
                    <form
                      onSubmit={this.handleSubmit}
                      className="searchForm settingAddProductOuter yourInventorySearch m-l-0"
                    >
                      <span className="searchOuter">
                        <i className="fa fa-search search-icon" />
                        <input
                          className="setting-search-input search-key"
                          placeholder="Search by area code"
                          name="term"
                          autoComplete="off"
                          value={this.state.term}
                          onChange={this.handleInputChange}
                        />
                      </span>
                    </form>
                  )}
              </>
            )}
            {this.state.friendly_name != "" ? (
              <div className="displayNumber inboxSectedNumber m-t-5">
                <span className="displayNumber__label">Selected number:</span>
                <span className="displayNumber__number">
                  {this.state.friendly_name}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={"table-responsive"}>
            <table className="table-updated setting-table no-td-border table-min-width">
              <thead className="table-updated-thead">
                <tr>
                  <th className="col-xs-3 table-updated-th">
                    {this.state.globalLang.signup_label_country}
                  </th>
                  <th className="col-xs-3 table-updated-th">
                    {this.state.globalLang.setup_number}
                  </th>
                  <th className="col-xs-3 table-updated-th">
                    {this.state.globalLang.label_price}
                  </th>
                  <th className="col-xs-3 table-updated-th"></th>
                </tr>
              </thead>
              <tbody>
                {this.state.phoneNumbers &&
                this.state.phoneNumbers.length > 0 ? (
                  this.state.phoneNumbers.map((obj, idx) => {
                    return (
                      <tr
                        className={
                          this.state.friendly_name == obj.friendly_name
                            ? "bold"
                            : ""
                        }
                        key={"number-" + idx}
                      >
                        <td className="col-xs-3 table-updated-td">
                          {this.state.country_name}
                        </td>
                        <td className="col-xs-3 table-updated-td">
                          {obj.friendly_name}
                        </td>
                        <td className="col-xs-3 table-updated-td">
                          {process.env.REACT_APP_SETUP_PRICE} per month
                        </td>
                        <td className="col-xs-3 table-updated-td">
                          {this.state.friendly_name == obj.friendly_name ? (
                            <div className="new-blue-btn-2 text-center pull-right m-r-5">
                              <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                            </div>
                          ) : (
                            <button
                              className="new-line-btn text-center pull-right m-r-5"
                              onClick={this.saveNumberData.bind(this, obj)}
                            >
                              {this.state.globalLang.label_select}
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : this.state.isDataLoaded === true &&
                  this.state.showLoader === false ? (
                  <tr>
                    <td colSpan="5" className="table-updated-td text-center">
                      {
                        this.state.globalLang
                          .lable_sms_service_not_available_for_selected_country
                      }
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td
                      colSpan="5"
                      className={
                        this.state.showLoader === false
                          ? "table-updated-td text-center"
                          : "table-updated-td text-center no-display"
                      }
                    >
                      {this.state.globalLang.no_data_found}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {this.state.selectedNumber != "" && (
            <div className="footer-static">
              <button
                className={
                  this.state.selectedNumber != ""
                    ? "new-blue-btn pull-right"
                    : "no-display"
                }
                onClick={this.goToNextScreen}
                id="save-profile"
              >
                Continue
              </button>
            </div>
          )}
        </div>
        <div className={this.state.showUsageSection ? "m-b-15" : "no-display"}>
          <div className={cx("mainSectionTitle m-b-20", classes.numberToBuy)}>
            {this.state.globalLang.buy_number} : {this.state.friendly_name}
          </div>
          <div
            className={cx(
              "usageSettingtitle no-border m-b-20",
              classes.numberToBuySubtitle,
            )}
          >
            <div className="no-padding">{setupMessage}</div>
          </div>
          <div className={cx("footer-static", classes.numberToBuyFooter)}>
            <button
              className="new-blue-btn pull-left"
              type="button"
              id="go-back"
              onClick={this.goBackToPrevSection}
            >
              {this.state.globalLang.global_back}
            </button>
            <button
              className="new-blue-btn pull-right"
              onClick={this.submitNumber}
              id="save-profile"
            >
              {this.state.globalLang.finish_setup}
            </button>
          </div>
        </div>
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {};
  if (state.InboxReducer.action === "SEARCH_PHONE_NUMBER") {
    if (state.InboxReducer.data.status == 200) {
      returnState.phoneNumbers = state.InboxReducer.data.data;
      returnState.phoneNumbersTime = new Date();
    }
  }

  if (state.InboxReducer.action === "SAVE_TWILIO_NUMBER") {
    if (state.InboxReducer.data.status == 200) {
      returnState.redirect = true;
      returnState.phoneConfirmTime = new Date();
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      searchPhoneNumber,
      confirmNumber,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(InboxSetup));
