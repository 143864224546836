import React, { Component } from "react";
import cx from "clsx";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import LoaderLow from "../../Common/LoaderLow.js";
import { MEMBERSHIP_REPORT_SLUGS } from "./Sales.consts";
import {
  getSalesReportDetails,
  resetReducerAction,
  exportData,
  fetchMembershipRevenueDetail,
  exportMembershipRevenue,
  fetchDiscountClinicDetail,
  fetchMembershipDeclineDetail,
  hideDeclineInvoice,
  fetchMrrReportDetail,
  fetchChrunReportDetail,
  resendEGiftCardMail,
  fetchBdAspireDetail,
  fetchRetailReportDetail,
  fetchAspireRedeemedCertificates,
} from "../../../Actions/BusinessInsights/businessInsightActions.js";
import InsightsSideBar from "../InsightsSideBar.js";
import SalesTitle from "./SalesTitle.js";
import TipsPerProvider from "./ReportFiles/TipsPerProvider.js";
import PaymentMethods from "./ReportFiles/PaymentMethods.js";
import EgiftCards from "./ReportFiles/EgiftCards.js";
import CostToCompany from "./ReportFiles/CostToCompany.js";
import CategorySales from "./ReportFiles/CategorySales.js";
import PackageSales from "./ReportFiles/PackageSales.js";
import ItemSales from "./ReportFiles/ItemSales.js";
import NetSales from "./ReportFiles/NetSales.js";
import GrossSales from "./ReportFiles/GrossSales.js";
import Commisions from "./ReportFiles/Commisions.js";
import Discounts from "./ReportFiles/Discounts.js";
import BdAspire from "./ReportFiles/BdAspire.js";
import { SalesSummary } from "./ReportFiles/SalesSummary.js";
import Taxes from "./ReportFiles/Taxes.js";
import TreatmentPlans from "./ReportFiles/TreatmentPlans.js";
import { ShortTermLiability } from "./Reports/ShortTermLiability/ShortTermLiability.js";
import StaffTreatment from "./ReportFiles/StaffTreatment.js";
import MembershipRevenueDetail from "./ReportFiles/MembershipRevenueDetail.js";
import EGiftCardsRevenue from "./ReportFiles/EGiftCardsRevenue.js";
import CancelationFees from "./ReportFiles/CancelationFees.js";
import DiscountClinicDetail from "./ReportFiles/DiscountClinicDetail.js";
import MrrReportDetail from "./ReportFiles/MrrReportDetail.js";
import BdAspireDetail from "./ReportFiles/BdAspireDetail.js";
import { Refund } from "./ReportFiles/Refund.js";
import RetailReport from "./ReportFiles/RetailReport.js";
import RetailReportDetail from "./ReportFiles/RetailReportDetail.js";
import IVPackageSales from "./ReportFiles/IVPackageSales.js";
import AspireRedeemedCertificates from "./ReportFiles/AspireRedeemedCertificates";
import ShopifyProducts from "./ReportFiles/ShopifyProducts";
import ShopifyFinancials from "./ReportFiles/ShopifyFinancials";
import {
  autoScrolling,
  showFormattedDate,
  positionFooterCorrectly,
  toggleServicesPerformed,
} from "../../../Utils/services.js";
import classes from "./sass/SalesInsightsReport.module.scss";
import { MembershipDeclineReport } from "./Reports/MembershipDeclineReport/MembershipDeclineReport.js";
import { MembershipChurnReport } from "./Reports/MembershipChurnReport/MembershipChurnReport.js";
import MembershipOverviewReport from "./Reports/MembershipOverviewReport/MembershipOverviewReport.js";
import ServicesPerformed from "./Reports/ServicesPerformed/ServicesPerformed.js";
import { ShopifyOrdersReport } from "./Reports/ShopifyOrdersReport/ShopifyOrdersReport.js";
import { isShopifyAvailable } from "../../../helpers/featureFlags.js";
import RepeatMdRedemptionReport from "../../NewBusinessInsights/BusinessInsightsContent/Reports/RepeatMdRedemptionReport/RepeatMdRedemptionReport.js";
import { HeaderActionsProvider } from "../../NewBusinessInsights/components/HeaderActionsProvider.js";

const ignoreFetchReportDetailsForReportSlugs = [
  MEMBERSHIP_REPORT_SLUGS.membership,
  MEMBERSHIP_REPORT_SLUGS.churn,
  MEMBERSHIP_REPORT_SLUGS.decline,
  MEMBERSHIP_REPORT_SLUGS.dailySales,
  MEMBERSHIP_REPORT_SLUGS.servicesPerformed,
  MEMBERSHIP_REPORT_SLUGS.shortTermLiability,
  MEMBERSHIP_REPORT_SLUGS.shopifyOrdersReport,
  MEMBERSHIP_REPORT_SLUGS.repeatMdRedemption,
];

const ignoreLegacyTitleForReportSlugs = [
  MEMBERSHIP_REPORT_SLUGS.membership,
  MEMBERSHIP_REPORT_SLUGS.churn,
  MEMBERSHIP_REPORT_SLUGS.decline,
  MEMBERSHIP_REPORT_SLUGS.servicesPerformed,
  MEMBERSHIP_REPORT_SLUGS.shortTermLiability,
  MEMBERSHIP_REPORT_SLUGS.shopifyOrdersReport,
  MEMBERSHIP_REPORT_SLUGS.repeatMdRedemption,
];

const ignoreLoaderForReportSlugs = [
  MEMBERSHIP_REPORT_SLUGS.refunds,
  MEMBERSHIP_REPORT_SLUGS.dailySales,
  MEMBERSHIP_REPORT_SLUGS.shopifyOrdersReport,
  MEMBERSHIP_REPORT_SLUGS.repeatMdRedemption,
];

const ignoreChildFilterRefetchForReportSlugs = [
  MEMBERSHIP_REPORT_SLUGS.refunds,
  MEMBERSHIP_REPORT_SLUGS.dailySales,
  MEMBERSHIP_REPORT_SLUGS.shopifyOrdersReport,
  MEMBERSHIP_REPORT_SLUGS.repeatMdRedemption,
];

const ApiDateFormat = "YYYY-MM-DD";

class SalesInsightsReport extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    const dateData = JSON.parse(localStorage.getItem("insight-sales-date"));

    this.state = {
      showLoader: false,
      showLoaderCustom: false,
      globalLang: languageData.global,
      reportSlug: this.props.match.params.reportType || null,
      fromDate: dateData ? dateData.startDate : moment().format(ApiDateFormat),
      toDate: dateData ? dateData.endDate : moment().format(ApiDateFormat),
      selectedMonth: moment().format("MM"),
      selectedYear: moment().format("YYYY"),
      loadFresh: true,
      churn_filter: "both",

      membershipRevenueDetail: {},
      isMembershipDetail: false,
      membershipName: "",
      membershipTierId: null,
      action_type: "active",
      selectedDate: moment().toDate(),
      report_type: "clinic_name",

      membership_tier_id: -1,
      isDiscountClinicDetail: false,
      discountClinicName: "",
      discountClinicId: null,
      discountClinicDate: "",
      discountClinicData: {},
      discountClinicDetail: [],
      discountClinicHeader: [],

      mrrReportDetail: {},
      isMrrReportDetail: false,

      chrunReportDetail: {},
      isChrunReportDetail: false,
      detailMembershipTierId: -1,
      detailMembershipName: "",
      detailClinicId: -1,
      detailClinicName: "",
      detailData: [],
      detailHeader: [],
      detailDate: "",
      pagesize: 15,
      membershipDeclineDetail: {},
      isMembershipDeclineDetail: false,
      isHidedDeclineInvoice: false,
      isReloadDeclineReport: false,
      BdAspireDetail: {},
      isBdAspireDetail: false,
      isReloadBdAspireReport: false,
      reportDataReset: false,

      isRetailReportDetail: false,
      child_category_name: "",
      redeemedCertificatesFilters: null,
      searchedTerm: "",
      clinic_id:
        JSON.parse(localStorage.getItem("insight-sales-clinicid")) || [],
    };
  }

  componentDidMount() {
    this.initReportDetails();
  }

  componentDidUpdate(_, prevState) {
    const nextReportSlug = this.props.match.params.reportType || null;
    if (prevState.reportSlug !== nextReportSlug) {
      this.setState({
        reportSlug: nextReportSlug,
      });
    }
    if (prevState.reportSlug !== this.state.reportSlug) {
      this.initReportDetails();
    }
  }

  initReportDetails = () => {
    const dateData = JSON.parse(localStorage.getItem("insight-sales-date"));
    let startDate = dateData
      ? dateData.startDate
      : moment().format(ApiDateFormat);
    let endDate = dateData ? dateData.endDate : moment().format(ApiDateFormat);
    const clinicData = localStorage.getItem("insight-sales-clinicid")
      ? JSON.parse(localStorage.getItem("insight-sales-clinicid"))
      : null;

    if (startDate && endDate) {
      this.setState({ fromDate: startDate, toDate: endDate });
    }

    if (
      !ignoreFetchReportDetailsForReportSlugs.includes(this.state.reportSlug)
    ) {
      if (this.state.reportSlug === MEMBERSHIP_REPORT_SLUGS.dailySales) {
        this.setState({
          showLoader: true,
          reportData: [],
          fromDate: startDate,
          toDate: endDate,
        });
      } else {
        this.setState({ showLoader: true, reportData: [] });
      }
    }

    autoScrolling(true);

    let formData = {
      fromDate: startDate,
      toDate: endDate,
    };

    if (clinicData) {
      formData.clinic_id = clinicData;
    }

    if (clinicData && clinicData.length > 1) {
      delete formData.clinic_id;
    }

    if (
      this.state.reportSlug &&
      (this.state.reportSlug === MEMBERSHIP_REPORT_SLUGS.revenue ||
        this.state.reportSlug === MEMBERSHIP_REPORT_SLUGS.mrr)
    ) {
      formData.month = this.state.selectedMonth;
      formData.year = this.state.selectedYear;

      delete formData.fromDate;
      delete formData.toDate;
    } else if (
      this.state.reportSlug &&
      this.state.reportSlug === MEMBERSHIP_REPORT_SLUGS.churn
    ) {
      formData.month = this.state.selectedMonth;
      formData.year = this.state.selectedYear;
      formData.churn_filter = this.state.churn_filter;
    }

    if (
      this.state.reportSlug &&
      this.state.reportSlug === MEMBERSHIP_REPORT_SLUGS.commission
    ) {
      formData.report_type = this.state.report_type;
    }

    if (
      this.state.reportSlug &&
      this.state.reportSlug === MEMBERSHIP_REPORT_SLUGS.refunds
    ) {
      formData.pagesize = this.state.pagesize;
      formData.page = 1;
    }
    if (
      !ignoreFetchReportDetailsForReportSlugs.includes(this.state.reportSlug)
    ) {
      this.fetchSalesReportDetails(this.state.reportSlug, formData);
    }
  };

  fetchSalesReportDetails = (slug, formData) => {
    this.setState({
      showLoader: true,
    });
    this.props
      .getSalesReportDetails(slug, formData)
      .then((res) => {
        this.setState({
          reportData: res.data,
        });
      })
      .catch((res) => {
        toast.error(
          this.state.globalLang[res.message] ||
            "Unable to fetch data. Try again later",
        );
      })
      .finally(() => {
        this.setState({
          showLoader: false,
        });
      });
  };

  getAspireRedeemedCertificates = (data) => {
    this.setState({ showLoader: true });
    this.fetchSalesReportDetails(this.state.reportSlug, {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      ...data,
    });
  };

  getReportData = (formData) => {
    this.setState({ loadFresh: false });
    if (
      this.state.reportSlug &&
      this.state.reportSlug === MEMBERSHIP_REPORT_SLUGS.refunds
    ) {
      formData.pagesize = this.state.pagesize;
      formData.fromDate = this.state.fromDate;
      formData.toDate = this.state.toDate;
      const clinicData = localStorage.getItem("insight-sales-clinicid")
        ? JSON.parse(localStorage.getItem("insight-sales-clinicid"))
        : null;
      if (clinicData) {
        formData.clinic_id = clinicData;
      }
    }
    this.fetchSalesReportDetails(this.state.reportSlug, formData);
  };

  static getDerivedStateFromProps(props, state) {
    let returnState = {};

    if (
      props.reportData !== undefined &&
      props.reportData.status === 200 &&
      props.reportData.data !== state.reportData
    ) {
      returnState.reportData = props.reportData.data;
      returnState.showLoader = false;
      returnState.reportData.loadFresh = state.loadFresh ? true : false;
      returnState.reportData.timestamp = new Date();
      returnState.reportDataReset = false;
      if (
        state.reportSlug &&
        state.reportSlug !== MEMBERSHIP_REPORT_SLUGS.refunds
      ) {
        autoScrolling(false);
      }
      autoScrolling(false);
    } else if (
      props.reportData !== undefined &&
      props.reportData.status !== 200 &&
      props.reportData.data !== state.reportData
    ) {
      returnState.reportData = props.reportData.data;
      returnState.showLoader = false;
    }

    if (
      props.exportDetails !== undefined &&
      props.exportDetails.status === 200 &&
      props.exportDetails.data !== state.exportDetails
    ) {
      returnState.exportDetails = props.exportDetails.data;
      returnState.showLoaderCustom = false;

      if (props.exportDetails.data && props.exportDetails.data.file) {
        window.open(
          process.env.REACT_APP_API_URL +
            "download-data/" +
            props.exportDetails.data.file,
          "_blank",
        );
      }
      props.resetReducerAction();
    }

    if (
      props.membershipRevenueDetail !== undefined &&
      props.membershipRevenueDetail !== state.membershipRevenueDetail
    ) {
      returnState.membershipRevenueDetail = props.membershipRevenueDetail;
      returnState.showLoader = false;
      returnState.isMembershipDetail = true;
      props.resetReducerAction();
    }

    if (
      props.discountClinicData !== undefined &&
      props.discountClinicData !== state.discountClinicData
    ) {
      returnState.discountClinicData = props.discountClinicData;
      returnState.discountClinicDetail = props.discountClinicData.report_data
        ? props.discountClinicData.report_data
        : [];
      returnState.discountClinicHeader = props.discountClinicData.report_header
        ? props.discountClinicData.report_header
        : [];
      returnState.showLoader = false;
      returnState.isDiscountClinicDetail = true;
      returnState.discountClinicDate =
        showFormattedDate(state.fromDate) +
        ` - ` +
        showFormattedDate(state.toDate);
      props.resetReducerAction();
    }

    if (
      props.mrrReportDetail !== undefined &&
      props.mrrReportDetail !== state.mrrReportDetail
    ) {
      returnState.mrrReportDetail = props.mrrReportDetail;
      returnState.detailData = props.mrrReportDetail.report_data
        ? props.mrrReportDetail.report_data
        : [];
      returnState.detailHeader = props.mrrReportDetail.report_header
        ? props.mrrReportDetail.report_header
        : [];
      returnState.isMrrReportDetail = true;
      returnState.showLoader = false;
      props.resetReducerAction();
    }
    if (
      props.chrunReportDetail !== undefined &&
      props.chrunReportDetail !== state.chrunReportDetail
    ) {
      returnState.chrunReportDetail = props.chrunReportDetail;
      returnState.detailData = props.chrunReportDetail.report_data
        ? props.chrunReportDetail.report_data
        : [];
      returnState.detailHeader = props.chrunReportDetail.report_header
        ? props.chrunReportDetail.report_header
        : [];
      returnState.detailDate =
        showFormattedDate(state.fromDate) +
        ` - ` +
        showFormattedDate(state.toDate);
      returnState.isChrunReportDetail = true;
      returnState.showLoader = false;
      props.resetReducerAction();
    }
    if (
      props.membershipDeclineDetail !== undefined &&
      props.membershipDeclineDetail !== state.membershipDeclineDetail
    ) {
      returnState.membershipDeclineDetail = props.membershipDeclineDetail;
      returnState.detailData = props.membershipDeclineDetail.report_data
        ? props.membershipDeclineDetail.report_data
        : [];
      returnState.detailHeader = props.membershipDeclineDetail.report_header
        ? props.membershipDeclineDetail.report_header
        : [];
      returnState.detailDate =
        showFormattedDate(state.fromDate) +
        ` - ` +
        showFormattedDate(state.toDate);
      returnState.isMembershipDeclineDetail = true;
      returnState.isHidedDeclineInvoice = false;
      returnState.showLoader = false;
      props.resetReducerAction();
    }

    if (
      props.BdAspireDetail !== undefined &&
      props.BdAspireDetail !== state.BdAspireDetail
    ) {
      returnState.BdAspireDetail = props.BdAspireDetail.data;
      returnState.detailData = props.BdAspireDetail.data.report_data
        ? props.BdAspireDetail.data.report_data
        : [];
      returnState.detailHeader = props.BdAspireDetail.data.report_header
        ? props.BdAspireDetail.data.report_header
        : [];
      returnState.detailDate =
        showFormattedDate(state.fromDate) +
        ` - ` +
        showFormattedDate(state.toDate);
      returnState.isBdAspireDetail = true;
      returnState.isHidedDeclineInvoice = false;
      returnState.showLoader = false;
      props.resetReducerAction();
    }

    if (props.isHidedDeclineInvoice && props.isHidedDeclineInvoice === true) {
      returnState.isHidedDeclineInvoice = true;
      returnState.isReloadDeclineReport = true;
      returnState.isReloadBdAspireReport = true;
      props.resetReducerAction();
    }

    if (
      props.retailReportDetail !== undefined &&
      props.retailReportDetail !== state.retailReportDetail
    ) {
      returnState.retailReportDetail = props.retailReportDetail;
      returnState.detailData = props.retailReportDetail.report_data
        ? props.retailReportDetail.report_data
        : [];
      returnState.detailHeader = props.retailReportDetail.report_header
        ? props.retailReportDetail.report_header
        : [];
      returnState.detailDate =
        showFormattedDate(state.fromDate) +
        ` - ` +
        showFormattedDate(state.toDate);
      returnState.isRetailReportDetail = true;
      returnState.showLoader = false;
      props.resetReducerAction();
    }

    if (props.showLoader === false) {
      returnState.showLoader = false;
      props.resetReducerAction();
    }

    return returnState;
  }

  handleChildDownload = (formData) => {
    this.setState({ showLoaderCustom: true });

    if (
      this.state.reportSlug === MEMBERSHIP_REPORT_SLUGS.redeemedAspire &&
      this.state.redeemedCertificatesFilters
    ) {
      formData.filter_by = this.state.redeemedCertificatesFilters;
    }

    this.props.exportData(formData);
  };

  handleChildFilter = (formData) => {
    if (formData.month && formData.year && formData.selectedDate) {
      this.setState({
        selectedMonth: formData.month,
        selectedYear: formData.year,
      });
      if (
        this.state.reportSlug &&
        this.state.reportSlug === MEMBERSHIP_REPORT_SLUGS.mrr &&
        this.state.membership_tier_id
      ) {
        formData.membership_tier_id = this.state.membership_tier_id;
      }
    } else {
      if (
        this.state.reportSlug &&
        (this.state.reportSlug === MEMBERSHIP_REPORT_SLUGS.revenue ||
          this.state.reportSlug === MEMBERSHIP_REPORT_SLUGS.mrr)
      ) {
        formData.month = this.state.selectedMonth;
        formData.year = this.state.selectedYear;
      }
    }
    if (formData.fromDate && formData.toDate) {
      this.setState({ fromDate: formData.fromDate, toDate: formData.toDate });
    }
    if (formData.churn_filter) {
      this.setState({ churn_filter: formData.churn_filter });
    }
    let x = JSON.parse(JSON.stringify(formData));
    if (formData.fromDate && formData.toDate) {
      this.setState({ fromDate: formData.fromDate, toDate: formData.toDate });
    }
    if (
      this.state.reportSlug &&
      this.state.reportSlug == MEMBERSHIP_REPORT_SLUGS.refunds
    ) {
      autoScrolling(true);
      formData.pagesize = this.state.pagesize;
      formData.page = 1;

      this.setState({ reportDataReset: true });
    }

    const clinicData = localStorage.getItem("insight-sales-clinicid")
      ? JSON.parse(localStorage.getItem("insight-sales-clinicid"))
      : null;
    if (clinicData) {
      formData.clinic_id = clinicData;
    }
    if (clinicData && clinicData.length > 1) {
      delete formData.clinic_id;
    }

    x.loadFresh = true;
    this.setState(x);
    if (
      ![
        ...ignoreFetchReportDetailsForReportSlugs,
        ...ignoreChildFilterRefetchForReportSlugs,
      ].includes(this.state.reportSlug)
    ) {
      this.fetchSalesReportDetails(this.state.reportSlug, formData);
    }
  };

  membershipRevenueBack = () => {
    this.setState({ isMembershipDetail: false, action_type: "active" });
  };

  fetchMembershipRevenueDetail = (chaildState, isDownload) => {
    let detailMembershipTierId = chaildState.detailMembershipTierId;
    let detailClinicId = chaildState.detailClinicId;
    let detailMembershipName = chaildState.detailMembershipName;
    let detailClinicName = chaildState.detailClinicName;
    let action_type = chaildState.action_type
      ? chaildState.action_type
      : this.state.action_type;

    chaildState.membership_tier_id = chaildState.detailMembershipTierId;
    chaildState.clinic_id = chaildState.detailClinicId;
    chaildState.action_type = chaildState.action_type
      ? chaildState.action_type
      : this.state.action_type;
    chaildState.month = this.state.selectedMonth;
    chaildState.year = this.state.selectedYear;

    this.setState({
      showLoader: true,
      detailMembershipTierId: detailMembershipTierId,
      detailMembershipName: detailMembershipName,
      detailClinicName: detailClinicName,
      detailClinicId: detailClinicId,
      action_type: action_type,
    });
    if (isDownload) {
      this.props.exportData(chaildState);
    } else {
      this.props.fetchMembershipRevenueDetail(chaildState);
    }
  };

  discountClinicBack = () => {
    this.setState({ isDiscountClinicDetail: false });
  };

  fetchDiscountClinicDetail = (chaildState) => {
    let discountClinicId = chaildState.discountClinicId;
    let discountClinicName = chaildState.discountClinicName;
    let formData = {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      clinic_id: [chaildState.discountClinicId],
    };
    this.setState({
      showLoader: true,
      discountClinicId: discountClinicId,
      discountClinicName: discountClinicName,
    });
    this.props.fetchDiscountClinicDetail(formData);
  };

  handleDiscountClinicExport = (exportType, discountClinicId) => {
    let formData = {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      export_type: exportType,
      clinic_id: [discountClinicId],
      export_name: "discounts_by_clinic",
    };
    this.setState({ showLoader: true });
    this.props.exportData(formData);
  };

  handleChildState = (childState) => {
    this.setState(childState);
  };

  mrrReportBack = () => {
    this.setState({
      isMrrReportDetail: false,
      membership_tier_id: this.state.membership_tier_id,
    });
  };

  fetchMrrReportDetail = (chaildState, isDownload) => {
    let detailMembershipTierId = chaildState.detailMembershipTierId;
    let detailClinicId = chaildState.detailClinicId;
    let detailMembershipName = chaildState.detailMembershipName;
    let detailClinicName = chaildState.detailClinicName;

    chaildState.membership_tier_id = chaildState.detailMembershipTierId;
    chaildState.clinic_id = chaildState.detailClinicId;
    chaildState.pagesize = this.state.pagesize;

    chaildState.month = this.state.selectedMonth;
    chaildState.year = this.state.selectedYear;

    this.setState({
      showLoader: true,
      detailMembershipTierId,
      detailMembershipName,
      detailClinicName,
      detailClinicId,
    });

    if (isDownload) {
      this.props.exportData(chaildState);
    } else {
      this.props.fetchMrrReportDetail(chaildState);
    }
  };

  chrunReportBack = () => {
    this.setState({ isChrunReportDetail: false });
  };

  fetchChrunReportDetail = (chaildState, isDownload) => {
    let detailMembershipTierId = chaildState.detailMembershipTierId;
    let detailClinicId = chaildState.detailClinicId;
    let detailMembershipName = chaildState.detailMembershipName;
    let detailClinicName = chaildState.detailClinicName;

    chaildState.membership_tier_id = chaildState.detailMembershipTierId;
    chaildState.clinic_id = chaildState.detailClinicId;
    chaildState.churn_filter = this.state.churn_filter;
    chaildState.fromDate = this.state.fromDate;
    chaildState.toDate = this.state.toDate;

    this.setState({
      showLoader: true,
      detailMembershipTierId: detailMembershipTierId,
      detailMembershipName: detailMembershipName,
      detailClinicName: detailClinicName,
      detailClinicId: detailClinicId,
    });
    if (isDownload) {
      this.props.exportData(chaildState);
    } else {
      this.props.fetchChrunReportDetail(chaildState);
    }
  };

  membershipDeclineBack = () => {
    if (this.state.isReloadDeclineReport) {
      this.setState({
        loadFresh: true,
        showLoader: true,
        isReloadDeclineReport: false,
      });
      this.fetchSalesReportDetails(this.state.reportSlug, {
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      });
    }
    this.setState({ isMembershipDeclineDetail: false });
  };

  fetchMembershipDeclineDetail = (chaildState, isDownload) => {
    let detailMembershipTierId = -1;
    let detailClinicId = chaildState.detailClinicId;
    let detailMembershipName = "";
    let detailClinicName = chaildState.detailClinicName;

    chaildState.membership_tier_id = chaildState.detailMembershipTierId;
    chaildState.clinic_id = chaildState.detailClinicId;
    chaildState.fromDate = this.state.fromDate;
    chaildState.toDate = this.state.toDate;

    this.setState({
      showLoader: true,
      detailMembershipTierId: detailMembershipTierId,
      detailMembershipName: detailMembershipName,
      detailClinicName: detailClinicName,
      detailClinicId: detailClinicId,
      isHidedDeclineInvoice: false,
    });
    if (isDownload) {
      this.props.exportData(chaildState);
    } else {
      this.props.fetchMembershipDeclineDetail(chaildState);
    }
  };

  hideDeclineInvoice = (hideDeclineInvoiceId) => {
    if (hideDeclineInvoiceId) {
      this.setState({ showLoader: true });
      this.props.hideDeclineInvoice(hideDeclineInvoiceId);
    }
  };

  BdAspireDetailBack = () => {
    if (this.state.isReloadBdAspireReport) {
      this.setState({
        loadFresh: true,
        showLoader: true,
        isReloadBdAspireReport: false,
      });
      this.fetchSalesReportDetails(this.state.reportSlug, {
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      });
    }
    positionFooterCorrectly();
    this.setState({ isBdAspireDetail: false });
  };

  fetchBdAspireDetail = (chaildState, isDownload) => {
    let detailMembershipTierId = -1;
    let detailClinicId = chaildState.detailClinicId;
    let detailMembershipName = "";
    let detailClinicName = chaildState.detailClinicName;

    chaildState.clinic_id = chaildState.detailClinicId;
    chaildState.fromDate = this.state.fromDate;
    chaildState.toDate = this.state.toDate;
    chaildState.pagesize = this.state.pagesize;
    this.setState({
      showLoader: true,
      detailMembershipTierId: detailMembershipTierId,
      detailMembershipName: detailMembershipName,
      detailClinicName: detailClinicName,
      detailClinicId: detailClinicId,
      isHidedDeclineInvoice: false,
    });
    if (isDownload) {
      this.props.exportData(chaildState);
    } else {
      this.props.fetchBdAspireDetail(chaildState);
    }
  };

  fetchAspireRedeemedCertificates = (data) => {
    data.fromDate = this.state.fromDate;
    data.toDate = this.state.toDate;
    this.props.fetchAspireRedeemedCertificates(data);
  };

  resendEGiftCardMail = (id) => {
    if (id) {
      this.setState({ showLoader: true });
      this.props.resendEGiftCardMail(id);
    }
  };

  fetchRetailDetail = (childState, isDownload) => {
    childState.fromDate = this.state.fromDate;
    childState.toDate = this.state.toDate;

    this.setState({
      showLoader: true,
      child_category_name: childState.category_name,
    });

    if (isDownload) {
      this.props.exportData(childState);
    } else {
      this.props.fetchRetailReportDetail({
        ...childState,
        clinic_id: this.state.clinic_id || [],
      });
    }
  };

  retailReportBack = () => {
    this.setState({ isRetailReportDetail: false, child_category_name: "" });
  };

  handleRedeemedCertificatesFilters = (filters) => {
    this.setState({ redeemedCertificatesFilters: filters });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let formData = {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      search_key: "to:" + this.state.searchedTerm,
    };
    this.fetchSalesReportDetails("egift_cards_revenue", formData);
    this.setState({ showLoader: true });
  };

  renderReport = () => {
    const reportData = this.state.reportData;

    const reportsBySlug = {
      [MEMBERSHIP_REPORT_SLUGS.shopifyOrdersReport]: isShopifyAvailable() ? (
        <ShopifyOrdersReport />
      ) : null,

      [MEMBERSHIP_REPORT_SLUGS.membership]: <MembershipOverviewReport />,

      [MEMBERSHIP_REPORT_SLUGS.churn]: <MembershipChurnReport />,

      [MEMBERSHIP_REPORT_SLUGS.decline]: <MembershipDeclineReport />,

      [MEMBERSHIP_REPORT_SLUGS.tipReport]: (
        <TipsPerProvider reportData={reportData} />
      ),

      [MEMBERSHIP_REPORT_SLUGS.paymentMethods]: (
        <PaymentMethods reportData={reportData} />
      ),

      [MEMBERSHIP_REPORT_SLUGS.giftCards]: (
        <EgiftCards reportData={reportData} />
      ),

      [MEMBERSHIP_REPORT_SLUGS.constToCompany]: (
        <CostToCompany reportData={reportData} />
      ),

      [MEMBERSHIP_REPORT_SLUGS.categorySales]: (
        <CategorySales reportData={reportData} />
      ),

      [MEMBERSHIP_REPORT_SLUGS.packageSales]: (
        <PackageSales reportData={reportData} />
      ),

      [MEMBERSHIP_REPORT_SLUGS.ivPackageSales]: (
        <IVPackageSales reportData={reportData} />
      ),

      [MEMBERSHIP_REPORT_SLUGS.itemSales]: (
        <ItemSales reportData={reportData} />
      ),

      [MEMBERSHIP_REPORT_SLUGS.shopifyProducts]: (
        <ShopifyProducts reportData={reportData} />
      ),

      [MEMBERSHIP_REPORT_SLUGS.shopifySales]: isShopifyAvailable() ? (
        <ShopifyFinancials reportData={reportData} />
      ) : null,

      [MEMBERSHIP_REPORT_SLUGS.netSales]: <NetSales reportData={reportData} />,

      [MEMBERSHIP_REPORT_SLUGS.grossSales]: (
        <GrossSales reportData={reportData} />
      ),

      [MEMBERSHIP_REPORT_SLUGS.commission]: (
        <Commisions
          reportType={this.state.report_type}
          reportData={reportData}
        />
      ),

      [MEMBERSHIP_REPORT_SLUGS.discounts]: (
        <Discounts
          reportData={reportData}
          fetchDiscountClinicDetail={this.fetchDiscountClinicDetail}
        />
      ),

      [MEMBERSHIP_REPORT_SLUGS.bdAspire]: (
        <BdAspire
          reportData={reportData}
          fetchBdAspireDetail={this.fetchBdAspireDetail}
        />
      ),

      [MEMBERSHIP_REPORT_SLUGS.redeemedAspire]: (
        <AspireRedeemedCertificates
          reportData={reportData}
          getAspireRedeemedCertificates={this.getAspireRedeemedCertificates}
          fetchPage={this.fetchAspireRedeemedCertificates}
          data={this.props.AspireRedeemedCertificatesData}
          onFilterChange={this.handleRedeemedCertificatesFilters}
        />
      ),

      [MEMBERSHIP_REPORT_SLUGS.dailySales]: (
        <SalesSummary
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
          clinicIds={this.state.clinic_id}
          onDownloadXls={() => {
            this.handleChildDownload({
              fromDate: this.state.fromDate,
              toDate: this.state.toDate,
              clinic_id: this.state.clinic_id,
              export_type: "xls",
              export_name: "daily_discount_reports",
            });
          }}
          setReportData={(data) => this.setState({ reportData: data })}
        />
      ),

      [MEMBERSHIP_REPORT_SLUGS.taxes]: <Taxes reportData={reportData} />,

      [MEMBERSHIP_REPORT_SLUGS.treatmentPlans]: (
        <TreatmentPlans reportData={reportData} />
      ),

      [MEMBERSHIP_REPORT_SLUGS.shortTermLiability]: <ShortTermLiability />,

      [MEMBERSHIP_REPORT_SLUGS.staffTreatments]: (
        <StaffTreatment reportData={reportData} />
      ),

      [MEMBERSHIP_REPORT_SLUGS.giftCardsRevenue]: (
        <EGiftCardsRevenue
          reportData={reportData}
          resendEGiftCardMail={this.resendEGiftCardMail}
          refetchReport={this.initReportDetails}
        />
      ),

      [MEMBERSHIP_REPORT_SLUGS.cancelationFees]: (
        <CancelationFees reportData={reportData} />
      ),

      [MEMBERSHIP_REPORT_SLUGS.refunds]: (
        <Refund
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
          clinicIds={this.state.clinic_id}
        />
      ),

      [MEMBERSHIP_REPORT_SLUGS.retail]: (
        <RetailReport
          reportData={reportData}
          fetchRetailDetail={this.fetchRetailDetail}
        />
      ),

      [MEMBERSHIP_REPORT_SLUGS.repeatMdRedemption]: (
        <HeaderActionsProvider>
          <RepeatMdRedemptionReport />
        </HeaderActionsProvider>
      ),
    };

    if (toggleServicesPerformed()) {
      reportsBySlug[MEMBERSHIP_REPORT_SLUGS.servicesPerformed] = (
        <ServicesPerformed />
      );
    }

    return reportsBySlug[this.state.reportSlug];
  };

  render() {
    const isSomeDetailsVisible = [
      this.state.isMembershipDetail,
      this.state.isDiscountClinicDetail,
      this.state.isMrrReportDetail,
      this.state.isChrunReportDetail,
      this.state.isMembershipDeclineDetail,
      this.state.isBdAspireDetail,
      this.state.isRetailReportDetail,
    ].some(Boolean);

    return (
      <div id="content">
        <div
          className={cx("container-fluid content setting-wrapper", {
            [classes.membershipReportContainer]:
              this.state.reportSlug === MEMBERSHIP_REPORT_SLUGS.membership,
          })}
        >
          <InsightsSideBar />
          <div className="memberWalletOuter business-section">
            {!isSomeDetailsVisible && (
              <>
                {!ignoreLegacyTitleForReportSlugs.includes(
                  this.state.reportSlug,
                ) && (
                  <SalesTitle
                    reportSlug={this.state.reportSlug}
                    reportData={this.state.reportData}
                    handleChildDownload={this.handleChildDownload}
                    handleChildFilter={this.handleChildFilter}
                    parentState={this.state}
                    handleChildState={this.handleChildState}
                    handleInputChange={this.handleInputChange}
                    handleSubmit={this.handleSubmit}
                  />
                )}
                {this.renderReport()}
              </>
            )}
            {this.state.isMembershipDetail && (
              <MembershipRevenueDetail
                fetchMembershipRevenueDetail={this.fetchMembershipRevenueDetail}
                membershipRevenueBack={this.membershipRevenueBack}
                membershipRevenueDetail={this.state.membershipRevenueDetail}
                detailMembershipName={this.state.detailMembershipName}
                detailMembershipTierId={this.state.detailMembershipTierId}
                detailClinicName={this.state.detailClinicName}
                detailClinicId={this.state.detailClinicId}
                selectedDate={this.state.selectedDate}
                action_type={this.state.action_type}
              />
            )}
            {this.state.isMrrReportDetail && (
              <MrrReportDetail
                fetchMrrReportDetail={this.fetchMrrReportDetail}
                mrrReportBack={this.mrrReportBack}
                mrrReportDetail={this.state.mrrReportDetail}
                detailHeader={this.state.detailHeader}
                detailData={this.state.detailData}
                detailMembershipName={this.state.detailMembershipName}
                detailMembershipTierId={this.state.detailMembershipTierId}
                detailClinicName={this.state.detailClinicName}
                detailClinicId={this.state.detailClinicId}
                detailDate={this.state.detailDate}
                selectedDate={this.state.selectedDate}
              />
            )}
            {this.state.isDiscountClinicDetail && (
              <DiscountClinicDetail
                handleDiscountClinicExport={this.handleDiscountClinicExport}
                discountClinicBack={this.discountClinicBack}
                discountClinicDetail={this.state.discountClinicDetail}
                discountClinicHeader={this.state.discountClinicHeader}
                discountClinicName={this.state.discountClinicName}
                discountClinicId={this.state.discountClinicId}
                selectedDate={this.state.discountClinicDate}
                action_type={this.state.action_type}
              />
            )}
            {this.state.isBdAspireDetail && (
              <BdAspireDetail
                fetchBdAspireDetail={this.fetchBdAspireDetail}
                BdAspireDetailBack={this.BdAspireDetailBack}
                BdAspireDetail={this.state.BdAspireDetail}
                detailHeader={this.state.detailHeader}
                detailData={this.state.detailData}
                detailMembershipName={this.state.detailMembershipName}
                detailMembershipTierId={this.state.detailMembershipTierId}
                detailClinicName={this.state.detailClinicName}
                detailClinicId={this.state.detailClinicId}
                detailDate={this.state.detailDate}
                hideDeclineInvoice={this.hideDeclineInvoice}
                isHidedDeclineInvoice={this.state.isHidedDeclineInvoice}
              />
            )}
            {this.state.isRetailReportDetail && (
              <RetailReportDetail
                retailReportBack={this.retailReportBack}
                detailData={this.state.retailReportDetail}
                detailHeader={this.state.detailHeader}
                fetchRetailDetail={this.fetchRetailDetail}
                detailDate={this.state.detailDate}
              />
            )}
          </div>
        </div>
        <div
          className={
            this.state.showLoaderCustom
              ? "new-loader text-left displayBlock"
              : "new-loader text-left"
          }
          style={{ position: "fixed" }}
        >
          <div className="loader-outer">
            <img
              alt=""
              id="loader-outer"
              src="/images/Eclipse.gif"
              className="loader-img"
            />
            <div id="modal-confirm-text" className="popup-subtitle">
              Please wait, we’re working hard to pull your report. <br /> Allow
              a few minutes before refreshing or closing out of this window
            </div>
          </div>
        </div>
        {!ignoreLoaderForReportSlugs.includes(this.state.reportSlug) && (
          <LoaderLow showLoader={this.state.showLoader} isFullWidth={true} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {};
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  if (state.BusinessInsightReducer.action === "BI_EXPORT_FILE") {
    if (state.BusinessInsightReducer.data.status != 200) {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    } else {
      returnState.exportDetails = state.BusinessInsightReducer.data;
    }
    returnState.showLoader = false;
  }

  if (state.BusinessInsightReducer.action === "MEMBERSHIP_REVENUE_DETAIL") {
    if (state.BusinessInsightReducer.data.status === 200) {
      returnState.membershipRevenueDetail =
        state.BusinessInsightReducer.data.data;
    } else {
      returnState.showLoader = false;
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    }
  }
  if (state.BusinessInsightReducer.action === "MEMBERSHIP_REVENUE_EXPORT") {
    if (state.BusinessInsightReducer.data.status === 200) {
      returnState.exportDetails = state.BusinessInsightReducer.data;
    } else {
      returnState.showLoader = false;
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    }
  }

  if (state.BusinessInsightReducer.action === "DISCOUNT_CLINIC_DETAIL") {
    if (state.BusinessInsightReducer.data.status === 200) {
      returnState.discountClinicData = state.BusinessInsightReducer.data.data;
    } else {
      returnState.showLoader = false;
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    }
  }
  if (state.BusinessInsightReducer.action === "MRR_REPORT_DETAIL") {
    if (state.BusinessInsightReducer.data.status === 200) {
      returnState.mrrReportDetail = state.BusinessInsightReducer.data.data;
    } else {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    }
    returnState.showLoader = false;
  }
  if (state.BusinessInsightReducer.action === "MEMBERSHIP_DECLINE_DETAIL") {
    if (state.BusinessInsightReducer.data.status === 200) {
      returnState.membershipDeclineDetail =
        state.BusinessInsightReducer.data.data;
    } else {
      returnState.showLoader = false;
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    }
  }
  if (state.BusinessInsightReducer.action === "CHRUN_REPORT_DETAIL") {
    if (state.BusinessInsightReducer.data.status === 200) {
      returnState.chrunReportDetail = state.BusinessInsightReducer.data.data;
    } else {
      returnState.showLoader = false;
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    }
  }
  if (
    state.BusinessInsightReducer.action === "HIDE_MEMBERSHIP_DECLINE_INVOICE"
  ) {
    toast.dismiss();
    if (state.BusinessInsightReducer.data.status === 200) {
      returnState.isHidedDeclineInvoice = true;
      toast.success(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    } else {
      returnState.showLoader = false;
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    }
  }
  if (state.BusinessInsightReducer.action === "RESEND_EGIFT_CARD_MAIL") {
    toast.dismiss();
    if (state.BusinessInsightReducer.data.status === 200) {
      returnState.showLoader = false;
      toast.success(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    } else {
      returnState.showLoader = false;
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    }
  }

  if (state.BusinessInsightReducer.action === "BDASPIRE_DETAILS") {
    if (state.BusinessInsightReducer.data.status != 200) {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.BdAspireDetail = state.BusinessInsightReducer.data;
    }
  }

  if (
    state.BusinessInsightReducer.action === "GET_ASPIRE_REDEEMED_CERTIFICATES"
  ) {
    if (state.BusinessInsightReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.AspireRedeemedCertificatesData =
        state.BusinessInsightReducer.data;
    }
  }

  if (state.BusinessInsightReducer.action === "RETAIL_REPORT_DETAIL") {
    if (state.BusinessInsightReducer.data.status === 200) {
      returnState.retailReportDetail = state.BusinessInsightReducer.data.data;
    } else {
      returnState.showLoader = false;
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetReducerAction,
      getSalesReportDetails,
      exportData,
      fetchMembershipRevenueDetail,
      exportMembershipRevenue,
      fetchDiscountClinicDetail,
      fetchMembershipDeclineDetail,
      hideDeclineInvoice,
      fetchMrrReportDetail,
      fetchChrunReportDetail,
      resendEGiftCardMail,
      fetchBdAspireDetail,
      fetchRetailReportDetail,
      fetchAspireRedeemedCertificates,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SalesInsightsReport));
