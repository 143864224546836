import React from "react";
import { useDeleteNonSavedTreatmentPlanMutation } from "../../../../../api/mutations/useDeleteNonSavedTreatmentPlanMutation";
import { uiNotification } from "../../../../../services/UINotificationService";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { ConfirmModal } from "../../../../../boxes/ConfirmModal/ConfirmModal";

export function DeletePrescribedPlan({ onSuccess, planId }) {
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);

  const mutation = useDeleteNonSavedTreatmentPlanMutation({
    onSuccess: () => {
      onSuccess();
      uiNotification.success("Prescribed treatment plan has been successfully deleted");
    },
    onError: () => {
      uiNotification.error("Failed to delete prescribed treatment plan. Try again later");
    },
  });

  return (
    <>
      <a
        className="line-btn pull-right text-center d-flex align-center gap-4 justify-center"
        onClick={() => setIsConfirmOpen(true)}
        disabled={mutation.isLoading}
      >
        {mutation.isLoading && <CircularProgress size="tiny" />}
        Delete
      </a>
      {isConfirmOpen && (
        <ConfirmModal
          isOpen
          onClose={() => setIsConfirmOpen(false)}
          onCancel={() => setIsConfirmOpen(false)}
          onConfirm={() => {
            mutation.mutate({
              planId,
            });
            setIsConfirmOpen(false);
          }}
        >
          Are you sure you want to delete this prescribed treatment plan?
        </ConfirmModal>
      )}
    </>
  );
}
