import React from "react";

const RepeatMdRedemptions = ({ repeatMdRedemptionsItems }) => {
  return repeatMdRedemptionsItems.map((item) => (
    <tr key={item.product_id}>
      <td
        style={{
          borderBottom: "1px solid #dddddd",
        }}
      >
        <div
          style={{
            fontSize: "14px",
            color: "#777777",
            padding: "10px 0px",
          }}
        >
          {`RepeatMD - ${item.repeat_md_redeemed_item.foreign_wallet_item_name}`}
        </div>
      </td>
      <td
        align="right"
        style={{
          borderBottom: "1px solid #dddddd",
          padding: "10px 0px",
        }}
      >
        <div
          style={{
            fontSize: "13px",
            color: "#777777",
            textTransform: "uppercase",
            textAlign: "right",
          }}
        >
          {item.repeat_md_redeemed_item.quantity} unit(s)
        </div>
      </td>
    </tr>
  ));
};

export default RepeatMdRedemptions;
