import React, { useMemo } from "react";
import { store } from "../store/store";

export const useBetaFeatures = () => {
  const state = store.getState();
  const betaFeatures = state.betaFeatures.betaFeatures;

  const toggledOnBetaFeaturesLabels = useMemo(
    () =>
      betaFeatures
        .map((feature) => {
          if (feature.toggled === "on") {
            return feature.label;
          }
        })
        .filter(Boolean),
    [betaFeatures],
  );

  return { betaFeatures, toggledOnBetaFeaturesLabels };
};

export const withBetaFeatures = (Component) => {
  return function Wrapper(props) {
    const betaFeature = useBetaFeatures();
    return <Component {...props} betaFeature={betaFeature} />;
  };
};
