import React from "react";
import classes from "../../widgets/Amount/Amount.module.scss";
import { TipsApplier } from "../../boxes/TipsApplier/TipsApplier";
import { tSales } from "../../../../../i18n/useAppTranslation";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { MergedInvoice } from "../../widgets/Amount/MergedInvoice/MergedInvoice";
import styles from "./scss/viewTipsModal.module.scss";
import TipsModalField from "./components/TipsModalField";
import { formatCurrency } from "../../../../../utilities/general";
import { AlertBox } from "../../../../../shared/AlertBox/AlertBox";
import { InfoIcon } from "../../../../../assets/Icons/InfoIcon";
import { PAYMENT_MODES } from "../../../../../consts/api";

const ViewTipsModal = ({
  amountToPay,
  amountMax,
  tipsDisabled,
  paymentMethod,
}) => {
  const { invoice, beforeTipAmount, currencySymbol } = useInvoice();
  const { applyTip } = useInvoiceActions();

  const onApplyCustomTip = ({ amount }) => {
    applyTip.initiate({ tipAmount: amount });
  };

  const paymentAmount = amountMax
    ? Math.min(
        amountMax,
        parseFloat(amountToPay) + parseFloat(invoice.tipAmount),
      )
    : parseFloat(amountToPay) + parseFloat(invoice.tipAmount);

  const walletPaymentAmount = Math.min(amountMax, amountToPay);

  const onApplyTip = (tipOptionValue) => {
    applyTip.initiate({
      tipAmount: applyTip.optionToAmount(tipOptionValue),
    });
  };

  return (
    <div className={styles.modalView}>
      <TipsModalField
        label="Invoice Amount"
        amount={beforeTipAmount}
        info={formatCurrency(beforeTipAmount, currencySymbol)}
      />
      <div className={classes.tipsWrap}>
        <div className={classes.tipHeader}>
          {tSales("checkoutInvoice.tips.header")}
        </div>
        {!invoice?.isMerger && (
          <TipsApplier
            onApply={onApplyTip}
            onApplyCustom={onApplyCustomTip}
            tipAmount={invoice?.tipAmount}
            beforeTipAmount={beforeTipAmount}
            isApplying={applyTip.isLoading || tipsDisabled}
            currencySymbol={invoice?.currencySymbol}
          />
        )}
        {invoice?.isMerger &&
          invoice?.mergedInvoices?.map(
            (mergedInvoice, index) =>
              mergedInvoice.isTipsEnabled && (
                <MergedInvoice key={index} mergedInvoice={mergedInvoice} />
              ),
          )}
        <span className={styles.tip}>
          Tip Amount:{" "}
          <span className={styles.amount}>
            {formatCurrency(invoice.tipAmount, currencySymbol)}
          </span>{" "}
        </span>
      </div>
      <TipsModalField
        label="Total Due"
        amount={invoice.totalAmount}
        info={`${formatCurrency(
          beforeTipAmount,
          currencySymbol,
        )} + ${formatCurrency(invoice.tipAmount, currencySymbol)} tip`}
      />
      <TipsModalField
        label="Payment Amount"
        amount={
          paymentMethod === PAYMENT_MODES.wallet
            ? walletPaymentAmount
            : paymentAmount
        }
        tips={invoice.tipAmount}
      />
      {paymentMethod === PAYMENT_MODES.wallet && (
        <AlertBox className={styles.infoBox}>
          <InfoIcon width="17px" />
          <span>{tSales("checkoutInvoice.info.walletTipsInfo")}</span>
        </AlertBox>
      )}
    </div>
  );
};

export default ViewTipsModal;
