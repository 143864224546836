import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { useEfaxPatientMutation } from "../../../../../../api/efax/useEfaxPatientMutation";

export const useSubmit = () => {
  const { tInbox } = useAppTranslation.Inbox();

  const { mutateAsync, isLoading } = useEfaxPatientMutation({
    onError: () => {
      uiNotification.error(tInbox("efaxOrders.error.assign"));
    },
    onSuccess: () => {
      uiNotification.success(tInbox("efaxOrders.success.assign"));
    },
  });

  return {
    isLoading,
    initiate: ({ patientId, clinicId, senderId, text, faxNumber }) => {
      return mutateAsync({
        patientId,
        clinicId,
        senderId,
        text,
        faxNumber,
      });
    },
  };
};
