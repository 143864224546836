export const useSalesFromProceduresPerformedReport = (data) => {
  const reportData = [
    {
      label: "Sales from Procedures",
      value: data?.dailyProcedureTotalSales?.procedure_daily_gross_sales,
      redirect: "/refactor-business-insight/sales/gross_sales",
    },
    {
      label: "Wallet Debits",
      value: data?.dailyProcedureTotalSales?.procedure_wallet_debits,
      leftSymbol: "-",
    },
    {
      label: "eGift Cards Redemption",
      value: data?.dailyProcedureTotalSales?.gift_card_redemptions,
      leftSymbol: "-",
    },
    {
      label: "Refunds",
      value: data?.dailyProcedureTotalSales?.procedure_refunds,
      leftSymbol: "-",
    },
    {
      label: "Tips",
      value: data?.dailyProcedureTotalSales?.procedure_tips,
      leftSymbol: "-",
      redirect: "/refactor-business-insight/sales/tip-report",
    },
    {
      label: "Tax",
      value: data?.dailyProcedureTotalSales?.procedure_tax,
      leftSymbol: "-",
    },
    {
      label: "Processing & Business Fees",
      value:
        data?.dailyProcedureTotalSales?.procedure_processing_and_bussiness_fees,
      leftSymbol: "-",
    },
    {
      label: "Discount & Complimentary",
      value: data?.dailyProcedureTotalSales?.procedure_discount_complementry,
      leftSymbol: "-",
    },
    {
      label: "Membership Coupons",
      value: data?.dailyProcedureTotalSales?.procedure_membership_coupons,
      leftSymbol: "-",
    },
    {
      label: "Procedure Net Sales",
      value: data?.dailyProcedureTotalSales?.procedure_daily_total_net_Sales,
      isBold: true,
    },
  ];

  return { reportData };
};
