import React, { useState } from "react";
import PropTypes from "prop-types";
import { uiNotification } from "../../../../services/UINotificationService";
import { useDownloadPatientDocsMutation } from "../../../../api/mutations/useDownloadPatientDocsMutation";
import {
  HTTP_ENDPOINTS,
  PATIENT_DOC_SUB_TYPES,
  PATIENT_DOC_TYPES,
  REQ_HEADERS,
} from "../../../../consts/api";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { LOCAL_STORAGE_KEYS } from "../../../../consts/localStorage";
import { composeApiUrl } from "../../../../utilities/api";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import cx from "clsx";
import ConfirmUnlockModal from "../../Timeline/ConfirmUnlockModal";

export function DownloadFiles({
  filesToDownload,
  clear,
  patientId,
  onRequestStart,
  onRequestTimeoutError,
  refetchProcedures,
}) {
  const { tCommon } = useAppTranslation.Common();
  const [isLoading, setIsLoading] = useState(false);
  const [lockConfirmationModal, setlockConfirmationModal] = useState(false);

  const { mutateAsync } = useDownloadPatientDocsMutation();

  const composeReqDocFile = (doc) => ({
    type: PATIENT_DOC_TYPES.file,
    name: doc.name,
    patient_id: patientId,
    document_id: Number(doc.object_id),
  });

  const composeReqDocConsent = (doc) => ({
    type: PATIENT_DOC_TYPES.consent,
    name: doc.name,
    procedure_id: Number(doc.parent_id),
    consent_id: Number(doc.object_id),
    patient_id: patientId,
    subType: doc.sub_type,
  });

  const composeReqDocProcedure = (doc) => ({
    type: PATIENT_DOC_TYPES.procedure,
    name: doc.name,
    patient_id: patientId,
    procedure_id: Number(doc.parent_id),
  });

  const composeReqDocAgreement = (doc) => ({
    type: PATIENT_DOC_TYPES.agreement,
    name: doc.name,
    patient_id: parseInt(patientId),
    is_expired: doc.sub_type === "expired_agreement" ? 1 : 0,
    agreement_id: Number(doc.object_id),
  });

  const composeReqDocQuestionnaire = (doc) => {
    if (doc?.sub_type === PATIENT_DOC_SUB_TYPES.procedure) {
      return {
        type: PATIENT_DOC_TYPES.procedureQuestionnaire,
        name: doc.name,
        procedure_id: Number(doc.parent_id),
        questionnaire_id: Number(doc.consultation_id),
      };
    } else if (doc?.sub_type === PATIENT_DOC_SUB_TYPES.appointment) {
      return {
        type: PATIENT_DOC_TYPES.appointmentQuestionnaire,
        name: doc.name,
        appointment_id: Number(doc.parent_id),
        consultation_id: Number(doc.consultation_id),
        patient_id: patientId,
        service_id: Number(doc.service_id),
      };
    }
  };

  const composeReqDocHealthQuestionnaire = (doc) => {
    if (doc?.sub_type === PATIENT_DOC_SUB_TYPES.procedure) {
      return {
        type: PATIENT_DOC_TYPES.procedureHealthQuestionnaire,
        name: doc.name,
        patient_id: patientId,
        procedure_template_id: Number(doc.parent_id),
        procedure_id: Number(doc.object_id),
      };
    } else {
      return {
        type: PATIENT_DOC_TYPES.healthQuestionnaires,
        name: doc.name,
        appointment_id: Number(doc.object_id),
        patient_id: patientId,
        procedure_template_id: Number(doc.parent_id),
      };
    }
  };

  const composeReqDocEPrescription = (doc) => ({
    type: PATIENT_DOC_TYPES.ePrescription,
    name: doc.name,
    prescription_id: Number(doc.object_id),
    patient_id: patientId,
  });

  const composeReqDocGfe = (doc) => ({
    type: PATIENT_DOC_TYPES.gfe,
    name: doc.name,
    patient_id: patientId,
    document_id: Number(doc.object_id),
  });

  const composeReqDocEfax = (doc) => ({
    type: PATIENT_DOC_TYPES.efax,
    name: doc.name,
    patient_id: patientId,
    document_id: Number(doc.object_id),
  });

  const composerByType = {
    [PATIENT_DOC_TYPES.file]: composeReqDocFile,
    [PATIENT_DOC_TYPES.consent]: composeReqDocConsent,
    [PATIENT_DOC_TYPES.procedure]: composeReqDocProcedure,
    [PATIENT_DOC_TYPES.agreement]: composeReqDocAgreement,
    [PATIENT_DOC_TYPES.questionnaires]: composeReqDocQuestionnaire,
    [PATIENT_DOC_TYPES.healthQuestionnaires]: composeReqDocHealthQuestionnaire,
    [PATIENT_DOC_TYPES.ePrescription]: composeReqDocEPrescription,
    [PATIENT_DOC_TYPES.gfe]: composeReqDocGfe,
    [PATIENT_DOC_TYPES.efax]: composeReqDocEfax,

  };

  const bluredSelectedIds = filesToDownload.flatMap((procedure) =>
    procedure.is_lock ? [procedure.parent_id] : [],
  );

  const handleDownloadActions = () => {
    if (bluredSelectedIds.length > 0) {
      setlockConfirmationModal(true);
    } else {
      download();
    }
  };

  const download = async () => {
    try {
      setIsLoading(true);

      if (onRequestStart) {
        onRequestStart();
      }

      const res = await mutateAsync({
        docs: filesToDownload.map((doc) => composerByType[doc.type](doc)),
      });

      if (res.failedFiles.length > 0) {
        uiNotification.error(
          tCommon("error.documentsDownloadForFiles", {
            files: res.failedFiles.join(", "),
          }),
        );
      }

      const zipResponse = await fetch(
        composeApiUrl(HTTP_ENDPOINTS.getDownloadDocsZip(res.fileName)),
        {
          headers: {
            [REQ_HEADERS.accessToken]: localStorage.getItem(
              LOCAL_STORAGE_KEYS.accessToken,
            ),
          },
        },
      );

      const blobZip = await zipResponse.blob();

      window.location.href = URL.createObjectURL(blobZip);
      clear();
    } catch (e) {
      setlockConfirmationModal(false);
      console.error(e);
      if (e?.response?.status === 504) {
        if (onRequestTimeoutError) {
          onRequestTimeoutError();
        } else {
          uiNotification.error(tCommon("error.requestTimeout"));
        }
      } else {
        uiNotification.error(tCommon("error.documentsDownload"));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <button
        className={cx("new-blue-btn pull-right d-flex align-center gap-8", {
          buttonDisabled: isLoading,
        })}
        onClick={handleDownloadActions}
        disabled={isLoading}
      >
        {isLoading && <CircularProgress size="tiny" color="white" />}
        {`${tCommon("label.download")} (${filesToDownload.length})`}
      </button>
      {lockConfirmationModal && (
        <ConfirmUnlockModal
          isOpen={lockConfirmationModal}
          handleClose={() => setlockConfirmationModal(false)}
          procedureIds={bluredSelectedIds}
          refetchProcedures={refetchProcedures}
          downloadProcedures={download}
        />
      )}
    </>
  );
}

DownloadFiles.propTypes = {
  filesToDownload: PropTypes.array.isRequired,
  clear: PropTypes.func.isRequired,
  patientId: PropTypes.number.isRequired,
  onRequestStart: PropTypes.func,
  onRequestTimeoutError: PropTypes.func,
};
