import React from "react";
import { Trans } from "react-i18next";
import { LayoutSettings } from "../../../../boxes/LayoutSettings/LayoutSettings";
import { EntityHeader } from "../../../../widgets/EntityHeader/EntityHeader";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Toggle } from "../../../../shared/Toggle/Toggle";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import styles from "./styles.module.scss";
import { useIntegration } from "./hooks/useIntegration";
import { Form } from "./shared/Form/Form";
import { useForm } from "./hooks/useForm";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { AlertBox } from "../../../../shared/AlertBox/AlertBox";
import { Box } from "../../../../shared/Box/Box";
import { submitAdapter } from "./utilities";

export const Efax = () => {
  const { tSettings } = useAppTranslation.Settings();
  const { status, enable, disable } = useIntegration();

  const form = useForm({
    onSubmit: (formValues) => {
      enable.initiate(submitAdapter(formValues));
    },
    initialValues: {
      isEnabled: status.isEnabled,
      faxNumber: status.faxNumber,
    },
  });

  const isToggleLoading = status.isLoading || disable.isLoading;
  const isToggleDisabled = isToggleLoading || enable.isLoading;

  return (
    <LayoutSettings contentClassName={styles.root}>
      <EntityHeader
        title={tSettings("efaxIntegration.title")}
        right={
          <div className={styles.toggleWrap}>
            {isToggleLoading && <CircularProgress size="small" color="gray1" />}
            <Toggle
              isEnabled={form.isEnabled.value}
              onChange={form.isEnabled.toggle}
              isDisabled={isToggleDisabled}
            />
          </div>
        }
      />
      {form.isEnabled.value && (
        <Box>
          <div className={styles.content}>
            <Form
              onSubmit={form.submit}
              isSubmitting={enable.isLoading}
              isIntegrationEnabled={status.isEnabled}
              faxNumber={{
                value: form.faxNumber.value,
                onChange: form.faxNumber.onChange,
                error: form.faxNumber.error,
              }}
            />
            {!status.isEnabled && (
              <span className={styles.selectAreaCode}>
                {tSettings("efaxIntegration.selectAreaCode")}
              </span>
            )}
            <AlertBox size="small">
              {tSettings("efaxIntegration.feeAlert")}
            </AlertBox>
          </div>
        </Box>
      )}
      {form.isDisableConfirmOpen.value && (
        <ConfirmModal
          isOpen
          isConfirming={disable.isLoading}
          onClose={form.isDisableConfirmOpen.close}
          onCancel={form.isDisableConfirmOpen.close}
          onConfirm={() =>
            disable.initiate().then(form.isDisableConfirmOpen.close)
          }
        >
          <Trans i18nKey={tSettings("efaxIntegration.confirm.disable")} />
        </ConfirmModal>
      )}
    </LayoutSettings>
  );
};
