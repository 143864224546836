import { tBi } from "../../../../../../i18n/useAppTranslation";

export const useTotalSalesReport = (data) => {
  const reportData = [
    {
      label: tBi("salesSummaryReport.staticData.procedureAndRetailSales"),
      value: data?.dailyTotalSales?.daily_gross_sales,
      redirect: "/refactor-business-insight/sales/gross_sales",
    },
    {
      label: tBi("membershipOverviewReport.label"),
      value: data?.dailyTotalSales?.daily_membership_revenue,
      redirect: "/refactor-business-insight/sales/membership_overview",
    },
    {
      label: "Wallet Credits",
      value: data?.dailyTotalSales?.pre_paid_products,
    },
    {
      label: tBi("salesSummaryReport.staticData.giftCardPurchases"),
      value: data?.dailyTotalSales?.gift_card_purchase,
      redirect: "/business-insight/sales/egift_cards_revenue",
    },
    {
      label: "Appointment Cancellation Fee",
      value: data?.dailyTotalSales?.cancellation_feecollected,
    },
    {
      label: "Gross Revenue", //Bold
      value: data?.dailyTotalSales?.gross_sales_revenue,
      isBold: true,
    },
    {
      label: "Wallet Debits",
      value: data?.dailyTotalSales?.wallet_debits,
      leftSymbol: "-",
    },
    {
      label: "eGift Cards Redemption",
      value: data?.dailyTotalSales?.gift_card_redemptions,
      leftSymbol: "-",
    },
    {
      label: "Refunds (This Period)",
      value: data?.dailyTotalSales?.refunds,
      leftSymbol: "-",
    },
    {
      label: "Refunds (Prior Period)",
      value: data?.dailyTotalSales?.unmatched_refunds,
      leftSymbol: "-",
    },
    {
      label: tBi("salesSummaryReport.staticData.tips"),
      value: data?.dailyTotalSales?.tips,
      redirect: "/refactor-business-insight/sales/gross_sales",
      leftSymbol: "-",
    },
    {
      label: "Tax",
      value: data?.dailyTotalSales?.tax,
      leftSymbol: "-",
    },
    {
      label: "Processing & Business Fees",
      value: data?.dailyTotalSales?.processing_and_bussiness_fees,
      leftSymbol: "-",
    },
    {
      label: "Discount & Complimentary",
      value: data?.dailyTotalSales?.discount_complementry,
      leftSymbol: "-",
    },
    {
      label: "Membership Coupons",
      value: data?.dailyTotalSales?.membership_coupons,
      leftSymbol: "-",
    },
    {
      label: "Evolus Rewards",
      value: data?.dailyTotalSales?.deposit_evolus_coupons,
      leftSymbol: "-",
    },
    {
      label: "Total Discounts, Liabilities & Fees",
      value: data?.dailyTotalSales?.daily_total_discounts_liabilities_fees,
      isBold: true,
    },
    {
      label: "Total Net Sales",
      value: data?.dailyTotalSales?.daily_total_net_Sales,
      isBold: true,
    },
  ];

  return { reportData };
};
