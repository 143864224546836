import React, { Component } from "react";
import momentTimeZone from "moment-timezone";
import { SketchField, Tools } from "react-sketch";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import validator from "validator";
import { toast } from "react-toastify";
import axios from "axios";
import Sidebar from "../../../_legacy/Containers/Settings/sidebar.js";
import {
  getUser,
  userProfile,
  uploadImage,
  uploadBase64Image,
  disableGoogleCalendarSync,
  exportEmptyData,
  get2FA,
  disable2FA,
  getGoogle2FA,
  verifyGoogleToken,
  sendOTP,
  verifyMobileOTP,
  sendEmailOTP,
  verifyEmailOTP,
  updateHeader,
} from "../../../Actions/Settings/settingsActions.js";
import { Select } from "../../../shared/Select/Select.js";
import { InputLabel } from "../../../shared/InputLabel/InputLabel.js";
import defLogo from '../../../_legacy/images/appmale.png';
import Loader from '../../../Components/Common/Loader.js'
import picClose from '../../../_legacy/images/popupClose.png';
import { isFormSubmit, toggleBodyScroll } from '../../../Utils/services.js';
import FileUploader from '../../FileUploader/FileUploader.js';
import { InputPhone } from '../../../shared/InputPhone/InputPhone.js';
import { phoneUtil } from '../../../utilities/phone';
import { uiNotification } from '../../../services/UINotificationService.js';
import { tCommon } from '../../../i18n/useAppTranslation.js';
import { SmsApptNotifications } from './shared/SmsApptNotifications/SmsAppointmentNotifications.js';

class Profile extends Component {
  interval = null;

  static defaultProps = {
    width: 400,
    height: 200,
    color: "#000",
    size: 5,
    fillColor: "",
    canvasClassName: "canvas",
    debounceTime: 1000,
    animate: true,
    userChanged: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      icon: "faAngleDown",
      clicked: false,
    };

    const userData = JSON.parse(localStorage.getItem("userData"));
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang: languageData.global,
      timezones: [],
      userId: userData.user.id,
      firstname: "",
      lastname: "",
      email_id: "",
      password: "",
      showPassModal: false,
      showSignModal: false,
      old_password: "",
      repeat_password: "",
      contact_number_1: "",
      file: "",
      file_name: "",
      contactClass: "newInputField",
      firstnameClass: "newInputField",
      lastnameClass: "newInputField",
      email_idClass: "newInputField",
      passwordClass: "passwordclassName newInputField",
      oldPasswordClass: "passwordclassName newInputField",
      repeatpasswordClass: "passwordclassName newInputField",
      userChanged: false,
      contactError: false,
      user_image: "",
      signature: "",
      canvasClass: "signature-box sig-div ",
      inputOut: "input-outer",
      clearClass: "new-white-btn no-margin clear no-display",
      resetClass: "new-blue-btn reset no-display m-l-10",
      changeClass: "new-blue-btn no-margin Change m-l-10",
      defImageCls: "no-display",
      cameraInPreviewCls: "camra-icon dz-clickable no-image",
      uploadedFile: "",
      dzImgObj: {},
      user_image_url: "",
      dzCSS: "",
      show_signature_popup: false,
      google_calender_sync: false,
      dbfirstname: "",
      uploadedSignature: "",
      uploadedSignature_url: "",
      userDetails: {},
      showLoader: false,
      google_oauth_url: "",

      pictures: [],
      sms_class: "row factor-step setting-container",
      google_class: "setting-container factor-step",
      enabledDisabledText: "",
      isDisabled: "Disabled",
      value: 1,
      two_factor_auth_status: 0,
      verification_type: false,
      googleImage: "",
      addNewDevice: false,
      addNewPhone: false,
      otp_sent: false,
      circleText: false,
      phone_number_display: "",
      authTokenClass: "newInputField ",
      otpClass: "newInputField",
      google_auth_code_display: "",
      user2FaData: {},
      defaultCountry: localStorage.getItem("cCode"),
      authSettings: {},
      phone_number1: "",
      contactClass1: "newInputField",
      contactError1: "false",
      imgsrcClass: "img-src",
      email: "",
      emailClass: "newInputField",
      addEmail: false,
      otp_email_sent: false,
      otpEmailClass: "newInputField",
      email_display: "",
      email_otp_verified: false,
      email_otp: "",
      phone_otp_verified: false,
      pass_code: "",
      passcodeError: false,
      passcodeClass: "newInputField",
      otpNumberError: false,

      membershipTiers: [],
      favouriteMembershipTiers: [],
    };

    window.onscroll = () => {
      return false;
    };
  }

  showPassModal = () => {
    this.setState({ showPassModal: true });
    toggleBodyScroll(true);
  };

  dismissPassModal = () => {
    this.setState({ showPassModal: false });
    toggleBodyScroll(false);
  };

  fetchGoogle2FA = (data) => {
    this.setState({ showLoader: true });
    this.props
      .getGoogle2FA(data)
      .catch((res) => {
        toast.error(
          this.state.globalLang[res.message] || "Unable To Retrieve Google 2FA",
        );
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  dismissModal = () => {
    this.setState({
      inputOut: "input-outer",
      imgsrcClass: "img-src",
      canvasClass: "signature-box sig-div no-display",
      clearClass: "new-white-btn no-margin clear no-display",
      resetClass: "new-blue-btn reset  no-display",
      changeClass: "new-blue-btn no-margin Change",
      showSignModal: false,
    });
    toggleBodyScroll(false);
  };

  handleInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    let returnState = {};

    switch (target.type) {
      case "checkbox": {
        value = target.checked;
        break;
      }
      case "file": {
        value = target.files[0];
        break;
      }
      default:
        break;
    }

    if (target.name == "two_factor_auth_status") {
      if (!value) {
        returnState.addNewPhone = false;
        returnState.addNewDevice = false;
        returnState.verification_type = "";
        returnState.two_factor_auth_status = 0;
        returnState.email = "";
        returnState.email_otp = "";
        returnState.phone_number1 = "";
        returnState.otp = "";

        this.setState({ showLoader: true });

        this.props
          .disable2FA({ two_factor_auth_status: 0 })
          .then((res) => {
            toast.success(
              this.state.globalLang[res.message] || "2FA Disabled Successfully",
            );
          })
          .catch((res) => {
            toast.error(
              this.state.globalLang[res.message] || "Unable To Disable 2FA",
            );
          })
          .finally(() => {
            this.setState({ showLoader: false });
          });
      }
    }

    if (target.name == "verification_type") {
      if (value == "google" && this.state.google_auth_code_display == "") {
        returnState.addNewDevice = true;
        returnState.addNewPhone = false;
        returnState.addEmail = false;
        this.fetchGoogle2FA({});
      } else if (value == "sms") {
        returnState.addNewPhone = true;
        returnState.addNewDevice = false;
        returnState.addEmail = false;
      } else {
        returnState.addEmail = true;
        returnState.addNewEmail = false;
        returnState.addNewDevice = false;
        returnState.addNewPhone = false;
      }
    }

    returnState[event.target.name] = value;
    returnState.dataChanged = true;
    returnState.userChanged = true;

    this.setState(returnState);
  };

  phoneNumberChanged = (nextPhone) => {
    if (
      this.state.contactError &&
      (phoneUtil.isPossible(phoneUtil.compose(nextPhone)) || nextPhone === "")
    ) {
      this.setState({
        contactClass: "newInputField",
        contactError: false,
      });
    }

    this.setState({
      contact_number_1: nextPhone,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.user !== undefined &&
      prevProps.user.status === 200 &&
      this.props.user !== undefined
    ) {
      if (
        prevProps.user.data.user_image_url !==
        this.props.user.data.user_image_url
      ) {
        if (this.props.user.data.user_image !== "") {
          this.setState({
            defImageCls: "no-display",
            cameraInPreviewCls: "camra-icon dz-clickable camera-in-preview",
            dzCSS: "",
            user_image: this.props.user.data.user_image,
            user_image_url: this.props.user.data.user_image_url,
          });
        } else {
          this.setState({
            uploadedFile: this.props.user.data.user_image,
            user_image: this.props.user.data.user_image,
          });
        }

        let reInitData = {};

        reInitData.dzImgObj = this.state.dzImgObj;

        reInitData.mockFile = {
          name: this.props.user.data.user_image_url,
          accepted: true,
          size: 7627,
        };

        reInitData.user_image_url = this.props.user.data.user_image_url;
        reInitData.user_image = this.props.user.data.user_image;
        this.refs.child.reInit(reInitData);
      }
    }
  }

  updateProfile = (data, id, mode) => {
    this.setState({ showLoader: true });

    this.props
      .userProfile(data, id, mode)
      .then((res) => {
        toast.success(
          this.state.globalLang[res.message] || "Profile Updated Successfully",
        );
      })
      .catch((res) => {
        toast.error(
          this.state.globalLang[res.message] || "Unable To Update Profile",
        );
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  handlePassSubmit = (event) => {
    if (isFormSubmit()) {
      event.preventDefault();

      let error = false;
      let passError = false;
      let regularExpression =
        /^(?=.{8,})(?=.*[A-Z])(?=.*[`~*-/\[\]\\|{}().:;,''""!_<>-@#$%^&+=]).*$/;

      this.setState({
        oldPassError: false,
        passwordError: false,
        repeatpasswordError: false,
        old_passwordError: "",
        repeat_passwordError: "",
      });

      if (
        this.state.old_password === undefined ||
        this.state.old_password === null ||
        this.state.old_password === ""
      ) {
        this.setState({
          oldPassError: true,
          oldPasswordClassError: "newInputField field_error",
        });
        error = true;
      } else if (this.state.old_password) {
        this.setState({
          oldPassError: false,
          oldPassword: "newInputField",
        });
      }

      if (
        this.state.repeat_password === "" &&
        this.state.password === "" &&
        this.state.old_password === ""
      ) {
        passError = true;
      } else {
        if (
          this.state.password === undefined ||
          this.state.password === null ||
          this.state.password === ""
        ) {
          this.setState({
            passwordError: true,
            passwordClassError: "newInputField field_error",
          });
          error = true;
        } else if (this.state.password) {
          this.setState({
            passwordError: false,
            passwordClassError: "newInputField",
          });
          if (this.state.password.length < 8) {
            this.setState({
              passwordError: true,
              passwordClassError: "newInputField field_error",
            });
            error = true;
            toast.error(this.state.globalLang.signup_error_password_length);
            return false;
          } else if (!regularExpression.test(this.state.password)) {
            this.setState({
              passwordError: true,
              passwordClassError: "newInputField field_error",
            });
            error = true;
            toast.error(
              this.state.globalLang.signup_error_password_combination,
            );
            return false;
          } else {
            this.setState({
              passwordError: false,
              passwordClassError: "newInputField",
            });
          }
        }

        if (
          this.state.repeat_password === undefined ||
          this.state.repeat_password === null ||
          this.state.repeat_password === ""
        ) {
          this.setState({
            repeatpasswordError: true,
            repeatpasswordClassError: "newInputField field_error",
          });
          error = true;
        } else if (this.state.repeat_password) {
          this.setState({
            repeatpasswordError: false,
            repeatpassword: "newInputField",
          });
        }

        if (this.state.password !== this.state.repeat_password) {
          this.setState({
            passwordError: true,
            passwordClassError: "newInputField field_error",
          });
          error = true;
          toast.error(this.state.globalLang.signup_confirm_password_same);

          return false;
        } else if (this.state.password) {
          this.setState({
            passwordError: false,
            passwordClassError: "newInputField",
          });
        }
      }

      if (error === true) {
        return;
      }

      let formData = {};

      if (!passError) {
        formData.notification_msg = "password_changed_success";
        formData.password = this.state.password;
        formData.old_password = this.state.old_password;
        formData.request_type = "profile";
      }

      this.updateProfile(formData, this.state.userId, "profile");
      this.dismissPassModal();
    }
  };

  handleSubmit = (event) => {
    if (isFormSubmit()) {
      event.preventDefault();

      if (
        !this.state.phone_otp_verified &&
        this.state.verification_type == "sms" &&
        this.state.addNewPhone &&
        this.state.phone_number1 &&
        !this.state.otp
      ) {
        toast.error("Please Verify OTP");
        return;
      }

      if (
        !this.state.email_otp_verified &&
        this.state.verification_type == "email" &&
        this.state.addEmail &&
        this.state.email &&
        !this.state.email_otp
      ) {
        toast.error("Please Verify OTP");
        return;
      }

      let error = false;

      this.setState({
        firstnameError: false,
        lastnameError: false,
        email_idError: false,
        timezoneError: false,
      });

      if (
        !phoneUtil.isPossible(phoneUtil.compose(this.state.contact_number_1)) &&
        this.state.contact_number_1 !== ""
      ) {
        this.setState({
          contactClass: "newInputField field_error",
          contactError: true,
        });
        error = true;
        uiNotification.error(tCommon("formError.phoneInvalid"));
      }

      if (
        typeof this.state.firstname === undefined ||
        this.state.firstname === null ||
        this.state.firstname === "" ||
        this.state.firstname.trim() == ""
      ) {
        this.setState({
          firstnameError: true,
          firstnameClassError: "newInputField field_error",
        });
        error = true;
      } else if (this.state.firstname) {
        this.setState({
          firstnameError: false,
          firstnameClass: "newInputField",
        });
      }
      if (
        typeof this.state.lastname === undefined ||
        this.state.lastname === null ||
        this.state.lastname === "" ||
        this.state.lastname.trim() == ""
      ) {
        this.setState({
          lastnameError: true,
          lastnameClassError: "newInputField field_error",
        });
        error = true;
      } else if (this.state.lastname) {
        this.setState({
          lastnameError: false,
          lastnameClass: "newInputField",
        });
      }

      if (this.state.contactError) {
        error = true;
      }
      if (
        typeof this.state.email_id === undefined ||
        this.state.email_id === null ||
        this.state.email_id === "" ||
        this.state.email_id.trim() == ""
      ) {
        this.setState({
          email_idError: true,
          email_idClassError: "newInputField field_error",
        });
        error = true;
      } else if (!validator.isEmail(this.state.email_id)) {
        toast.error("Incorrect email address");
        error = true;
      } else if (this.state.email_id) {
        this.setState({
          email_idError: false,
          email_idErrorClass: "newInputField",
        });
      }

      if (
        typeof this.state.timezone === undefined ||
        this.state.timezone === null ||
        this.state.timezone === ""
      ) {
        this.setState({
          timezoneError: true,
        });
        error = true;
      } else if (this.state.timezone) {
        this.setState({
          timezoneError: false,
        });
      }

      if (
        typeof this.state.pass_code === undefined ||
        this.state.pass_code === null ||
        this.state.pass_code === ""
      ) {
        this.setState({
          passcodeError: true,
          passcodeClassError: "newInputField field_error",
        });
        error = true;
      } else {
        this.setState({
          passcodeError: false,
          passcodeErrorClass: "newInputField",
        });
      }

      if (error === true) {
        return;
      }

      let formData = {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email_id: this.state.email_id,
        contact_number_1: phoneUtil.compose(this.state.contact_number_1),
        show_signature_popup: this.state.show_signature_popup ? 1 : 0,
        user_image: this.state.user_image,
        timezone: this.state.timezone,
        request_type: "profile",
        pass_code: this.state.pass_code,
        favorite_membership_ids: this.state.favouriteMembershipTiers.map(
          (x) => x.value,
        ),
      };
      this.setState({ showLoader: true });
      this.updateProfile(formData, this.state.userId, "profile");
    }
  };

  fetchUser = (id, mode) => {
    this.setState({ showLoader: true });
    this.props
      .getUser(id, mode)
      .then((res) => {
        const membershipTiers = res.data.membership_tiers || [];
        const favouriteMembershipTiers = membershipTiers.filter(
          (x) => x.favorite === 1,
        );

        this.setState({
          membershipTiers: membershipTiers.map((x) => ({
            label: x.tier_name,
            value: x.id,
          })),

          favouriteMembershipTiers: favouriteMembershipTiers.map((x) => ({
            label: x.tier_name,
            value: x.id,
          })),
        });
      })
      .catch((res) => {
        toast.error(
          this.state.globalLang[res.message] || "Unable To Fetch User Data",
        );
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.showLoader != undefined && props.showLoader == false) {
      props.exportEmptyData();
      return { showLoader: false };
    }

    let returnState = {};
    if (
      props.user !== undefined &&
      props.user.status === 200 &&
      props.user.data !== state.userDetails
    ) {
      if (props.user.data.signature_url == "") {
        returnState.canvasClass = "signature-box sig-div";
        returnState.inputOut = "input-outer no-display";
        returnState.imgsrcClass = "img-src no-display";
        returnState.clearClass = "new-white-btn no-margin clear";
        returnState.resetClass = "new-blue-btn reset ";
        returnState.changeClass = "new-blue-btn no-margin Change no-display";
      } else {
        returnState.canvasClass = "signature-box sig-div no-display";
        returnState.inputOut = "input-outer";
        returnState.imgsrcClass = "img-src";
        returnState.clearClass = "new-white-btn no-margin clear no-display";
        returnState.resetClass = "new-blue-btn reset no-display";
        returnState.changeClass = "new-blue-btn no-margin Change ";
      }
      returnState.userDetails = props.user.data;
      returnState.authSettings = props.user.data.auth_settings;
      returnState.timezones = props.user.data.timezones;
      returnState.timezones = returnState.timezones.map((e) => {
        const timezoneName = e.timezone.split(" ");
        timezoneName.shift();
        const utcOffset = momentTimeZone
          .tz(new Date(), e.php_timezone)
          .format("ZZ");
        const utcOffsetFormatted = `(UTC${utcOffset
          .split("")
          .splice(0, 3)
          .join("")}:${utcOffset.split("").splice(3).join("")})`;
        e.timezone = `${utcOffsetFormatted} ${timezoneName.join(" ")}`;
        return e;
      });
      returnState.timezone = props.user.data.timezone;
      returnState.firstname = props.user.data.firstname;
      returnState.lastname = props.user.data.lastname;
      returnState.email_id = props.user.data.email_id;
      returnState.old_password = "";
      returnState.password = "";
      returnState.repeat_password = "";
      returnState.contact_number_1 = props.user.data.contact_number_1;
      returnState.user_image =
        props.user.data.user_image == null ? "" : props.user.data.user_image;
      returnState.user_image_url = props.user.data.user_image_url;
      returnState.signature = props.user.data.signature;
      returnState.signature_url = props.user.data.signature_url;
      returnState.show_signature_popup = props.user.data.show_signature_popup
        ? 1
        : 0;
      returnState.google_calender_sync = props.user.data.google_calender_sync
        ? 1
        : 0;
      returnState.dbfirstname = props.user.data.firstname;
      returnState.google_oauth_url = props.user.data.google_oauth_url;
      returnState.two_factor_auth_status = returnState.authSettings
        ? returnState.authSettings.two_factor_auth_status
          ? true
          : false
        : false;
      returnState.verification_type =
        returnState.authSettings &&
          returnState.authSettings.two_factor_auth_status
          ? returnState.authSettings.verification_type
          : false;
      returnState.google_auth_code =
        returnState.authSettings &&
          returnState.authSettings.verification_type == "google" &&
          returnState.authSettings.two_factor_auth_status
          ? returnState.authSettings.google_auth_code
          : "";
      returnState.google_auth_code_display =
        returnState.authSettings &&
          returnState.authSettings.verification_type == "google" &&
          returnState.authSettings.two_factor_auth_status
          ? returnState.authSettings.google_auth_code
          : "";
      returnState.otp_sent = false;
      returnState.otp_email_sent = false;
      returnState.email_otp_verified = false;
      returnState.phone_otp_verified = false;
      returnState.phone_number_display =
        returnState.authSettings &&
          returnState.authSettings.verification_type == "sms" &&
          returnState.authSettings.two_factor_auth_status
          ? returnState.authSettings.phone_number
          : "";
      returnState.phone_number =
        returnState.authSettings &&
          returnState.authSettings.verification_type == "sms" &&
          returnState.authSettings.two_factor_auth_status
          ? returnState.authSettings.phone_number
          : "";
      returnState.email_display =
        returnState.authSettings &&
          returnState.authSettings.verification_type == "email" &&
          returnState.authSettings.two_factor_auth_status
          ? returnState.authSettings.email_id
          : "";
      returnState.email =
        returnState.authSettings &&
          returnState.authSettings.verification_type == "email" &&
          returnState.authSettings.two_factor_auth_status
          ? returnState.authSettings.email_id
          : "";
      returnState.addNewPhone =
        returnState.authSettings && returnState.authSettings.phone_number
          ? false
          : true;
      returnState.addNewDevice =
        returnState.authSettings && returnState.authSettings.google_auth_code
          ? false
          : true;
      returnState.addEmail =
        returnState.authSettings && returnState.authSettings.email_id
          ? false
          : true;
      returnState.pass_code = props.user.data.pass_code;
      returnState.showLoader = false;
      localStorage.setItem("current_wc_full_name", props.user.data.full_name);
      props.exportEmptyData();
      props.updateHeader();
      return returnState;
    } else if (
      props.signature != undefined &&
      props.signature != state.signature
    ) {
      returnState.signature = props.signature;
      props.exportEmptyData();
      return returnState;
    }
    if (
      props.google2FaData !== undefined &&
      props.google2FaData.status === 200 &&
      props.google2FaData.data != state.google2FaData
    ) {
      return {
        google2FaData: props.google2FaData.data,
        qr_code_url: props.google2FaData.data.qr_code_url,
        google_auth_code: props.google2FaData.data.google_auth_code,
        otp_sent: false,
        otp_email_sent: false,
        showLoader: false,
      };
    }

    if (props.otp_sent !== undefined && props.otp_sent == true) {
      return {
        otp_sent: props.otp_sent,
        phone_otp_verified: false,
        showLoader: false,
      };
    }
    if (
      props.phone_otp_verified !== undefined &&
      props.phone_otp_verified == true
    ) {
      return {
        phone_otp_verified: props.phone_otp_verified,
        showLoader: false,
      };
    }
    if (props.otp_email_sent !== undefined && props.otp_email_sent == true) {
      return {
        otp_email_sent: props.otp_email_sent,
        email_otp_verified: false,
        showLoader: false,
      };
    }
    if (
      props.email_otp_verified !== undefined &&
      props.email_otp_verified == true
    ) {
      return {
        email_otp_verified: props.email_otp_verified,
      };
    }

    if (props.showLoader !== undefined && props.showLoader == true) {
      return {
        otp_sent: props.otp_sent ? props.otp_sent : false,
        otp_email_sent: props.otp_email_sent ? props.otp_email_sent : false,
        phone_number: "",
        email: "",
        showLoader: false,
      };
    }

    if (
      props.reload != undefined &&
      props.reload == true &&
      props.reloadTimeStamp != state.reloadTimeStamp
    ) {
      this.fetchUser(props.userId, "profile");
      return {
        reloadTimeStamp: props.reloadTimeStamp,
      };
    }
    return null;
  }

  componentDidMount() {
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    if (languageData?.settings)
      this.setState({
        profileHeader: languageData.settings["profileHeader"],
        profile_subheader: languageData.settings["profile_subheader"],
        profile_first_name: languageData.settings["profile_first_name"],
        profile_last_name: languageData.settings["profile_last_name"],
        profile_phone: languageData.settings["profile_phone"],
        profile_email: languageData.settings["profile_email"],
        profile_password_subheader:
          languageData.settings["profile_password_subheader"],
        profile_current_password:
          languageData.settings["profile_current_password"],
        profile_new_password: languageData.settings["profile_new_password"],
        profile_repeat_password:
          languageData.settings["profile_repeat_password"],
        profile_personal_signature:
          languageData.settings["profile_personal_signature"],
        profile_signature_popup:
          languageData.settings["profile_signature_popup"],
        profile_sync_google_calendar:
          languageData.settings["profile_sync_google_calendar"],
        sidebar_userRole_menu: languageData.settings["sidebar_userRole_menu"],
        sidebar_users_menu: languageData.settings["sidebar_users_menu"],
        sidebar_teammates_menu: languageData.settings["sidebar_teammates_menu"],
        sidebar_appointmentReminder_menu:
          languageData.settings["sidebar_appointmentReminder_menu"],
        sidebar_patient_menu: languageData.settings["sidebar_patient_menu"],
        sidebar_cancellation_menu: languageData["sidebar_cancellation_menu"],
        sidebar_url_menu: languageData.settings["sidebar_url_menu"],
        sidebar_survey_menu: languageData.settings["sidebar_survey_menu"],
        sidebar_appointment_menu:
          languageData.settings["sidebar_appointment_menu"],
        sidebar_Appointments_menu:
          languageData.settings["sidebar_Appointments_menu"],
        sidebar_procedure_menu: languageData.settings["sidebar_procedure_menu"],
        sidebar_consents_menu: languageData.settings["sidebar_consents_menu"],
        sidebar_questionnaires_menu:
          languageData.settings["sidebar_questionnaires_menu"],
        sidebar_menu_clinics: languageData.settings["sidebar_menu_clinics"],
        sidebar_manageClinics_menu:
          languageData.settings["sidebar_manageClinics_menu"],
        sidebar_AR_menu: languageData.settings["sidebar_AR_menu"],
        sidebar_2FA_menu: languageData.settings["sidebar_2FA_menu"],
        sidebar_profile_menu: languageData.settings["sidebar_profile_menu"],
        sidebar_account_menu: languageData.settings["sidebar_account_menu"],
        user_save_btn_text: languageData.settings["user_save_btn_text"],
        showLoader: true,
        globalLang: languageData.global,

        twoFA_enabled: languageData.settings["twoFA_enabled"],
        twoFA_disabled: languageData.settings["twoFA_disabled"],
        twoFA_header: languageData.settings["twoFA_header"],
        twoFA_subheader: languageData.settings["twoFA_subheader"],
        twoFA_googleAuth_key: languageData.settings["twoFA_googleAuth_key"],
        twoFA_device: languageData.settings["twoFA_device"],
        twoFA_google_auth: languageData.settings["twoFA_google_auth"],
        twoFA_sms_verify: languageData.settings["twoFA_sms_verify"],
        twoFA_email_verify: languageData.settings["twoFA_email_verify"],
        twoFA_email_address: languageData.settings["twoFA_email_address"],
        twoFA_step1: languageData.settings["twoFA_step1"],
        twoFa_Scan_bar: languageData.settings["twoFa_Scan_bar"],
        twoFA_download_link: languageData.settings["twoFA_download_link"],
        twoFA_step2: languageData.settings["twoFA_step2"],
        twoFA_Enter_key: languageData.settings["twoFA_Enter_key"],
        twoFA_otp: languageData.settings["twoFA_otp"],
        twoFA_sms_no: languageData.settings["twoFA_sms_no"],
        clinic_Please_Wait: languageData.settings["clinic_Please_Wait"],
        Two_Factor_Authentication_Change_Add_New_Device:
          languageData.settings[
          "Two_Factor_Authentication_Change_Add_New_Device"
          ],
        twoFA_sms_phoneNo: languageData.settings["twoFA_sms_phoneNo"],
        Two_Factor_Authentication_Change:
          languageData.settings["Two_Factor_Authentication_Change"],
        Two_Factor_Authentication_Click_on_reload_button:
          languageData.settings[
          "Two_Factor_Authentication_Click_on_reload_button"
          ],
        Two_Factor_Authentication_Send_OTP:
          languageData.settings["Two_Factor_Authentication_Send_OTP"],
        Two_Factor_Authentication_OTP_sent_successfully:
          languageData.settings[
          "Two_Factor_Authentication_OTP_sent_successfully"
          ],
        Two_Factor_Authentication_Enter_OTP_to_verify:
          languageData.settings[
          "Two_Factor_Authentication_Enter_OTP_to_verify"
          ],
        Two_Factor_Authentication_Verify:
          languageData.settings["Two_Factor_Authentication_Verify"],
      });

    this.fetchUser(this.state.userId, "profile");
  }

  onDrop(picture) {
    this.setState({
      pictures: this.state.pictures.concat(picture),
    });
  }

  removeItems() {
    this.setState({ icon: "faAngleRight" });
  }

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  clearCanvas = () => {
    if (this._sketch) {
      this._sketch.clear();
      this._sketch.setBackgroundFromDataUrl("");
    }
    this.setState({
      showSignModal: true,
      canvasClass: "signature-box sig-div",
      inputOut: "input-outer no-display",
      imgsrcClass: "img-src no-display",
      clearClass: "new-white-btn no-margin clear",
      resetClass: "new-blue-btn reset ",
      changeClass: "new-blue-btn no-margin Change no-display",
    });
    toggleBodyScroll(true);
  };

  saveSignature = () => {
    if (
      (this._sketch &&
        this._sketch.toJSON().objects.length === 0 &&
        this.state.canvasClass.indexOf("no-display") === -1) ||
      (this.state.canvasClass.indexOf("no-display") > 0 &&
        this.state.signature_url === "")
    ) {
      toast.error(this.state.globalLang.validation_md_signature_required_if);
      return;
    }

    var pngUrl = this._sketch.toDataURL();
    this.setState({ showLoader: true });
    axios
      .post(process.env.REACT_APP_API_URL + "upload-and-save/signature", {
        image_data: pngUrl,
        upload_type: "signatures",
      })
      .then((response) => {
        if (response.data && response.data.status === 200) {
          toast.success(
            this.state.globalLang["signature_" + response.data.message],
          );
          this.setState({
            signature_url: response.data.data.signature_url,
            uploadedSignature_url: response.data.data.signature_url,
            uploadedSignature: response.data.data.file_name,
            signature: response.data.data.file_name,
            inputOut: "input-outer",
            imgsrcClass: "img-src",
            canvasClass: "signature-box sig-div  no-display",
            clearClass: "new-white-btn no-margin clear no-display",
            resetClass: "new-blue-btn reset  no-display",
            changeClass: "new-blue-btn no-margin Change",
          });
        } else {
          toast.error(this.state.globalLang[response.data.message]);
        }
        this.setState({ showLoader: false });
      })
      .catch((error) => {
        toast.error(this.state.globalLang.unknown_error_file_upload);
        this.setState({ showLoader: false });
        //error.response.data
      });
    this.dismissModal();
  };

  handleChildChange = (stateToUpdate) => {
    this.setState(stateToUpdate);
    this.setState({ showLoader: true });
    if (stateToUpdate.showLoader == false) {
      this.setState({ showLoader: false });
    }
  };

  handleReset = () => {
    this.setState({
      inputOut: "input-outer no-display",
      imgsrcClass: "img-src no-display",
      canvasClass: "signature-box sig-div  ",
      clearClass: "new-white-btn no-margin clear no-display",
      resetClass: "new-blue-btn reset no-display",
      changeClass: "new-blue-btn no-margin Change",
    });
  };

  newCanvas = () => {
    this.canvas.clearRect(0, 0, 0, 0);
  };

  clear = () => {
    if (this._sketch) {
      this._sketch.clear();
      this._sketch.setBackgroundFromDataUrl("");
    }
    this.setState({
      controlledValue: null,
      backgroundColor: "transparent",
      fillWithBackgroundColor: false,
      canUndo: this._sketch.canUndo(),
      canRedo: this._sketch.canRedo(),
    });
  };

  enableCalenderSync = () => {
    this.setState({ google_calender_sync: true });
    const goolgeCalendarUrl = this.state.google_oauth_url;
    window.location = goolgeCalendarUrl;
  };

  disableCalenderSync = () => {
    this.setState({ showLoader: true, google_calender_sync: false });
    this.props
      .disableGoogleCalendarSync()
      .then((res) => {
        toast.success(
          this.state.globalLang[res.message] ||
          "Calendar Sync Disabled Successfully",
        );
      })
      .catch((res) => {
        toast.error(
          this.state.globalLang[res.message] ||
          "Unable To Disable Calendar Sync",
        );
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  addNewDevice = () => {
    this.setState({ addNewDevice: true, addNewPhone: false, auth_token: "" });
    this.fetchGoogle2FA({});
  };

  addNewPhone = () => {
    this.setState({ addNewPhone: true, addNewDevice: false });
  };

  phoneNumberChanged1 = (nextPhone) => {
    if (
      this.state.otpNumberError &&
      phoneUtil.isPossible(phoneUtil.compose(nextPhone))
    ) {
      this.setState({
        contactClass1: "newInputField",
        contactError1: false,
        userChanged: true,
        otpNumberError: false,
      });
    }
    this.setState({
      phone_number1: nextPhone,
    });
  };

  sendOtpToNumber = () => {
    if (!phoneUtil.isPossible(phoneUtil.compose(this.state.phone_number1))) {
      this.setState({
        contactClass1: "newInputField field_error",
        otpNumberError: true,
      });
      return uiNotification.error(tCommon("formError.phoneInvalid"));
    }
    this.setState({ showLoader: true, otp: "" });
    this.props
      .sendOTP({ phone_number: phoneUtil.compose(this.state.phone_number1) })
      .then((res) => {
        toast.success(
          this.state.globalLang[res.message] || "OTP Code Sent Successfully",
        );
      })
      .catch((res) => {
        toast.error(
          this.state.globalLang[res.message] || "Unable To Send OTP Code",
        );
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  reloadBarcode = () => {
    this.fetchGoogle2FA({ params: { is_refresh: 1 } });
  };

  verifyOTPGmail = () => {
    if (!this.state.auth_token) {
      this.setState({ authTokenClass: "newInputField  field-error" });
      return;
    } else {
      this.setState({ authTokenClass: "newInputField" });
    }
    this.setState({ showLoader: true });
    this.props
      .verifyGoogleToken({
        google_auth_code: this.state.google_auth_code,
        auth_token: this.state.auth_token,
      })
      .then((res) => {
        toast.success(
          this.state.globalLang[res.message] || "Successfully Verified",
        );
      })
      .catch((res) => {
        toast.error(this.state.globalLang[res.message] || "Unable To Verify");
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  verifyOTPSms = () => {
    if (!this.state.otp) {
      this.setState({ otpClass: "newInputField field-error" });
      return;
    } else {
      this.setState({ otpClass: "newInputField" });
    }
    this.setState({ showLoader: true });
    this.props
      .verifyMobileOTP({
        phone_number: this.state.phone_number1,
        otp: this.state.otp,
      })
      .then((res) => {
        toast.success(
          this.state.globalLang[res.message] ||
          "Successfully Verified OTP Code",
        );
      })
      .catch((res) => {
        toast.error(
          this.state.globalLang[res.message] || "Unable To Verify OTP Code",
        );
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  sendOtpToEmail = () => {
    this.setState({ email_otp_verified: false, email_otp: "" });
    if (isFormSubmit()) {
      let error = false;
      this.setState({
        emailError: false,
      });
      if (
        typeof this.state.email === undefined ||
        this.state.email === null ||
        this.state.email.trim() === ""
      ) {
        this.setState({
          emailError: true,
          emailClass: "newInputField field_error",
        });
        error = true;
      } else if (!validator.isEmail(this.state.email)) {
        this.setState({
          emailError: true,
          emailClass: "newInputField field_error",
        });
        toast.error("Incorrect email address");
        error = true;
      } else if (this.state.email) {
        this.setState({
          emailError: false,
          emailClass: "newInputField",
        });
      }

      if (error === true) {
        return;
      }

      this.setState({ showLoader: true });
      this.props
        .sendEmailOTP({ email_id: this.state.email })
        .then((res) => {
          toast.success(
            this.state.globalLang[res.message] || "OTP Email Sent Successfully",
          );
        })
        .catch((res) => {
          toast.error(
            this.state.globalLang[res.message] || "Unable To Send OTP Email",
          );
        })
        .finally(() => {
          this.setState({ showLoader: false });
        });
    }
  };

  verifyOTPEmail = () => {
    if (!this.state.email_otp) {
      this.setState({ otpEmailClass: "newInputField field-error" });
      return;
    } else {
      this.setState({ otpEmailClass: "newInputField" });
    }
    this.setState({ showLoader: true });
    this.props
      .verifyEmailOTP({ email_id: this.state.email, otp: this.state.email_otp })
      .then((res) => {
        toast.success(
          this.state.globalLang[res.message] || "Successfully Verified",
        );
      })
      .catch((res) => {
        toast.error(this.state.globalLang[res.message] || "Unable To Verify");
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  addNewEmail = () => {
    this.setState({ addEmail: true });
  };

  getPassCode = () => {
    this.setState({ showLoader: true });
    axios
      .get(process.env.REACT_APP_API_URL + `user/check-passcode`)
      .then((res) => {
        this.setState({ pass_code: res.data.data.passcode, showLoader: false });
      })
      .catch(function(error) { });
  };

  render() {
    let enabledDisabledText = "";
    if (this.state.two_factor_auth_status) {
      enabledDisabledText = this.state.twoFA_enabled;
    } else {
      enabledDisabledText = this.state.twoFA_disabled;
    }
    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="setting-setion">
              <div className="nobottommargin" autoComplete="new-password">
                <div className="setting-container">
                  <div className="row">
                    <div className="col-lg-8 col-xs-12">
                      <div className="setting-title">
                        {this.state.profileHeader
                          ? this.state.profileHeader
                          : ""}

                        <a
                          onClick={this.showPassModal}
                          className="easy-link pull-right no-padding-right"
                        >
                          <i className="fa fa-lock"></i>&nbsp; Change password
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-8 col-xs-12 profile-detail-left">
                      <div className="main-profile-picture">
                        {this.state.dbfirstname != "" && (
                          <FileUploader
                            type="profile"
                            uploadedFileName={"user_image"}
                            fileUrl={"user_image_url"}
                            user_image={this.state.user_image}
                            user_image_url={this.state.user_image_url}
                            defLogo={defLogo}
                            logo_url={this.state.user_image_url}
                            handleChildChange={this.handleChildChange}
                            ref="child"
                            containerClass={"dropzone-holder"}
                          />
                        )}
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <div className="newInputFileldOuter m-t-0">
                            <div className="newInputLabel">
                              {this.state.profile_first_name}
                              <span className="setting-require">*</span>
                            </div>

                            <input
                              name="firstname"
                              id="first_name"
                              className={
                                this.state.firstnameError === true
                                  ? this.state.firstnameClassError
                                  : this.state.firstnameClass
                              }
                              placeholder="firstname"
                              maxLength="255"
                              type="text"
                              value={this.state.firstname}
                              onChange={this.handleInputChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="newInputFileldOuter m-t-0">
                            <div className="newInputLabel">
                              {this.state.profile_last_name}
                              <span className="setting-require">*</span>
                            </div>

                            <input
                              name="lastname"
                              placeholder="lastname"
                              className={
                                this.state.lastnameError === true
                                  ? this.state.lastnameClassError
                                  : this.state.lastnameClass
                              }
                              maxLength="255"
                              type="text"
                              value={this.state.lastname}
                              onChange={this.handleInputChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="newInputFileldOuter relative m-t-17">
                            <div className="newInputLabel absolute top--10">
                              {this.state.profile_phone}{" "}
                              <span className="setting-require"></span>
                            </div>

                            {this.state.dbfirstname != "" && (
                              <InputPhone
                                fullWidth
                                variant="legacy-outlined"
                                value={this.state.contact_number_1 || ""}
                                onChange={this.phoneNumberChanged}
                                isError={this.state.contactError}
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              {this.state.profile_email}{" "}
                              <span className="setting-require">*</span>
                            </div>

                            <input
                              name="email_id"
                              className={
                                this.state.email_idError === true
                                  ? this.state.email_idClassError
                                  : this.state.email_idClass
                              }
                              placeholder="email"
                              maxLength="500"
                              type="text"
                              value={this.state.email_id}
                              onChange={this.handleInputChange}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="none"
                              spellCheck="false"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              {"Time Zone"}{" "}
                              <span className="setting-require">*</span>
                            </div>

                            <select
                              name="timezone"
                              className={
                                this.state.timezoneError === true
                                  ? "setting-select-box field-error"
                                  : "newSelectField"
                              }
                              type="text"
                              value={this.state.timezone}
                              onChange={this.handleInputChange}
                            >
                              {this.state.timezones &&
                                this.state.timezones.length > 0 &&
                                this.state.timezones.map((obj, idx) => {
                                  return (
                                    <option key={idx} value={obj.php_timezone}>
                                      {" "}
                                      {obj.timezone}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="newInputFileldOuter passcode">
                            <div className="newInputLabel">
                              Passcode<span className="setting-require">*</span>
                            </div>
                            <div className="setting-input-outer">
                              <input
                                name="pass_code"
                                type="text"
                                className={
                                  this.state.passcodeError === true
                                    ? this.state.passcodeClassError
                                    : this.state.passcodeClass
                                }
                                value={
                                  this.state.pass_code
                                    ? this.state.pass_code
                                    : ""
                                }
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <a
                            id="generate_number"
                            className="new-white-btn pull-right gene-pascod"
                            onClick={this.getPassCode}
                          >
                            Generate Passcode
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-xs-12 sign-box-outer profile-signature">
                      <div
                        className={this.state.canvasClass}
                        id="sig-div"
                      ></div>
                      <div className={this.state.imgsrcClass} id="img-src">
                        <div
                          className={this.state.inputOut}
                          style={{ background: "#fff none repeat scroll 0 0" }}
                        >
                          <img
                            className=""
                            id="signature_image"
                            src={
                              this.state.uploadedSignature_url
                                ? this.state.uploadedSignature_url
                                : this.state.signature_url
                            }
                          />
                        </div>
                      </div>

                      <div className="sig-div">
                        <div className="pull-left"></div>
                      </div>
                      <div className="img-src change-sig">
                        <div className="pull-left">
                          <a
                            className="sign-link"
                            id="change"
                            onClick={() => this.clearCanvas()}
                          >
                            <i className="fa fa-pencil-alt"></i>
                          </a>
                        </div>
                      </div>
                      <div className="sign-switch-outer">
                        <span className="setting-custom-switch pull-right">
                          <span className="switch-text">
                            {this.state.profile_signature_popup}
                          </span>
                          <label
                            className="setting-switch pull-right"
                            htmlFor="show_signature_popup"
                          >
                            <input
                              type="checkbox"
                              name="show_signature_popup"
                              id="show_signature_popup"
                              checked={
                                this.state.show_signature_popup ? "checked" : 0
                              }
                              onChange={this.handleInputChange}
                            />
                            <span className="setting-slider"></span>
                          </label>
                        </span>

                        <span className="setting-custom-switch pull-right syn-wid-google">
                          <span className="switch-text">
                            {this.state.profile_sync_google_calendar}
                          </span>
                          <label className="setting-switch pull-right">
                            <input
                              type="checkbox"
                              name="google_calender_sync"
                              id="google_calender_sync"
                              checked={
                                this.state.google_calender_sync ? "checked" : 0
                              }
                              onChange={
                                this.state.google_calender_sync
                                  ? this.disableCalenderSync
                                  : this.enableCalenderSync
                              }
                            />
                            <span className="setting-slider"></span>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                  {/*  Two Auth START  */}

                  <div className="m-t-40">
                    <div className="setting-title m-b-40">
                      <div>{this.state.twoFA_header}</div>
                      <label className="setting-switch enable-disable-switch no-float m-t-20">
                        <span className="factor_status">
                          {enabledDisabledText}
                        </span>
                        <input
                          type="checkbox"
                          id="two_step"
                          onChange={this.handleInputChange}
                          name="two_factor_auth_status"
                          checked={
                            this.state.two_factor_auth_status
                              ? "checked"
                              : false
                          }
                          className="setting-custom-switch-input"
                        />
                        <span className="setting-slider "></span>
                      </label>
                    </div>
                    <div
                      id="two_factors"
                      className={
                        this.state.two_factor_auth_status
                          ? "ques-radio-right m-b-20"
                          : "ques-radio-right m-b-20 no-display"
                      }
                    >
                      <div className="p-text  m-b-20">
                        {this.state.twoFA_subheader}
                      </div>
                      <div className="row">
                        <div className=" col-lg-3 col-md-4 col-sm-6">
                          <div className="pos-stripe-outer m-b-10 m-t-0">
                            <input
                              type="radio"
                              className="factor-type pos-stripe-input pos-stripe-option"
                              id="type-google"
                              name="verification_type"
                              onChange={this.handleInputChange}
                              value="google"
                              checked={
                                this.state.verification_type == "google"
                                  ? "checked"
                                  : false
                              }
                            />
                            <label
                              className="pos-stripe-discrip"
                              htmlFor="type-google"
                            >
                              <span className="pos-stripe-title authenti-radio-label">
                                {this.state.twoFA_google_auth}
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-4  col-sm-6">
                          <div className="pos-stripe-outer m-b-10 m-t-0">
                            <input
                              type="radio"
                              className="factor-type pos-stripe-input pos-stripe-option"
                              id="type-sms"
                              name="verification_type"
                              onChange={this.handleInputChange}
                              value="sms"
                              checked={
                                this.state.verification_type == "sms"
                                  ? "checked"
                                  : false
                              }
                            />
                            <label
                              className="pos-stripe-discrip"
                              htmlFor="type-sms"
                            >
                              <span className="pos-stripe-title authenti-radio-label">
                                {this.state.twoFA_sms_verify}
                              </span>
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-4  col-sm-6">
                          <div className="pos-stripe-outer m-b-10 m-t-0">
                            <input
                              type="radio"
                              className="factor-type pos-stripe-input pos-stripe-option"
                              id="type-email"
                              name="verification_type"
                              onChange={this.handleInputChange}
                              value="email"
                              checked={
                                this.state.verification_type == "email"
                                  ? "checked"
                                  : false
                              }
                            />
                            <label
                              className="pos-stripe-discrip"
                              htmlFor="type-email"
                            >
                              <span className="pos-stripe-title authenti-radio-label">
                                {this.state.twoFA_email_verify}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        this.state.two_factor_auth_status ? "" : "no-display"
                      }
                    >
                      <div
                        id="google_edit"
                        className={
                          this.state.two_factor_auth_status &&
                            this.state.verification_type == "google" &&
                            this.state.google_auth_code_display != "" &&
                            !this.state.addNewDevice
                            ? "row factor-step setting-container"
                            : "row factor-step setting-container no-display"
                        }
                      >
                        <div className="col-sm-12 m-b-20">
                          <div className="newInputLabel">
                            {this.state.twoFA_googleAuth_key}
                          </div>
                          <div className="setting-input-outer">
                            <div
                              className=" newInputField w-200"
                              placeholder="Enter Token"
                            >
                              {this.state.google_auth_code_display}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <a
                            className="google new-blue-btn m-l-0 m-t-10"
                            onClick={this.addNewDevice}
                          >
                            {
                              this.state
                                .Two_Factor_Authentication_Change_Add_New_Device
                            }
                          </a>
                        </div>
                      </div>
                      <div
                        id="sms_edit"
                        className={
                          this.state.two_factor_auth_status &&
                            this.state.verification_type == "sms" &&
                            this.state.phone_number_display != "" &&
                            !this.state.addNewPhone
                            ? "row factor-step setting-container"
                            : "row factor-step setting-container no-display"
                        }
                      >
                        <div className="col-sm-12 m-b-20">
                          <div className="newInputLabel">
                            {this.state.twoFA_sms_phoneNo}
                          </div>
                          <div className="setting-input-outer">
                            <div
                              className=" newInputField w-200"
                              placeholder="Enter Token"
                            >
                              {this.state.phone_number_display}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <a
                            className="sms new-blue-btn m-l-0 m-t-10"
                            onClick={this.addNewPhone}
                          >
                            {this.state.Two_Factor_Authentication_Change}
                          </a>
                        </div>
                      </div>

                      <div
                        id="email_edit"
                        className={
                          this.state.two_factor_auth_status &&
                            this.state.verification_type == "email" &&
                            this.state.email_display != "" &&
                            !this.state.addEmail
                            ? "row factor-step setting-container"
                            : "row factor-step setting-container no-display"
                        }
                      >
                        <div className="col-sm-12 m-b-20">
                          <div className="newInputLabel">
                            {"Email Address for Email Verification"}
                          </div>
                          <div className="setting-input-outer">
                            <div
                              className=" newInputField w-200"
                              placeholder="Enter Token"
                            >
                              <strong>{this.state.email_display}</strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <a
                            className="sms new-blue-btn m-l-0 m-t-10"
                            onClick={this.addNewEmail}
                          >
                            {this.state.Two_Factor_Authentication_Change}
                          </a>
                        </div>
                      </div>
                      <div
                        className={
                          this.state.verification_type == "google" &&
                            this.state.addNewDevice
                            ? "setting-container factor-step"
                            : "setting-container factor-step no-display"
                        }
                        id="google"
                      >
                        <div className="row">
                          <div className="settings-subtitle m-b-20 col-xs-12">
                            {this.state.twoFA_step1}
                          </div>
                          <div className="col-lg-6 col-xs-12 m-b-40">
                            <p className="setting-text">
                              {this.state.twoFa_Scan_bar}
                            </p>
                            <div className="barcode-img">
                              <img src={this.state.qr_code_url} />
                            </div>
                            <p className="authe-icons">
                              <a
                                className="confirm-model"
                                onClick={this.reloadBarcode}
                              >
                                <i className="fas fa-redo-alt"></i>
                              </a>
                              <a
                                className="barcode-help m-l-10"
                                onClick={() =>
                                  this.setState({
                                    circleText: !this.state.circleText,
                                  })
                                }
                              >
                                <i className="fas fa-info-circle"></i>
                              </a>
                            </p>
                            <p
                              className={
                                this.state.circleText
                                  ? "barcode-title"
                                  : "no-display"
                              }
                            >
                              {
                                this.state
                                  .Two_Factor_Authentication_Click_on_reload_button
                              }
                            </p>
                          </div>
                          <div className="col-lg-6 col-xs-12 m-b-40">
                            <p className="authe-discription">
                              {this.state.twoFA_download_link}
                            </p>
                            <span className="twofas-light-icons">
                              <a
                                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                target="_blank"
                                rel="noopener"
                              >
                                <img
                                  src="/images/google-play.png"
                                  alt="Google Authenticator - Google Play"
                                />
                              </a>
                              <a
                                className="m-l-10"
                                href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
                                target="_blank"
                                rel="noopener"
                              >
                                <img
                                  src="/images/app-store.png"
                                  alt="Google Authenticator - App Store"
                                />
                              </a>
                            </span>
                          </div>
                          <div className="col-xs-12">
                            <div className="settings-subtitle m-b-10">
                              {this.state.twoFA_step2}
                            </div>
                            <div className="row">
                              <div className="col-md-6 m-b-20">
                                <div className="newInputFileldOuter">
                                  <div className="newInputLabel">
                                    {this.state.twoFA_Enter_key}
                                  </div>
                                  <div className="setting-input-outer">
                                    <input
                                      id="auth_token"
                                      className={this.state.authTokenClass}
                                      placeholder="Enter Token"
                                      autoComplete="off"
                                      name="auth_token"
                                      type="text"
                                      onChange={this.handleInputChange}
                                      value={this.state.auth_token}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-xs-12">
                                <a
                                  id="verify_google_token"
                                  onClick={this.verifyOTPGmail}
                                  className="new-blue-btn m-l-0 m-t-10"
                                >
                                  {this.state.Two_Factor_Authentication_Verify}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          this.state.verification_type == "sms" &&
                            this.state.addNewPhone
                            ? "factor-step setting-container"
                            : "row factor-step setting-container no-display"
                        }
                        id="sms"
                      >
                        <div className="row">
                          <div className="col-md-6 col-xs-12">
                            <div className="setting-field-outer">
                              <span className="authe-discription">
                                {this.state.twoFA_otp}
                              </span>
                              <div className="newInputFileldOuter m-b-20">
                                <div className="newInputLabel">
                                  {this.state.twoFA_sms_no}
                                </div>
                                <div className="intl-tel-input allow-dropdown">
                                  {this.state.addNewPhone && (
                                    <InputPhone
                                      fullWidth
                                      variant="legacy-outlined"
                                      value={this.state.phone_number1 || ""}
                                      onChange={this.phoneNumberChanged1}
                                      isError={this.state.otpNumberError}
                                    />
                                  )}
                                </div>
                              </div>
                              <a
                                id="validate_phone"
                                className={"new-blue-btn m-l-0 m-t-10"}
                                onClick={this.sendOtpToNumber}
                              >
                                {this.state.phone_otp_verified
                                  ? "Resend OTP"
                                  : this.state
                                    .Two_Factor_Authentication_Send_OTP}
                              </a>
                            </div>
                          </div>
                          <div
                            className={
                              this.state.otp_sent
                                ? "col-md-6 col-xs-12"
                                : "col-md-6 col-xs-12 no-display"
                            }
                          >
                            <div id="smstwostep">
                              <span className="authe-discription">
                                {
                                  this.state
                                    .Two_Factor_Authentication_OTP_sent_successfully
                                }
                              </span>
                              <div className="newInputFileldOuter m-b-25">
                                <div className="newInputLabel">
                                  {
                                    this.state
                                      .Two_Factor_Authentication_Enter_OTP_to_verify
                                  }
                                </div>
                                <input
                                  name="otp"
                                  id="sms_otp"
                                  className={
                                    this.state.phone_otp_verified
                                      ? "newInputField disable"
                                      : this.state.otpClass
                                  }
                                  placeholder="Enter OTP"
                                  type="text"
                                  autoComplete="off"
                                  onChange={this.handleInputChange}
                                  value={this.state.otp}
                                />
                              </div>
                              <a
                                id="verify_sms_otp"
                                type="button"
                                className={
                                  this.state.phone_otp_verified
                                    ? "no-display"
                                    : "new-blue-btn m-l-0 m-t-10"
                                }
                                onClick={this.verifyOTPSms}
                              >
                                {this.state.Two_Factor_Authentication_Verify}
                              </a>
                              <div
                                className={
                                  this.state.phone_otp_verified
                                    ? "twoFector-OTP-Verified"
                                    : "no-display"
                                }
                              >
                                <i className="fa fa-check-circle"></i> Phone
                                Number OTP Verified Successfully
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          this.state.verification_type == "email" &&
                            this.state.addEmail
                            ? "factor-step setting-container"
                            : "row factor-step setting-container no-display"
                        }
                        id="email"
                      >
                        <div className="row">
                          <div className="col-md-6 col-xs-12">
                            <div className="setting-field-outer">
                              <span className="authe-discription">
                                {"Please enter email to receive OTP via Email"}
                              </span>
                              <div className="newInputFileldOuter m-b-25">
                                <div className="newInputLabel">
                                  {"Please Enter Your Email"}
                                </div>
                                <input
                                  type="text"
                                  className={this.state.emailClass}
                                  name="email"
                                  id="email"
                                  placeholder="Email"
                                  autoComplete="off"
                                  onChange={this.handleInputChange}
                                  value={this.state.email}
                                />
                              </div>
                              <a
                                id="validate_email"
                                className="new-blue-btn m-l-0 m-t-10"
                                onClick={this.sendOtpToEmail}
                              >
                                {this.state.otp_email_sent
                                  ? "Resend OTP"
                                  : this.state
                                    .Two_Factor_Authentication_Send_OTP}
                              </a>
                            </div>
                          </div>
                          <div
                            className={
                              this.state.otp_email_sent
                                ? "col-md-6 col-xs-12"
                                : "col-md-6 col-xs-12 no-display"
                            }
                          >
                            <div id="smstwostep">
                              <span className="authe-discription">
                                {
                                  "OTP sent successfully to the email you have provided"
                                }
                              </span>
                              <div className="newInputFileldOuter m-b-25">
                                <div className="newInputLabel">
                                  {
                                    this.state
                                      .Two_Factor_Authentication_Enter_OTP_to_verify
                                  }
                                </div>
                                <input
                                  name="email_otp"
                                  id="email_otp"
                                  className={
                                    this.state.email_otp_verified
                                      ? "newInputField disable"
                                      : this.state.otpEmailClass
                                  }
                                  placeholder="Enter OTP"
                                  type="text"
                                  autoComplete="off"
                                  readOnly={
                                    this.state.email_otp_verified ? true : false
                                  }
                                  onChange={this.handleInputChange}
                                  value={this.state.email_otp}
                                />
                              </div>
                              {
                                <a
                                  id="verify_email_otp"
                                  type="button"
                                  className={
                                    this.state.email_otp_verified
                                      ? "no-display"
                                      : "new-blue-btn m-l-0 m-t-10"
                                  }
                                  onClick={this.verifyOTPEmail}
                                >
                                  {this.state.Two_Factor_Authentication_Verify}
                                </a>
                              }
                              <div
                                className={
                                  this.state.email_otp_verified
                                    ? "twoFector-OTP-Verified"
                                    : "no-display"
                                }
                              >
                                <i className="fa fa-check-circle"></i> Email OTP
                                Verified Successfully
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 m-b-45">
                    <InputLabel>Favourite Membership Plans</InputLabel>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      size="small"
                      value={this.state.favouriteMembershipTiers}
                      options={this.state.membershipTiers}
                      onChange={(options) =>
                        this.setState({ favouriteMembershipTiers: options })
                      }
                    />
                  </div>

                  {/*  Two Auth END  */}

                  <div className="inline-table full-width">
                    <SmsApptNotifications />
                  </div>

                </div>
                <div className="footer-static">
                  <button
                    className="new-blue-btn pull-right"
                    id="save-profile"
                    onClick={this.handleSubmit}
                  >
                    {this.state.user_save_btn_text}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*Pop-UP Starts  (conditional render used for password and signature )*/}
          <div
            className={
              this.state.showPassModal || this.state.showSignModal
                ? "blackOverlay"
                : "blackOverlay no-display"
            }
          >
            <div className="vert-middle vert-middle-report-option">
              <div
                className={
                  this.state.showPassModal
                    ? "white-popup xs-popup"
                    : "white-popup small-popup"
                }
              >
                <div className="white-popup-wrapper">
                  <div className="membershipTypeTitle">
                    {this.state.showPassModal
                      ? "Change Password"
                      : "Edit Signature"}
                    <a
                      onClick={
                        this.state.showPassModal
                          ? this.dismissPassModal
                          : this.dismissModal
                      }
                      className="popupClose"
                    >
                      <img src={picClose} />
                    </a>
                  </div>
                  {this.state.showPassModal ? (
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">
                            {this.state.profile_current_password}{" "}
                            <span className="setting-require">*</span>
                          </div>
                          <input
                            name="old_password"
                            type="password"
                            className={
                              this.state.oldPassError === true
                                ? this.state.oldPasswordClassError
                                : this.state.oldPasswordClass
                            }
                            value={this.state.old_password}
                            onChange={this.handleInputChange}
                            autoComplete="new-password"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">
                            {this.state.profile_new_password}
                            <span className="setting-require">*</span>
                          </div>
                          <input
                            name="password"
                            type="password"
                            className={
                              this.state.passwordError == true
                                ? this.state.passwordClassError
                                : this.state.passwordClass
                            }
                            value={this.state.password}
                            onChange={this.handleInputChange}
                            autoComplete="new-password"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">
                            {this.state.profile_repeat_password}{" "}
                            <span className="setting-require">*</span>
                          </div>

                          <input
                            name="repeat_password"
                            type="password"
                            className={
                              this.state.repeatpasswordError === true
                                ? this.state.repeatpasswordClassError
                                : this.state.repeatpasswordClass
                            }
                            value={this.state.repeat_password}
                            onChange={this.handleInputChange}
                            autoComplete="new-password"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-xs-12">
                        {this.state.showSignModal == true && (
                          <div className={this.state.canvasClass} id="sig-div">
                            <SketchField
                              width="400px"
                              ref={(c) => (this._sketch = c)}
                              height="200px"
                              tool={Tools.Pencil}
                              lineColor="black"
                              lineWidth={6}
                            />
                          </div>
                        )}
                        <div className="sig-div">
                          <div className="pull-left"></div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="footer-static">
                  {this.state.showPassModal ? (
                    <div>
                      <a
                        className="new-blue-btn pull-right"
                        onClick={this.handlePassSubmit}
                      >
                        {this.state.user_save_btn_text}
                      </a>
                      <a
                        className="new-white-btn pull-right"
                        onClick={this.dismissPassModal}
                      >
                        {this.state.globalLang.label_cancel}
                      </a>
                    </div>
                  ) : (
                    <div className="pull-right">
                      <button
                        type="button"
                        id="change1"
                        onClick={this.clear}
                        className={this.state.clearClass}
                      >
                        Clear
                      </button>
                      <button
                        type="button"
                        id="change2"
                        onClick={this.dismissModal}
                        className={this.state.resetClass}
                      >
                        Reset
                      </button>
                      <button
                        type="button"
                        id="change3"
                        onClick={this.saveSignature}
                        className={this.state.resetClass}
                      >
                        Save Signature
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*popup Ends*/}
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const returnState = {};

  if (state.SettingReducer.action === "PROFILE_GET") {
    returnState.user = state.SettingReducer.data;
  } else if (state.SettingReducer.action === "PROFILE_UPDATE") {
    if (state.SettingReducer.data.status === 200) {
      returnState.user = state.SettingReducer.data;
      if (
        state.SettingReducer.data.data !== undefined &&
        state.SettingReducer.data.data.login_data !== undefined
      ) {
        let login_data = state.SettingReducer.data.data.login_data;
        if (login_data && login_data.user_role_id > 0) {
          let userData = JSON.parse(localStorage.getItem("userData"));
          userData.user = login_data;
          localStorage.setItem("currentUserRole", login_data.user_role_id);
          localStorage.setItem("userData", JSON.stringify(userData));
          returnState.status = 200;
        }
      }
    }
  } else if (state.SettingReducer.action === "USER_2FA_DISABLED") {
    if (state.SettingReducer.data.status === 200) {
      return {
        otp_sent: false,
        otp_email_sent: false,
      };
    }
  } else if (state.SettingReducer.action === "GOOGLE_2FA_GET") {
    if (state.SettingReducer.data.status === 200) {
      return {
        google2FaData: state.SettingReducer.data,
      };
    }
  } else if (state.SettingReducer.action === "GOOGLE_2FA_VERIFY") {
    if (state.SettingReducer.data.status === 200) {
      return {
        user2FaData: state.SettingReducer.data,
        timestamp: new Date(),
      };
    }
  } else if (state.SettingReducer.action === "SEND_OTP") {
    if (state.SettingReducer.data.status === 200) {
      return { otp_sent: true };
    }
  } else if (state.SettingReducer.action === "VERIFY_OTP") {
    if (state.SettingReducer.data.status === 200) {
      return { phone_otp_verified: true };
    }
  } else if (state.SettingReducer.action === "SEND_EMAIL_OTP") {
    if (state.SettingReducer.data.status === 200) {
      return { otp_email_sent: true };
    }
  } else if (state.SettingReducer.action === "VERIFY_EMAIL_OTP") {
    if (state.SettingReducer.data.status === 200) {
      return { email_otp_verified: true };
    }
  } else if (state.SettingReducer.action === "REFRESH_PROFILE_PAGE") {
    if (state.SettingReducer.data.status === 200) {
      return {
        userId: state.SettingReducer.data.data.user_id,
        reload: true,
        reloadTimeStamp: new Date(),
      };
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      uploadImage,
      getUser,
      userProfile,
      uploadBase64Image,
      disableGoogleCalendarSync,
      exportEmptyData,
      get2FA,
      disable2FA,
      getGoogle2FA,
      verifyGoogleToken,
      sendOTP,
      verifyMobileOTP,
      sendEmailOTP,
      verifyEmailOTP,
      updateHeader,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
