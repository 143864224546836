/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import cx from "clsx";
import { toast } from "react-toastify";
import "./leads.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as FaIcons from "@fortawesome/free-solid-svg-icons";
import {
  fetchLeads,
  deleteLead,
  bookConvertOrMessageLead,
  resetLeadCount,
} from "../../Actions/Leads/leadsActions";
import Loader from "../../Components/Common/Loader";
import { MultiSelectFilter } from "../Common/MultiSelectFilter";
import { getTwilioConfig } from "../../Utils/services";
import LeadsMessageModal from "./LeadsMessageModal";
import { DateRangePicker } from "../../shared/DateRangePicker/DateRangePicker";
import { withCurrentUserQuery } from "../../api/queries/useUserQuery";
import { ButtonDownloadExcel } from "./shared/ButtonDownloadExcel";
import { withBulkDelete } from "./hocs/withBulkDelete";
import { Checkbox } from "../../shared/Checkbox/Checkbox";
import { shortenClinicName } from "../../helpers/general";

class Leads extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      languageData,
      sortby: "created_at",
      sortorder: "desc",
      pagination: {},
      filters: {},
      leads: [],
      term: "",
      letter_key: "",
      selectedClinic: new Set(),
      selectedSource: new Set(),
      selectedOptIn: new Set(),
      leadMessage: "",
      leadMessageModalOpen: false,
      leadSource: "",
      period: {
        from: null,
        to: null,
      },
    };
  }

  loadMore = () => {
    if (!this.isLoadingLeads() && !this.state.pagination.is_last_page) {
      this.fetchLeads(this.state.pagination.current_page + 1);
    }
  };

  observer = null;
  loadMoreButton = React.createRef();

  componentDidMount() {
    this.fetchLeads();
    this.props.resetLeadCount();

    this.observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry && entry.isIntersecting) {
        this.loadMore();
      }
    });
    this.observer.observe(this.loadMoreButton.current);
  }

  componentWillUnmount() {
    this.observer.unobserve(this.loadMoreButton.current);
  }

  componentDidUpdate(prevProps) {
    const newLeadsStatus = this.props.fetchLeadsStatus;
    const oldLeadsStatus = prevProps.fetchLeadsStatus;

    if (
      oldLeadsStatus.status === "loading" &&
      newLeadsStatus.status === "success"
    ) {
      const pagination = newLeadsStatus.data.pagination;
      let leads = newLeadsStatus.data.leads;

      if (!newLeadsStatus.data.pagination.is_first_page) {
        leads = [...this.state.leads, ...newLeadsStatus.data.leads];
      }

      const state = { pagination, leads };

      if (Object.keys(this.state.filters).length === 0) {
        state.filters = newLeadsStatus.data.filters;
      }

      this.setState(state);
    }

    const newDeleteStatus = this.props.deleteLeadsStatus;
    const oldDeleteStatus = prevProps.deleteLeadsStatus;

    if (
      oldDeleteStatus.status === "loading" &&
      newDeleteStatus.status === "success"
    ) {
      this.setState({
        leads: this.state.leads.filter(
          (l) => !newDeleteStatus?.data?.includes?.(l.id),
        ),
      });
      toast.success("Lead deleted");
    }

    if (
      oldDeleteStatus.status === "loading" &&
      newDeleteStatus.status === "error"
    ) {
      toast.error(newDeleteStatus.error);
    }

    const newBookConvertOrMessageStatus =
      this.props.bookConvertOrMessageLeadStatus;
    const oldBookConvertOrMessageStatus =
      prevProps.bookConvertOrMessageLeadStatus;

    if (
      oldBookConvertOrMessageStatus.status === "loading" &&
      newBookConvertOrMessageStatus.status === "success"
    ) {
      if (newBookConvertOrMessageStatus.data.ids) {
        this.setState({
          leads: this.state.leads.filter(
            (l) => !newBookConvertOrMessageStatus.data.ids.includes(l.id),
          ),
        });
      }
      window.location.replace(newBookConvertOrMessageStatus.data.redirect);
    }

    if (
      oldBookConvertOrMessageStatus.status === "loading" &&
      newBookConvertOrMessageStatus.status === "error"
    ) {
      toast.error(newBookConvertOrMessageStatus.error);
    }
  }

  isLoading = () =>
    this.props.fetchLeadsStatus.status === "loading" ||
    this.props.deleteLeadsStatus.status === "loading" ||
    this.props.bookConvertOrMessageLeadStatus.status === "loading";

  isLoadingLeads = () => this.props.fetchLeadsStatus.status === "loading";

  isLoadMoreDisabled = () =>
    this.isLoadingLeads() || this.state.pagination.is_last_page;

  formatOptInOption(optInOption) {
    return Number.parseInt(optInOption) ? "Yes" : "No";
  }

  fetchLeads = (page = 1) => {
    this.props.fetchLeads(
      page,
      this.state.sortby,
      this.state.sortorder,
      this.getFilters(),
      this.state.period.from,
      this.state.period.to,
    );
  };

  onLetterKeyChange = (letter_key) => {
    this.setState({ letter_key }, this.fetchLeads);
    this.props.bulkDelete.reset();
  };

  onClinicChange = (selectedClinic) => {
    this.setState({ selectedClinic }, this.fetchLeads);
    this.props.bulkDelete.reset();
  };

  onSourceChange = (selectedSource) => {
    this.setState({ selectedSource }, this.fetchLeads);
    this.props.bulkDelete.reset();
  };

  onPeriodChange = ({ startDate, endDate }) => {
    this.setState(
      {
        period: {
          from: startDate,
          to: endDate,
        },
      },
      this.fetchLeads,
    );
    this.props.bulkDelete.reset();
  };

  onPeriodClear = () => {
    this.setState(
      {
        period: {
          from: null,
          to: null,
        },
      },
      this.fetchLeads,
    );
    this.props.bulkDelete.reset();
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.fetchLeads();
    this.props.bulkDelete.reset();
  };

  handleTermChange = (e) => {
    this.setState({ term: e.target.value });
  };

  getFilters = () => {
    return {
      opt_in: [...this.state.selectedOptIn].map((o) => o.value),
      source: [...this.state.selectedSource].map((o) => o.value),
      clinics: [...this.state.selectedClinic].map((o) => o.value),
      letter_key: this.state.letter_key,
      term: this.state.term,
    };
  };

  renderLeadsRows = () => {
    if (this.state.leads.length === 0) {
      return (
        <tr>
          <td className="table-updated-td text-center" colSpan={100}>
            No Leads
          </td>
        </tr>
      );
    }

    return this.state.leads.map((lead) => {
      return (
        <tr key={lead.id}>
          {this.props.bulkDelete.isPermitted && (
            <td className="table-updated-td">
              <Checkbox
                size="small"
                isChecked={this.props.bulkDelete.getIsSelected(lead.id)}
                onChange={() => this.props.bulkDelete.toggleSelected(lead.id)}
                className="m-0-auto"
              />
            </td>
          )}
          <td className="table-updated-td break-all text-nowrap">
            {lead.first_name}
          </td>
          <td className="table-updated-td break-all text-nowrap">
            {lead.last_name}
          </td>
          <td className="table-updated-td break-all text-nowrap">
            {shortenClinicName(lead.clinic_name)}
          </td>
          <td className="table-updated-td break-all text-nowrap">
            {lead.email}
          </td>
          <td className="table-updated-td break-all text-nowrap">
            {lead.client_phone_number}
          </td>
          <td className="table-updated-td break-all text-nowrap">
            {lead.source_name}
          </td>
          <td className="table-updated-td break-all lead-table-created-column text-nowrap">
            {lead.created_at}
          </td>
          <td className="table-updated-td break-all lead-table-action-column text-nowrap">
            <div className="leads-action-list">
              <button
                className="leads-action-button"
                title="Book Now"
                onClick={() =>
                  this.props.bookConvertOrMessageLead("book", lead.id)
                }
              >
                <FontAwesomeIcon icon={FaIcons.faCalendarAlt} />
              </button>
              <button
                className="leads-action-button"
                title="Add to Patients"
                onClick={() =>
                  this.props.bookConvertOrMessageLead("convert", lead.id)
                }
              >
                <FontAwesomeIcon icon={FaIcons.faCheckSquare} />
              </button>
              <button
                className="leads-action-button"
                title="Delete Lead"
                onClick={() => this.props.deleteLead(lead.id)}
              >
                <FontAwesomeIcon icon={FaIcons.faTimes} />
              </button>
              {getTwilioConfig() && (
                <button
                  className="leads-action-button"
                  title="2-Way Text"
                  onClick={() =>
                    this.props.bookConvertOrMessageLead("message", lead.id)
                  }
                >
                  <FontAwesomeIcon icon={FaIcons.faComments} />
                </button>
              )}
              {lead.message && (
                <button
                  className="leads-action-button"
                  title="More Info"
                  onClick={() =>
                    this.setState({
                      leadMessage: lead.message,
                      leadMessageModalOpen: true,
                      leadSource: lead.source_name,
                    })
                  }
                >
                  <FontAwesomeIcon icon={FaIcons.faEnvelopeOpenText} />
                </button>
              )}
              {lead.file && (
                <a
                  className="leads-action-button"
                  href={lead.file}
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Questionnaire"
                >
                  <FontAwesomeIcon icon={FaIcons.faClipboardList} />
                </a>
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  searchFilterItems = (search, item) => {
    return item.label?.toLowerCase()?.includes(search.toLowerCase());
  };

  onSort = (sortBy) => {
    let sortOrder = this.state.sortorder === "asc" ? "desc" : "asc";
    this.setState(
      {
        sortby: sortBy,
        sortorder: sortOrder,
      },
      () => {
        this.fetchLeads(1);
      },
    );
  };

  renderTableHead = () => {
    return (
      <tr>
        {this.props.bulkDelete.isPermitted && (
          <th className="table-updated-th sorting sortData text-nowrap th-sticky" />
        )}
        <th
          className="table-updated-th sorting sortData text-nowrap th-sticky"
          onClick={() => this.onSort("firstname")}
        >
          First Name{" "}
          <i
            className={
              this.state.sortorder === "asc" &&
              this.state.sortby === "firstname"
                ? "blue-gray"
                : this.state.sortorder === "desc" &&
                  this.state.sortby === "firstname"
                ? "gray-blue"
                : "gray-gray"
            }
          />
        </th>
        <th
          className="table-updated-th sorting sortData text-nowrap th-sticky"
          onClick={() => this.onSort("lastname")}
        >
          Last Name{" "}
          <i
            className={
              this.state.sortorder === "asc" && this.state.sortby === "lastname"
                ? "blue-gray"
                : this.state.sortorder === "desc" &&
                  this.state.sortby === "lastname"
                ? "gray-blue"
                : "gray-gray"
            }
          />
        </th>
        <th className="table-updated-th text-nowrap th-sticky">Location</th>
        <th
          className="table-updated-th sorting text-nowrap th-sticky"
          onClick={() => this.onSort("email")}
        >
          Email{" "}
          <i
            className={
              this.state.sortorder === "asc" && this.state.sortby === "email"
                ? "blue-gray"
                : this.state.sortorder === "desc" &&
                  this.state.sortby === "email"
                ? "gray-blue"
                : "gray-gray"
            }
          />
        </th>
        <th
          className="table-updated-th sorting text-nowrap th-sticky"
          onClick={() => this.onSort("phone_number")}
        >
          Phone Number{" "}
          <i
            className={
              this.state.sortorder === "asc" && this.state.sortby === "email"
                ? "blue-gray"
                : this.state.sortorder === "desc" &&
                  this.state.sortby === "email"
                ? "gray-blue"
                : "gray-gray"
            }
          />
        </th>
        <th
          className="table-updated-th sorting text-nowrap th-sticky"
          onClick={() => this.onSort("source")}
        >
          Source{" "}
          <i
            className={
              this.state.sortorder === "asc" && this.state.sortby === "email"
                ? "blue-gray"
                : this.state.sortorder === "desc" &&
                  this.state.sortby === "email"
                ? "gray-blue"
                : "gray-gray"
            }
          />
        </th>
        <th
          className="table-updated-th sorting text-nowrap th-sticky"
          onClick={() => this.onSort("created_at")}
        >
          Create On{" "}
          <i
            className={
              this.state.sortorder === "asc" &&
              this.state.sortby === "created_at"
                ? "blue-gray"
                : this.state.sortorder === "desc" &&
                  this.state.sortby === "created_at"
                ? "gray-blue"
                : "gray-gray"
            }
          />
        </th>
        <th className="table-updated-th text-center w-220 text-nowrap th-sticky">
          Action
        </th>
      </tr>
    );
  };

  renderFilters = () => {
    const dateFormat = this.props.currentUserQuery.data?.dateFormat;

    return (
      <div className="leads-filters-container">
        <div className="filter-outer">
          <div className="leads-filters">
            <div className="row">
              <div className="total-patients col-sm-2">
                <label className="patient-count">
                  Leads:{" "}
                  <b id="customers_count">
                    {this.state.pagination.total || "0"}
                  </b>
                </label>
              </div>
              <div className="col-sm-10 text-center srch-outer srch-wid-alpha d-flex align-center justify-end flex-wrap">
                <ButtonDownloadExcel
                  page={this.state.pagination.current_page}
                  sortBy={this.state.sortby}
                  order={this.state.sortorder}
                  filters={this.getFilters()}
                  period={{
                    from: this.state.period.from,
                    to: this.state.period.to,
                  }}
                />
                <DateRangePicker
                  size="small"
                  dateFormat={dateFormat}
                  onClear={this.onPeriodClear}
                  onChange={this.onPeriodChange}
                  value={{
                    startDate: this.state.period.from,
                    endDate: this.state.period.to,
                  }}
                />
                <div className="leads-filter-icon pull-right">
                  <i className="fa fa-filter" />
                </div>
                <MultiSelectFilter
                  title="Clinic"
                  data={this.state.filters.clinics}
                  getDisplayValue={(i) => i.label}
                  onChange={this.onClinicChange}
                  selectedItems={this.state.selectedClinic}
                  searchItems={this.searchFilterItems}
                  className="leads-filter-item pull-right"
                  btnClassName="m-b-0"
                />
                <MultiSelectFilter
                  title="Source"
                  data={this.state.filters.source}
                  getDisplayValue={(i) => i.label}
                  onChange={this.onSourceChange}
                  selectedItems={this.state.selectedSource}
                  searchItems={this.searchFilterItems}
                  className="leads-filter-item pull-right"
                  btnClassName="m-b-0"
                />
                <div className="navbar-form search search-drop-down relative pull-right">
                  <form onSubmit={this.onSearchSubmit}>
                    <div className="field-outer search-bg">
                      <i className="fas fa-search" />
                      <input
                        className="setting-search-input search-key"
                        data-url="/clients"
                        name="term"
                        placeholder={this.state.languageData.client_search}
                        value={this.state.term}
                        onChange={this.handleTermChange}
                        autoComplete="off"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="d-flex align-center justify-between m-t-8">
              {this.props.bulkDelete.isPermitted && (
                <button
                  className={cx("easy-link text-no-wrap", {
                    "disabled-easy-link": this.props.bulkDelete.count === 0,
                  })}
                  onClick={() =>
                    this.props.bulkDelete.request(() => {
                      this.fetchLeads();
                    })
                  }
                  disabled={this.props.bulkDelete.count === 0}
                >
                  Bulk Delete ({this.props.bulkDelete.count})
                </button>
              )}
              <ul className="patient-alpha profile-toggler">
                <li>
                  <a
                    onClick={() => this.onLetterKeyChange("")}
                    className={
                      this.state.letter_key === ""
                        ? "letter caps-all alpha-select"
                        : "letter caps-all"
                    }
                  >
                    {this.state.languageData.clients.client_all}
                  </a>
                </li>
                {[
                  "a",
                  "b",
                  "c",
                  "d",
                  "e",
                  "f",
                  "g",
                  "h",
                  "i",
                  "j",
                  "k",
                  "l",
                  "m",
                  "n",
                  "o",
                  "p",
                  "q",
                  "r",
                  "s",
                  "t",
                  "u",
                  "v",
                  "w",
                  "x",
                  "y",
                  "z",
                ].map((l) => (
                  <li key={l}>
                    <a
                      onClick={() => this.onLetterKeyChange(l)}
                      className={
                        this.state.letter_key === l
                          ? "letter alpha-select"
                          : "letter"
                      }
                    >
                      {this.state.languageData.clients[`client_alpha_${l}`]}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <div id="content">
          <div className="container-fluid content setting-wrapper-leads">
            <Loader showLoader={this.isLoading()} isFullWidth />
            {this.renderFilters()}
            <div className="table-responsive patient-responsive profile-toggler overflow-x-scroll p-t-0 z-index-9">
              <table className="table-updated juvly-table min-w-1000 leads-table">
                <thead className="table-updated-thead mobile-patient-head">
                  {this.renderTableHead()}
                </thead>
                <tbody className="ajax_body">{this.renderLeadsRows()}</tbody>
              </table>
              <div
                ref={this.loadMoreButton}
                className="text-center p-t-15 p-b-15"
              >
                <button
                  onClick={this.loadMore}
                  className={`btn btn-default btn-sm ${
                    this.isLoadMoreDisabled() && "disable"
                  } ${!this.state.leads.length && "no-display"}`}
                >
                  {this.isLoadingLeads()
                    ? "Loading Leads"
                    : this.state.pagination.is_last_page
                    ? "No More Leads"
                    : "Load More Leads"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <LeadsMessageModal
          isOpen={this.state.leadMessageModalOpen}
          title={""}
          message={this.state.leadMessage}
          cancelFn={() => this.setState({ leadMessageModalOpen: false })}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetchLeadsStatus: state.LeadsReducer.leads,
    deleteLeadsStatus: state.LeadsReducer.deleteLead,
    bookConvertOrMessageLeadStatus: state.LeadsReducer.bookConvertOrMessageLead,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { fetchLeads, deleteLead, bookConvertOrMessageLead, resetLeadCount },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCurrentUserQuery(withBulkDelete(Leads)));
