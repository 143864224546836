import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../../../consts/api";
import { http } from "../../../../services/HttpService";

const composeResponse = (res) => {
  const result = {
    card: {
      available: res.data.data.card?.available,
      maxAmount: res.data.data.card?.max_payable_amount,
    },
    cash: {
      available: res.data.data.cash?.available,
      maxAmount: res.data.data.cash?.max_payable_amount,
    },
    careCredit: {
      available: res.data.data.careCredit?.available,
      maxAmount: res.data.data.careCredit?.max_payable_amount,
    },
    cherry: {
      available: res.data.data.cherry?.available,
      maxAmount: res.data.data.cherry?.max_payable_amount,
    },
    check: {
      available: res.data.data.check?.available,
      maxAmount: res.data.data.check?.max_payable_amount,
    },
    giftCard: {
      available: res.data.data.giftCard?.available,
      maxAmount: res.data.data.giftCard?.max_payable_amount,
    },
    greenSky: {
      available: res.data.data.greensky?.available,
      maxAmount: res.data.data.greensky?.max_payable_amount,
    },
    klarna: {
      available: res.data.data.klarna?.available,
      maxAmount: res.data.data.klarna?.max_payable_amount,
    },
    text2pay: {
      available: res.data.data.text2pay?.available,
      maxAmount: res.data.data.text2pay?.max_payable_amount,
    },
    wallet: {
      available: res.data.data.wallet?.available,
      maxAmount: res.data.data.wallet?.max_payable_amount,
    },
  };
  return result;
};

export const useAvailablePaymentMethods = (invoiceId, options) => {
  return useQuery(
    ["availablePaymentMethods"],
    async () => {
      const res = await http.get(
        HTTP_ENDPOINTS.getAvailablePaymentMethods(invoiceId),
      );
      return composeResponse(res);
    },
    {
      ...options,
      cacheTime: 0,
    },
  );
};
