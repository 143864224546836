import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { ModalCharge } from "../../../../shared/ModalCharge/ModalCharge";
import { dispatch } from "../../../../../../../store/store";
import { checkoutInvoice } from "../../../../../../../store/checkoutInvoice";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../../shared/InputCurrency/InputCurrency";
import { Select } from "../../../../../../../shared/Select/Select";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import Loader from "../../../../../../../Components/Common/Loader";
import {
  getStripePaymentIntent,
  getClientCardDataStripe,
} from "../../../../../../../Actions/Stripe/StripeActions";
import ViewTipsModal from "../../../ViewTipsModal/ViewTipsModal";
import { useInvoiceActions } from "../../../../hooks/useInvoiceActions";
import { usePaymentFlow } from "../../../../hooks/usePaymentFlow";

const FormCardOnFile = ({
  cardList,
  getStripePaymentIntent,
  type,
  isTipsApplying,
}) => {
  const { tSales } = useAppTranslation.Sales();
  const { invoice, amountToPay, refetchInvoice, isStartStage, isTipsEnabled } =
    useInvoice();
  const { paymentViewClose } = useInvoiceActions();
  const { tipsOnlyFlow } = usePaymentFlow();

  const [cardError, setCardError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [cardSelected, setCardSelected] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(amountToPay || "");
  const [showTipsModal, setShowTipsModal] = useState(false);

  const isTipsAvailable = isStartStage && !showTipsModal && isTipsEnabled;

  const onClose = () => {
    if (showTipsModal) {
      setShowTipsModal(false);
    } else {
      paymentViewClose();
    }
  };

  const cardOptions = useMemo(() => {
    return (
      cardList?.length &&
      cardList.map((c) => {
        const last4 = `ending with ${c.card.last4}`;
        return {
          label: c.type === "card" ? `${c.card.brand} ${last4}` : c.card.email,
          value: c.id,
        };
      })
    );
  }, [cardList]);

  const completePayment = async () => {
    if (checkErrors()) return;

    if (isTipsAvailable && !tipsOnlyFlow) {
      setShowTipsModal(true);
      return;
    }

    const totalAmount = showTipsModal
      ? parseFloat(paymentAmount) + parseFloat(invoice.tipAmount)
      : paymentAmount;

    let stripeFormData = {};
    stripeFormData.payment_method_id = cardSelected.value;
    stripeFormData.invoice_id = invoice.id;
    stripeFormData.amount = totalAmount;

    setShowLoader(true);
    try {
      await getStripePaymentIntent(stripeFormData);
      refreshInvoiceDetails();
      toast.success("Payment done successfully");
    } catch (error) {
      toast.error(error.message);
    }
    setShowLoader(false);
    dispatch(checkoutInvoice.actions.paymentViewClose());
  };

  const refreshInvoiceDetails = async () => {
    await refetchInvoice();

    let invoiceDetailInterval = setInterval(() => {
      refetchInvoice().then((response) => {
        if (response?.data?.status !== "pending") {
          clearInterval(invoiceDetailInterval);
        }
      });
    }, 2000);
  };

  const checkErrors = () => {
    let error = false;
    if (!cardSelected?.value) {
      error = true;
      toast.error(
        cardList?.length ? "Please select a card" : "There are no saved cards",
      );
      setCardError(true);
    }
    if (paymentAmount < 0.5 || !paymentAmount) {
      error = true;
      toast.error("Please enter minimum amount $0.5");
      setAmountError(true);
    }
    if (paymentAmount > amountToPay) {
      error = true;
      toast.error("Please enter maximum amount $" + amountToPay);
      setAmountError(true);
    }
    if (!invoice?.id) {
      error = true;
      toast.error("Invalid Invoice Id");
    }
    return error;
  };

  useEffect(() => {
    if (cardOptions.length === 1) {
      setCardSelected(cardOptions[0]);
    } else {
      setCardSelected(null);
    }
  }, [cardOptions]);

  return (
    <div>
      {!showTipsModal ? (
        <>
          <div className="col-sm-6 p-b-10">
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.amount")}
            </InputLabel>
            <InputCurrency
              size="medium"
              prefix={invoice?.currencySymbol}
              value={paymentAmount}
              isError={amountError}
              onChange={(value) => {
                setPaymentAmount(value);
                setAmountError(false);
              }}
              placeholder={tSales(
                "checkoutInvoice.formInputPlaceholder.amount",
              )}
            />
          </div>
          <div className="col-sm-12 p-b-10">
            <InputLabel>
              {type === "card"
                ? tSales("checkoutInvoice.formInputLabel.creditCard")
                : tSales("checkoutInvoice.formInputLabel.link")}
            </InputLabel>
            <Select
              size="medium"
              isError={cardError}
              value={cardSelected}
              onChange={(option) => {
                setCardSelected(option);
                setCardError(false);
              }}
              options={cardOptions || []}
            />
          </div>
        </>
      ) : (
        <ViewTipsModal amountToPay={paymentAmount} />
      )}

      <ModalCharge.Footer
        onClose={onClose}
        isCharging={false}
        onCharge={completePayment}
        isChargeDisabled={isTipsApplying}
      />

      <Loader isFullWidth={true} showLoader={showLoader} />
    </div>
  );
};

FormCardOnFile.propTypes = {
  cardList: PropTypes.array,
  getStripePaymentIntent: PropTypes.func,
};

const mapStateToProps = (state, { type }) => {
  const list =
    type === "card"
      ? state.StripeReducer.cardList.filter((card) => card.type === "card")
      : state.StripeReducer.cardList.filter((card) => card.type === "link");

  return {
    cardList: list,
    isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getClientCardDataStripe, getStripePaymentIntent },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(FormCardOnFile));
