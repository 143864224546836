import React, { useEffect } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../assets/svg";
import { ModalCharge } from "../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../shared/InputCurrency/InputCurrency";
import { useChargeForm } from "../../hooks/useChargeForm";
import { InputError } from "../../../../../shared/InputError/InputError";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";
import ViewTipsModal from "../ViewTipsModal/ViewTipsModal";
import { usePaymentFlow } from "../../hooks/usePaymentFlow";
import { PAYMENT_VIEW_TYPES } from "../../../../../store/checkoutInvoice/consts";

const ViewChargeCash = ({ isTipsApplying }) => {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { invoice, amountToPay, isStartStage, isTipsEnabled } = useInvoice();
  const { chargeCash, paymentViewClose } = useInvoiceActions();
  const { showTipsModal, isTipsAvailable, tipsOnlyFlow } = usePaymentFlow();

  const cashPayment = (amount) => {
    chargeCash.initiate({ amount }).then(() => {
      dispatch(checkoutInvoice.actions.paymentViewClose());
    });
  };

  const onClose = () => {
    if (showTipsModal.value) {
      showTipsModal.update(false);
    } else {
      paymentViewClose();
    }
  };

  const schema = yup.object({
    amount: composeAmountSchema(
      { amountToPay, currency: invoice?.currency },
      true,
    ),
  });

  const { form, changeFormValue, hasError, errors, isValid } = useChargeForm({
    schema,
    initialValues: {
      amount: amountToPay || "",
    },
  });

  const handleSubmit = () => {
    if (isTipsEnabled && isStartStage && !tipsOnlyFlow) {
      showTipsModal.update(true);
    } else {
      cashPayment(form.amount);
    }
  };

  const handleTipsPayment = () => {
    const paymentAmount = parseFloat(form.amount);
    const tipAmount = parseFloat(invoice.tipAmount);

    if (!tipsOnlyFlow) {
      cashPayment(paymentAmount + tipAmount);
      return;
    }

    if (paymentAmount > 0) {
      cashPayment(paymentAmount);
    } else {
      dispatch(
        checkoutInvoice.actions.paymentViewChange({
          paymentView: PAYMENT_VIEW_TYPES.optionSelection,
        }),
      );
    }
  };

  useEffect(() => {
    if (amountToPay === 0 && isTipsAvailable) {
      showTipsModal.update(true);
      changeFormValue("amount", 0);
    }
  }, []);

  return !showTipsModal.value ? (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.cash")}
      imageSrc={svg.cashPrimary}
      onCharge={handleSubmit}
      isCharging={chargeCash.isLoading}
      chargeLabel={
        isTipsAvailable ? tCommon("label.continue") : tCommon("label.apply")
      }
      isChargeDisabled={!isValid || isTipsApplying}
    >
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={form.amount}
          isError={hasError("amount")}
          onChange={(value) => changeFormValue("amount", value)}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
        />
        {hasError("amount") && <InputError>{errors.amount}</InputError>}
      </ModalCharge.InputWrap>
    </ModalCharge>
  ) : (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.cash")}
      imageSrc={svg.cashPrimary}
      onCharge={handleTipsPayment}
      isCharging={chargeCash.isLoading}
      chargeLabel={
        isTipsAvailable ? tCommon("label.continue") : tCommon("label.apply")
      }
      isChargeDisabled={!isValid || isTipsApplying}
    >
      <ViewTipsModal amountToPay={form.amount} />
    </ModalCharge>
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(ViewChargeCash);
