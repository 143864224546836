import * as yup from "yup";
import { useExtendedFormik } from "../../../../../hooks/useExtendedFormik";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";

/**
 * @param {object} param0
 * @param {object} param0.initialValues
 * @param {boolean} param0.initialValues.isEnabled
 * @param {string?} param0.initialValues.faxNumber
 * @param {Function} param0.onSubmit
 */
export const useForm = ({ initialValues, onSubmit }) => {
  const { tSettings } = useAppTranslation.Settings();

  const schema = yup.object({
    isDisableConfirmOpen: yup.boolean().required(),
    isEnabled: yup.boolean().required(),
    faxNumber: yup
      .string()
      .required(tSettings("efaxIntegration.formError.faxNumberRequired")),
  });

  const { values, changeField, getError, handleSubmit, resetForm } =
    useExtendedFormik({
      onSubmit,
      validationSchema: schema,
      enableReinitialize: true,
      initialValues: {
        isDisableConfirmOpen: false,
        ...initialValues,
      },
    });

  return {
    submit: handleSubmit,
    isEnabled: {
      value: values.isEnabled,
      toggle: () => {
        if (values.isEnabled) {
          if (initialValues.isEnabled) {
            return changeField("isDisableConfirmOpen", true);
          } else {
            resetForm();
            return changeField("isEnabled", false);
          }
        } else {
          changeField("isEnabled", true);
        }
      },
    },
    isDisableConfirmOpen: {
      value: values.isDisableConfirmOpen,
      close: () => {
        changeField("isDisableConfirmOpen", false);
      },
    },
    faxNumber: {
      value: values.faxNumber,
      onChange: (value) => {
        changeField("faxNumber", value);
      },
      error: getError("faxNumber"),
    },
  };
};
