/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-string-refs */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import momentTimeZone from "moment-timezone";
import moment from "moment";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";
import { isEqual } from "lodash";
import NotificationMenu from "./NotificationMenu";
import {
  getIsPosEnabled,
  getIsDashboardEnabled,
  logout as legacyLogout,
  getRedirectTo,
  getTwilioConfig,
  getIsAcceleratorPlan,
  upgradeAcceleratorUrl,
  getIsAccountTypePaid,
  getTimeClockSetting,
  getToken,
  positionFooterInstantly,
  setRedirectTo,
} from "../../../Utils/services.js";
import arLogo from "../../images/logo.png";
import UpgradeAccountModal from "../../../Components/Common/UpgradeAccountModal.js";
import ClockMenu from "./ClockMenu";
import ClockOutModal from "../../../Components/Common/ClockOutModal.js";
import ClinicModalList from "../../../Components/Common/ClinicModalList";
import ClinicsModal from "../../../Components/Common/ClinicsModal.js";
import {
  changeClockStatus,
  emptyClockReducer,
} from "../../../Actions/Clock/clockActions";
import { fetchClockStatus } from "../../../Actions/Clock/clockDataActions.js";
import { calulateDiffDuration } from "../../../Utils/date-services.js";
import {
  updateClinicsData,
  switchWorkspaces,
  refreshProfilePage,
  refreshDashboard,
  refreshClientsData,
} from "../../../Actions/Settings/settingsActions";
import Loader from "../../../Components/Common/Loader.js";
import { fetchNotificationsPopupsMenu } from "../../../Actions/Dashboard/dashboardActions.js";
import { accountStatusResponseInterceptor } from "../../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";
import { useCheckUpdateUserPrivilege } from "../../../hooks/useUpdatePrivilege";
import { withWorkspaces } from "./withWorkspaces";
import GlobalSearch from "../../../widgets/GlobalSearch/GlobalSearch";
import { isWorkspaceStatusInactive } from "../../../utilities/api";
import { uiNotification } from "../../../services/UINotificationService.js";
import {
  hoursToMs,
  minutesToMs,
  parseTimeString,
  secondsToMs,
} from "../../../utilities/time.js";
import { ModalInfo } from "../../../boxes/ModalInfo/ModalInfo.js";
import { tCommon } from "../../../i18n/useAppTranslation.js";
import {
  clinicTimezone,
  lockAppUntilAccountAddressUpdated,
} from "../../../utilities/localStorage.js";
import { withCurrentUserQuery } from "../../../api/queries/useUserQuery.js";
import { PERMISSIONS } from "../../../consts/api.js";
import { logout } from "../../../helpers/auth.js";
import { withBetaFeatures } from "../../../hooks/useBetaFeatures.js";
import { inventoryProductsFilter } from "../../../store/inventoryFilter";
import { dispatch } from "../../../store/store";
import { privacyPolicyPopup } from "../../../store/privacyPolicyPopup/index.js";
import { InputSearch } from "../../../boxes/InputSearch/InputSearch.js";
import styles from "./scss/Header.module.scss";

const INBOX_SETUP_URL = "/settings/inbox";

const url = process.env.REACT_APP_API_URL;
const headerInstance = axios.create();

headerInstance.interceptors.response.use(accountStatusResponseInterceptor);

const checkPermission = (globalPermission, specificPermission) => {
  return (
    globalPermission &&
    globalPermission.find((str) => str === specificPermission)
  );
};

const setIsClockOutCancelled = (value) => {
  localStorage.setItem("isLoggedClockCancelled", value);
};

const setClockPromptCancelTime = (value) => {
  localStorage.setItem("clockPromptCancelTime", value);
};

function composeParsedTime(parsedTime) {
  const i18nKeyMap = {
    hours: "pluralHours",
    minutes: "pluralMinutes",
    seconds: "pluralSeconds",
  };

  return Object.entries(parsedTime)
    .reduce((carry, node) => {
      if (node[1]) {
        const i18nKey = i18nKeyMap[node[0]];
        return (carry += `${tCommon(`label.${i18nKey}`, {
          count: node[1],
        })} `);
      }
      return carry;
    }, "")
    .trim();
}

const getPropmtModalStatus = () => {
  let isShowClockOutModal = false;
  const isLoggedClockCancelled = localStorage.getItem("isLoggedClockCancelled");

  if (isLoggedClockCancelled != 1) {
    isShowClockOutModal = true;
  } else {
    let clockPromptCancelTime = localStorage.getItem("clockPromptCancelTime");
    if (clockPromptCancelTime) {
      clockPromptCancelTime = moment(clockPromptCancelTime);
      let difference = moment.duration(
        moment().diff(moment(clockPromptCancelTime)),
      );
      difference = difference.asMinutes();
      if (difference >= 30) {
        isShowClockOutModal = true;
      }
    } else {
      isShowClockOutModal = true;
    }
  }

  return isShowClockOutModal;
};

const renderHeaderMenu = (toggledOnBetaFutures = []) => {
  let headerMenuContent = [];

  let userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;

  let languageData = localStorage.getItem("languageData")
    ? JSON.parse(localStorage.getItem("languageData"))
    : null;
  languageData =
    languageData && languageData.global ? languageData.global : null;

  const userPermissions =
    userData && userData.permissions ? userData.permissions : [];

  let headerPermissions = userPermissions ? [...userPermissions] : [];

  let isPOSEnabled = getIsPosEnabled() ? 1 : 0; //(userData && userData.account) ? userData.account.pos_enabled : 0;
  let isDashEnabled = getIsDashboardEnabled(); //(userData && userData.user) ? userData.user.is_dashboard_enabled : false;
  let userRoleID = userData && userData.user ? userData.user.user_role_id : 0;
  let isProvider =
    userData && userData.user ? userData.user.is_provider : false;
  let userDefaultClinicId =
    userData && userData.user ? userData.user.clinic_id : 0;
  let userId = userData && userData.user ? userData.user.id : 0;
  let isTouchMd =
    userData && userData.account
      ? userData.account.account_preference.is_touch_md
      : 0;
  let touchMdPlan =
    userData && userData.account
      ? userData.account.account_subscription.plan_code
      : 0;

  headerMenuContent.push({
    label: "What's New",
    to: "https://updates.aestheticrecord.com",
    match: "https://updates.aestheticrecord.com",
    external: true,
  });

  headerMenuContent.push({
    label: "Marketplace",
    to: "https://market.aestheticrecord.com/",
    match: "https://market.aestheticrecord.com/",
    external: true,
  });

  headerMenuContent.push({
    label: "Learning Lab",
    to: "https://learn.aestheticrecord.com",
    match: "https://learn.aestheticrecord.com",
    external: true,
  });

  if (isTouchMd != 1 || touchMdPlan != "practice_booking") {
    if (userData && isPOSEnabled === 1 && isDashEnabled === true) {
      headerMenuContent.push({
        label: languageData && languageData["headerDashboard"],
        to: "/dashboard",
        match: "/dashboard",
      });
    }
  }

  if (checkPermission(headerPermissions, "view-patients")) {
    headerMenuContent.push({
      label: languageData && languageData["header_clients"],
      to: "/clients",
      match: "/clients",
    });
  }

  if (isTouchMd == 1 && touchMdPlan == "practice_booking") {
    headerMenuContent.push({
      label: "Leads",
      to: getTwilioConfig() == false ? "/leads" : "/leads",
      match: getTwilioConfig() == false ? "/leads" : "/leads",
    });
  } else {
    headerMenuContent.push({
      label: "Leads",
      to:
        getIsAcceleratorPlan() === true
          ? getTwilioConfig() == false
            ? "/leads"
            : "/leads"
          : userData && userData.user_type === "superadmin"
          ? upgradeAcceleratorUrl()
          : "",
      match:
        getIsAcceleratorPlan() === true
          ? getTwilioConfig() == false
            ? "/leads"
            : "/leads"
          : "/leads",
    });
  }

  if (checkPermission(headerPermissions, "view-appointments")) {
    headerMenuContent.push({
      label: languageData && languageData["header_appointments"],
      to: isProvider
        ? `/appointment/provider/${userId}/clinic/${userDefaultClinicId}/day`
        : "/appointment/index",
      match: "/appointment",
    });
  }

  if (isPOSEnabled === 1 && checkPermission(headerPermissions, "view-sales")) {
    let toURL = "";

    if (checkPermission(headerPermissions, "view-sales-invoices")) {
      toURL = "/sales/invoices";
    } else if (checkPermission(headerPermissions, "view-sales-invoice-text")) {
      toURL = "/sales/invoice-text";
    } else if (checkPermission(headerPermissions, "view-cash-drawer")) {
      toURL = "/sales/cash-drawer";
    } else if (checkPermission(headerPermissions, "view-sales-goals")) {
      toURL = "/sales/office-sales-goals";
    }

    headerMenuContent.push({
      label: languageData && languageData["header_sales"],
      to: toURL,
      match: "/sales",
    });
  }

  if (isTouchMd != 1 || touchMdPlan != "practice_booking") {
    if (checkPermission(headerPermissions, "view-products-inventory")) {
      headerMenuContent.push({
        label: languageData && languageData["header_inventory"],
        to: "/inventory/products/active",
        match: "/inventory",
      });
    }
  }

  if (
    (userData && userData.user_type === "superadmin") ||
    checkPermission(headerPermissions, "view-sales-report")
  ) {
    if (isTouchMd == 1 && touchMdPlan == "practice_booking") {
      headerMenuContent.push({
        label: languageData && languageData["header_insights"],
        to: "/business-insight/appointments",
        match: "/business-insight",
      });
    } else {
      headerMenuContent.push({
        label: languageData && languageData["header_insights"],
        to:
          getIsPosEnabled() === true &&
          ((userData && userData.user_type === "superadmin") ||
            checkPermission(headerPermissions, "business-insight-sales"))
            ? "/business-insight/sales"
            : "/business-insight/clients",
        match: "/business-insight",
      });
      if (toggledOnBetaFutures.includes("new_bi")) {
        headerMenuContent.push({
          label: "NEW BI (BETA)",
          to: "/refactor-business-insight/sales",
          match: "/refactor-business-insight/sales",
        });
      }
    }
  }

  if (isTouchMd != 1 || touchMdPlan != "practice_booking") {
    if (userRoleID === 4) {
      headerMenuContent.push({
        label: languageData && languageData["header_md_room"],
        to: "/md-room/pending",
        match: "/md-room",
      });
    }
  }

  if (isTouchMd != 1 || touchMdPlan != "practice_booking") {
    if (userRoleID === 2 || isProvider === true) {
      headerMenuContent.push({
        label: languageData && languageData["header_provider_room"],
        to: "/provider-room/pending",
        match: "/provider-room",
      });
    }
  }
  if (isTouchMd == 1 && touchMdPlan == "practice_booking") {
    headerMenuContent.push({
      label: languageData && languageData["header_inbox"],
      to: "/inbox/sms/all",
      match: getTwilioConfig() == false ? INBOX_SETUP_URL : "/inbox/sms/all",
    });
  } else {
    headerMenuContent.push({
      label: languageData && languageData["header_inbox"],
      to:
        getIsAcceleratorPlan() === true
          ? "/inbox/sms/all"
          : userData && userData.user_type === "superadmin"
          ? upgradeAcceleratorUrl()
          : "",
      match:
        getIsAcceleratorPlan() === true
          ? getTwilioConfig() == false
            ? INBOX_SETUP_URL
            : "/inbox/sms/all"
          : INBOX_SETUP_URL,
    });
  }

  return [headerMenuContent];
};

const { actions } = inventoryProductsFilter;

class Header extends React.Component {
  constructor(props) {
    super(props);

    let languageData = JSON.parse(localStorage.getItem("languageData"));
    let userData = JSON.parse(localStorage.getItem("userData"))
      ? JSON.parse(localStorage.getItem("userData"))
      : null;

    this.state = {
      email: "",
      password: "",
      isClicked: false,
      inventoryActive: "active",
      headerTextContent: renderHeaderMenu(),
      globalLang:
        languageData && languageData.global ? languageData.global : null,
      userData: userData,
      navTo: "",
      isPosEnabled: getIsPosEnabled(),
      isDashboardEnabled: getIsDashboardEnabled(),
      isTwilioConfig: getTwilioConfig(),
      isAccountTypePaid: getIsAccountTypePaid(),
      isAcceleratorPlan: getIsAcceleratorPlan(),
      isTimeClockSetting: getTimeClockSetting(),
      currentURL: "",
      isShowUpgradeAlertModal: false,
      notificationCount: 0,
      clockOutModalWasToggled: false,
      unseen_new_leads: 0,

      user_name: localStorage.getItem("current_wc_full_name")
        ? localStorage.getItem("current_wc_full_name")
        : "",
      clock_label: "Clock In",
      clock_action_current: "out",
      clock_action_next: "in",
      clock_in: null,
      clock_out: null,
      clocked_in_duration: "00:00",
      clocked_in_hours: 0,
      prompt_clockout_hours: 8,
      prompt_clockout_min: 0,
      intervalResource: null,
      clockStatusData: {},
      isShowClockOutModal: false,
      isRender: false,
      clocked_in_durationObj: calulateDiffDuration(),
      clocked_in_duration_label: "0 Hour 0 Min",
      need_clinic_city_state: localStorage.getItem("need_clinic_city_state")
        ? localStorage.getItem("need_clinic_city_state")
        : false,
      clinic_list: JSON.parse(localStorage.getItem("clinic_list")),
      closeClinicsPopup: false,
      showDropdown: false,
      showHoldModal: false,
      overtime: null,
      allowOvertime: false,
      workspaceSearch: "",
    };
  }

  handleClickHeader = (e) => {
    if (
      e.target.id !== "noti-icon" &&
      e.target.id !== "headerUserImage" &&
      e.target.name !== "workspaceSearch"
    ) {
      this.setState({ showDropdown: false, isClicked: false });
      return;
    } else if (e.target.id == "noti-icon") {
      this.toggleMenu(e.target);
    } else if (e.target.id == "headerUserImage") {
      this.toggleWorkspaceMenu(e.target);
    }
  };

  toggleWorkspaceMenu = (elem) => {
    let showDropdown = false;
    if (this.state.showDropdown == false && elem.id == "headerUserImage") {
      showDropdown = true;
    } else {
      showDropdown = false;
    }
    this.setState({ showDropdown: showDropdown });
  };

  toggleMenu = (elem) => {
    if (elem.id !== "noti-icon" && this.state.isClicked === false) {
      return;
    }

    let isClicked = false;

    if (this.state.isClicked === false && elem.id === "noti-icon") {
      isClicked = true;
    } else {
      isClicked = false;
    }

    this.setState({ isClicked: isClicked });
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickHeader, false);
    if (this.state.intervalResource) {
      clearInterval(this.state.intervalResource);
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickHeader, false);
    if (getTimeClockSetting() && getIsAcceleratorPlan()) {
      this.props.fetchClockStatus();
    }
    this.fetchClockStatus();
  }

  getActiveClass = (obj) => {
    let returnClass = "";

    if (obj !== undefined) {
      obj.map((io) => {
        if (io.match !== undefined) {
          if (
            window.location.pathname.startsWith(io.match) &&
            !this.manageHeaderClass()
          ) {
            returnClass = "active";
          }
        }
      });
    }

    return { returnClass };
  };

  static getDerivedStateFromProps(props, state) {
    let returnState = {};
    const toggledOnBetaFeaturesLabels =
      props.betaFeature.toggledOnBetaFeaturesLabels;

    if (props.showPrivacyPolicyPopup === true) {
      dispatch(privacyPolicyPopup.actions.open());
    }

    if (getIsPosEnabled() !== state.isPosEnabled) {
      returnState.isPosEnabled = getIsPosEnabled();
      returnState.headerTextContent = renderHeaderMenu(
        toggledOnBetaFeaturesLabels,
      );
    }

    if (getIsDashboardEnabled() !== state.isDashboardEnabled) {
      returnState.isDashboardEnabled = getIsDashboardEnabled();
      returnState.headerTextContent = renderHeaderMenu(
        toggledOnBetaFeaturesLabels,
      );
    }

    if (getTwilioConfig() !== state.isTwilioConfig) {
      returnState.isTwilioConfig = getTwilioConfig();
      returnState.headerTextContent = renderHeaderMenu(
        toggledOnBetaFeaturesLabels,
      );
    }

    if (getIsAccountTypePaid() !== state.isAccountTypePaid) {
      returnState.isAccountTypePaid = getIsAccountTypePaid();
      returnState.headerTextContent = renderHeaderMenu(
        toggledOnBetaFeaturesLabels,
      );
    }

    if (getIsAcceleratorPlan() !== state.isAcceleratorPlan) {
      returnState.isAcceleratorPlan = getIsAcceleratorPlan();
      returnState.headerTextContent = renderHeaderMenu(
        toggledOnBetaFeaturesLabels,
      );
    }

    if (getTimeClockSetting() !== state.isTimeClockSetting) {
      returnState.isTimeClockSetting = getTimeClockSetting();
      returnState.headerTextContent = renderHeaderMenu(
        toggledOnBetaFeaturesLabels,
      );
    }

    if (props.currentURL !== state.currentURL) {
      returnState.currentURL = props.currentURL;
      returnState.headerTextContent = renderHeaderMenu(
        toggledOnBetaFeaturesLabels,
      );
    }

    if (props.showLoader != undefined && props.showLoader == false) {
      returnState.showLoader = false;
    }

    if (
      props.user !== undefined &&
      props.user.status === 200 &&
      props.user.data !== state.user
    ) {
      returnState.user = props.user.data;
    }

    if (
      props.notificationData != undefined &&
      props.notificationDataTime != state.notificationDataTime
    ) {
      returnState.notificationCount = props.notificationData;
      returnState.unseen_new_leads = props.unseen_new_leads;
      returnState.notificationDataTime = props.notificationDataTime;
    }

    if (props.unseen_new_leads !== undefined) {
      returnState.unseen_new_leads = props.unseen_new_leads;
    }

    if (
      props.clockStatusData &&
      props.clockStatusData !== state.clockStatusData
    ) {
      props.emptyClockReducer();
      returnState.clockStatusData = props.clockStatusData;
      returnState.clock_action_current = props.clockStatusData.clock_status
        ? props.clockStatusData.clock_status
        : "out";
      returnState.clock_action_next =
        returnState.clock_action_current === "out" ? "in" : "out";
      returnState.clock_label =
        returnState.clock_action_current === "in" ? "Clock Out" : "Clock In";
      returnState.clock_in = props.clockStatusData.clock_in
        ? props.clockStatusData.clock_in
        : null;
      returnState.clock_out =
        props.clockStatusData.clock_out &&
        props.clockStatusData.clock_out !== "0000-00-00 00:00:00"
          ? props.clockStatusData.clock_out
          : null;
      returnState.clocked_in_duration =
        returnState.clock_action_next === "out" &&
        props.clockStatusData.clock_in &&
        props.clockStatusData.clock_in !== "0000-00-00 00:00:00"
          ? props.clockStatusData.clocked_in_duration
          : "00:00";
      returnState.clocked_in_hours =
        returnState.clock_action_next === "out" &&
        props.clockStatusData.clock_in &&
        props.clockStatusData.clock_in !== "0000-00-00 00:00:00"
          ? props.clockStatusData.clocked_in_hours
          : 0;
      returnState.prompt_clockout_hours =
        props.clockStatusData.account_settings &&
        props.clockStatusData.account_settings.prompt_clockout_hours >= 0
          ? props.clockStatusData.account_settings.prompt_clockout_hours
          : 8;
      returnState.prompt_clockout_min =
        props.clockStatusData.account_settings &&
        props.clockStatusData.account_settings.prompt_clockout_min >= 0
          ? props.clockStatusData.account_settings.prompt_clockout_min
          : 0;
      returnState.isRender = true;

      if (props.clockStatusData?.account_settings?.overtime_enabled) {
        returnState.allowOvertime = Boolean(
          props.clockStatusData.account_settings.overtime_enabled,
        );
      }

      if (props.isClockStatusCheck) {
        if (state.isRender) {
          returnState.isShowClockOutModal = true;
        } else {
          if (returnState.clock_action_current === "in") {
            returnState.clocked_in_durationObj = calulateDiffDuration(
              returnState.clock_in,
            );
            returnState.clocked_in_duration_label =
              returnState.clocked_in_durationObj.hours +
              " Hour " +
              returnState.clocked_in_durationObj.minutes +
              " Min";
            returnState.clocked_in_duration_decimal = parseFloat(
              returnState.clocked_in_durationObj.hours
                .toString()
                .padStart(2, 0) +
                "." +
                returnState.clocked_in_durationObj.minutes
                  .toString()
                  .padStart(2, 0),
            );
            //returnState.clocked_in_duration_decimal = parseFloat(returnState.clocked_in_duration.replace(':','.'));
            returnState.clocked_out_prompt_decimal = parseFloat(
              returnState.prompt_clockout_hours.toString().padStart(2, 0) +
                "." +
                returnState.prompt_clockout_min.toString().padStart(2, 0),
            );
            if (
              returnState.clocked_in_duration_decimal >=
              returnState.clocked_out_prompt_decimal
            ) {
              returnState.isShowClockOutModal = getPropmtModalStatus();
            }
          } else {
            returnState.clocked_in_durationObj = calulateDiffDuration();
            returnState.clocked_in_duration_decimal = null;
            returnState.clocked_in_duration_label = "0 Hour 0 Min";
            setIsClockOutCancelled(0);
            setClockPromptCancelTime("");
          }
        }
      }

      const isClockPromptLoginDisplay = localStorage.getItem(
        "isClockPromptLoginDisplay",
      );

      if (isClockPromptLoginDisplay == 1) {
        returnState.isShowClockOutModal = true;
        localStorage.removeItem("isClockPromptLoginDisplay");
      }

      return returnState;
    }

    if (
      props.closeClinicsPopup != undefined &&
      props.closeClinicsPopup == true
    ) {
      returnState.closeClinicsPopup = true;
      returnState.need_clinic_city_state = false;
      returnState.showLoader = false;
      localStorage.setItem("need_clinic_city_state", false);
      localStorage.removeItem("clinic_list");
    }

    if (
      props.userDataTime != state.userDataTime &&
      props.userData != undefined
    ) {
      returnState.userDataTime = props.userDataTime;
      returnState.user_name =
        props.userData && props.userData.current_wc_full_name
          ? props.userData.current_wc_full_name
          : "";
      returnState.showLoader = false;

      if (props.status == 604) {
        props.history.push("/upgrade-account-to-stripe");
      } else if (props.status == 603) {
        props.history.push("/upgrade-subscription-plan");
      } else if (props.status == 602) {
        props.history.push("/accept-agreement");
      } else if (props.status == 605) {
        props.history.push("/two-factor-login");
      } else if (props.status == 200) {
        localStorage.setItem(
          "languageData",
          JSON.stringify(props.userData.languageData),
        );
        localStorage.setItem(
          "globalPrivileges",
          JSON.stringify(props.userData.globalPrivileges),
        );
        localStorage.setItem(
          "need_clinic_city_state",
          props.userData.need_clinic_city_state,
        );
        returnState.need_clinic_city_state =
          props.userData.need_clinic_city_state == true ? "true" : "false";

        if (props.userData.need_clinic_city_state == true) {
          localStorage.setItem(
            "clinic_list",
            JSON.stringify(props.userData.clinic_list),
          );
        }

        localStorage.setItem("cCode", props.userData.country_code);
        localStorage.setItem("current_wc_full_name", returnState.user_name);

        localStorage.setItem("isClockPromptLoginDisplay", 1);
        localStorage.removeItem("isLoggedClockCancelled");
        localStorage.removeItem("clockPromptCancelTime");

        if (
          props.currentURL == "/settings/profile" &&
          props.redirect_url == "/settings"
        ) {
          props.refreshProfilePage(props.userData.user.id);
          props.fetchNotificationsPopupsMenu();
        } else if (
          props.currentURL == "/dashboard" &&
          props.redirect_url == "/dashboard"
        ) {
          props.refreshDashboard();
          props.fetchNotificationsPopupsMenu();
        } else if (
          props.currentURL == "/clients" &&
          props.redirect_url == "/clients"
        ) {
          props.refreshClientsData();
        } else {
          props.history.push(props.redirect_url);
          props.fetchNotificationsPopupsMenu();
        }
      }
    }

    if (
      props.headerUpdate != undefined &&
      props.headerUpdate == true &&
      props.updateHeaderDateTime != state.updateHeaderDateTime
    ) {
      returnState.updateHeaderDateTime = props.updateHeaderDateTime;
      returnState.user_name = localStorage.getItem("current_wc_full_name")
        ? localStorage.getItem("current_wc_full_name")
        : "";
      returnState.userData = JSON.parse(localStorage.getItem("userData"));
    }

    if (props.accountInactiveDataTime != state.accountInactiveDataTime) {
      returnState.showLoader = false;
      returnState.accountInactiveDataTime = props.accountInactiveDataTime;
    }

    return returnState;
  }

  navClicked = (to) => {
    this.setState({
      navTo: to,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.location.pathname.startsWith("/inventory") &&
      prevProps.location.pathname.startsWith("/inventory")
    ) {
      dispatch(actions.removeInventoryProductsFilter());
    }
    if (this.state.user !== null && this.state.user !== prevState.user) {
      let userData = JSON.parse(localStorage.getItem("userData"));

      userData.user["firstname"] = this.state.user.firstname;
      userData.user["lastname"] = this.state.user.lastname;
      userData.user["user_image_url"] = this.state.user.user_image_url;
      userData = JSON.stringify(userData);
      localStorage.setItem("userData", userData);

      this.setState({ userUpdated: true });
    }

    if (
      !prevProps.isAccountOnHold &&
      this.props.isAccountOnHold &&
      !lockAppUntilAccountAddressUpdated.get()
    ) {
      this.setState({ showHoldModal: true, isShowClockOutModal: false });
    }

    if (
      !isEqual(
        prevProps.betaFeature.toggledOnBetaFeaturesLabels,
        this.props.betaFeature.toggledOnBetaFeaturesLabels,
      )
    ) {
      this.setState({
        headerTextContent: renderHeaderMenu(
          this.props.betaFeature.toggledOnBetaFeaturesLabels,
        ),
      });
    }
  };

  logUserOut = () => {
    legacyLogout();
    logout();
  };

  manageHeaderClass = () => {
    let headerActiveClass = "";
    let current_url =
      window.location && window.location.pathname
        ? window.location.pathname.split("/")[2] || ""
        : "";
    let current_url_segment =
      window.location && window.location.pathname
        ? window.location.pathname.split("/")[1] || ""
        : "";

    if (current_url_segment === "settings") {
      headerActiveClass = current_url_segment;
    } else if (
      current_url === "notifications" ||
      current_url === "sms-notifications" ||
      current_url === "user-logs"
    ) {
      headerActiveClass =
        current_url === "sms-notifications" ? "notifications" : current_url;
    } else {
      headerActiveClass = "";
    }

    return headerActiveClass;
  };

  rediretToUpgrade = () => {
    return <div>{this.props.history.push(upgradeAcceleratorUrl())}</div>;
  };

  handleShowUpgradeAlertModal = (state) => {
    this.setState(state);
  };

  fetchClockStatus = () => {
    const _self = this;

    setInterval(function () {
      if (getTimeClockSetting() && getIsAcceleratorPlan()) {
        headerInstance.get(url + "clock_data").then((response) => {
          let returnState = {};
          if (response.data && response.data.data) {
            returnState.clock_action_current = response.data.data.clock_status
              ? response.data.data.clock_status
              : "out";
            returnState.clock_action_next =
              returnState.clock_action_current === "out" ? "in" : "out";
            returnState.clock_label =
              returnState.clock_action_current === "in"
                ? "Clock Out"
                : "Clock In";
            returnState.clock_in = response.data.data.clock_in
              ? response.data.data.clock_in
              : null;
            returnState.clock_out =
              response.data.data.clock_out &&
              response.data.data.clock_out !== "0000-00-00 00:00:00"
                ? response.data.data.clock_out
                : null;
            returnState.clocked_in_duration =
              returnState.clock_action_next === "out" &&
              response.data.data.clock_in &&
              response.data.data.clock_in !== "0000-00-00 00:00:00"
                ? response.data.data.clocked_in_duration
                : "00:00";
            returnState.clocked_in_hours =
              returnState.clock_action_next === "out" &&
              response.data.data.clock_in &&
              response.data.data.clock_in !== "0000-00-00 00:00:00"
                ? response.data.data.clocked_in_hours
                : 0;
            returnState.prompt_clockout_hours =
              response.data.data.account_settings &&
              response.data.data.account_settings.prompt_clockout_hours >= 0
                ? response.data.data.account_settings.prompt_clockout_hours
                : 8;
            returnState.prompt_clockout_min =
              response.data.data.account_settings &&
              response.data.data.account_settings.prompt_clockout_min >= 0
                ? response.data.data.account_settings.prompt_clockout_min
                : 0;
            if (returnState.clock_action_current === "in") {
              returnState.clocked_in_durationObj = calulateDiffDuration(
                returnState.clock_in,
              );
            } else {
              returnState.clocked_in_duration_decimal = null;
              returnState.clocked_in_durationObj = calulateDiffDuration();
              returnState.clocked_in_duration_label = "0 Hour 0 Min";
            }

            if (
              returnState.clock_action_current === "in" &&
              _self.state.isShowClockOutModal === false
            ) {
              returnState.clocked_in_duration_label =
                returnState.clocked_in_durationObj.hours +
                " Hour " +
                returnState.clocked_in_durationObj.minutes +
                " Min";
              returnState.clocked_in_duration_decimal = parseFloat(
                returnState.clocked_in_durationObj.hours
                  .toString()
                  .padStart(2, 0) +
                  "." +
                  returnState.clocked_in_durationObj.minutes
                    .toString()
                    .padStart(2, 0),
              );
              returnState.clocked_out_prompt_decimal = parseFloat(
                returnState.prompt_clockout_hours.toString().padStart(2, 0) +
                  "." +
                  returnState.prompt_clockout_min.toString().padStart(2, 0),
              );
              if (
                returnState.clocked_in_duration_decimal >=
                returnState.clocked_out_prompt_decimal
              ) {
                returnState.isShowClockOutModal = getPropmtModalStatus();
              }
            } else if (returnState.clock_action_current === "out") {
              setIsClockOutCancelled(0);
              setClockPromptCancelTime("");
            }
          } else {
            returnState.clock_action_current = "out";
            returnState.clock_action_next = "in";
            returnState.clock_label = "Clock In";
            returnState.clock_in = null;
            returnState.clock_out = null;
            returnState.clocked_in_duration = "00:00";
            returnState.clocked_in_hours = 0;
            returnState.clocked_in_duration_decimal = null;
            returnState.clocked_in_durationObj = calulateDiffDuration();
            returnState.clocked_in_duration_label = "0 Hour 0 Min";
            setIsClockOutCancelled(0);
            setClockPromptCancelTime("");
          }
          _self.setState(returnState);
        });
      } else {
        _self.setState({ isShowClockOutModal: false });
      }
    }, 4 * 60000);
  };

  handleClockInActionError = (error) => {
    if (error?.message === "working time is up") {
      uiNotification.error("You've already reached your working time limit");
    }
    if (error?.message === "cant_clock_in_future_time") {
      uiNotification.error(tCommon(`error.${error.message}`));
    }
    if (
      error?.message === "already_clock_in" ||
      error?.message === "already_clock_out"
    ) {
      this.props.fetchClockStatus();
      return;
    }
    this.setState({ isShowClockOutModal: false });
  };

  handleClockOutActionSuccess = (data, clockOutDate) => {
    const timeLeft = data?.data?.left_work_time;
    const clockInDateString = data?.data?.clock_in;

    if (timeLeft && clockInDateString && !this.state.allowOvertime) {
      const parsedTimeLeft = parseTimeString(timeLeft);
      const workedTimeMs = clockOutDate.diff(moment(clockInDateString));

      const timeLeftMs =
        hoursToMs(parsedTimeLeft.hours) +
        minutesToMs(parsedTimeLeft.minutes) +
        secondsToMs(parsedTimeLeft.seconds);

      if (workedTimeMs > timeLeftMs) {
        const workedDuration = moment.duration(workedTimeMs);
        this.setState({
          overtime: {
            timeLeft: composeParsedTime(parsedTimeLeft),
            workedTime: composeParsedTime({
              hours: workedDuration.hours(),
              minutes: workedDuration.minutes(),
              seconds: workedDuration.seconds(),
            }),
          },
        });
      }
    }
  };

  handleClockAction = () => {
    if (this.state.clock_action_next === "out") {
      if (this.state.isShowClockOutModal) {
        const date = moment();
        this.props
          .changeClockStatus({
            clock_status: this.state.clock_action_next,
            clock_time: date.format("YYYY-MM-DD HH:mm:ss"),
            current_time: date.format("YYYY-MM-DD HH:mm:ss"),
            time_zone: momentTimeZone.tz.guess(true),
          })
          .then((data) => {
            this.handleClockOutActionSuccess(data, date);
            this.setState({ isShowClockOutModal: false });
          })
          .catch(this.handleClockInActionError);
        setIsClockOutCancelled(0);
        setClockPromptCancelTime("");
      } else {
        this.props.fetchClockStatus();
      }
    } else {
      setIsClockOutCancelled(0);
      setClockPromptCancelTime("");

      if (this.state.isShowClockOutModal) {
        const date = moment();
        this.props
          .changeClockStatus({
            clock_status: this.state.clock_action_next,
            clock_time: date.format("YYYY-MM-DD HH:mm:ss"),
            current_time: date.format("YYYY-MM-DD HH:mm:ss"),
            time_zone: momentTimeZone.tz.guess(true),
          })
          .catch(this.handleClockInActionError);
      } else {
        this.setState({ isShowClockOutModal: true });
      }
    }
  };

  dismissClockOutModal = () => {
    if (
      this.state.clock_action_next === "out" &&
      this.state.clocked_in_duration_decimal >=
        this.state.clocked_out_prompt_decimal
    ) {
      setIsClockOutCancelled(1);
      setClockPromptCancelTime(moment().toDate());
    } else {
      setIsClockOutCancelled(0);
      setClockPromptCancelTime("");
    }

    this.setState({ isShowClockOutModal: false });
    this.setState({ clockOutModalWasToggled: true });
  };

  updateClinicsData = (handleChildState) => {
    this.setState({ showLoader: true });
    this.props.updateClinicsData(handleChildState);
  };

  changeWorkSpacePopup = () => {
    let check = this.state.showDropdown;
    this.setState({ showDropdown: !check });
  };

  switchWorkspace = ({ workspaceId, accountId }) => {
    if (accountId === this.props.activeAccount.id) {
      return;
    } else {
      this.setState({ showLoader: true });
      let formData = {
        workspace_id: workspaceId,
      };
      this.props.switchWorkspaces(formData).finally(() => {
        this.setState({ showLoader: false });
        this.props.fetchClockStatus();
      });
    }
  };

  redirectToProfile = () => {
    this.props.history.push("/settings/profile");
  };

  isWorkspaceActive = (workspace) => {
    return !isWorkspaceStatusInactive(workspace.status);
  };

  get sortedWorkspaces() {
    return this.props.workspaces.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  }

  get searchedWorkspaces() {
    return this.sortedWorkspaces.filter((workspace) => {
      return workspace.name
        .toLowerCase()
        .includes(this.state.workspaceSearch.toLowerCase());
    });
  }

  render() {
    const userPrivileges = this.props.currentUserQuery?.data?.privileges || [];

    const userData = JSON.parse(localStorage.getItem("userData"));

    let isTouchMd =
      userData && userData.account
        ? userData.account.account_preference.is_touch_md
        : 0;
    let touchMdPlan =
      userData && userData.account
        ? userData.account.account_subscription.plan_code
        : "";

    let nav = [];

    const filteredHeaderContent = this.state.headerTextContent[0].filter(
      (node) => {
        if (
          node.match === "/inbox/sms/all" &&
          !userPrivileges.includes(PERMISSIONS.inbox.headerMenuLink)
        ) {
          return false;
        }

        return true;
      },
    );

    nav = filteredHeaderContent.map((obj, idx) => {
      let activeClass = "";
      activeClass = this.getActiveClass([obj]).returnClass;

      return obj.external ? (
        <li
          data-toggle="collapse"
          data-target="#navbar"
          onClick={() => this.navClicked(obj.to)}
          className={activeClass}
          key={idx}
          ref={"parents"}
        >
          {" "}
          <a href={obj.to} target="_blank" rel="noopener noreferrer">
            {" "}
            {obj.label}{" "}
          </a>{" "}
        </li>
      ) : (
        <li
          data-toggle="collapse"
          data-target="#navbar"
          onClick={() => this.navClicked(obj.to)}
          className={activeClass}
          key={idx}
          ref={"parents"}
        >
          {isTouchMd == 1 && touchMdPlan == "practice_booking" ? (
            <Link to={obj.to}>
              {obj.match === "/inbox/sms/all" && (
                <span
                  id="unread-notification-count"
                  className={
                    this.state.notificationCount
                      ? "inboxCount"
                      : "no-display inboxCount"
                  }
                >
                  {this.state.notificationCount}
                </span>
              )}
              {obj.match === "/leads" && (
                <span
                  id="unread-notification-count"
                  className={
                    this.state.unseen_new_leads
                      ? "inboxCount"
                      : "no-display inboxCount"
                  }
                >
                  {this.state.unseen_new_leads}
                </span>
              )}
              <label htmlFor={obj.label}>{obj.label}</label>
            </Link>
          ) : (obj.match === INBOX_SETUP_URL &&
              getIsAcceleratorPlan() === false &&
              this.state.userData &&
              this.state.userData.user_type !== "superadmin") ||
            (obj.match === "/leads" &&
              getIsAcceleratorPlan() === false &&
              this.state.userData &&
              this.state.userData.user_type !== "superadmin") ? (
            <a
              onClick={() => {
                this.setState({ isShowUpgradeAlertModal: true });
              }}
            >
              <span className="inboxLock">
                <i className="fa fa-lock" />
              </span>
              <label htmlFor={obj.label}>{obj.label}</label>
            </a>
          ) : (
            <Link to={obj.to}>
              {obj.match === INBOX_SETUP_URL &&
                (getIsAcceleratorPlan() === false ||
                  getTwilioConfig() === false) && (
                  <span className="inboxLock">
                    <i className="fa fa-lock" />
                  </span>
                )}

              {obj.match === "/leads" && getIsAcceleratorPlan() === false && (
                <span className="inboxLock">
                  <i className="fa fa-lock" />
                </span>
              )}

              {obj.match === "/inbox/sms/all" && (
                <span
                  id="unread-notification-count"
                  className={
                    this.state.notificationCount
                      ? "inboxCount"
                      : "no-display inboxCount"
                  }
                >
                  {this.state.notificationCount}
                </span>
              )}
              {obj.match === "/leads" && (
                <span
                  id="unread-notification-count"
                  className={
                    this.state.unseen_new_leads
                      ? "inboxCount"
                      : "no-display inboxCount"
                  }
                >
                  {this.state.unseen_new_leads}
                </span>
              )}
              <label htmlFor={obj.label}>{obj.label}</label>
            </Link>
          )}
        </li>
      );
    });

    let userImage =
      userData && userData.user && userData.user["user_image_url"]
        ? userData.user["user_image_url"]
        : "../../../images/user.png";
    let companyImage = "../../../images/user.png";
    let settingsClass = "setting show-desktop right-menus-a";

    if (
      window.location &&
      window.location.pathname &&
      window.location.pathname.startsWith("/settings")
    ) {
      settingsClass = "setting show-desktop right-menus-a select";
    }

    return (
      <React.Fragment>
        <header>
          <div className="container-fluid">
            <div className="navbar-header">
              <button
                aria-controls="navbar"
                aria-expanded="false"
                className="navbar-toggle collapsed"
                data-target="#navbar"
                data-toggle="collapse"
                type="button"
              >
                <span className="sr-only">
                  {this.state.globalLang &&
                    this.state.globalLang.global_toggle_navigation}
                </span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              {isTouchMd == 1 && touchMdPlan == "practice_booking"
                ? ""
                : getIsAcceleratorPlan() === false && (
                    <a
                      className="new-blue-btn upgradeBtn show-mobile pull-right m-r-10 show-mobile-upgrade m-l-0"
                      onClick={this.rediretToUpgrade}
                    >
                      <i className="fa fa-suitcase"></i>
                      {this.state.globalLang &&
                        this.state.globalLang.label_upgrade}
                    </a>
                  )}
              {getIsAcceleratorPlan() === true &&
                getTimeClockSetting() === true && (
                  <div className="mobileClockIn">
                    {
                      <ClockMenu
                        handleClockAction={this.handleClockAction}
                        clock_label={this.state.clock_label}
                      />
                    }
                  </div>
                )}
              <div className="navbar-brand-wrap">
                <Link className="navbar-brand" to={getRedirectTo()}>
                  <img alt="" src={arLogo} />
                </Link>
                <GlobalSearch />
              </div>
            </div>
            <div id="navbar" className="navbar-collapse collapse">
              <div className="dropdown worksapceDropDrown">
                <button
                  className="btn btn-default dropdown-toggle"
                  type="button"
                  id="dropdownMenu1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  Change Account
                  <span className="caret"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                  {this.sortedWorkspaces
                    .filter(this.isWorkspaceActive)
                    .map((workspace) => (
                      <li key={workspace.id}>
                        <a
                          href="#"
                          onClick={() =>
                            this.switchWorkspace({
                              workspaceId: workspace.id,
                              accountId: workspace.accountId,
                            })
                          }
                          className={
                            workspace.accountId === this.props.activeAccount.id
                              ? "active"
                              : ""
                          }
                        >
                          <img
                            alt=""
                            src={workspace.imageUrl || companyImage}
                            className="accountLogo"
                          />
                          {workspace.name}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>

              <div className="nav-outer">
                <ul className="nav navbar-nav right-menus">
                  <li
                    className={
                      this.manageHeaderClass() == "user-logs" ? "active" : ""
                    }
                    data-toggle="collapse"
                    data-target="#navbar"
                  >
                    <Link
                      id="users-logs"
                      className="users-logs show-desktop right-menus-a"
                      to="/dashboard/user-logs"
                      title="User Activities"
                    ></Link>
                    <Link
                      id="users-logs"
                      className="users-logs show-mobile"
                      to="/dashboard/user-logs"
                    >
                      {this.state.globalLang &&
                        this.state.globalLang.global_view_users_activities}
                    </Link>
                  </li>
                  <li
                    className={
                      this.manageHeaderClass() == "notifications"
                        ? "active"
                        : ""
                    }
                    ref={(node) => {
                      this.node = node;
                    }}
                  >
                    {<NotificationMenu isClicked={this.state.isClicked} />}
                  </li>
                  {isTouchMd == 1 && touchMdPlan == "practice_booking"
                    ? ""
                    : getIsAcceleratorPlan() === false &&
                      this.state.userData &&
                      this.state.userData.user_type === "superadmin" && (
                        <li className="show-desktop-upgrade">
                          <a
                            className="new-blue-btn upgradeBtn"
                            onClick={this.rediretToUpgrade}
                          >
                            <i className="fa fa-suitcase"></i>{" "}
                            {this.state.globalLang &&
                              this.state.globalLang.label_upgrade}
                          </a>
                        </li>
                      )}
                  <li className="arow-link-outer">
                    <div className="arow-link">
                      <div
                        className="user-picture no-display"
                        id="headerUserImage"
                        style={{ backgroundImage: `url(${userImage})` }}
                      ></div>

                      {getIsAcceleratorPlan() === true &&
                        getTimeClockSetting() === true && (
                          <ClockMenu
                            handleClockAction={this.handleClockAction}
                            clock_label={this.state.clock_label}
                          />
                        )}
                    </div>
                  </li>

                  <li
                    className={
                      this.manageHeaderClass() == "settings" ? "active" : ""
                    }
                    data-toggle="collapse"
                    data-target="#navbar"
                  >
                    <Link
                      className={settingsClass}
                      to="/settings/profile"
                      onClick={this.navClicked.bind(this, "settings")}
                      title="Settings"
                    ></Link>
                    <Link
                      className="setting show-mobile"
                      to="/settings/profile"
                    >
                      {this.state.globalLang &&
                        this.state.globalLang.header_settings}
                    </Link>
                  </li>
                  <li data-target="#workspaceNavbar" className="headerDropDown">
                    <div className="headerUpdatedImageOuter">
                      <div
                        className="user-picture headerUpdatedImage"
                        id="headerUserImage"
                        style={{ backgroundImage: `url(${userImage})` }}
                      >
                        <div className="headerUserName">
                          <p>{this.state.user_name}</p>
                          <span>
                            {this.props.activeAccount.name
                              ? this.props.activeAccount.name
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                      <div
                        className={
                          this.state.showDropdown
                            ? "myAccountPopup"
                            : "no-display"
                        }
                        id="workspaceNavbar"
                      >
                        <div className={styles.inputSearch}>
                          <InputSearch
                            name="workspaceSearch"
                            value={this.state.workspaceSearch}
                            onChange={(value) => {
                              this.setState({
                                workspaceSearch: value,
                              });
                            }}
                          />
                        </div>
                        <div className="ClinicSection">
                          {this.searchedWorkspaces
                            .filter(this.isWorkspaceActive)
                            .map((workspace) => (
                              <a
                                key={workspace.id}
                                onClick={() =>
                                  this.switchWorkspace({
                                    workspaceId: workspace.id,
                                    accountId: workspace.accountId,
                                  })
                                }
                                className={
                                  workspace.accountId ===
                                  this.props.activeAccount.id
                                    ? "selected"
                                    : ""
                                }
                              >
                                <img
                                  alt=""
                                  src={workspace.imageUrl || companyImage}
                                  className="branchLogo"
                                />
                                {workspace.name}
                              </a>
                            ))}
                        </div>
                        <ul className="nav navbar-nav clinicdropdownLink">
                          <li>
                            <a
                              className="popupAccount"
                              onClick={this.redirectToProfile}
                            >
                              My Account
                            </a>
                          </li>
                          <li>
                            <a
                              className="popupLogout"
                              onClick={this.logUserOut}
                            >
                              Logout
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>

                <ul className="nav navbar-nav main-navbar text-nowrap">
                  {nav}
                </ul>
              </div>
            </div>
          </div>
        </header>
        <UpgradeAccountModal
          notificaionType={"feature"}
          isShowUpgradeAlertModal={this.state.isShowUpgradeAlertModal}
          handleShowUpgradeAlertModal={this.handleShowUpgradeAlertModal}
        />
        {this.state.isShowClockOutModal && (
          <ClockOutModal
            isShowClockOutModal={this.state.isShowClockOutModal}
            user_name={this.state.user_name}
            clock_in={this.state.clock_in}
            clock_out={this.state.clock_out}
            clocked_in_duration={this.state.clocked_in_duration}
            handleClockAction={this.handleClockAction}
            dismissClockOutModal={this.dismissClockOutModal}
            clock_label={this.state.clock_label}
            clock_action_current={this.state.clock_action_current}
          />
        )}
        {this.state.isShowClockOutModal === false &&
          this.state.clockOutModalWasToggled === true && <ClinicModalList />}
        {((this.state.userData &&
          this.state.userData.user_type == "superadmin") ||
          localStorage.getItem("currentUserRole") == "1") &&
          this.state.need_clinic_city_state == "true" && (
            <ClinicsModal
              clinicsList={this.state.clinic_list}
              clinicListDataTime={new Date()}
              showClinicsModal="true"
              logout={this.logUserOut}
              updateClinicsData={this.updateClinicsData}
            ></ClinicsModal>
          )}
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />

        {(this.props.isAccountOnHold || this.props.isAccountCancelled) &&
          this.state.showHoldModal && (
            <div>
              <div className="modalOverlay">
                <div className="small-popup-outer">
                  <div className="small-popup-header p-l-15">
                    <div className="popup-name">Account On Hold!</div>
                    <a
                      className="small-cross"
                      onClick={() => this.setState({ showHoldModal: false })}
                    >
                      ×
                    </a>
                  </div>
                  <div className="small-popup-content">
                    <div className="juvly-container">
                      Your account is on hold. Please update your credit card
                      details to continue using Aesthetic Record!
                    </div>
                    <button
                      className="new-blue-btn pull-right m-b-10 m-r-10"
                      onClick={() => this.setState({ showHoldModal: false })}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        {this.state.timeLeft && (
          <ModalInfo
            isOpen
            onClose={() => this.setState({ timeLeft: null })}
            onOk={() => this.setState({ timeLeft: null })}
          >
            You have <b>{this.state.timeLeft}</b> left for today
          </ModalInfo>
        )}
        {this.state.overtime && (
          <ModalInfo
            isOpen
            onClose={() => this.setState({ overtime: null })}
            onOk={() => this.setState({ overtime: null })}
          >
            <div className="m-b-10 font-16">
              Only <b>{this.state.overtime?.timeLeft}</b> will be logged since
              you've worked with overtime.
            </div>
            <div className="font-14">
              Time worked: <b>{this.state.overtime?.workedTime}</b>
            </div>
            <div className="font-14">
              Maximum time left: <b>{this.state.overtime?.timeLeft}</b>
            </div>
          </ModalInfo>
        )}
      </React.Fragment>
    );
  }
}

const HeaderWrapper = (props) => {
  useCheckUpdateUserPrivilege();
  return <Header {...props} />;
};

function mapStateToProps(state) {
  positionFooterInstantly();
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  let returnState = {
    isPosEnabled: getIsPosEnabled(),
    isDashboardEnabled: getIsDashboardEnabled(),
    currentURL:
      window.location && window.location.pathname
        ? window.location.pathname
        : "",
    isTwilioConfig: getTwilioConfig(),
    isAccountTypePaid: getIsAccountTypePaid(),
    isAcceleratorPlan: getIsAcceleratorPlan(),
    isTimeClockSetting: getTimeClockSetting(),
  };

  if (state.SettingReducer.action === "PROFILE_UPDATE") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false;
    } else {
      returnState.user = state.SettingReducer.data;
    }
  }

  if (state.DashboardReducer.action == "FETCH_POPUPS_MENU") {
    if (state.DashboardReducer.data.status != 200) {
      returnState.showLoader = false;
    } else {
      returnState.notificationData =
        state.DashboardReducer.data.data.unread_sms_notification_count;
      returnState.unseen_new_leads =
        state.DashboardReducer.data.data.unseen_new_leads;
      returnState.notificationDataTime = new Date();
    }
  }

  if (state.ClockReducer.action === "LOGGED_CLOCK_STATUS") {
    if (state.ClockReducer.data.status === 200) {
      returnState.clockStatusData = state.ClockReducer.data.data;
      returnState.isClockStatusCheck = true;
    } else {
      returnState.showLoader = false;
    }

    return returnState;
  } else if (state.ClockReducer.action === "CHANGE_LOGGED_CLOCK_STATUS") {
    toast.dismiss();
    if (state.ClockReducer.data.status === 200) {
      returnState.clockStatusData = state.ClockReducer.data.data;
      returnState.isClockStatusCheck = false;
      toast.success(languageData.global[state.ClockReducer.data.message]);
    } else {
      const isErrorMatchWithKey =
        languageData.global[state.ClockReducer.data.message];
      if (isErrorMatchWithKey) {
        state.ClockReducer.data.message === "already_clock_in" ||
        state.ClockReducer.data.message === "already_clock_out"
          ? toast.success(isErrorMatchWithKey)
          : toast.error(isErrorMatchWithKey);
      }
      returnState.showLoader = false;
    }

    return returnState;
  }

  if (state.SettingReducer.action == "UPDATE_CLINCS_HEADER") {
    if (state.SettingReducer.data.status == 200) {
      toast.dismiss();
      toast.success(languageData.global[state.SettingReducer.data.message]);
      returnState.closeClinicsPopup = true;
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message]);
    }
  }

  if (state.SettingReducer.action == "SWITCH_WORKSPACE") {
    if (state.SettingReducer.data.status == 200) {
      toast.dismiss();
      toast.success(languageData.global[state.SettingReducer.data.message]);

      const userData = state.SettingReducer.data.data;
      const userId = userData?.user?.id;
      const accountAdminId = userData?.account?.admin_id;

      returnState.showPrivacyPolicyPopup =
        Boolean(userData?.account?.update_privacy_flag) &&
        userId === accountAdminId;

      localStorage.removeItem("recentlyViewedData");
      localStorage.removeItem("calendarFilter");
      localStorage.removeItem("providerWeekFilter");
      localStorage.removeItem("focusedRange");
      localStorage.removeItem("dateFormat");
      localStorage.removeItem("twilioSetup");
      localStorage.removeItem("currencySymbol");
      localStorage.removeItem("accountType");
      localStorage.removeItem("twilioFromNumber");
      localStorage.removeItem("accountStatus");
      localStorage.removeItem("subscriptionType");
      localStorage.removeItem("cCode");
      localStorage.removeItem("currencyLabel");
      localStorage.removeItem("stripeConnection");
      localStorage.removeItem("timeFormat");
      localStorage.removeItem("time_clock_setting");
      localStorage.removeItem("isMembershipEnabled");
      localStorage.removeItem("cTerm");
      localStorage.removeItem("current_wc_full_name");
      clinicTimezone.set(userData?.user?.user_default_clinic_timezone);
      returnState.userDataTime = new Date();

      if (userData.render_view) {
        returnState.status = 605;
        returnState.twoFactorData = userData;
        localStorage.setItem("twoFactorData", JSON.stringify(userData));
      } else {
        returnState.status = 200;
        returnState.login_attempts = 1;
        returnState.loggingIn = true;
        localStorage.removeItem("showRecaptcha");
        localStorage.removeItem("login_attempts");
        returnState.userData = state.SettingReducer.data.data;

        if (
          userData.redirect_to != undefined &&
          userData.redirect_to != null &&
          userData.redirect_to != ""
        ) {
          setRedirectTo(userData.redirect_to);
          returnState.redirect_to = userData.redirect_to;
          returnState.redirect_url = getRedirectTo();
        }

        if (
          userData.redirect_to != undefined &&
          userData.redirect_to != "upgrade-account-to-stripe" &&
          userData.redirect_to != "upgrade-subscription-plan" &&
          userData.is_bba_signed != undefined &&
          userData.is_bba_signed == 1
        ) {
          returnState.status = 200;
          localStorage.setItem("currentUserRole", userData.user.user_role_id);
          localStorage.setItem("userData", JSON.stringify(userData));
          localStorage.setItem("isLoggedIn", 1);
          localStorage.setItem(
            "user_listing_settings",
            JSON.stringify(userData.user_listing_settings),
          );
        } else {
          if (
            userData.redirect_to != undefined &&
            userData.redirect_to == "upgrade-account-to-stripe"
          ) {
            returnState.status = 604;
          } else if (
            userData.redirect_to != undefined &&
            userData.redirect_to == "upgrade-subscription-plan"
          ) {
            returnState.status = 603;
          } else {
            setRedirectTo("accept-agreement");
            returnState.status = 602;
          }

          const tempLoggedUserData = {
            userData: userData,
            currentUserRole: userData.user.user_role_id,
            isLoggedIn: 1,
            user_listing_settings: userData.user_listing_settings,

            access_token: getToken(),
          };

          localStorage.setItem(
            "tempLoggedUserData",
            JSON.stringify(tempLoggedUserData),
          );
        }
      }
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.accountInactiveDataTime = new Date();
    }
  }
  if (state.SettingReducer.action === "UPDATE_HEADER") {
    if (state.SettingReducer.data.status == 200) {
      returnState.headerUpdate = true;
      returnState.updateHeaderDateTime = new Date();
    }
  }

  if (state.LeadsReducer.action === "RESET_LEADS_COUNT") {
    returnState.headerUpdate = true;
    returnState.updateHeaderDateTime = new Date();
    returnState.unseen_new_leads = 0;
  }

  returnState.isAccountOnHold =
    state.AccountHoldRedirectReducer.isAccountOnHold;
  returnState.isAccountCancelled =
    state.AccountHoldRedirectReducer.isAccountCancelled;

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchClockStatus,
      changeClockStatus,
      emptyClockReducer,
      updateClinicsData,
      switchWorkspaces,
      refreshProfilePage,
      refreshDashboard,
      fetchNotificationsPopupsMenu,
      refreshClientsData,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withRouter(
    withWorkspaces(withCurrentUserQuery(withBetaFeatures(HeaderWrapper))),
  ),
);
