import React from "react";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Input } from "../../../../../../shared/Input/Input";
import { InputError } from "../../../../../../shared/InputError/InputError";
import styles from "./styles.module.scss";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";
import { useEfaxTemplatePlaceholdersQuery } from "../../../../../../api/efax/useEfaxTemplatePlaceholdersQuery";
import { AlertBox } from "../../../../../../shared/AlertBox/AlertBox";
import { InfoIcon } from "../../../../../../assets/Icons/InfoIcon";
import { TextEditor } from "../../../../../../shared/TextEditor/TextEditor";

/**
 * @param {object} param0
 * @param {{
 *  value: string;
 *  onChange: (nextValue) => void;
 *  error?: string;
 * }} param0.name
 * @param {{
 *  value: string;
 *  onChange: (nextValue) => void;
 *  error?: string;
 * }} param0.content
 * @param {boolean} param0.isLoading
 */
export const FormCreate = ({ name, content, isLoading }) => {
  const { tSettings } = useAppTranslation.Settings();
  const { data: placeholders } = useEfaxTemplatePlaceholdersQuery();

  return (
    <div className={styles.root}>
      <div>
        <div>
          <InputLabel>{tSettings("efaxTemplates.form.name")}</InputLabel>
          {isLoading ? (
            <Skeleton height={48} borderRadius="8px" />
          ) : (
            <Input
              value={name.value}
              onChange={(e) => name.onChange(e.target.value)}
            />
          )}
          {!!name.error && <InputError>{name.error}</InputError>}
        </div>
        <div>
          <InputLabel>{tSettings("efaxTemplates.form.content")}</InputLabel>
          {isLoading ? (
            <Skeleton height={200} borderRadius="8px" />
          ) : (
            <TextEditor
              value={content.value}
              onChange={(value) => content.onChange(value)}
              init={{ min_height: 250 }}
            />
          )}
          {!!content.error && <InputError>{content.error}</InputError>}
        </div>
      </div>
      {placeholders && (
        <AlertBox className={styles.placeholdersBox}>
          <div className={styles.placeholdersHeader}>
            <InfoIcon width="17px" />
            {tSettings("efaxTemplates.placeholder.title")}:
          </div>
          {placeholders.map((placeholder) => (
            <div key={placeholder}>
              <b>{placeholder}</b> -{" "}
              {tSettings(`efaxTemplates.placeholder.${placeholder}`)}
            </div>
          ))}
        </AlertBox>
      )}
    </div>
  );
};
