import { tBi } from "../../../../../../i18n/useAppTranslation";

export const useDepositSlipReport = (data) => {
  const reportData = [
    {
      label: tBi("salesSummaryReport.staticData.procedureAndRetailSales"),
      value: data?.dailyDepositSlip?.deposit_daily_gross_sales,
      redirect: "/refactor-business-insight/sales/gross_sales",
    },
    {
      label: tBi("membershipOverviewReport.label"),
      value: data?.dailyDepositSlip?.deposit_daily_membership_revenue,
      redirect: "/refactor-business-insight/sales/membership_overview",
    },
    {
      label: "Wallet Credits",
      value: data?.dailyDepositSlip?.deposit_pre_paid_products,
    },
    {
      label: tBi("salesSummaryReport.staticData.giftCardPurchases"),
      value: data?.dailyDepositSlip?.gift_card_purchase,
      redirect: "/business-insight/sales/egift_cards_revenue",
    },
    {
      label: "Appointment Cancellation Fee",
      value: data?.dailyDepositSlip?.cancellation_feecollected,
    },
    {
      label: "Wallet Debits",
      value: data?.dailyDepositSlip?.deposit_wallet_debits,
      leftSymbol: "-",
    },
    {
      label: "Discount & Complimentary",
      value: data?.dailyDepositSlip?.deposit_discount_complementry,
      leftSymbol: "-",
    },
    {
      label: "Membership Coupons",
      value: data?.dailyDepositSlip?.deposit_membership_coupons,
      leftSymbol: "-",
    },
    {
      label: "Evolus Rewards",
      value: data?.dailyDepositSlip?.deposit_evolus_coupons,
      leftSymbol: "-",
    },
    {
      label: "eGift Cards Redemption",
      value: data?.dailyDepositSlip?.deposit_gift_card,
      leftSymbol: "-",
    },
    {
      label: "Refunds",
      value: data?.dailyDepositSlip?.deposit_refunds,
      leftSymbol: "-",
    },
    {
      label: "Processing & Business Fees",
      value: data?.dailyDepositSlip?.deposit_processing_and_bussiness_fees,
      leftSymbol: "-",
    },
    {
      label: "Collected Payments",
      value: data?.dailyDepositSlip?.daily_deposit_total_collected_payments,
      isBold: true,
    },
    {
      label: "Payments from Unmatched Revenue",
      value: data?.dailyDepositSlip?.payments_unmatched_revenue,
      leftSymbol: "+",
    },
    {
      label: "Future Rewards Payments (Alle, Aspire, Xperience)",
      value: data?.dailyDepositSlip?.future_rewards_payments,
      leftSymbol: "-",
    },
    {
      label: "Total Deposit Amount",
      value: data?.dailyDepositSlip?.daily_deposit_total_net_Sales,
      isBold: true,
    },
  ];

  return { reportData };
};
