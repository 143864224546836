import * as yup from "yup";
import validator from "validator";
import { useEffect, useMemo, useState } from "react";
import { useExtendedFormik } from "../../../../../../hooks/useExtendedFormik";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import { useUsersQuery } from "../../../../../../api/queries/useUsersQuery";
import { useEfaxTemplatesQuery } from "../../../../../../api/efax/useEfaxTemplatesQuery";
import { useFavoritesOptions } from "./useFavoritesOptions";

export const useForm = (submitter) => {
  const { tInbox } = useAppTranslation.Inbox();
  const { data: currentUser } = useCurrentUserQuery();
  const { data: users } = useUsersQuery();
  const { data: templates } = useEfaxTemplatesQuery();
  const [makeDefault, setMakeDefault] = useState(false);

  const allUsers = useMemo(() => {
    return users?.map((user) => ({
      label: `${user.firstname} ${user.lastname}`,
      value: user.id,
    }));
  }, [users]);

  const currentUserSelected = allUsers?.find(
    (user) => user.value === currentUser?.id,
  );

  const favoritesOptions = useFavoritesOptions();

  const templateOptions = useMemo(() => {
    return templates?.templates.map((template) => ({
      label: template.name,
      value: template.id,
    }));
  }, [templates]);

  const { values, changeField, getError, handleSubmit } = useExtendedFormik({
    enableReinitialize: true,
    initialValues: {
      clinic: null,
      favorites: {
        label: "",
        value: null,
        faxNumber: "",
        templateId: null,
      },
      patient: null,
      template: null,
      faxNumber: "",
      user: currentUserSelected,
      checkedTests: [],
    },
    validationSchema: yup.object({
      favorites: yup
        .object({
          label: yup
            .string()
            .required(
              tInbox("efaxOrders.modalAdd.formError.favoritesRequired"),
            ),
          value: yup.number(),
        })
        .nullable()
        .required(tInbox("efaxOrders.modalAdd.formError.favoritesRequired")),
      clinic: yup
        .object({
          label: yup.string().required(),
          value: yup.number().required(),
        })
        .nullable()
        .required(tInbox("efaxOrders.modalAdd.formError.clinicRequired")),
      faxNumber: yup
        .string()
        .required("Missing Fax Number")
        .test("is-numeric", "Fax Number must be a number", (value) =>
          validator.isNumeric(value || ""),
        ),
      template: yup
        .object({
          label: yup
            .string()
            .required(tInbox("efaxOrders.modalAdd.formError.templateRequired")),
          value: yup.number(),
        })
        .nullable()
        .required(tInbox("efaxOrders.modalAdd.formError.templateRequired")),
      checkedTests: yup.array().min(1, "At least one test must be selected"),
    }),
    onSubmit: submitter,
  });

  useEffect(() => {
    const preselectedTemplate = templateOptions?.find(
      (t) => t.value === values.favorites.templateId,
    );
    if (preselectedTemplate) {
      changeField("template", preselectedTemplate);
    } else {
      changeField("template", null);
    }
  }, [values.favorites.value, templateOptions]);

  return {
    submit: handleSubmit,
    patient: {
      value: values.patient,
      onChange: (x) => changeField("patient", x),
      getError: () => getError("patient"),
    },
    favorites: {
      value: values.favorites,
      onChange: (x) => changeField("favorites", x),
      getError: () => getError("favorites"),
      options: favoritesOptions,
    },
    clinic: {
      value: values.clinic,
      onChange: (x) => changeField("clinic", x),
      getError: () => getError("clinic"),
    },
    faxNumber: {
      value: values.faxNumber,
      onChange: (x) => changeField("faxNumber", x),
      getError: () => getError("faxNumber"),
    },
    user: {
      value: values.user,
      options: allUsers,
      onChange: (x) => changeField("user", x),
      getError: () => getError("user"),
    },
    checkedTests: {
      value: values.checkedTests,
      onChange: (x) => changeField("checkedTests", x),
      getError: () => getError("checkedTests"),
    },
    template: {
      value: values.template,
      options: templateOptions,
      onChange: (x) => changeField("template", x),
      getError: () => getError("template"),
    },
    makeDefault: {
      value: makeDefault,
      onChange: () => setMakeDefault((prev) => !prev),
    },
  };
};
