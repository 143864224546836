import React, { Component } from "react";
import {
  isPositiveNumber,
  isFormSubmit,
  getCurrencySymbol,
  isNumber,
  checkIfPermissionAllowed,
} from "../../../Utils/services.js";
import popupClose from "../../../_legacy/images/popupClose.png";
import { toast } from "react-toastify";
import { withRouter } from "react-router";
import { Input } from "../../../shared/Input/Input.js";
import { Select } from "../../../shared/Select/Select.js";
import { Textarea } from "../../../shared/Textarea/Textarea.js";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal.js";
import { Button } from "../../../shared/Button/Button.js";

const initMembershipTypeProductClass = () => {
  return {
    product_name: "table-updated-td",
    units: "units filled-border",
  };
};

const frequencyOptions = [
  { label: "Monthly", value: "monthly", name: "frequency_option" },
  { label: "Yearly", value: "yearly", name: "frequency_option" },
  { label: "Both", value: "both", name: "frequency_option"}
];

class MembershipTypeModal extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      showLoader: false,

      settingsLang: languageData.settings,
      globalLang: languageData.global,
      inventoryLang: languageData.inventory,
      isEditWallet: false,
      isEditMemberShip: false,
      showMembershipTypeModal: false,
      membershipTypeList: [],
      membershipTypeData: {},
      membershipTierId: 0,
      isActive: null,
      membership_type_tier_name: "",
      membership_type_discount_percentage: "",
      membership_type_price_per_month: "",
      membership_type_one_time_setup_fee: "",
      membership_type_tier_nameClass: "",
      membership_type_discount_percentageClass: "",
      membership_type_price_per_monthClass: "",
      membership_type_price_per_yearClass: "",
      membership_type_one_time_setup_feeClass: "",
      membership_type_product: [],
      membership_type_productClass: [],

      productList: [],
      searched_product_id: 0,
      searched_product_name: "",
      is_product_search: false,
      search_product_keyword: "",
      searched_product_units: "",
      search_product_keywordClass: "",
      searched_product_unitsClass: "",
      is_search_first_time: true,
      frequency_option: "monthly",
      membership_price_per_year: "",
      autoRenew: "",

      membership_color: "",
      membershipColorList: [],
      membership_colorError: false,
      agreement_id: "",
      agreementClass: "",
      membership_type_agreement_Class: "full-width",
      add_fees_to_client_wallet: false,
      show_membership_on_portal: true,
      show_discount_on_portal: false,
      membership_description_for_booking_portal: "",
      isDiscountPriceEditFirstModal: false,
      isDiscountPriceEditSecondModal: false,
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.handleOnClick, false);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.autoProductData?.products &&
      prevState.autoProductData !== nextProps.autoProductData
    ) {
      const { products } = nextProps.autoProductData;
      return {
        autoProductData: nextProps.autoProductData,
        productList: products,
        showLoader: false,
        is_search_first_time: prevState.is_search_first_time
          ? false
          : prevState.is_search_first_time,
      };
    }

    if (
      nextProps.membershipTypeData?.tier_name &&
      prevState.membershipTypeData !== nextProps.membershipTypeData
    ) {
      const {
        tier_name,
        discount_percentage,
        price_per_month,
        one_time_setup_fee,
        membership_payment_options,
        price_per_year,
        membership_auto_renew,
        membership_color,
        membership_agreement_id,
        add_fees_to_client_wallet = false,
        add_fee_without_discount_to_client_wallet = false,
        show_membership_on_portal = false,
        show_discount_on_portal = false,
        membership_description_for_booking_portal = "",
        tier_products = null,
      } = nextProps.membershipTypeData;

      const membership_type_product = (tier_products || []).map((obj) => ({
        product_id: obj.product_id,
        product_name: obj.product?.product_name || "",
        units: obj.units,
      }));

      const membership_type_productClass = (tier_products || []).map((obj) =>
        initMembershipTypeProductClass(),
      );

      return {
        membershipTypeData: nextProps.membershipTypeData,
        showMembershipTypeModal: true,
        membership_type_tier_name: tier_name,
        membership_type_discount_percentage: discount_percentage,
        membership_type_price_per_month: price_per_month,
        membership_type_one_time_setup_fee: one_time_setup_fee,
        frequency_option: membership_payment_options,
        membership_price_per_year: price_per_year,
        autoRenew: membership_auto_renew,
        membership_color,
        membership_colorError: false,
        agreement_id: membership_agreement_id,
        add_fees_to_client_wallet,
        add_fee_without_discount_to_client_wallet,
        show_membership_on_portal,
        show_discount_on_portal,
        membership_description_for_booking_portal,
        membership_type_product,
        membership_type_productClass,
      };
    }

    if (
      nextProps.showMembershipTypeModal !== undefined &&
      nextProps.showMembershipTypeModal !== prevState.showMembershipTypeModal
    ) {
      return { showMembershipTypeModal: nextProps.showMembershipTypeModal };
    }

    if (
      nextProps.membershipTierId !== undefined &&
      nextProps.membershipTierId !== prevState.membershipTierId
    ) {
      // return { membershipTierId: nextProps.membershipTierId }
    }

    return null;
  }

  handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;

    if (value === "settings/membership-agreements/create") {
      this.props.history.push(`/${value}`);
    } else if (name === "agreement") {
      this.setState({ agreement_id: value });
    }

    const isChecked = type === "checkbox" ? checked : undefined;

    this.setState({
      [name]: isChecked !== undefined ? isChecked : value,
      userChanged: true,
    });
  };

  handleSelectChange = (selectedValue) => {
    const { name, value } = selectedValue;
    this.setState({
      [name]: value,
      userChanged: true,
    });
  };

  showMembershipTypeModal = (membershipTierId) => {
    this.setState({
      membershipTierId: membershipTierId,
      showMembershipTypeModal: true,
    });
  };

  hideMembershipTypeModal = (membershipTierId) => {
    this.props.hideMembershipTypeModal({
      showMembershipTypeModal: false,
      membershipTierId: 0,
    });
    //this.setState({ showMembershipTypeModal: false, membershipTierId: 0 })
  };

  checkIsFeeAndDiscountPriceEdit = (stateData, propsData) => {
    const { membership_type_price_per_month, membership_price_per_year } =
      stateData;
  
    const { price_per_month, price_per_year, membership_payment_options } =
      propsData;
  
    const monthlyPriceChanged =
      parseFloat(membership_type_price_per_month) !== parseFloat(price_per_month);
    const yearlyPriceChanged =
      parseFloat(membership_price_per_year) !== parseFloat(price_per_year);
  
    if (membership_payment_options?.toLowerCase() === "both") {
      return monthlyPriceChanged || yearlyPriceChanged;
    } else if (membership_payment_options?.toLowerCase() === "monthly") {
      return monthlyPriceChanged;
    } else if (membership_payment_options?.toLowerCase() === "yearly") {
      return yearlyPriceChanged;
    }
  
    return false;
  };

  compareTierProductDataList = (membershipProduct, tierProduct) => {
    if (membershipProduct?.length !== tierProduct?.length) {
      return false;
    }
    const sortedMembershipProduct = membershipProduct
      ?.slice?.()
      .sort?.(
        (a, b) => membershipProduct.indexOf(a) - membershipProduct.indexOf(b),
      );
    const sortedTierProduct = tierProduct
      ?.slice?.()
      .sort?.((a, b) => tierProduct.indexOf(a) - tierProduct.indexOf(b));
  
    for (let i = 0; i < sortedMembershipProduct.length; i++) {
      const memberProductObj = sortedMembershipProduct[i];
      const tierProductObj = sortedTierProduct[i];
  
      if (
        parseInt(memberProductObj.units) !== parseInt(tierProductObj.units) ||
        memberProductObj.product_id !== tierProductObj.product_id
      ) {
        return false;
      }
    }
    return true;
  };

  handleCheckProductDiscountPriceEdit = () => {
    if (this.props.membershipTierId > 0) {
      const { membership_type_discount_percentage, membership_type_product } =
        this.state;
  
      const { discount_percentage, tier_products } =
        this.props?.membershipTypeData;
  
      const isFreeProductNotEdit = this.compareTierProductDataList(
        membership_type_product,
        tier_products,
      );

      const wasMembershipFeeToggleChanged = this.props.membershipTypeData?.add_fees_to_client_wallet !== this.state.add_fees_to_client_wallet;

      const valuesAreSame =
        parseFloat(membership_type_discount_percentage) ===
          parseFloat(discount_percentage) &&
        !this.checkIsFeeAndDiscountPriceEdit(
          this.state,
          this.props?.membershipTypeData,
        ) &&
        isFreeProductNotEdit && 
        !wasMembershipFeeToggleChanged;
      return {
        isFreeProductNotEdit: isFreeProductNotEdit,
        isDiscountProductAndFeeNotEdit: valuesAreSame,
      };
    } else
      return { 
        isFreeProductNotEdit: true,
        isDiscountProductAndFeeNotEdit: true 
      };
  };

  handleSubmitMembershipType = (event, isEditedMemberShipData) => {
    event.preventDefault();
    const {
      membership_type_tier_name,
      membership_type_discount_percentage,
      membership_type_price_per_month,
      membership_price_per_year,
      membership_type_one_time_setup_fee,
      agreement_id,
      membership_type_product,
      membership_color,
    } = this.state;
    const {isFreeProductNotEdit, isDiscountProductAndFeeNotEdit } = this.handleCheckProductDiscountPriceEdit();

    if(isDiscountProductAndFeeNotEdit || isEditedMemberShipData) {
      if (isFormSubmit()) {
        let error = false;

        if (
          !membership_type_tier_name ||
          membership_type_tier_name.trim() === ""
        ) {
          this.setState({
            membership_type_tier_nameClass: "simpleInput field-error",
          });
          error = true;
        } else {
          this.setState({ membership_type_tier_nameClass: "simpleInput" });
        }

        if (
          !isNumber(membership_type_discount_percentage) ||
          !isPositiveNumber(membership_type_discount_percentage, 0, 100)
        ) {
          this.setState({
            membership_type_discount_percentageClass: "group-error",
          });
          error = true;
        } else {
          this.setState({ membership_type_discount_percentageClass: "" });
        }

        if (
          (this.state.frequency_option?.toLowerCase() === "monthly" ||
            this.state.frequency_option?.toLowerCase() === "both") &&
          (!membership_type_price_per_month ||
            !isPositiveNumber(membership_type_price_per_month, 1))
        ) {
          this.setState({ membership_type_price_per_monthClass: "group-error" });
          error = true;
        } else {
          this.setState({ membership_type_price_per_monthClass: "" });
        }

        if (
          (this.state.frequency_option?.toLowerCase() === "yearly" ||
            this.state.frequency_option?.toLowerCase() === "both") &&
          (!membership_price_per_year ||
            !isPositiveNumber(membership_price_per_year, 1))
        ) {
          this.setState({ membership_type_price_per_yearClass: "group-error" });
          error = true;
        } else {
          this.setState({ membership_type_price_per_yearClass: "" });
        }

        if (!isNumber(membership_type_one_time_setup_fee) || parseFloat(membership_type_one_time_setup_fee) < 0) {
          this.setState({
            membership_type_one_time_setup_feeClass: "group-error",
          });
          error = true;
        } else {
          this.setState({ membership_type_one_time_setup_feeClass: "" });
        }

        if (!agreement_id) {
          this.setState({ membership_type_agreement_Class: "group-error" });
          error = true;
        } else {
          this.setState({ membership_type_agreement_Class: "" });
        }

        const membership_type_productClass = membership_type_product.map(
          (obj, idx) => {
            if (!obj.units || !isPositiveNumber(obj.units, 0.001, null, 4)) {
              error = true;
              return { units: "units filled-border field-error" };
            } else {
              return { units: "units" };
            }
          },
        );

        this.setState({ membership_type_productClass });

        if (!membership_color) {
          this.setState({ membership_colorError: true });
          error = true;
          toast.error("Please choose membership color");
        }

        if (error) {
          return;
        }

        const formData = {
          tier_name: membership_type_tier_name,
          discount_percentage: membership_type_discount_percentage,
          price_per_month: this.state.frequency_option?.toLowerCase() === "monthly" ||
             this.state.frequency_option?.toLowerCase() === "both" ?
             membership_type_price_per_month :
             0,
          one_time_setup_fee: membership_type_one_time_setup_fee,
          membership_payment_options: this.state.frequency_option,
          price_per_year: this.state.frequency_option?.toLowerCase() === "yearly" ||
             this.state.frequency_option?.toLowerCase() === "both" ?
             membership_price_per_year :
             0,
          products: membership_type_product.length > 0 ? membership_type_product : [],
          membership_auto_renew: this.state.autoRenew ? this.state.autoRenew : 0,
          membership_color: membership_color,
          membership_agreement_id: agreement_id,
          add_fees_to_client_wallet: this.state.add_fees_to_client_wallet ? 1 : 0,
          add_fee_without_discount_to_client_wallet: this.state
             .add_fee_without_discount_to_client_wallet ?
             1 :
             0,
          show_membership_on_portal: this.state.show_membership_on_portal ? 1 : 0,
          show_discount_on_portal: this.state.show_discount_on_portal ? 1 : 0,
          membership_description_for_booking_portal: this.state.membership_description_for_booking_portal,
          free_monthly_products: !isFreeProductNotEdit ? 1 : 0,
          apply_on_existing: this.state.isDiscountPriceEditSecondModal ? 1 : 0,
       };
        this.props.saveMembershipMultiTier(formData);
      }
    } else {
      if (checkIfPermissionAllowed("update-existing-membership-fee-discount")) {
        this.setState({ isDiscountPriceEditFirstModal: true });
      } else {
        toast.error(this.state.globalLang?.action_not_allowed);
      }      
    }
  };

  handleConfirmModalAction = (
    event,
    isChangesForBothOrSingleMember,
    isApplyForBothMember,
  ) => {
    const isEditMode = true;
  
    if (isChangesForBothOrSingleMember) {
      this.setState({
        isDiscountPriceEditSecondModal: true,
        isDiscountPriceEditFirstModal: false,
      });
    } else if (!isApplyForBothMember) {
      this.setState({ isDiscountPriceEditFirstModal: false });
      this.handleSubmitMembershipType(event, isEditMode);
    } else {
      this.setState({ isDiscountPriceEditSecondModal: false });
      this.handleSubmitMembershipType(event, isEditMode);
    }
  }; 

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOnClick, false);
  }

  handleOnClick = (e) => {
    if (
      this.ref_search_product !== undefined &&
      this.ref_search_product !== null &&
      !this.ref_search_product.contains(e.target) &&
      this.state.is_product_search !== undefined &&
      this.state.is_product_search === true
    ) {
      this.setState({ is_product_search: false });
    }
  };

  searchProductByName = (value) => {
    const { membership_type_product } = this.state;
    const selected_product_ids = membership_type_product
      .filter((obj) => obj.product_id)
      .map((obj) => obj.product_id);

    const formData = {
      params: {
        limit: 20,
        selected_product_ids: selected_product_ids.join(","),
        term: value.trim(),
      },
    };

    if (typeof value === "string" && value.length >= 3) {
      this.props.searchProductByName(formData);
    } else {
      this.setState({ is_search_first_time: true, productList: [] });
    }
  };

  handleProductChange = (event) => {
    const target = event.target;
    let value = target.value;
    let name = event.target.name;
    let returnState = {};

    returnState[event.target.name] = value;
    returnState.is_product_search = true;
    returnState.searched_product_id = 0;
    returnState.searched_product_name = "";
    this.setState(returnState);
    this.searchProductByName(value);
  };

  handleOnFocus = (mode, index, event) => {
    //this.searchProductByName(this.state.search_product_keyword)
    this.setState({ is_search_first_time: true, productList: [] });
  };

  handleOnBlur = (event) => {
    this.setState({ is_product_search: false, is_search_first_time: true });
  };

  selectProduct = (event) => {
    const target = event.target;
    let name = event.currentTarget.dataset.name;
    let id = event.currentTarget.dataset.id;
    this.setState({
      searched_product_id: id,
      searched_product_name: name,
      search_product_keyword: name,
      is_product_search: false,
    });
  };

  handleAddMembershipProduct = (event) => {
    event.preventDefault();

    const {
      searched_product_id,
      searched_product_units,
      searched_product_name,
    } = this.state;
    let error = false;

    if (!searched_product_id) {
      this.setState({
        search_product_keywordClass: "group-error",
      });
      if (this.state.search_product_keyword !== "") {
        //toast.dismiss();
        //toast.error(this.state.inventoryLang.inventory_error_please_selectvalid_product_name);
      }
      error = true;
    } else {
      this.setState({
        search_product_keywordClass: "",
      });
    }
    if (
      !searched_product_units ||
      searched_product_units === "" ||
      !isPositiveNumber(searched_product_units, 0.001, null, 4)
    ) {
      this.setState({
        searched_product_unitsClass: "group-error",
      });
      error = true;
    } else {
      this.setState({
        searched_product_unitsClass: "",
      });
    }

    if (error) return;

    const membership_type_product = [
      ...this.state.membership_type_product,
      {
        product_id: searched_product_id,
        product_name: searched_product_name,
        units: searched_product_units,
      },
    ];
    const membership_type_productClass = [
      ...this.state.membership_type_productClass,
      initMembershipTypeProductClass(),
    ];

    this.setState({
      membership_type_product,
      membership_type_productClass,
      searched_product_id: 0,
      searched_product_name: "",
      search_product_keyword: "",
      is_product_search: false,
      searched_product_units: "",
      productList: [],
      is_search_first_time: true,
    });
  };

  handleDeleteMembershipProduct = (index) => {
    let membership_type_product = this.state.membership_type_product;
    let membership_type_productClass = this.state.membership_type_productClass;
    membership_type_product.splice(index, 1);
    membership_type_productClass.splice(index, 1);
    this.setState({
      membership_type_product: membership_type_product,
      membership_type_productClass: membership_type_productClass,
    });
  };

  handleChangeMembershipUnit = (event) => {
    const target = event.target;
    let value = target.value;
    let name = event.target.name;
    let index = event.currentTarget.dataset.index;
    if (index != undefined) {
      let membership_type_product = this.state.membership_type_product;
      membership_type_product[index]["units"] = value;
      let membership_type_productClass =
        this.state.membership_type_productClass;
      if (value) {
        membership_type_productClass[index]["units"] = "units";
      } else {
        membership_type_productClass[index]["units"] =
          "units filled-border field-error";
      }
      this.setState({
        membership_type_product: membership_type_product,
        membership_type_productClass: membership_type_productClass,
      });
    }
  };

  render() {
    return (
      <div
        className={
          this.state.showMembershipTypeModal === true
            ? "blackOverlay"
            : "blackOverlay no-display"
        }
      >
        <div className="vert-middle">
          <div className="white-popup membership-type-modal">
            <div className="white-popup-wrapper">
              <div className="membershipTypeTitle m-b-15">
                {this.props.membershipTierId
                  ? this.state.settingsLang.settings_edit_membership_type
                  : this.state.settingsLang.settings_create_membership_type}
                <a
                  onClick={() => this.hideMembershipTypeModal()}
                  className="popupClose"
                >
                  <img src={popupClose} />
                </a>
              </div>
              <div className="row editMembershipType">
                <div className="col-sm-12 col-xs-12 p-l-0 p-r-0">
                  <div className="col-xs-8 m-b-5">
                    <div className="simpleField">
                      <div className="simpleLabel">
                        {this.state.settingsLang.settings_membership_type_name}
                        <span className="setting-require">*</span>
                      </div>
                      <Input
                        type="text"
                        className={this.state.membership_type_tier_nameClass}
                        autoComplete="off"
                        name="membership_type_tier_name"
                        value={this.state.membership_type_tier_name}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-12">
                    <div className="simpleField memberprice">
                      <div className="simpleLabel">
                        {this.state.inventoryLang.inventory_Discount}
                        <span className="setting-require">*</span>
                      </div>
                      <Input
                        className={
                          this.state.membership_type_discount_percentageClass
                        }
                        type="text"
                        placeholder={"0.00"}
                        autoComplete="off"
                        name="membership_type_discount_percentage"
                        value={this.state.membership_type_discount_percentage}
                        onChange={this.handleInputChange}
                        rightAdornment="%"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-xs-12 p-l-0 p-r-0">
                  <div className="col-sm-4 col-xs-12">
                    <div className="simpleField memberDiscount ">
                      <div className="simpleLabel">
                        Payment Frequency Options
                        <span className="setting-require">*</span>
                      </div>
                      <Select
                        options={frequencyOptions}
                        value={frequencyOptions.find(
                          (i) => i.value === this.state.frequency_option,
                        )}
                        onChange={this.handleSelectChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-12">
                    <div className="simpleField memberprice">
                      <div className="simpleLabel">
                        One-Time Setup Fee
                        <span className="setting-require">*</span>
                      </div>
                      <Input
                        type="text"
                        placeholder={"0.00"}
                        autoComplete="off"
                        className={
                          this.state.membership_type_one_time_setup_feeClass
                        }
                        name="membership_type_one_time_setup_fee"
                        value={this.state.membership_type_one_time_setup_fee}
                        onChange={this.handleInputChange}
                        rightAdornment={getCurrencySymbol()}
                      />
                    </div>
                  </div>
                  {(this.state.frequency_option?.toLowerCase() == "monthly" ||
                    this.state.frequency_option?.toLowerCase() == "both") && (
                    <div className="col-sm-4 col-xs-12">
                      <div className="simpleField memberprice">
                        <div className="simpleLabel">
                          {
                            this.state.settingsLang
                              .Patient_MONTHLY_MEMBERSHIP_FEES
                          }
                          <span className="setting-require">*</span>
                        </div>
                        <Input
                          className={
                            this.state.membership_type_price_per_monthClass
                          }
                          type="text"
                          placeholder={"0.00"}
                          autoComplete="off"
                          name="membership_type_price_per_month"
                          value={this.state.membership_type_price_per_month}
                          onChange={this.handleInputChange}
                          rightAdornment={getCurrencySymbol()}
                        />
                      </div>
                    </div>
                  )}
                  {(this.state.frequency_option?.toLowerCase() == "yearly" ||
                    this.state.frequency_option?.toLowerCase() == "both") && (
                    <div className="col-sm-4 col-xs-12">
                      <div className="simpleField memberprice">
                        <div className="simpleLabel">
                          {
                            this.state.settingsLang
                              .Patient_YEARLY_MEMBERSHIP_FEES
                          }
                          <span className="setting-require">*</span>
                        </div>
                        <Input
                          type="text"
                          className={
                            this.state.membership_type_price_per_yearClass
                          }
                          placeholder={"0.00"}
                          autoComplete="off"
                          name="membership_price_per_year"
                          value={this.state.membership_price_per_year}
                          onChange={this.handleInputChange}
                          rightAdornment={getCurrencySymbol()}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-sm-4 col-xs-12">
                    <div className="simpleField memberprice">
                      <div className="simpleLabel">
                        {"Membership Agreement"}
                        <span className="setting-require">*</span>
                      </div>
                      <Select
                        isError={
                          this.state.membership_type_agreement_Class ===
                          "group-error"
                        }
                        options={this.props.membershipAgreeData.map((item) => ({
                          label: item.name,
                          value: item.id,
                          name: "agreement_id",
                        }))}
                        value={this.props.membershipAgreeData
                          .map((item) => ({
                            label: item.name,
                            value: item.id,
                            name: "agreement_id",
                          }))
                          .find((i) => i.value === this.state.agreement_id)}
                        onChange={this.handleSelectChange}
                      />
                    </div>
                  </div>

                  <div className="col-sm-4  col-xs-8">
                    <div
                      className={
                        this.state.frequency_option?.toLowerCase() == "both"
                          ? "preferred-color full-width position-relative dropdown m-t-10"
                          : "preferred-color full-width position-relative dropdown m-t-25"
                      }
                    >
                      <label>Membership Color</label>
                      <span
                        className="current-color"
                        style={{
                          backgroundColor: this.state.membership_color
                            ? this.state.membership_color
                            : "#fff",
                          borderColor: this.state.membership_color
                            ? this.state.membership_color
                            : this.state.membership_colorError
                            ? "#e74c3c"
                            : "#667680",
                        }}
                      ></span>
                    </div>
                  </div>
                <div className="col-xs-4">
                <div
                      className={
                        this.state.frequency_option?.toLowerCase() == "both"
                          ? "preferred-color full-width position-relative dropdown m-t-10"
                          : "preferred-color full-width position-relative dropdown m-t-25"
                      }
                    >
                   
                      <div className="change-button">
                        <a
                          className="new-white-btn change-color-btn"
                          id="dropdownMenu-preferredColor2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          Change
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenu-preferredColor2"
                        >
                          {this.props.membershipColorList.map((obj, idx) => {
                            return (
                              <li key={obj} data-color={obj}>
                                <span
                                  className="color-palette"
                                  onClick={() =>
                                    this.setState({
                                      membership_color: obj,
                                      membership_colorError: false,
                                    })
                                  }
                                  style={{
                                    backgroundColor: obj,
                                    borderColor: obj,
                                  }}
                                >
                                  {obj == this.state.membership_color && (
                                    <span className="color-palette-checked">
                                      <i
                                        className="fa fa-check"
                                        style={{ color: "#fff" }}
                                      ></i>
                                    </span>
                                  )}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                </div>
                </div>              

                <div className="col-sm-6 col-xs-12">
                  <div
                    className={
                      this.state.frequency_option?.toLowerCase() == "both"
                        ? "singleCheckBox m-t-15"
                        : this.state.frequency_option?.toLowerCase() !== "monthly"
                        ? "singleCheckBox m-t-0"
                        : "no-display"
                    }
                  >
                    Auto renew yearly subscriptions{" "}
                    <input
                      type="checkbox"
                      name="autoRenew"
                      className="productDirectoryCheck"
                      checked={this.state.autoRenew == 1 ? "checked" : false}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-xs-12">
                  <div className="simpleField">
                    <div className="setting-custom-switch wallet-switch no-border m-t-10 ">
                      {
                        this.state.settingsLang
                          .patient_do_you_want_to_add_membership_fees_in_clients_wallet
                      }
                      <label className="setting-switch pull-right">
                        <input
                          type="checkbox"
                          id="add_fees_to_client_wallet1"
                          className="setting-custom-switch-input"
                          name="add_fees_to_client_wallet"
                          checked={this.state.add_fees_to_client_wallet}
                          onChange={this.handleInputChange}
                        />
                        <span className="setting-slider" />
                      </label>
                    </div>
                  </div>
                  {this.state.add_fees_to_client_wallet ? (
                    <div className="simpleField font-italic">
                      <div className="setting-custom-switch wallet-switch no-border m-t-10">
                        *If Discount Code is applied, do you want to send full
                        membership fee to Patient Wallet?
                        <label className="setting-switch pull-right">
                          <input
                            type="checkbox"
                            id="add_fee_without_discount_to_client_wallet"
                            className="setting-custom-switch-input"
                            name="add_fee_without_discount_to_client_wallet"
                            checked={
                              this.state
                                .add_fee_without_discount_to_client_wallet
                            }
                            onChange={this.handleInputChange}
                          />
                          <span className="setting-slider" />
                        </label>
                      </div>
                    </div>
                  ) : ""}

                  <div className="simpleField">
                    <div>
                      Patient Portal activation is required to enable eCommerce
                      options.
                    </div>
                    <div className="setting-custom-switch wallet-switch no-border">
                      {`Do you want to show this membership on Patient Portal? `}
                      <label className="setting-switch pull-right">
                        <input
                          type="checkbox"
                          id="show_membership"
                          className="setting-custom-switch-input"
                          name="show_membership_on_portal"
                          checked={this.state.show_membership_on_portal}
                          onChange={this.handleInputChange}
                        />
                        <span className="setting-slider" />
                      </label>
                    </div>
                  </div>

                  <div className="simpleField">
                    <div className="setting-custom-switch wallet-switch no-borde m-b-30">
                      Do you want to show discounts and free items on the
                      Patient Portal?
                      <label className="setting-switch pull-right">
                        <input
                          type="checkbox"
                          id="show_discount_on_portal"
                          className="setting-custom-switch-input"
                          name="show_discount_on_portal"
                          checked={this.state.show_discount_on_portal}
                          onChange={this.handleInputChange}
                        />
                        <span className="setting-slider" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simpleField">
                <div className="simpleLabel">
                  Membership Description For Booking Portal
                </div>
                <Textarea
                  name="membership_description_for_booking_portal"
                  value={this.state.membership_description_for_booking_portal}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="membershipTypeTitle m-b-15 m-t-10">
                {this.state.settingsLang.settings_free_monthly_products}
              </div>
              <div className="row">
                <div className="col-xs-12 p-r-0 p-l-0">
                  <div className="col-sm-6 col-xs-12">
                    <div
                      className="search_product_container"
                      ref={(ref_search_product) =>
                        (this.ref_search_product = ref_search_product)
                      }
                    >
                      <div className="simpleField">
                        <div className="simpleLabel">
                          {
                            this.state.inventoryLang
                              .inventory_product_name_label
                          }
                        </div>
                        <Input
                          type="text"
                          className={this.state.search_product_keywordClass}
                          autoComplete="off"
                          name="search_product_keyword"
                          value={this.state.search_product_keyword}
                          onChange={this.handleProductChange.bind(this)}
                          onFocus={this.handleOnFocus.bind(this)}
                        />
                      </div>
                      <ul
                        className={
                          typeof this.state.search_product_keyword ===
                            "string" &&
                          this.state.search_product_keyword.trim() !== "" &&
                          this.state.is_product_search !== undefined &&
                          this.state.is_product_search === true &&
                          this.state.search_product_keyword.length > 2
                            ? " search-dropdown"
                            : "cal-dropdown clinicname-dropdown no-display"
                        }
                      >
                        {this.state.productList.length > 0 ? (
                          this.state.productList.map((obj, idx) => {
                            return (
                              <li
                                key={obj.id}
                                data-id={obj.id}
                                data-name={obj.product_name}
                                onClick={this.selectProduct}
                              >
                                <a>{obj && obj.product_name}</a>
                              </li>
                            );
                          })
                        ) : (
                          <li
                            key={"search_product-norecord"}
                            data-id={0}
                            data-name={"product_match_not_found"}
                            data-index={-1}
                          >
                            <a>
                              {this.state.is_search_first_time
                                ? this.state.globalLang.label_searching_with_dot
                                : this.state.globalLang.product_match_not_found}
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-3 col-xs-9">
                    <div className="simpleField">
                      <div className="simpleLabel">
                        {this.state.inventoryLang.inventory_units}
                      </div>
                      <Input
                        className={this.state.searched_product_unitsClass}
                        type="text"
                        autoComplete="off"
                        name="searched_product_units"
                        value={this.state.searched_product_units}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-xs-3 m-t-25 p-l-5">
                    <a onClick={this.handleAddMembershipProduct} className="easy-link no-padding font-size-23">
                      <i className="fa fa-plus-circle m-r-5" />
                    </a>
                  </div>
                </div>
              </div>

              <p className="p-text m-b-0">
                <i>
                  Free monthly products will begin appearing in the wallet on
                  the first full month draft. In the case of a pro-rated
                  payment, no free products will be sent to the wallet.
                </i>
              </p>
            </div>
            {this.state.membership_type_product.length > 0 && (
              <div className="table-responsive appointmenTable">
                <table className="table-updated juvly-table">
                  <thead className="table-updated-thead">
                    <tr>
                      <th className="col-xs-6 table-updated-th cursor-pointer sorting p-l-25">
                        {this.state.globalLang.label_name}
                      </th>
                      <th className="col-xs-3 table-updated-th">
                        {this.state.inventoryLang.inventory_units}
                      </th>
                      <th className="col-xs-3 table-updated-th">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.membership_type_product.map((obj, idx) => {
                      return (
                        <tr key={obj.product_name} className="table-updated-tr">
                          <td className="table-updated-td p-l-25">
                            {obj.product_name}
                          </td>
                          <td className="table-updated-td count-link">
                            <input
                              type="text"
                              className={
                                this.state.membership_type_productClass[idx][
                                  "units"
                                ]
                              }
                              autoComplete="off"
                              name="units"
                              value={obj.units}
                              onChange={this.handleChangeMembershipUnit}
                              data-index={idx}
                            />
                          </td>
                          <td className="table-updated-td td-membership-product-delete">
                            <a
                              onClick={() =>
                                this.handleDeleteMembershipProduct(idx)
                              }
                              className="easy-link"
                            >
                              <i className="fa fa-trash-alt m-r-5" />
                              {this.state.globalLang.label_delete}
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
            <div className="footer-static p-r-24">
              <a
                className="new-blue-btn pull-right"
                onClick={(e)=> this.handleSubmitMembershipType(e, false)}
              >
                {this.state.globalLang.label_save}
              </a>
            </div>
          </div>
        </div>

        {this.state.isDiscountPriceEditFirstModal && <ConfirmModal
            isOpen={this.state.isDiscountPriceEditFirstModal}
            onClose={() => this.setState({ isDiscountPriceEditFirstModal: false,})}
            confirmTitle="New Members"
          >
            <div className=" d-flex flex-column justify-content-between">
                Should we apply this change to all existing & newly enrolled memberships or newly enrolled memberships only?
                <div className="d-flex gap-8 m-t-20 m-b-10 ">
                  <div className="col-auto">
                    <Button
                      onClick={(e) => this.handleConfirmModalAction(e, false)}
                      isDisabled={false}
                    >
                      New
                    </Button>
                  </div>
                  <div className="col-auto">
                    <Button
                      onClick={(e) => this.handleConfirmModalAction(e, true)}
                      isDisabled={false}
                    >
                      New & Existing
                    </Button>
                  </div>
                </div>
            </div>
          </ConfirmModal>}
        
        {this.state.isDiscountPriceEditSecondModal && <ConfirmModal
            isOpen={this.state.isDiscountPriceEditSecondModal}
            onConfirm={(e) =>  this.handleConfirmModalAction(e, false, true)}
            onCancel={() => this.setState({ isDiscountPriceEditSecondModal: false,})}
            onClose={() => this.setState({ isDiscountPriceEditSecondModal: false,})}
            confirmTitle="Yes"
            cancelTitle="No"
        >
          This change will impact your membership agreement with currently enrolled members. Do you wish to apply these changes?
          </ConfirmModal>}
          
      </div>
    );
  }
}
export default withRouter(MembershipTypeModal);
