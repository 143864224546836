import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  searchTerm: yup.string().nullable(),
});

const responseSchema = yup.object({
  templates: yup.array().of(
    yup.object({
      id: yup.number().required(),
      name: yup.string().required(),
      lastModifiedBy: yup.string().required(),
    }),
  ),
});

const composeResponse = (res) => {
  return res?.data?.data || {};
};

const createEfaxTemplatesQueryKey = (...args) => [
  QUERY_KEYS.efax.templates,
  ...args,
];

export const useEfaxTemplatesQuery = (payload = {}, options = {}) => {
  return useQuery(
    createEfaxTemplatesQueryKey(payload.searchTerm),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(HTTP_ENDPOINTS.efax.getTemplates(), {
        params: {
          name: req.searchTerm,
        },
      });
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
};
