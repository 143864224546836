import React, { Component } from 'react';
import { checkIfPermissionAllowed, numberFormat, showFormattedDate } from '../../../Utils/services.js';
import { Scrollbars } from 'react-custom-scrollbars';
import ExpiredPaymentIcon from './icons/ExpiredPaymentIcon.js';
import { shortenClinicName } from "../../../helpers/general";

const userData      = JSON.parse(localStorage.getItem('userData'));

export default class HealthTimeline extends Component {
  constructor(props) {
    super(props);

    const userData = JSON.parse(localStorage.getItem('userData'));
    const ePrescriptionSetup  = (localStorage.getItem('ePrescriptionSetup')) ? JSON.parse(localStorage.getItem('ePrescriptionSetup')) : null;

    this.state = {
      userData: userData,
      isTouchMd : userData.account.account_preference.is_touch_md,
      plan_selected : userData.account.account_subscription.plan_code,
      ePrescriptionSetup : ePrescriptionSetup
    }
  }

  get invoiceStatusIcon () {
    const status = {
      check: ['paid', 'paid_by_merge', 'refunded', 'partial_refunded', 'refunded_from_merge'],
      alert: ['partial paid', 'partial_paid']
    }
    const invoiceStatus = this.props.hobj.pos_invoices?.invoice_status
    const hasInvoice = this.props.hobj.invoice_id > 0 && this.props.hobj.pos_invoices
    const isSessionExpired = this.props.hobj.checkout_session_expired && !hasInvoice

    let iconClass = 'fa fa-times'

    if (hasInvoice) {
      if (status.check.includes(invoiceStatus)) {
        iconClass = 'fa fa-check'
      }
      if (status.alert.includes(invoiceStatus)) {
        iconClass = 'alertIcon pull-right m-t-1'
      }
    }

    const handleClick = () => {
      this.props.goToInvoicePage(this.props.hobj);
    }
    
    return (
      <li className="action-button-li pending-payment" onClick={handleClick}> 
        Invoice {invoiceStatus === "pending" && 
          <span className="inline-block">{"(pending payment)"}</span>} 
          {isSessionExpired ? 
            <ExpiredPaymentIcon /> : 
              <i className={iconClass} aria-hidden="true"></i>}
      </li>
    )
  }

  render() {
    return (
      <div className="col-xs-12 health-timeline">

        <div className="row">
          {this.state.isTouchMd == 1 && this.state.plan_selected == 'practice_booking' ?  <div className="col-sm-4 col-xs-12 m-b-15">
              <label className="popup-input-field-name">Date & Time</label>
              <p className="pro-time healthTimelineDetail m-b-0">{showFormattedDate(this.props.hobj.procedure_date, true)}</p>
            </div> :
            <div className="col-sm-4 col-xs-12 m-b-15">
              {checkIfPermissionAllowed('manage-procedures') ? <a onClick={() => this.props.openProcedureDetail(this.props.hobj.id, this.props.hobj.procedure_type)} className="modal-link"><h4 className="procedure-name">{this.props.hobj.procedure_name}</h4>
              </a> : <a className="modal-link pro-name"><h4>{this.props.hobj.procedure_name}</h4></a>}
              <p className="pro-time healthTimelineDetail m-b-0">{showFormattedDate(this.props.hobj.procedure_date, true)}</p>
            </div>}
          <div className="col-sm-4 col-xs-12 m-b-15">
            <label className="popup-input-field-name">{this.props.languageData.clientprofile_provider}</label>
            <span className="healthTimelineDetail m-b-0">{this.props.hobj.doctor_name}</span>
          </div>
          <div className="col-sm-4 col-xs-12 m-b-15">
            {(this.props.hobj.template_used == 1) &&
            <div>
              <label className="popup-input-field-name">Consultation Fee</label>
              <span className="healthTimelineDetail m-b-0">{numberFormat(this.props.hobj.consultation_fee, 'currency')}</span>
            </div>
            }
            {(this.props.hobj.template_used == 0 && this.props.hobj.clinic) &&
            <div>
              <label className="popup-input-field-name">Location</label>
              <span className="healthTimelineDetail">{shortenClinicName(this.props.hobj?.clinic?.clinic_name)}</span>
            </div>
            }
          </div>
        </div>

        {(this.props.hobj.template_used == 0 && this.props.hobj.note) &&
        <div className="row p-b-0 p-t-0">
          <div className="col-md-12">
            <div className="health-smry-outer auto-height m-b-25 ">
              <label className="title-1 p-t-20">Details</label>
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Encounter Note</div>
                <div className="newtextareaField DiagnosisDetailstextbox">
                  <Scrollbars autoHeight autoHeightMax="160px" className={"custome-scroll"}>
                    <div style={{ "paddingRight": '15px', "fontSize": '13px!important' }} className="activity-detail" dangerouslySetInnerHTML={{ __html: this.props.hobj.note }}></div>
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
        </div>
        }

        {(this.props.hobj.procedure_prescription && this.props.hobj.procedure_prescription.length > 0) &&
        <div className="row p-b-10 p-t-0">
          <div className="col-md-12">
            <div className="health-smry-outer auto-height m-b-25">
              {(this.props.hobj.procedure_prescription.length > 0) && <h4 className="title-1 m-b-10">Treatment Summary</h4>}
              {(this.props.hobj.procedure_prescription.length > 0) &&
              <ul className="treat-sumry profile-treat-sumry no-padding">
                {
                  this.props.hobj.procedure_prescription.map((ppObj, ppIdx) => {
                    return (
                      <li key={'procedure-health-' + ppIdx} className="treat-sumry-li">
                        <label>{ppObj.medicine_name}</label> <span>{(ppObj.dosage) ? ppObj.dosage : ''}</span>
                      </li>
                    )
                  })
                }
              </ul>
              }
            </div>
          </div>
        </div>
        }
        {(this.props.hobj.scripsure_medication && this.props.hobj.scripsure_medication.length > 0) &&
        <div className="row p-b-10 p-t-0">
          <div className="col-md-12">
            <div className="health-smry-outer auto-height m-b-25">
              {(this.props.hobj.scripsure_medication.length > 0) && <h4 className="title-1 m-b-10">Prescriptions</h4>}
              {(this.props.hobj.scripsure_medication.length > 0) &&
              <ul className="treat-sumry profile-treat-sumry no-padding">
                {
                  this.props.hobj.scripsure_medication.map((ppObj, ppIdx) => {
                    return (
                      <li key={'procedure-health-ePrescribe' + ppIdx} className="treat-sumry-li">
                        <label style={{"width" : "100%"}}>{ppObj.medicine}</label>
                      </li>
                    )
                  })
                }
              </ul>
              }
            </div>
          </div>
        </div>
        }

        <div className="row">
          <div className="col-xs-12">
            <div className="action-button-wrapper">
              <div className="action-button-inner">
                {this.props.hobj.erx_prescription == '0' && <div className="col-sm-3 col-xs-12 action-button-section">
                  <h3 className="action-button-title">Paperwork</h3>
                  <ul className="action-button-ul">
                    {(this.props.hobj.template_used == 1 && (this.props.hobj.answers_count > 0 || this.props.hobj.answer_multiples_count > 0)) ?
                      <li className="action-button-li" onClick={() => this.props.viewQuestionnaires(this.props.hobj.id)}>Questionnaires<i className="fa fa-check pull-right" aria-hidden="true"></i></li>
                      :
                      (this.props.hobj.template_used == 0 && this.props.hobj.show_questionnaires_btn === 1) ?
                        <li className="action-button-li" onClick={() => this.props.viewQuestionnairesList(this.props.hobj)}>Questionnaires<i className="fa fa-check pull-right" aria-hidden="true"></i></li>
                        :
                        (this.props.hobj.template_used == 0 && this.props.hobj.show_questionnaires_btn !== 1) ?
                          <li className="action-button-li" onClick={() => this.props.viewQuestionnairesList(this.props.hobj)}>Questionnaires<i className="fa fa-times pull-right" aria-hidden="true"></i></li>
                          :
                          <li className="action-button-li" >Questionnaires<i className="fa fa-times pull-right" aria-hidden="true"></i></li>
                    }


                    {((this.props.hobj.consent_ids !== null && this.props.hobj.consent_ids !== '') || this.props.hobj.show_consents_btn === 1) ? <li className="action-button-li" onClick={() => this.props.viewConsents(this.props.hobj.id)}>Consent <i className="fa fa-check pull-right" aria-hidden="true"></i></li>
                      :
                      (this.props.hobj.show_consents_btn === 0) ? <li className="action-button-li" onClick={() => this.props.viewConsents(this.props.hobj.id)}>Consent <i className="fa fa-times pull-right" aria-hidden="true"></i></li>
                        :
                        <li className="action-button-li" >Consent <i className="fa fa-times pull-right" aria-hidden="true"></i></li>
                    }
                  </ul>
                </div>}
                {this.state.isTouchMd == 1 && this.state.plan_selected == 'practice_booking' ? '' :<div className="col-sm-3 col-xs-12 action-button-section">
                  <h3 className="action-button-title">Medication</h3>
                  <ul className="action-button-ul">
                    {/*this.state.ePrescriptionSetup != null && !this.state.ePrescriptionSetup.e_prescription_new && (this.props.hobj.procedure_prescription && this.props.hobj.procedure_prescription.length > 0) && <li className="action-button-li" onClick={() => this.props.showPrescriptionNew(this.props.hobj.id, this.props.hidx, true)}>Prescription  <i className="fa fa-check pull-right" aria-hidden="true"></i></li>*/}

                    {(this.props.hobj.procedure_prescription.length > 0 && this.state.ePrescriptionSetup != null && !this.state.ePrescriptionSetup.e_prescription_new) && <li className="action-button-li" onClick={() => this.props.showPrescriptionNew(this.props.hobj.id, this.props.hidx, true)}>Prescription  <i className="fa fa-check pull-right" aria-hidden="true"></i></li>}
                    {(this.props.hobj.procedure_prescription.length == 0 && this.state.ePrescriptionSetup != null && !this.state.ePrescriptionSetup.e_prescription_new) && <li className="action-button-li" onClick={() => this.props.showPrescriptionNew(this.props.hobj.id, this.props.hidx, false)}>Prescription  <i className="fa fa-times pull-right" aria-hidden="true"></i></li>}

                    {this.state.ePrescriptionSetup != null && this.state.ePrescriptionSetup.e_prescription_new && <li className="action-button-li" onClick={() => this.props.handleEprescribe(this.props.hobj)}>e-Prescribe</li>}
                    {checkIfPermissionAllowed('manage-tracebility-info') && <li className="action-button-li" onClick={() => this.props.viewHealthTraceability(this.props.hobj.id)} >{'Traceability'}</li>}
                  </ul>
                </div>}
                {this.state.isTouchMd == 1 && this.state.plan_selected == 'practice_booking' ? '' :<div className="col-sm-3 col-xs-12 action-button-section">
                  <h3 className="action-button-title">Payment</h3>
                  <ul className="action-button-ul">
                    {this.invoiceStatusIcon}
                  </ul>
                </div>}
                {this.state.isTouchMd == 1 && this.state.plan_selected == 'practice_booking' ? '' :<div className="col-sm-3 col-xs-12 action-button-section">
                  <h3 className="action-button-title">More</h3>
                  <ul className="action-button-ul">
                    <li className="action-button-li" onClick={() => this.props.viewNotes(this.props.hobj.id)}>View Notes  ({this.props.hobj.procedure_notes.length})</li>
                    {checkIfPermissionAllowed('manage-procedures') === true && <li className="action-button-li" onClick={() => this.props.openProcedureDetail(this.props.hobj.id, this.props.hobj.procedure_type)}>Edit Procedure</li>}
                    {(this.props.hobj.template_used == 1) && (checkIfPermissionAllowed('manage-procedures') === true) && <li className="action-button-li" onClick={() => this.props.viewProcedureDetail(this.props.hobj.id, this.props.hobj.procedure_type)}>View Procedure</li>}


                  </ul>
                </div>}
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}
