import React from "react";

export const GfeIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 708.000000 754.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,754.000000) scale(0.100000,-0.100000)"
        fill="red"
        stroke="none"
      >
        <path
          d="M20 3770 l0 -3770 3530 0 3530 0 0 3770 0 3770 -3530 0 -3530 0 0
-3770z m4729 3244 c111 -31 216 -130 249 -236 8 -24 16 -78 19 -120 l6 -78
351 0 c411 0 490 -7 593 -55 99 -46 199 -141 243 -233 18 -37 38 -76 44 -87 8
-15 12 -746 14 -2680 l3 -2660 -22 -65 c-43 -130 -135 -240 -257 -306 -129
-70 80 -64 -2373 -64 -2059 0 -2216 1 -2268 17 -31 10 -73 28 -93 40 -21 13
-42 23 -46 23 -5 0 -39 27 -76 61 -71 65 -121 141 -152 230 -18 52 -19 129
-19 2699 0 2510 1 2648 18 2696 64 184 173 292 362 360 44 16 97 18 433 22
l382 4 0 72 c0 181 112 325 285 367 17 4 529 7 1139 8 978 1 1116 -1 1165 -15z"
        />
        <path
          d="M2517 6673 c-9 -8 -9 -816 -1 -837 6 -14 110 -16 1075 -16 l1069 0 0
430 0 430 -1068 0 c-588 0 -1072 -3 -1075 -7z"
        />
        <path
          d="M1425 6213 c-45 -23 -75 -54 -96 -97 -19 -39 -19 -101 -19 -2612 0
-2342 1 -2575 16 -2610 20 -49 75 -98 124 -113 27 -8 622 -11 2178 -11 l2141
0 46 24 c56 30 80 58 100 116 13 41 15 322 13 2611 l-3 2566 -30 49 c-21 34
-44 56 -75 72 -44 22 -50 22 -421 22 l-377 0 -4 -237 -4 -238 -31 -67 c-38
-81 -102 -146 -181 -185 l-57 -28 -1155 0 -1155 0 -65 31 c-38 18 -82 49 -105
75 -97 106 -105 138 -105 421 l0 228 -352 0 c-302 -1 -357 -3 -383 -17z m3896
-974 c50 -17 98 -67 113 -119 24 -81 -21 -172 -103 -206 -28 -12 -286 -14
-1718 -14 -1539 0 -1687 1 -1716 16 -77 40 -118 124 -97 200 14 50 63 103 112
120 46 17 3359 19 3409 3z m25 -464 c44 -22 72 -56 87 -106 29 -94 -33 -194
-133 -217 -72 -17 -3285 -17 -3362 -1 -64 14 -117 63 -138 127 -22 70 12 148
85 193 29 18 89 19 1731 19 1479 0 1705 -2 1730 -15z m5 -458 c50 -27 91 -94
91 -151 0 -49 -30 -104 -75 -140 l-39 -31 -1651 -3 c-909 -2 -1680 0 -1714 3
-53 6 -68 12 -106 45 -83 74 -85 174 -5 252 20 20 48 38 60 41 13 2 782 5
1709 6 l1686 1 44 -23z m-20 -441 c48 -20 88 -66 104 -119 12 -41 12 -52 -1
-91 -18 -51 -55 -93 -101 -112 -27 -12 -319 -14 -1718 -14 -1660 0 -1686 0
-1725 20 -44 22 -72 58 -90 113 -23 74 25 167 106 203 45 20 3378 20 3425 0z
m34 -474 c56 -39 89 -119 73 -181 -12 -47 -63 -106 -105 -122 -15 -5 -684 -9
-1714 -9 -1404 0 -1695 2 -1722 14 -71 29 -119 125 -99 195 15 47 45 87 86
112 30 18 83 19 1736 19 l1705 -1 40 -27z m-43 -432 c84 -24 137 -123 111
-209 -7 -25 -25 -58 -40 -73 -60 -62 57 -59 -1793 -56 l-1685 3 -35 25 c-73
52 -100 114 -80 187 15 51 79 120 118 126 64 10 3368 6 3404 -3z m13 -459 c54
-25 82 -57 99 -115 13 -41 13 -53 -1 -92 -19 -55 -62 -100 -111 -114 -25 -6
-593 -10 -1711 -10 -1654 0 -1675 0 -1716 20 -76 37 -117 118 -96 191 16 55
38 83 93 114 l43 25 1680 0 c1612 0 1682 -1 1720 -19z m12 -457 c19 -9 46 -33
61 -52 23 -30 27 -45 27 -101 0 -75 -21 -114 -79 -150 l-33 -21 -1702 0
c-1838 0 -1727 -3 -1778 52 -24 26 -53 91 -53 118 0 41 40 110 81 140 l42 30
1699 0 c1555 0 1703 -1 1735 -16z m-25 -444 c83 -24 138 -124 112 -209 -15
-51 -45 -86 -96 -112 -36 -18 -86 -19 -1719 -19 -1523 0 -1684 2 -1715 16 -83
40 -126 122 -103 202 15 53 65 107 112 121 46 13 3361 14 3409 1z"
        />
      </g>
    </svg>
  );
};
