import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useInvoiceToPending } from "./useInvoiceToPending";
import { useUpdatePaymentIntent } from "./useUpdatePaymentIntent";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import { useInvoiceToDraft } from "./useInvoiceToDraft";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import { dispatch } from "../../../../../../../store/store";
import { checkoutInvoice } from "../../../../../../../store/checkoutInvoice";

let invoiceDetailInterval = null;

export const useConfirmPayment = (dto) => {
  const stripe = useStripe();
  const elements = useElements();
  const { refetchInvoice } = useInvoice();
  const { mutateAsync: setInvoiceToPending } = useInvoiceToPending();
  const { mutateAsync: updatePaymentIntent } = useUpdatePaymentIntent();
  const { mutateAsync: setInvoiceToDraft } = useInvoiceToDraft();

  const [isLoading, setIsLoading] = useState(false);

  const confirmPayment = async () => {
    const completePayment = async () => {
      await refetchInvoice();
      invoiceDetailInterval = setInterval(() => {
        refetchInvoice().then((response) => {
          if (response?.data?.status !== "pending") {
            clearInterval(invoiceDetailInterval);
            uiNotification.success("Payment done successfully");
            dispatch(checkoutInvoice.actions.paymentViewClose());
          }
        });
      }, 2000);
    };

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    await setInvoiceToPending();
    await updatePaymentIntent(dto);

    const result = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });
    if (result.error) {
      uiNotification.error(result.error.message);
      await setInvoiceToDraft();
      setIsLoading(false);
    } else {
      completePayment();
    }
  };

  return { confirmPayment, isLoading };
};
