import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const responseSchema = yup.object({
  isEnabled: yup.boolean().required(),
  faxNumber: yup.string().nullable(),
});

const composeResponse = (res) => {
  return res?.data?.data || {};
};

const createEfaxIntegrationStatusQueryKey = () => [QUERY_KEYS.efax.status];

export const useEfaxIntegrationStatusQuery = (options = {}) => {
  return useQuery(
    createEfaxIntegrationStatusQueryKey(),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.efax.getStatus());
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
};
