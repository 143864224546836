import React, { useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";

const StripeCheckoutForm = (props) => {
  const { type, onSubmit, validation, onCancel, onSubmitText, onCancelText } =
    props;
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (validation && (await validation())) {
      return;
    }

    setIsLoading(true);

    if (type === "setupIntent") {
      try {
        const result = await stripe.confirmSetup({
          elements,
          redirect: "if_required",
          confirmParams: {},
        });

        if (result.error) {
          toast.error(result.error.message);
        } else {
          onSubmit(event);
        }
      } catch (e) {
        /**/
      }
    }
    setIsLoading(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button className="new-blue-btn pull-right m-t-20" disabled={isLoading}>
        {isLoading ? "Processing..." : onSubmitText || "Charge"}
      </button>
      {onCancel && (
        <button className="new-blue-btn pull-right m-t-20" onClick={onCancel}>
          {onCancelText || "Cancel"}
        </button>
      )}
    </form>
  );
};

export default StripeCheckoutForm;
