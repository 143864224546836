import React, { useRef, useState } from "react";
import cx from "clsx";
import { Input } from "../../../../../../shared/Input/Input";
import classes from "./styles.module.scss";
import { PlusIcon } from "../../../../../../assets/Icons/PlusIcon";
import { useClickOutside } from "../../../../../../utilities/hooks/useClickOutside";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { tSettings } from "../../../../../../i18n/useAppTranslation";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { Button } from "../../../../../../shared/Button/Button";
import { useCreate } from "../../../../../../pages/Settings/Integrations/Efax/boxes/Favourites/hooks/useCreate";
import { useEdit } from "../../../../../../pages/Settings/Integrations/Efax/boxes/Favourites/hooks/useEdit";

const FavoritesHandler = ({
  favorites,
  favoritesOptions,
  faxNumber,
  createMode,
  setCreateMode,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const faxOptionsList = useRef();

  const favoriteSearch = favoritesOptions?.filter(
    (option) =>
      option.faxNumber.includes(faxNumber.value) ||
      option.label.toLowerCase().includes(faxNumber.value.toLowerCase()),
  );

  const create = useCreate();
  const edit = useEdit();

  const createFavorite = async () => {
    if (favorites.value.value) {
      return await edit
        .initiate({
          id: favorites.value.value,
          name: favorites.value.label,
          faxNumber: faxNumber.value,
        })
        .then(() => setCreateMode(false));
    }

    await create
      .initiate({
        name: favorites.value.label,
        faxNumber: faxNumber.value,
      })
      .then(() => {
        setCreateMode(false);
      });
  };

  const handleFaxNumberChange = (value) => {
    setShowOptions(true);
    faxNumber.onChange(value);
    favorites.onChange(
      favoritesOptions?.find((fav) => fav.faxNumber === value) || {},
    );
  };

  useClickOutside(faxOptionsList, () => setShowOptions(false));

  return (
    <div className={classes.favoritesHandlerContainer}>
      <Input
        name="faxNumber"
        className={classes.favoritesInput}
        value={faxNumber.value}
        onFocus={() => setShowOptions(true)}
        onChange={(e) => handleFaxNumberChange(e.target.value)}
        isError={Boolean(faxNumber.getError())}
        type="text"
        autoComplete="off"
      />
      {faxNumber.getError() && (
        <InputError className={classes.favoritesInput}>
          {faxNumber.getError()}
        </InputError>
      )}
      {showOptions && (
        <div className={classes.favoritesOptionsList} ref={faxOptionsList}>
          <button
            className={cx(classes.favoritesOption, classes.createFavorite)}
            onClick={() => {
              setCreateMode(true);
              setShowOptions(false);
            }}
          >
            <PlusIcon />
            {`Create New Favorite "${faxNumber.value}"`}
          </button>
          {favoriteSearch.map((option) => {
            return (
              <button
                key={option.value}
                className={classes.favoritesOption}
                onClick={() => {
                  favorites.onChange(option);
                  faxNumber.onChange(option.faxNumber);
                  setShowOptions(false);
                }}
              >
                {option.label}
                <span className={classes.optionFavoriteName}>
                  {option.faxNumber}
                </span>
              </button>
            );
          })}
        </div>
      )}
      {createMode && (
        <>
          <div className={classes.mb}>
            <InputLabel>
              {tSettings("efaxIntegration.favourites.favoriteName")}
            </InputLabel>
            <Input
              name="favorite"
              value={favorites.value?.label}
              onChange={(e) => {
                favorites.onChange({ label: e.target.value });
              }}
              type="text"
              autoComplete="off"
            />
          </div>
          <div className="flex gap-8">
            <Button
              variant="outlined"
              onClick={() => {
                faxNumber.onChange("");
                setCreateMode(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={createFavorite}
              isDisabled={!favorites.value?.label || !faxNumber.value}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default FavoritesHandler;
