import React from "react";
import { useEditProductPriceListMutation } from "../../../../api/mutations/useEditProductPriceListMutation";
import { uiNotification } from "../../../../services/UINotificationService";

export const withUpdatePriceList = (Component) => (props) => {
  const { mutateAsync, isLoading } = useEditProductPriceListMutation({
    onError: () => {
      uiNotification.error("Failed to update price list");
    },
    onSuccess: () => {
      uiNotification.success("Price list updated successfully");
    },
  });

  return (
    <Component
      {...props}
      updatePriceList={{
        initiate: mutateAsync,
        isLoading,
      }}
    />
  );
};
