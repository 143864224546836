import React from "react";
import cx from "clsx";
import summaryClasses from "../scss/salesSummary.module.scss";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";

function SummaryTable({
  reportData,
  title,
  rightTitle,
  isLoading = { isLoading },
}) {
  const redirectTo = (url, label) => {
    return (
      <a
        href={url}
        target="_blank"
        className={summaryClasses.newTabOpen}
        rel="noopener noreferrer"
      >
        {label}
      </a>
    );
  };

  const composeLabel = (row) => {
    return row.redirect ? redirectTo(row.redirect, row.label) : row.label;
  };

  return (
    <div className={summaryClasses.container}>
      {isLoading ? (
        <div className={summaryClasses.tableData}>
          <Skeleton height={32} count={8} />
        </div>
      ) : (
        <table className={summaryClasses.reportTable}>
          <thead className={summaryClasses.headTable}>
            <tr>
              <th>{title}</th>
              {rightTitle && (
                <th className={summaryClasses.alignRight}>{rightTitle}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {reportData?.map((row, index) => {
              return (
                <tr
                  key={index}
                  className={cx(row.isBold && summaryClasses.bold)}
                >
                  <td
                    className={cx(
                      summaryClasses.tableData,
                      row.leftSymbol && summaryClasses.negativeTableData,
                    )}
                  >
                    {`${row.leftSymbol || ""}`} {composeLabel(row)}
                  </td>
                  <td
                    className={cx(
                      summaryClasses.tableData,
                      summaryClasses.alignRight,
                    )}
                  >
                    {row.value}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default SummaryTable;
