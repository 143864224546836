export const usePaymentMethodNetReport = (data) => {
  const reportData = [
    {
      label: "Cash",
      value: data?.dailyPaymentMethod?.daily_payment_cash,
    },
    {
      label: "Check",
      value: data?.dailyPaymentMethod?.daily_payment_check,
    },
    {
      label: "Credit Card",
      value: data?.dailyPaymentMethod?.daily_payment_credit,
    },
    {
      label: "Cherry",
      value: data?.dailyPaymentMethod?.daily_payment_cherry,
    },
    {
      label: "Greensky",
      value: data?.dailyPaymentMethod?.daily_payment_greensky,
    },
    {
      label: "Care Credit",
      value: data?.dailyPaymentMethod?.daily_payment_care_credit,
    },
    {
      label: "Aspire Coupons",
      value: data?.dailyPaymentMethod?.daily_payment_aspire_coupons,
    },
    {
      label: "Allē Coupons",
      value: data?.dailyPaymentMethod?.daily_payment_bd_coupons,
    },
    {
      label: "Xperience Coupons",
      value: data?.dailyPaymentMethod?.daily_payment_xperience_coupons,
    },
    {
      label: "Link",
      value: data?.dailyPaymentMethod?.daily_payment_link,
    },
    {
      label: "Klarna",
      value: data?.dailyPaymentMethod?.daily_payment_klarna,
    },
    {
      label: "Total Payments",
      value: data?.dailyPaymentMethod?.daily_payment_total_net_Sales,
      isBold: true,
    },
  ];

  return { reportData };
};
