import React, { useState } from "react";
import moment from "moment";
import { WIDGET_NAMES } from "../../Dashboard.consts";
import { WidgetBox } from "../../boxes/WidgetBox/WidgetBox.js";
import { useRevenuePerHour } from "./hooks/useRevenuePerHour.js";
import RevenuePerHourChart from "./components/RevenuePerHourChart.js";

const WidgetRevenuePerHour = ({ fromReportData }) => {
  const [period, setPeriod] = useState({
    from: moment().startOf("month").toDate(),
    to: moment().endOf("month").toDate(),
  });

  const numberOfSelectedMonth = moment(period.from).month() + 1;

  const params = fromReportData || { month: numberOfSelectedMonth };

  const { data: widgetData, isLoading: isWidgetDataLoading } =
    useRevenuePerHour(params, {
      select: (data) => data.data.data.data || [],
      enabled: Boolean(params.month),
    });

  const chartData = widgetData?.map((data) => {
    return {
      x: data.gross_revenue_hour_raw.toFixed(2),
      y: data.hours_worked_period,
      name: data.provider_name,
      color: data.color,
    };
  });

  const monthlyRange = !fromReportData
    ? {
        dates: {
          start: period.from,
          end: period.to,
        },
        update: setPeriod,
      }
    : null;

  return (
    <WidgetBox
      label={WIDGET_NAMES.revenue_per_hour}
      isLoading={isWidgetDataLoading}
      isEmpty={widgetData?.length === 0}
      headerClassName="flex justify-between items-center"
      monthlyRange={monthlyRange}
    >
      <RevenuePerHourChart chartData={chartData} />
    </WidgetBox>
  );
};

export default WidgetRevenuePerHour;
