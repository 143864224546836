import React, { useState } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../assets/svg";
import { ModalCharge } from "../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../shared/InputCurrency/InputCurrency";
import { useChargeForm } from "../../hooks/useChargeForm";
import { InputError } from "../../../../../shared/InputError/InputError";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { formatCurrency } from "../../../../../utilities/general";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";
import { AvailableBalance } from "../../shared/AvailableBalance/AvailableBalance";
import ViewTipsModal from "../ViewTipsModal/ViewTipsModal";
import { PAYMENT_MODES } from "../../../../../consts/api";
import { useAvailablePaymentMethods } from "../../hooks/useAvailablePaymentMethods";

const ViewChargeArWallet = ({ isTipsApplying }) => {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { invoice, amountToPay, isStartStage, isTipsEnabled } = useInvoice();
  const { chargeWallet, paymentViewClose } = useInvoiceActions();
  const [showTipsModal, setShowTipsModal] = useState(false);

  const isTipsAvailable = isStartStage && !showTipsModal && isTipsEnabled;
  const { data: methods } = useAvailablePaymentMethods(invoice.id);

  const amountMax =
    methods.wallet.maxAmount >= amountToPay
      ? amountToPay
      : methods.wallet.maxAmount;

  const onClose = () => {
    if (showTipsModal) {
      setShowTipsModal(false);
    } else {
      paymentViewClose();
    }
  };

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: amountMax,
      currency: invoice?.currency,
    }),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount }) => {
        const totalAmount = isStartStage
          ? Math.min(parseFloat(amountMax), parseFloat(amount))
          : amount;
        chargeWallet
          .initiate({ amount: totalAmount })
          .then(() => dispatch(checkoutInvoice.actions.paymentViewClose()));
      },
      initialValues: {
        amount: amountMax || "",
      },
    });

  const handleSubmit = () => {
    if (isTipsAvailable) {
      setShowTipsModal(true);
    } else {
      submit();
    }
  };

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.arWallet")}
      imageSrc={svg.arWalletPrimary}
      onCharge={handleSubmit}
      isCharging={chargeWallet.isLoading}
      chargeLabel={
        isTipsAvailable ? tCommon("label.continue") : tCommon("label.apply")
      }
      isChargeDisabled={!isValid || isTipsApplying}
    >
      {!showTipsModal ? (
        <>
          {" "}
          <ModalCharge.InputWrap>
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.amount")}
            </InputLabel>
            <InputCurrency
              size="medium"
              prefix={invoice?.currencySymbol}
              value={form.amount}
              isError={hasError("amount")}
              onChange={(value) => changeFormValue("amount", value)}
              placeholder={tSales(
                "checkoutInvoice.formInputPlaceholder.amount",
              )}
            />
            {hasError("amount") && <InputError>{errors.amount}</InputError>}
          </ModalCharge.InputWrap>
          <ModalCharge.InputWrap>
            <AvailableBalance
              amount={formatCurrency(
                invoice?.patient?.walletBalance,
                invoice?.currency,
              )}
            />
          </ModalCharge.InputWrap>
        </>
      ) : (
        <ViewTipsModal
          amountToPay={form.amount}
          amountMax={amountMax}
          paymentMethod={PAYMENT_MODES.wallet}
        />
      )}
    </ModalCharge>
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: state.checkoutInvoice.isTipsApplying,
});

export default connect(mapStateToProps)(ViewChargeArWallet);
