import React, { useState } from "react";
import { withRouter } from "react-router";
import { useSubmit } from "./hooks/useSubmit";
import { useForm } from "./hooks/useForm";
import { useEfaxText } from "./hooks/useEfaxText";
import { useTemplateForm } from "./hooks/useTemplateForm";
import TemplateForm from "./components/TemplateForm";
import AddFaxForm from "./components/AddFaxForm";

const ModalAdd = ({ onClose, match }) => {
  const [showTemplateForm, setShowTemplateForm] = useState(false);
  const clientId = match.params.clientID;

  const form = useForm(() => {
    text.refetch();
  });

  const text = useEfaxText(
    {
      patientId: clientId || form.patient.value?.value,
      clinicId: form.clinic.value?.value,
      senderId: form.user.value?.value,
      labTestIds: form.checkedTests.value,
      favoriteId: form.favorites.value.value,
      defaultTemplate: Number(form.makeDefault.value),
      templateId: form.template.value?.value,
    },
    {
      enabled: false,
      onSuccess: (data) => {
        setShowTemplateForm(true);
        content.onChange(data.data?.data?.text);
      },
    },
  );

  const submit = useSubmit();
  const { content } = useTemplateForm();

  const handleSubmitTemplate = () => {
    submit
      .initiate({
        clinicId: form.clinic.value?.value,
        senderId: form.user.value?.value,
        faxNumber: form.faxNumber.value,
        patientId: parseInt(clientId) || form.patient.value?.value,
        text: content.value.value,
      })
      .then(onClose);
  };

  return !showTemplateForm ? (
    <AddFaxForm
      form={form}
      isLoading={text.isFetching || text.isRefetching}
      onClose={onClose}
      clientId={clientId}
    />
  ) : (
    <TemplateForm
      form={form}
      content={content}
      setShowTemplateForm={setShowTemplateForm}
      onClose={onClose}
      submit={handleSubmitTemplate}
      isLoading={submit.isLoading}
      clientId={clientId}
    />
  );
};
export default withRouter(ModalAdd);
