import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Sidebar from '../../../_legacy/Containers/Settings/sidebar.js';
import Loader from '../../Common/Loader';
import { getIsAcceleratorPlan, isFormSubmit } from '../../../Utils/services.js';
import {
  getConstantContactData,
  shareIntegrationConstantContactData
} from '../../../Actions/Integrations/integrationActions.js';
import { exportEmptyData } from '../../../Actions/Appointment/appointmentAction.js';
import { showFormattedDate } from '../../../Utils/services.js';
import UpgradeAccountModal from '../../Common/UpgradeAccountModal';

class ConstantContact extends Component {
  constructor (props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      sync: false,
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      url: '',
      all_patient: false,
      newly_created: false,
      apiKeyError: false,
      showLoader: false,
      editClicked: false,
      account_id: '',
      id: '',
      shareObject: null,
      childWindowListener: false,
      showShareNameModal: false,
      shareQueryString: {},
      shareNameList: '',
      shareNameListClass: 'simpleInput',
      access_token: '',
      auth: false,
      date: '',
      clicked: false,

      isShowUpgradeAlertModal: !this.isIntegrationToggleEnabled(),
    };
  }

  componentDidMount () {
    if (this.isIntegrationToggleEnabled()) {
      this.setState({ showLoader: true, auth: false });
      this.props.getConstantContactData();
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    let returnState = {};

    if (nextProps.invokeRedirect) {
      nextProps.history.push('/settings/profile');
    }

    if (nextProps.syncData != undefined && nextProps.syncDataTime != prevState.syncDataTime) {
      returnState.showLoader = false;
      returnState.shareObject = nextProps.syncData;
      returnState.all_patient = nextProps.syncData.sync_all == 1 ? true : false;
      returnState.newly_created = nextProps.syncData.sync_new == 1 ? true : false;
      returnState.sync = nextProps.syncData.is_enabled == 1 ? true : false;
      returnState.account_id = nextProps.syncData.account_id;
      returnState.url = nextProps.syncData.url;
      returnState.id = nextProps.syncData.id;
      returnState.auth = nextProps.syncData.auth == 0 ? false : true;
      returnState.access_token = nextProps.syncData.auth_data.access_token ? nextProps.syncData.auth_data.access_token : '';
      returnState.syncDataTime = nextProps.syncDataTime;
      returnState.name = nextProps.syncData.name;
      returnState.date = nextProps.syncData.auth_data.created;
    }

    if (nextProps.setSyncData != undefined && nextProps.setSyncDataTime != prevState.setSyncDataTime) {
      toast.success(nextProps.message);
      returnState.showLoader = false;
      returnState.setSyncDataTime = nextProps.setSyncDataTime;
      returnState.access_token = nextProps.setSyncData.access_token;
      returnState.auth = nextProps.setSyncData.auth == 1 ? true : false;
      returnState.sync = nextProps.setSyncData.is_enabled == 1 ? true : false;
      returnState.all_patient = nextProps.setSyncData.sync_all == 1 ? true : false;
      returnState.newly_created = nextProps.setSyncData.sync_new == 1 ? true : false;
      returnState.date = nextProps.setSyncData.created;
      returnState.clicked = false;
    }

    return returnState;
  }

  componentWillUnmount () {
    window.removeEventListener('message', this.handleChildWindow, false);
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [event.target.name]: value
    });
    if (target.type === 'checkbox' && value == true && event.target.name == 'sync') {
      this.setState({
        isEditSyncSettings: true,
        all_patient: false,
        newly_created: false,
        access_token: '',
        apiKeyError: false
      });
    }
    if (target.type === 'checkbox' && value == false && event.target.name == 'sync') {
      if (this.state.id && this.state.code) {
        let formData = {
          is_enabled: 0,
          access_token: '',
          sync_all: 0,
          sync_new: 0,
          code: ''
        };
        this.setState({ showLoader: true });
        this.props.shareIntegrationConstantContactData(formData);
      }
    }

  };

  cancelTask = () => {
    let formData = {
      is_enabled: 0,
      access_token: '',
      sync_all: 0,
      sync_new: 0,
      code: ''
    };
    this.setState({ showLoader: true });
    this.props.shareIntegrationConstantContactData(formData);

  };

  selectedAllPatients = () => {
    this.setState({
      all_patient: true,
      newly_created: false
    });
  };

  electedNewlyClicked = () => {
    this.setState({
      all_patient: false,
      newly_created: true
    });
  };

  handleSubmit = (event) => {
    if (isFormSubmit()) {
      event.preventDefault();
      let formData = {};

      if (this.state.access_token == '' || this.state.access_token == null || this.state.access_token == undefined) {
        toast.error('You have not aurthorised yet');
        return;
      }

      if (this.state.auth === false) {
        formData = {
          is_enabled: this.state.sync == true ? 1 : 0,
          access_token: this.state.access_token,
          sync_all: this.state.all_patient == true ? 1 : 0,
          sync_new: this.state.newly_created == true ? 1 : 0,
          code: this.state.access_token
        };
      } else {
        formData = {
          is_enabled: this.state.sync == true ? 1 : 0,
          access_token: this.state.access_token,
          sync_all: this.state.all_patient == true ? 1 : 0,
          sync_new: this.state.newly_created == true ? 1 : 0,
          code: ''
        };
      }
      this.setState({ showLoader: true, auth: false });
      this.props.shareIntegrationConstantContactData(formData);
    }
  };

  handleShareReport = () => {
    let shareObject = this.state.shareObject;
    let reportOptionFilter = {};
    if (shareObject && shareObject.url) {

      this.setState({ shareObject: shareObject });

      localStorage.setItem('shareReportOptionFilter', JSON.stringify(reportOptionFilter));
      this.setState({
        showShareNameModal: false,
        shareQueryString: {},
        shareNameList: '',
        shareNameListClass: 'simpleInput',
      });

      // This event hander will listen for messages from the child
      if (!this.state.childWindowListener) {
        const _self = this;
        window.addEventListener('message', function (e) {

          _self.handleChildWindow(e.data); // e.data hold the message
        }, false);
        this.setState({ childWindowListener: true });
      }

      window.open(shareObject.url, 'Ratting', 'width=550,height=550,left=150,top=200,toolbar=0,status=0,scrollbars=1', '_blank');
    }
  };

  handleChildWindow = (message) => {

    if (message && message.childWindow && message.childWindow == 'response') {

      if (message.query) {
        const query = message.query;
        if (query.error) {
          toast.dismiss();
          toast.error(query.error_message);
        } else {
          this.setState({
            showShareNameModal: false,
            shareQueryString: query,
            shareNameList: '',
            shareNameListClass: 'simpleInput',
          }, () => {
            let reportOptionFilter = JSON.parse(localStorage.getItem('shareReportOptionFilter'));
            if (reportOptionFilter) {
              this.handleShareNameListSubmit();
            } else {
              this.setState({ showShareNameModal: true });
            }
          });
        }
      }
    }
  };

  handleShareNameListSubmit = () => {
    this.setState({
      showShareNameModal: false,
    });
    let reportOptionFilter = JSON.parse(localStorage.getItem('shareReportOptionFilter'));
    if (reportOptionFilter && this.state.shareQueryString && this.state.shareQueryString.code) {
      reportOptionFilter.code = this.state.shareQueryString.code;
      reportOptionFilter.list_name = this.state.shareNameList;

      this.setState({
        shareQueryString: {},
        shareNameList: '',
        shareNameListClass: 'simpleInput',
        access_token: reportOptionFilter.code,
        clicked: true
      });
      localStorage.removeItem('shareReportOptionFilter');

    }
  };

  handleShowUpgradeAlertModal = (state) => {
    this.setState({ isShowUpgradeAlertModal: state.isShowUpgradeAlertModal });
  };

  isIntegrationToggleEnabled = () => getIsAcceleratorPlan();

  render () {
    return (
      <div id="content">
        <UpgradeAccountModal notificaionType={'integration'}
                             isShowUpgradeAlertModal={this.state.isShowUpgradeAlertModal}
                             handleShowUpgradeAlertModal={this.handleShowUpgradeAlertModal}/>
        <div className="container-fluid content setting-wrapper">
          <Sidebar/>
          {/* ****SYNC HEADING****** */}
          <div className="memberWalletOuter relative">
            <div className="setting-setion m-b-10">
              <div className="membership-title">{this.state.settingsLang.label_sync_constant_contact}
                <div className="memberRightActions">
                  <span className="switch-text"></span>
                  <label className="setting-switch pull-right">
                    <input type="checkbox" name="sync" id="sync"
                           disabled={!this.isIntegrationToggleEnabled()}
                           checked={this.state.sync && this.isIntegrationToggleEnabled()}
                           onChange={this.handleInputChange}/>
                    <span className="setting-slider"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              {(this.state.sync === true && this.isIntegrationToggleEnabled()) &&
                <div className="col-sm-12">
                  <div className="setting-setion">
                    <div className="membershipSectionTitle">{this.state.settingsLang.label_sync_settings}</div>
                    {(this.state.sync == true) &&
                      <div className="wallet-edit-block">
                        <div className="membershipSectionContent  p20">
                          <div className="row">
                            <div className="col-xs-12">
                              <button
                                className={(this.state.access_token == '') ? 'new-blue-btn pull-left m-l-0 m-b-10' : 'no-display'}
                                onClick={this.handleShareReport}>{this.state.settingsLang.label_sync_auth}</button>
                              <p className={(this.state.access_token) ? 'AuthorizedSync' : 'no-display'}><i
                                className="fa fa-check-circle"></i> {this.state.clicked ? 'Authorized' : 'Authorized on' + ' ' + showFormattedDate(this.state.date, true)}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-12 m-t-15">
                              <p className="p-text">{this.state.settingsLang.label_whom_to_sync} <span
                                className="setting-require">*</span></p>
                            </div>
                            <div className="col-xs-12">
                              <div className="setting-custom-switch product-active pull-left m-t-10">
                                <label className="setting-switch pull-left no-margin">
                                  <input type="checkbox" className="setting-custom-switch-input" name="all_patient"
                                         autoComplete="off" checked={this.state.all_patient ? 'checked' : false}
                                         onChange={this.handleInputChange} value={this.state.all_patient}/>
                                  <span className="setting-slider"></span>
                                </label>
                                <span id="membership_lable"
                                      className="m-l-15 radioLabel">{this.state.settingsLang.label_all_patients}</span>
                              </div>
                            </div>
                            <div className="col-xs-12">
                              <div className="setting-custom-switch product-active pull-left m-t-10 m-b-20">
                                <label className="setting-switch pull-left no-margin">
                                  <input type="checkbox" className="setting-custom-switch-input" name="newly_created"
                                         autoComplete="off" checked={this.state.newly_created ? 'checked' : false}
                                         onChange={this.handleInputChange} value={this.state.newly_created}/>
                                  <span className="setting-slider"></span>
                                </label>
                                <span id="membership_lable"
                                      className="m-l-15 radioLabel">{this.state.settingsLang.label_newly_created}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="footer-static">
                          <button className="new-blue-btn pull-right"
                                  onClick={this.handleSubmit}>{this.state.globalLang.label_save}</button>
                          {/*<button className="new-white-btn pull-right" onClick={this.cancelTask}>{this.state.globalLang.label_cancel}</button>*/}
                        </div>
                      </div>
                    }
                  </div>
                </div>}
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true}/>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  toast.dismiss();
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  const returnState = {};
  if (state.IntegrationReducer?.data?.status === 402) {
    returnState.invokeRedirect = true;
  }

  if (state.IntegrationReducer.action === 'GET_CONSTANT_CONTACT_DATA') {
    if (state.IntegrationReducer.data.status == 200 || state.IntegrationReducer.data.status == 201) {
      returnState.syncData = state.IntegrationReducer.data.data;
      returnState.syncDataTime = new Date();
    } else {

      toast.error(languageData.global[state.IntegrationReducer.data.message]);
    }
  }

  if (state.IntegrationReducer.action === 'SHARE_CONSTANT_CONTACT_DATA') {
    if (state.IntegrationReducer.data.status == 200 || state.IntegrationReducer.data.status == 201) {
      returnState.setSyncData = state.IntegrationReducer.data.data;
      returnState.setSyncDataTime = new Date();

      returnState.message = languageData.global[state.IntegrationReducer.data.message];

    } else {

      toast.error(languageData.global[state.IntegrationReducer.data.message]);
    }
  }
  return returnState;
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    exportEmptyData: exportEmptyData,
    getConstantContactData: getConstantContactData,
    shareIntegrationConstantContactData: shareIntegrationConstantContactData
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConstantContact));
