import React, { useMemo, useState } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { ModalCharge } from "../../../../shared/ModalCharge/ModalCharge";
import { dispatch } from "../../../../../../../store/store";
import { checkoutInvoice } from "../../../../../../../store/checkoutInvoice";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { useChargeForm } from "../../../../hooks/useChargeForm";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../../shared/InputCurrency/InputCurrency";
import { InputError } from "../../../../../../../shared/InputError/InputError";
import { Input } from "../../../../../../../shared/Input/Input";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../../../hooks/useInvoiceActions";
import {
  composeAmountSchema,
  composeEmailSchema,
  composeZipCodeSchema,
} from "../../../../SalesCheckoutInvoice.schemas";
import { Select } from "../../../../../../../shared/Select/Select";
import ViewTipsModal from "../../../ViewTipsModal/ViewTipsModal";
import { usePaymentFlow } from "../../../../hooks/usePaymentFlow";

const FormCardOnFile = ({ isTipsApplying }) => {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { invoice, amountToPay, isStartStage, isTipsEnabled } = useInvoice();
  const { chargeCard, paymentViewClose } = useInvoiceActions();
  const [showTipsModal, setShowTipsModal] = useState(false);
  const { tipsOnlyFlow } = usePaymentFlow();

  const isTipsAvailable = isStartStage && !showTipsModal && isTipsEnabled;

  const onClose = () => {
    if (showTipsModal) {
      setShowTipsModal(false);
    } else {
      paymentViewClose();
    }
  };

  const schema = yup.object({
    amount: composeAmountSchema({ amountToPay, currency: invoice?.currency }),
    email: composeEmailSchema(),
    zipCode: composeZipCodeSchema(),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: (values) => {
        const totalAmount = showTipsModal
          ? parseFloat(values.amount) + parseFloat(invoice.tipAmount)
          : values.amount;
        chargeCard
          .initiate({
            clearentEmail: values.email,
            clearentZip: values.zipCode,
            isSavedCard: true,
            amount: totalAmount,
            clearentCardToken: values.cardOnFile.value,
          })
          .then(() => dispatch(checkoutInvoice.actions.paymentViewClose()));
      },
      initialValues: {
        amount: amountToPay || "",
        email: invoice?.patient?.email || "",
        zipCode: invoice?.patient?.cardOnFiles?.[0]?.billingZip || "",
        cardOnFile: {
          label: invoice?.patient?.cardOnFiles?.[0]?.number,
          value: invoice?.patient?.cardOnFiles?.[0]?.cardOnFile,
        },
      },
    });

  const savedCards = useMemo(() => {
    return invoice?.patient?.cardOnFiles?.map((card) => ({
      value: card.cardOnFile,
      label: card.number,
      billingZip: card.billingZip,
    }));
  }, [invoice?.patient?.cardOnFiles]);

  const handleSubmit = () => {
    if (isTipsAvailable && !tipsOnlyFlow) {
      setShowTipsModal(true);
    } else {
      submit();
    }
  };

  return (
    <div>
      {!showTipsModal ? (
        <ModalCharge.Content>
          <ModalCharge.InputWrap>
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.amount")}
            </InputLabel>
            <InputCurrency
              size="medium"
              prefix={invoice?.currencySymbol}
              value={form.amount}
              isError={hasError("amount")}
              onChange={(value) => changeFormValue("amount", value)}
              placeholder={tSales(
                "checkoutInvoice.formInputPlaceholder.amount",
              )}
            />
            {hasError("amount") && <InputError>{errors.amount}</InputError>}
          </ModalCharge.InputWrap>
          <ModalCharge.InputWrap>
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.cardOnFile")}
            </InputLabel>
            <Select
              options={savedCards}
              value={form.cardOnFile}
              onChange={(value) => changeFormValue("cardOnFile", value)}
            />
          </ModalCharge.InputWrap>
          <ModalCharge.InputRow>
            <ModalCharge.InputWrap fullWidth>
              <InputLabel>{tCommon("label.email")}</InputLabel>
              <Input
                type="email"
                size="medium"
                value={form.email}
                isError={hasError("email")}
                onChange={(e) => changeFormValue("email", e.target.value)}
                placeholder={tCommon("formPlaceholder.email")}
              />
              {hasError("email") && <InputError>{errors.email}</InputError>}
            </ModalCharge.InputWrap>
            <ModalCharge.InputWrap width="170px">
              <InputLabel>
                {tSales("checkoutInvoice.formInputLabel.zipCode")}
              </InputLabel>
              <Input
                type="number"
                size="medium"
                value={form.zipCode}
                isError={hasError("zipCode")}
                onChange={(e) => changeFormValue("zipCode", e.target.value)}
                placeholder={tSales(
                  "checkoutInvoice.formInputPlaceholder.zipCode",
                )}
              />
              {hasError("zipCode") && <InputError>{errors.zipCode}</InputError>}
            </ModalCharge.InputWrap>
          </ModalCharge.InputRow>
        </ModalCharge.Content>
      ) : (
        <ViewTipsModal amountToPay={form.amount} />
      )}
      <ModalCharge.Footer
        onClose={onClose}
        isCharging={chargeCard.isLoading}
        onCharge={handleSubmit}
        isChargeDisabled={!isValid || chargeCard.isLoading || isTipsApplying}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(FormCardOnFile);
