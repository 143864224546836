import { LOCAL_STORAGE_KEYS } from "../consts/localStorage";

/**
 * @returns {unknown | null}
 */
export const getUserId = () => {
  const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.userData));
  if (data?.user) {
    return data.user.id;
  }
  return null;
};

/**
 * @returns {unknown | null}
 */
export const getAccountId = () => {
  const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.userData));
  if (data && data.account) {
    return data.account.id;
  }
  return null;
};

/**
 * @deprecated Use `useCurrentUserQuery` instead
 */
export const getUser = () => {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.userData));
};

/**
 * @deprecated Try to avoid of using localStorage language data.
 * Use local i18n translations instead
 */
export const getLanguageData = (key) => {
  const data = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.languageData),
  );
  if (data && key in data) {
    return data[key];
  }
  return data;
};

/**
 * @deprecated Use `useCurrentUserQuery` or `useCurrentAccountQuery` to access
 * current account payment system
 */
export const getUserPaymentSystem = () => {
  let result = "";
  const userData = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.userData),
  );
  if (userData?.account) {
    result = userData.account.pos_gateway || "";
  }
  return result;
};

/**
 * @deprecated Use `useCurrentAccountQuery` to access
 * current account subscription plan
 */
export const getAccountSubscriptionPlan = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.subscriptionPlan);
};

/**
 * @deprecated Use `useCurrentAccountQuery` to access
 * clients alias
 */
export const getClientsLabel = () => {
  const data =
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.languageData)) || {};
  return data.header_clients;
};

/**
 * @returns {string | null}
 */
export const clinicTimezone = {
  get: () => localStorage.getItem(LOCAL_STORAGE_KEYS.clinicTimezone),

  set: (value) =>
    localStorage.setItem(LOCAL_STORAGE_KEYS.clinicTimezone, value),

  remove: () => localStorage.removeItem(LOCAL_STORAGE_KEYS.clinicTimezone),
};

/**
 * @deprecated Use `useCurrentUserQuery` if possible
 */
export const getIsMainAdmin = () => {
  const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.userData));
  const adminId = data?.account?.admin_id;
  const userId = data?.user?.id;

  return adminId && userId && adminId === userId;
};

export const lockAppUntilAccountAddressUpdated = {
  get: () =>
    JSON.parse(
      localStorage.getItem(
        LOCAL_STORAGE_KEYS.lockAppUntilAccountAddressUpdated,
      ),
    ),

  set: (value) =>
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.lockAppUntilAccountAddressUpdated,
      JSON.stringify(value),
    ),

  remove: () =>
    localStorage.removeItem(
      LOCAL_STORAGE_KEYS.lockAppUntilAccountAddressUpdated,
    ),
};
