import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { privacyPolicyPopup } from "../../../store/privacyPolicyPopup";
import { dispatch } from "../../../store/store";
import {
  isPrivacyPolicyPopupScheduled,
  removePrivacyPolicyPopupSchedule,
} from "../../../store/privacyPolicyPopup/utilities";

function PrivacyPolicyPopup({ isOpen }) {
  const { tCommon: t } = useAppTranslation.Common();

  useEffect(() => {
    if (isPrivacyPolicyPopupScheduled()) {
      dispatch(privacyPolicyPopup.actions.open());
      removePrivacyPolicyPopupSchedule();
    }
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(privacyPolicyPopup.actions.close());
        removePrivacyPolicyPopupSchedule();
      }}
      header={<Modal.Title>{t("privacyPolicyPopup.title")}</Modal.Title>}
    >
      {t("privacyPolicyPopup.text")}
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  isOpen: privacyPolicyPopup.selectors.selectIsOpen(state),
});

export default connect(mapStateToProps)(PrivacyPolicyPopup);
