import { phoneUtil } from "../../../../utilities/phone";

export const submitAdapter = (formValues) => {
  const composedFaxNumber = phoneUtil.compose(formValues.faxNumber);

  return {
    countryCode: phoneUtil.extractDialCode(composedFaxNumber),
    areaCode: phoneUtil.extractNationalNumber(composedFaxNumber),
  };
};
