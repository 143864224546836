import cx from "clsx";
import React, { useMemo, useState, useEffect} from "react";
import { wrapFormDate } from "../../../pages/Event/Event.utils";
import classes from "../sass//membership.module.scss";
import { showFormattedDate } from "../../../Utils";
import { Button } from "../../../shared/Button/Button";
import MembershipBadge from "../../Common/MembershipBadge";
import AddMembershipModal from "../AddMembershipModal";
import Loader from "../../Common/Loader";
import ReactTooltip from "react-tooltip";
import { shortenClinicName } from "../../../helpers/general";

const getMembershipStatusView = (membership) => {
  if (membership.cancel_type === "expired") {
    return <span className="cancelled-text fontsize12">Canceled</span>;
  } else if (membership.cancel_at && membership.cancel_at.length > 0) {
    return <span className="cancelled-text fontsize12">Canceled</span>;
  } else if (membership.marked_for_cancel) {
    return (
      <span className="cancelled-text fontsize12">
        Active until {showFormattedDate(membership.subscription_valid_upto)}
      </span>
    );
  } else if (membership.membership_on_hold) {
    return (
      <span className="on-hold-text fontsize12">
        On Hold until {showFormattedDate(membership.membership_hold_until)}
      </span>
    );
  } else if (membership.marked_for_hold) {
    return (
      <span className="on-hold-text fontsize12">
        Active until {showFormattedDate(membership.subscription_valid_upto)}
      </span>
    );
  } else if (membership.active_membership && membership.type === "future") {
    return <span className="inactive fontsize12">Inactive</span>;
  } else {
    return <span className="active-text fontsize12">Active</span>;
  }
};

const membershipTableColumns = [
  {
    id: "membershipName",
    name: "Membership Name",
  },
  {
    id: "status",
    name: "Status",
  },
  {
    id: "membershipFee",
    name: "Membership Fee",
  },
  {
    id: "startDate",
    name: "Start Date",
  },
  {
    id: "validUpto",
    name: "Valid Upto",
  },
  {
    id: "clinic",
    name: "Clinic",
  },
  {
    id: "actions",
    name: "",
  },
];

const MembershipsList = ({
  clientID,
  patientMembershipsList,
  selectMembership,
  clientData,
  isFetchingList,
  onClose,
}) => {
  const [newMembershipModalOpen, setNewMembershipModalOpen] = useState(false);
  const [filterExpiredMemberships, setExpiredFilter] = useState(false);

  useEffect(() => {
    document.body.style.overflow = newMembershipModalOpen ? 'hidden' : 'unset';
  
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [newMembershipModalOpen]);

  const hasExpiredMembership = useMemo(() => {
    return (
      patientMembershipsList?.some(
        (membershipDetails) =>
          membershipDetails?.cancel_type === "expired" ||
          membershipDetails?.cancel_at?.length > 0,
      ) || false
    );
  }, [patientMembershipsList]);

  const filteredMemberships = useMemo(() => {
    if (filterExpiredMemberships) {
      return patientMembershipsList;
    }
    return patientMembershipsList?.filter(
      (membershipDetails) =>
        !(
          membershipDetails?.cancel_type === "expired" ||
          membershipDetails?.cancel_at?.length > 0
        ),
    );
  }, [filterExpiredMemberships, patientMembershipsList]);

  const toggleAddNewMembershipModal = () => {
    setNewMembershipModalOpen((modalStatus) => !modalStatus);
  };

  const showExpiredMemberships = () => {
    setExpiredFilter((filterStatus) => !filterStatus);
  };

  return (
    <>
      <div className={cx("patient-right col-sm-8", classes.root)}>
        <div className={cx(classes.card,  classes.tableContainer)}>
          <div className={classes.cardHeader}>
            <div className={classes.cardTitle}>
              <div
                className="pull-left go-back go-back-arrow cursor-pointer"
                onClick={onClose}
              ></div>
              Memberships
            </div>
            <Button size="small" onClick={toggleAddNewMembershipModal}>
              <i className="fa fa-plus  m-r-5" />
              Add Membership
            </Button>
          </div>
          {hasExpiredMembership && (
            <span
              className={classes.showExpiredMemberships}
              onClick={showExpiredMemberships}
            >
              <input
                type="checkbox"
                name="toggle-expired-memberships"
                checked={filterExpiredMemberships}
                className="filled-checkbox mr-10"
              />
              <span>Show Expired Memberships</span>
            </span>
          )}
          {/* Membership List */}
          {filteredMemberships?.length > 0 ? (
            <table className={classes.table}>
              <tbody>
                <tr className={classes.tableHeadingRow}>
                  {membershipTableColumns.map(({ id, name }) => {
                    return (
                      <th key={id} className={`${classes[`th${id}`]} centerTableHeading`}>
                        {name}
                      </th>
                    );
                  })}
                </tr>             
                {filteredMemberships?.map(
                  (patientMemberships, membershipIndex) => {
                    const {
                      membership_color,
                      tier_name,
                      mothly_membership_fees,
                      yearly_membership_fees,
                      subscription_started_at,
                      subscription_valid_upto,
                      membership_clinic_name,
                      monthly_membership_invoices,
                      payment_frequency,
                    } = patientMemberships;
                    
                    const isPaymentDue = Boolean(
                      monthly_membership_invoices[0]?.payment_status ===
                        "past_due",
                    );

                    return (
                      <tr
                        key={tier_name}
                        className={classes.membershipMetaData}
                      >
                        <td className={`${classes.membershipName} p-t-10`}>
                          {isPaymentDue ? (
                            <>
                              <span
                                className="colorRed m-r-3 font-size-16"
                                data-tip
                                data-for={`client-payment-error-${membershipIndex}`}
                              >
                                <i className="fa fa-exclamation-circle"></i>
                              </span>
                              <ReactTooltip
                                className="client-payment-error-tooltip"
                                id={`client-payment-error-${membershipIndex}`}
                                place="right"
                                border
                                textColor="red"
                                backgroundColor="#fff"
                                borderColor="red"
                                arrowColor="red"
                              >
                                <span>Payment declined for {tier_name}!</span>
                              </ReactTooltip>{" "}
                            </>
                          ) : (
                            <MembershipBadge
                              color={membership_color}
                              displayPage={`${classes.membershipBadgeBlock} m-t-2`}
                            />
                          )}
                          {tier_name}
                        </td>
                        <td>{getMembershipStatusView(patientMemberships)}</td>
                        <td>
                          $
                          {(
                            mothly_membership_fees || yearly_membership_fees
                          ).toFixed(2)}
                          {payment_frequency.toLowerCase() === 'monthly' ? "/month" : "/year"}
                        </td>
                        <td>{showFormattedDate(subscription_started_at)}</td>
                        <td>{showFormattedDate(subscription_valid_upto)}</td>
                        <td>{shortenClinicName(membership_clinic_name)}</td>
                        <td
                          onClick={() =>
                            selectMembership(() => {
                              const { membership_id } = patientMemberships;
                              return {
                                id: membership_id,
                                membershipIndex,
                              };
                            })
                          }
                          className={classes.showMembershipDetailsButton}
                        >
                          Show Details
                        </td>
                      </tr>
                    );
                  },
                )}
              </tbody>
            </table>
          ) : (
            <div className={classes.noMembershipsAvailable}>
              No Memberships Available
            </div>
          )}
        </div>
      </div>
      {Boolean(isFetchingList) && <Loader showLoader />}
      {newMembershipModalOpen && (
        <AddMembershipModal
          onClose={toggleAddNewMembershipModal}
          clientId={clientID}
          clientData={clientData}
        />
      )}
    </>
  );
};

export default MembershipsList;
