import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { shortenClinicName } from "../../helpers/general";

const responseSchema = yup.array().of(
  yup.object({
    id: yup.number().required(),
    name: yup.string().required(),
    isAvailableOnline: yup.bool().required(),
    cherryPracticeId: yup.string().nullable(),
    evolusFacilityId: yup.string().nullable(),
    zipCode: yup.string().nullable(),
    tax: yup.string().nullable(),
  }),
);

const composeResponse = (res) => {
  let result = [];
  if (res && Array.isArray(res.data.data)) {
    result = res.data.data.map((clinic) => ({
      id: clinic.id,
      name: shortenClinicName(clinic.clinic_name),
      isAvailableOnline: Boolean(clinic.is_available_online),
      cherryPracticeId: clinic.cherry_practice_id || null,
      evolusFacilityId: clinic.evolus_facility_id || null,
      zipCode: clinic.clinic_zipcode || null,
      tax: clinic.tax,
    }));
  }
  return result;
};

function createClinicsQueryKey() {
  return [QUERY_KEYS.clinics];
}

export function useClinicsQuery(options = {}) {
  return useQuery(
    createClinicsQueryKey(),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getClinics());
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
