import { useEffect, useState } from "react";
import { useInvoice } from "./invoice/useInvoice";

export const usePaymentFlow = () => {
  const { invoice, isStartStage, isTipsEnabled, beforeTipAmount } =
    useInvoice();

  const [tipsOnlyFlow, setTipsOnlyFlow] = useState(false);
  const [showTipsModal, setShowTipsModal] = useState(false);

  const isTipsAvailable = isStartStage && !showTipsModal && isTipsEnabled;

  useEffect(() => {
    if (beforeTipAmount === 0 && invoice.tipAmount > 0) {
      setTipsOnlyFlow(true);
    }
  }, [beforeTipAmount, invoice.tipAmount]);

  return {
    tipsOnlyFlow: tipsOnlyFlow,
    showTipsModal: {
      value: showTipsModal,
      update: setShowTipsModal,
    },
    isTipsAvailable,
  };
};
