import React from "react";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { InputPhone } from "../../../../../../shared/InputPhone/InputPhone";
import { Button } from "../../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import styles from "./styles.module.scss";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { InputError } from "../../../../../../shared/InputError/InputError";
import CopyIcon from "../../../../../../assets/Icons/CopyIcon";
import { uiNotification } from "../../../../../../services/UINotificationService";

/**
 * @param {object} param0
 * @param {Function} param0.onSubmit
 * @param {boolean} param0.isSubmitting
 * @param {object} param0.faxNumber
 * @param {string?} param0.faxNumber.value
 * @param {Function} param0.faxNumber.onChange
 * @param {string?} param0.faxNumber.error
 */
export const Form = ({
  onSubmit,
  isSubmitting,
  faxNumber,
  isIntegrationEnabled,
}) => {
  const { tSettings } = useAppTranslation.Settings();

  const copyNumber = () => {
    navigator.clipboard.writeText(faxNumber.value).then(() => {
      uiNotification.success(tSettings("efaxIntegration.success.copied"));
    });
  };

  return (
    <div className={styles.root}>
      <div>
        <InputLabel>{tSettings("efaxIntegration.faxNumber")}</InputLabel>
        <div className={styles.phoneInputBox}>
          <InputPhone
            fullWidth
            value={faxNumber.value}
            onChange={faxNumber.onChange}
            isError={!!faxNumber.error}
            isDisabled={isIntegrationEnabled}
            onlyCountries={["us"]}
            flagButtonClassName={styles.flagButton}
          />
          {isIntegrationEnabled && (
            <button className={styles.copyIcon} onClick={copyNumber}>
              <CopyIcon height={24} />
            </button>
          )}
        </div>
        {faxNumber.error && <InputError>{faxNumber.error}</InputError>}
      </div>
      {!isIntegrationEnabled && (
        <Button
          onClick={onSubmit}
          isDisabled={isSubmitting || !faxNumber.value}
          leftAdornment={
            isSubmitting ? (
              <CircularProgress size="small" color="white" />
            ) : undefined
          }
        >
          {tSettings("efaxIntegration.purchaseNumber")}
        </Button>
      )}
    </div>
  );
};
