import React, { useState } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { ModalCharge } from "../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../shared/InputCurrency/InputCurrency";
import { useChargeForm } from "../../hooks/useChargeForm";
import { InputError } from "../../../../../shared/InputError/InputError";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { img } from "../../../../../assets/img";
import { Textarea } from "../../../../../shared/Textarea/Textarea";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";
import ViewTipsModal from "../ViewTipsModal/ViewTipsModal";

const ViewChargeGreenSky = ({ isTipsApplying }) => {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { invoice, amountToPay, isStartStage, isTipsEnabled } = useInvoice();
  const { chargeGreenSky, paymentViewClose } = useInvoiceActions();
  const [showTipsModal, setShowTipsModal] = useState(false);

  const isTipsAvailable = isStartStage && !showTipsModal && isTipsEnabled;

  const onClose = () => {
    if (showTipsModal) {
      setShowTipsModal(false);
    } else {
      paymentViewClose();
    }
  };

  const schema = yup.object({
    amount: composeAmountSchema({ amountToPay, currency: invoice?.currency }),
    note: yup.string(),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount, note }) => {
        const totalAmount = isStartStage
          ? parseFloat(amount) + parseFloat(invoice.tipAmount)
          : amount;
        chargeGreenSky
          .initiate({
            amount: totalAmount,
            note,
          })
          .then(() => dispatch(checkoutInvoice.actions.paymentViewClose()));
      },
      initialValues: {
        amount: amountToPay || "",
        note: "",
      },
    });

  const handleSubmit = () => {
    if (isTipsAvailable) {
      setShowTipsModal(true);
    } else {
      submit();
    }
  };

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.greenSky")}
      imageSrc={img.greenSky}
      onCharge={handleSubmit}
      isCharging={chargeGreenSky.isLoading}
      chargeLabel={
        isTipsAvailable ? tCommon("label.continue") : tCommon("label.apply")
      }
      isChargeDisabled={!isValid || isTipsApplying}
    >
      {!showTipsModal ? (
        <>
          <ModalCharge.InputWrap>
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.amount")}
            </InputLabel>
            <InputCurrency
              size="medium"
              prefix={invoice?.currencySymbol}
              value={form.amount}
              isError={hasError("amount")}
              onChange={(value) => changeFormValue("amount", value)}
              placeholder={tSales(
                "checkoutInvoice.formInputPlaceholder.amount",
              )}
            />
            {hasError("amount") && <InputError>{errors.amount}</InputError>}
          </ModalCharge.InputWrap>
          <div>
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.note")}
            </InputLabel>
            <Textarea
              value={form.note}
              onChange={(e) => changeFormValue("note", e.target.value)}
              placeholder={tSales("checkoutInvoice.formInputPlaceholder.note")}
            />
          </div>
        </>
      ) : (
        <ViewTipsModal amountToPay={form.amount} />
      )}
    </ModalCharge>
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(ViewChargeGreenSky);
