import React from "react";
import PrivacyPolicyPopup from "./PrivacyPolicyPopup/PrivacyPolicyPopup";

export function GlobalPopups() {
  return (
    <>
      <PrivacyPolicyPopup />
    </>
  );
}
