import React, { Component } from "react";
import { withRouter } from "react-router";
import queryString from "query-string";
import cx from "clsx";
import {
  getTwilioConfig,
  positionFooterInstantly,
} from "../../Utils/services.js";
import { withLeadArAvailabilityStatusQuery } from "../../api/queries/useLeadArAvailabilityStatusQuery.js";
import { Tree } from "./shared/Tree.js";
import { ClinicsList } from "./boxes/ClinicsList/ClinicsList.js";
import { getIsEfaxIntegrationAccessible } from "../../helpers/integrations.js";
import { ROUTES } from "../../consts/routes.js";

let _self;
class InboxSidebar extends Component {
  constructor(props) {
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    super(props);
    this.state = {
      globalLang: languageData.global,
      twilioConfig: getTwilioConfig(),
      clinicList: [],
      isResetSideBar: true,
      inboxClinicId: props.inboxClinicId ? props.inboxClinicId : -1,
    };
    _self = this;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnnState = {};
    if (nextProps.clinicList && nextProps.clinicList !== prevState.clinicList) {
      returnnState.clinicList = nextProps.clinicList;
      returnnState.isResetSideBar = false;
    }
    if (returnnState.isResetSideBar === false) {
      setTimeout(function () {
        _self.setState({ isResetSideBar: true }, () => {
          positionFooterInstantly();
        });
      }, 200);
    }
    return returnnState;
  }

  handleClinicSelection = (clinicObj) => {
    if (clinicObj) {
      this.setState({ inboxClinicId: clinicObj.id });
      if (this.props.handleClinicSelection) {
        this.props.handleClinicSelection(clinicObj);
      }
      let inboxClinicId = clinicObj.id;
      if (inboxClinicId) {
        localStorage.setItem("inboxClinicId", clinicObj.id);
        localStorage.setItem("inboxClinicName", clinicObj.clinic_name);
        this.props.history.push("/inbox/sms/all/" + clinicObj.id);
      } else {
        localStorage.removeItem("inboxClinicId");
        localStorage.removeItem("inboxClinicName");
        this.props.history.push("/inbox/sms/all");
      }
      this.setState({ isResetSideBar: false });
      this.setState({ isResetSideBar: true }, () => {
        positionFooterInstantly();
      });
    }
  };
  renderSideBarContent = () => {
    let nav = [];
    const isLeadArAvailable =
      this.props.leadArAvailabilityStatusQuery?.data?.isAvailable || false;

    if (isLeadArAvailable) {
      nav.push(
        <Tree
          key={0}
          name="LeadAR"
          styleClass="new-setting-tabs-li"
          styleLinkClass={cx("parent_class new-setting-subtabs-a", {
            "active-menu":
              window.location.pathname.endsWith("/inbox/sms/lead-ar"),
          })}
          childClass="setting-submenu child"
          navigateTo="/inbox/sms/lead-ar"
          activeMenu={window.location.pathname.endsWith("/inbox/sms/lead-ar")}
        />,
      );
      if (window.location.pathname.includes("/inbox/sms/lead-ar")) {
        const queryParams = queryString.parse(window.location.search);
        nav.push(
          <ClinicsList
            key={1}
            navPath="/inbox/sms/lead-ar?clinicId="
            selectedClinicId={queryParams?.clinicId}
          />,
        );
      }
    }

    if (this.state.twilioConfig === false) {
      nav.push(
        <Tree
          name={this.state.globalLang.label_setup}
          styleClass={`new-setting-tabs-li`}
          styleLinkClass={`parent_class new-setting-subtabs-a ${
            window.location.pathname.startsWith("/settings/inbox")
              ? "active-menu"
              : ""
          }`}
          childClass={"setting-submenu child"}
          navigateTo={"/settings/inbox"}
          activeMenu={
            window.location.pathname.startsWith("/settings/inbox")
              ? true
              : false
          }
          key={2}
          parentIndex={0}
        />,
      );
    }

    if (getIsEfaxIntegrationAccessible() && this.props.isEfaxEnabled) {
      nav.push(
        <Tree
          key={3}
          name="eFax Orders"
          styleClass="new-setting-tabs-li"
          styleLinkClass={cx("parent_class new-setting-subtabs-a", {
            "active-menu": window.location.pathname.endsWith(
              ROUTES.inbox.efaxOrders(),
            ),
          })}
          childClass="setting-submenu child"
          navigateTo={ROUTES.inbox.efaxOrders()}
          activeMenu={window.location.pathname.endsWith(
            ROUTES.inbox.efaxOrders(),
          )}
        />,
      );
    }

    if (this.state.twilioConfig === true) {
      nav.push(
        <Tree
          key={4}
          name="Blacklist"
          styleClass="new-setting-tabs-li"
          styleLinkClass={cx("parent_class new-setting-subtabs-a", {
            "active-menu": window.location.pathname.endsWith(
              "/inbox/sms/black-list",
            ),
          })}
          childClass="setting-submenu child"
          navigateTo="/inbox/sms/black-list"
          activeMenu={window.location.pathname.endsWith(
            "/inbox/sms/black-list",
          )}
        />,
      );
      nav.push(
        <Tree
          name={"AR Messages"}
          styleClass={`new-setting-tabs-li`}
          styleLinkClass={`parent_class new-setting-subtabs-a ${
            window.location.pathname.endsWith("/inbox/sms/all/-1") &&
            this.props.inboxClinicId <= 0
              ? "active-menu"
              : ""
          }`}
          childClass={"setting-submenu child"}
          navigateTo={"/inbox/sms/all"}
          activeMenu={
            window.location.pathname.endsWith("/inbox/sms/all/-1") &&
            this.props.inboxClinicId <= 0
              ? true
              : false
          }
          key={5}
          parentIndex={1}
          handleClinicSelection={
            this.props.handleClinicSelection ? this.handleClinicSelection : null
          }
          clinicObj={{ id: -1, clinic_name: "AR Messages" }}
        />,
      );
    }
    if (
      this.state.twilioConfig === true &&
      window.location.pathname.includes("/inbox/sms/all")
    ) {
      nav.push(
        <ClinicsList
          key={6}
          inboxClinicId={this.state.inboxClinicId}
          handleClinicSelection={
            this.props.handleClinicSelection ? this.handleClinicSelection : null
          }
        />,
      );
    }
    return nav;
  };

  render() {
    return (
      <div className="setting-left-menu business-insight">
        <div className="setting-title">Inbox</div>
        {this.state.isResetSideBar === true && (
          <ul className="new-setting-tabs">{this.renderSideBarContent()}</ul>
        )}
      </div>
    );
  }
}

export default withRouter(withLeadArAvailabilityStatusQuery(InboxSidebar));
