import React from "react";
import { withRouter } from "react-router";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { EntityHeader } from "../../../../../widgets/EntityHeader/EntityHeader";
import styles from "./styles.module.scss";
import { DateRangePicker } from "../../../../../shared/DateRangePicker/DateRangePicker";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { Button } from "../../../../../shared/Button/Button";
import {
  apiDateStringToDate,
  dateToApiDateString,
} from "../../../../../utilities/api";
import { unwrapOr } from "../../../../../utilities/general";
import { history } from "../../../../../history";
import { CLIENTS_ROUTES } from "../../../../../consts/routes";
import { useClientQuery } from "../../../../../api/queries/useClientQuery";
import { usePermission } from "../../../../../hooks/usePermission";
import { PERMISSIONS } from "../../../../../consts/api";
import { useEfaxIntegrationStatusQuery } from "../../../../../api/efax/useEfaxIntegrationStatusQuery";

const Header = ({ period, onAdd, match }) => {
  const { tInbox } = useAppTranslation.Inbox();
  const { data: user } = useCurrentUserQuery();
  const { data: client } = useClientQuery({
    clientId: parseInt(match.params.clientID),
  });

  const { data: efaxIntegrationEnabled } = useEfaxIntegrationStatusQuery({
    select: (data) => data.isEnabled,
  });

  const sendEfaxUserPermission = usePermission(
    PERMISSIONS.patientManagement.sendEfaxOrders,
  );

  const hasSendEfaxUserPermission =
    efaxIntegrationEnabled &&
    ((sendEfaxUserPermission.loaded && sendEfaxUserPermission.permitted) ||
      user?.isMainAdmin);

  return (
    <EntityHeader
      title={
        !client?.fullName
          ? tInbox("efaxOrders.title")
          : `${tInbox("efaxOrders.title")} of ${client?.fullName}`
      }
      withBackBtn
      onBack={() => history.push(CLIENTS_ROUTES.profile(match.params.clientID))}
      className={styles.root}
      right={
        <div className={styles.right}>
          <DateRangePicker
            isDisabled={false}
            size="small"
            dateFormat={user?.dateFormat}
            onChange={({ startDate, endDate }) => {
              period.update({
                from: dateToApiDateString(startDate),
                to: dateToApiDateString(endDate),
              });
            }}
            value={{
              startDate: unwrapOr(
                () => apiDateStringToDate(period.value.from),
                new Date(),
              ),
              endDate: unwrapOr(
                () => apiDateStringToDate(period.value.to),
                new Date(),
              ),
            }}
          />
          {hasSendEfaxUserPermission && (
            <Button size="small" variant="outlined" onClick={onAdd}>
              {tInbox("efaxOrders.add")}
            </Button>
          )}
        </div>
      }
    />
  );
};

export default withRouter(Header);
