import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { ModalCharge } from "../../shared/ModalCharge/ModalCharge";
import { img } from "../../../../../assets/img";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../shared/InputCurrency/InputCurrency";
import { InputError } from "../../../../../shared/InputError/InputError";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import "react-phone-input-2/lib/style.css";
import { Input } from "../../../../../shared/Input/Input";
import classes from "./ViewChargeKlarna.module.scss";
import { createKlarnaPayment } from "../../../../../Actions/Sales/klarnaActions";
import { uiNotification } from "../../../../../services/UINotificationService";
import ViewTipsModal from "../ViewTipsModal/ViewTipsModal";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";

const ChargeFormKlarna = (props) => {
  const { createKlarnaPayment, chargeFormHandle, isTipsApplying } = props;
  const { tSales } = useAppTranslation.Sales();
  const { invoice, isStartStage, isTipsEnabled } = useInvoice();
  const { paymentViewClose } = useInvoiceActions();
  const [isCharging, setIsCharging] = useState(false);
  const [disabledInput, setDisabledInput] = useState(false);
  const [showTipsModal, setShowTipsModal] = useState(false);

  const { form, changeFormValue, hasError, errors } = chargeFormHandle;

  const isTipsAvailable = isStartStage && !showTipsModal && isTipsEnabled;

  useEffect(() => {
    setDisabledInput(form.email);
  }, []);

  const onClose = () => {
    if (showTipsModal) {
      setShowTipsModal(false);
    } else {
      paymentViewClose();
    }
  };

  const klarnaPayment = async () => {
    const totalAmount = isStartStage
      ? parseFloat(form.amount) + parseFloat(invoice.tipAmount)
      : form.amount;
    if (hasError("amount")) return;

    let count = (form?.country?.format.match(/\./g) || []).length;
    if (form?.country?.countryCode === "ro") count = 11;

    if (!form.phoneNumber) {
      return uiNotification.error("Please Enter Phone Number!");
    }

    if (form.phoneNumber.length < count) {
      return uiNotification.error("Please enter a valid phone number!");
    }

    setIsCharging(true);

    if (isTipsAvailable) {
      setShowTipsModal(true);
    } else {
      await createKlarnaPayment({
        requestedAmount: totalAmount,
        invoiceId: invoice.id,
        patientPhone: form.phoneNumber,
        patientEmail: form.email,
      });
    }

    setIsCharging(false);
  };

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.klarna")}
      imageSrc={img.klarna}
      imageClassName={classes.titleKlarna}
      onCharge={() => klarnaPayment()}
      isCharging={isCharging}
      chargeLabel="Continue"
      isChargeDisabled={isTipsApplying}
    >
      {!showTipsModal ? (
        <>
          <ModalCharge.InputWrap>
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.amount")}
            </InputLabel>
            <InputCurrency
              size="medium"
              prefix={invoice?.currencySymbol}
              value={form.amount || ""}
              isError={hasError("amount")}
              onChange={(value) => changeFormValue("amount", value)}
              placeholder={tSales(
                "checkoutInvoice.formInputPlaceholder.amount",
              )}
            />
            {hasError("amount") && <InputError>{errors.amount}</InputError>}
          </ModalCharge.InputWrap>

          <ModalCharge.InputWrap className={classes.modalKlarna}>
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.phoneNumber")}
            </InputLabel>
            <PhoneInput
              name="patientPhone"
              size="medium"
              className={classes.phoneKlarna + " klarna-tel-input"}
              country={"us"}
              countryCodeEditable={false}
              value={invoice?.patient?.phoneNumber || ""}
              onChange={(phone, country, event, formattedPhone) => {
                changeFormValue("country", country);
                changeFormValue("phoneNumber", formattedPhone);
              }}
              enableSearch={true}
              preferredCountries={["us"]}
              isError={hasError("phoneNumber")}
            />
            {hasError("phoneNumber") && (
              <InputError>{errors.phoneNumber}</InputError>
            )}
          </ModalCharge.InputWrap>

          <ModalCharge.InputWrap>
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.mail")}
            </InputLabel>
            <Input
              size="medium"
              name="email"
              value={form.email}
              onChange={(event) => {
                changeFormValue("email", event.target.value);
              }}
              placeholder={tSales("checkoutInvoice.formInputPlaceholder.mail")}
              isDisabled={disabledInput}
            />
          </ModalCharge.InputWrap>
          <p className={classes.smsLink}>
            A link will be sent to the patient via SMS to finish the Klarna
            checkout process
          </p>
        </>
      ) : (
        <ViewTipsModal amountToPay={form.amount} />
      )}
    </ModalCharge>
  );
};

ChargeFormKlarna.propTypes = {
  createKlarnaPayment: PropTypes.func,
  chargeFormHandle: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    ongoingKlarnaPayment: state.KlarnaReducer.ongoingKlarnaPayment,
    isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
  };
};

const mapDispatchToProps = () => {
  return bindActionCreators(
    {
      createKlarnaPayment: createKlarnaPayment,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChargeFormKlarna);
