import React, { useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { connect } from "react-redux";
import {
  tCommon,
  useAppTranslation,
} from "../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../assets/svg";
import { ModalCharge } from "../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../shared/InputCurrency/InputCurrency";
import { useChargeForm } from "../../hooks/useChargeForm";
import { InputError } from "../../../../../shared/InputError/InputError";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { AvailableBalance } from "../../shared/AvailableBalance/AvailableBalance";
import { formatCurrency } from "../../../../../utilities/general";
import { useCherry } from "../../hooks/useCherry";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";
import { PaymentAwaitLoader } from "./shared/PaymentAwaitLoader/PaymentAwaitLoader";
import { PendingPaymentAlert } from "./shared/PendingPaymentAlert/PendingPaymentAlert";
import ViewTipsModal from "../ViewTipsModal/ViewTipsModal";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { useAvailablePaymentMethods } from "../../hooks/useAvailablePaymentMethods";

function ViewChargeCherry({ isCherryPaymentWatchMode, isTipsApplying }) {
  const { tSales } = useAppTranslation.Sales();
  const { invoice, amountToPay, isStartStage, isTipsEnabled } = useInvoice();
  const { paymentViewClose } = useInvoiceActions();
  const [showTipsModal, setShowTipsModal] = useState(false);

  const isTipsAvailable = isStartStage && !showTipsModal && isTipsEnabled;

  const onClosePaymentView = () => {
    if (showTipsModal) {
      setShowTipsModal(false);
    } else {
      paymentViewClose();
    }
  };

  const {
    cherryPatient,
    chargeCherry,
    cancelOngoingCherryPayment,
    cancelPendingCherryPayment,
    ignorePendingCherryPayment,
    pendingCherryPayment,
  } = useCherry({
    onPaymentSuccess: () =>
      dispatch(checkoutInvoice.actions.paymentViewClose()),
  });

  const { data: methods } = useAvailablePaymentMethods(invoice.id);
  const amountMax =
    methods.cherry.maxAmount >= amountToPay
      ? amountToPay
      : methods.cherry.maxAmount;

  const onClose = () => {
    if (!isCherryPaymentWatchMode) {
      onClosePaymentView();
    }
  };

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: amountMax,
      currency: invoice?.currency,
    }),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount }) => {
        const totalAmount = isStartStage
          ? parseFloat(amount) + parseFloat(invoice.tipAmount)
          : amount;
        chargeCherry.initiate({ amount: totalAmount });
      },
      initialValues: {
        amount: amountMax,
      },
    });

  const isChargeDisabled = Boolean(
    !isValid ||
      chargeCherry.isLoading ||
      isCherryPaymentWatchMode ||
      pendingCherryPayment ||
      isTipsApplying,
  );

  const cancelOnGoingPayment = () => {
    cancelOngoingCherryPayment.initiate();
    dispatch(checkoutInvoice.actions.changeIsCherryPaymentWatchMode(false));
  };

  const handleSubmit = () => {
    if (isTipsAvailable) {
      setShowTipsModal(true);
    } else {
      submit();
    }
  };

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.cherry")}
      imageSrc={svg.cherryPay}
      onCharge={handleSubmit}
      isCharging={chargeCherry.isLoading}
      isCancelDisabled={isCherryPaymentWatchMode}
      isChargeDisabled={isChargeDisabled}
      chargeLabel={
        isTipsAvailable ? tCommon("label.continue") : tCommon("label.apply")
      }
    >
      {!showTipsModal ? (
        <>
          <ModalCharge.InputWrap>
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.amount")}
            </InputLabel>
            <InputCurrency
              size="medium"
              prefix={invoice?.currencySymbol}
              value={form.amount}
              isError={hasError("amount")}
              onChange={(value) => changeFormValue("amount", value)}
              placeholder={tSales(
                "checkoutInvoice.formInputPlaceholder.amount",
              )}
            />
            {hasError("amount") && <InputError>{errors.amount}</InputError>}
          </ModalCharge.InputWrap>
          <ModalCharge.InputWrap>
            <AvailableBalance
              amount={formatCurrency(
                cherryPatient?.balance?.amount,
                invoice?.currency,
              )}
            />
          </ModalCharge.InputWrap>
        </>
      ) : (
        <ViewTipsModal amountToPay={form.amount} />
      )}
      {isCherryPaymentWatchMode && (
        <PaymentAwaitLoader
          onCancelPayment={cancelOnGoingPayment}
          isCancelling={cancelOngoingCherryPayment.isLoading}
        />
      )}
      {pendingCherryPayment && (
        <PendingPaymentAlert
          message={pendingCherryPayment.message}
          onCancelPayment={cancelPendingCherryPayment.initiate}
          onIgnore={ignorePendingCherryPayment}
          isCancelling={cancelPendingCherryPayment.isLoading}
        />
      )}
    </ModalCharge>
  );
}

ViewChargeCherry.propTypes = {
  isCherryPaymentWatchMode: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isCherryPaymentWatchMode:
    checkoutInvoice.selectors.selectIsCherryPaymentWatchMode(state),
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(ViewChargeCherry);
