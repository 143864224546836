export const REDUCER_NAME = "checkoutInvoice";

export const PAYMENT_OPTIONS = {
  card: "card",
  cash: "cash",
  arWallet: "arWallet",
  cherry: "cherry",
  klarna: "klarna",
  careCredit: "careCredit",
  greenSky: "greenSky",
  check: "check",
  giftCard: "giftCard",
  text2pay: "text2pay",
};

export const PAYMENT_VIEW_TYPES = {
  optionSelection: "optionSelection",
  ...PAYMENT_OPTIONS,
};

export const ACTION_TYPES = {
  paymentViewChange: `${REDUCER_NAME}/paymentViewChange`,
  paymentViewClose: `${REDUCER_NAME}/paymentViewClose`,
  changeIsInvoiceWatchMode: `${REDUCER_NAME}/changeIsInvoiceWatchMode`,
  changeIsCherryPaymentWatchMode: `${REDUCER_NAME}/changeIsCherryPaymentWatchMode`,
  changeIsTipsApplying: `${REDUCER_NAME}/changeIsTipsApplying`,
};
