import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getCurrencySymbol } from "../../../../../Utils";

const RevenuePerHourChart = ({ chartData }) => {
  const xAxisMax = Math.max(...(chartData || []).map((data) => data.x));
  const yAxisMax = Math.max(...(chartData || []).map((data) => data.y));

  const options = {
    chart: {
      type: "scatter",
      reflow: true,
    },
    title: {
      text: "Gross Revenue per Hour",
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: `Gross Revenue (${getCurrencySymbol()})`,
      },
      min: 0,
      max: xAxisMax,
      labels: {
        formatter: function () {
          return `${getCurrencySymbol()}${this.value}`;
        },
      },
    },
    yAxis: {
      title: {
        text: "Hours",
      },
      min: 0,
      max: yAxisMax,
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return `<b>${this.point.name}</b><br>Hours: ${
          this.point.y
        }<br>Revenue: ${getCurrencySymbol()} ${this.point.x}`;
      },
    },
    series: [
      {
        data: chartData,
        marker: {
          symbol: "circle",
          radius: 10,
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}<br>${point.x}",
          style: {
            color: "#000000",
            textOutline: "none",
          },
        },
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default RevenuePerHourChart;
