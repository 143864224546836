import { ACTION_TYPES } from "./consts";

const initialState = {
  paymentView: null,
  isInvoiceWatchMode: false,
  isCherryPaymentWatchMode: false,
  isTipsApplying: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.paymentViewChange: {
      return {
        ...state,
        paymentView: action?.payload?.paymentView || null,
      };
    }
    case ACTION_TYPES.paymentViewClose: {
      return {
        ...state,
        paymentView: null,
      };
    }
    case ACTION_TYPES.changeIsInvoiceWatchMode: {
      return {
        ...state,
        isInvoiceWatchMode: action?.payload?.isInvoiceWatchMode || false,
      };
    }
    case ACTION_TYPES.changeIsCherryPaymentWatchMode: {
      return {
        ...state,
        isCherryPaymentWatchMode:
          action?.payload?.isCherryPaymentWatchMode || false,
      };
    }
    case ACTION_TYPES.changeIsTipsApplying: {
      return {
        ...state,
        isTipsApplying: action?.payload?.isTipsApplying || null,
      };
    }
    default: {
      return state;
    }
  }
}
