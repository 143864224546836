import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ProfileInformation from "./ProfileInformation";
import Procedures from "./Procedures";
import Wallet from "./Wallet";
import MemberShip from "./Membership-Treatment-Plans";
import { MedicalHistory } from "./MedicalHistory/MedicalHistory";
import CustomerNotes from "./Customer-Notes";
import Documents from "./Documents";
import ReviewMerge from "./Review-Merge";
import UpcomingAppointments from "./UpcomingApts";
import {
  getMergeData,
  exportEmptyData,
  getProcedureData,
  getWalletData,
  getTreatmentPlans,
  getMedicalHistory,
  getUpcomingAppointment,
  getCustomerNotes,
  getPatientDocuments,
  mergeClient,
} from "../../../Actions/Clients/clientsAction";
import Loader from "../../Common/Loader";
import { unwrapOr } from "../../../utilities/general";
import {
  getPatientsHistoryIntersection,
  getSectionVisibilityStatuses,
} from "./MergeProfilesMain.utils";
import { uiNotification } from "../../../services/UINotificationService";
import EfaxOrders from "./EfaxOrders/EfaxOrders";

class MergeProfile extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      profileInformation: [],
      profileDropDowns: {},
      defaultProfileData: {},
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      clientLang: languageData.clients,
      nextMedicalHistory: null,
      isMedicalHistorySummaryModalOpen: false,
      selectedOrders: [],
    };
    window.onscroll = () => {
      return false;
    };
  }

  componentDidMount() {
    let formData = {
      ids: JSON.parse(localStorage.getItem("profiles_id")),
    };
    this.setState({ showLoader: true });
    this.props.getMergeData(formData, "patient");
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    const default_profile_id = Number(
      localStorage.getItem("default_profile_id"),
    );
    // **************MERGE DATA FETCH AND SEND DATA******************************************
    if (
      nextProps.mergeData != undefined &&
      nextProps.mergeDataTime != prevState.mergeDataTime
    ) {
      returnState.mergeDataTime = nextProps.mergeDataTime;
      returnState.showLoader = false;
      let firstname = [];
      let lastname = [];
      let dob = [];
      let email = [];
      let phone = [];
      let address = [];
      let city = [];
      let state = [];
      let country = [];
      let pincode = [];
      let images = [];
      let addressline2 = [];
      let gender = [];
      let defaultProfileData = {};
      let profileData = nextProps.mergeData;
      returnState.profileInformation = nextProps.mergeData;
      defaultProfileData = nextProps.mergeData.find(
        (y) => y.id == default_profile_id,
      );
      if (defaultProfileData) {
        defaultProfileData.default_email = "";
        defaultProfileData.default_phone = "";
      }
      if (profileData.length > 0) {
        profileData.map((data, index) => {
          if (data.firstname != "" && data.firstname != null) {
            firstname.push(data.firstname);
          }
          if (data.lastname != "" && data.lastname != null) {
            lastname.push(data.lastname);
          }
          if (data.date_of_birth != "" && data.date_of_birth != null) {
            dob.push(data.date_of_birth);
          }
          if (data.gender != "" && data.gender != null) {
            let check = gender.find((y) => y == data.gender);
            if (!check) {
              gender.push(data.gender);
            }
          }
          if (data.email != "" && data.email != null) {
            let check = email.find((y) => y.value == data.email);
            if (!check) {
              email.push({
                id: index + 1,
                value: data.email,
              });
            }
          }
          if (data.phoneNumber != "" && data.phoneNumber != null) {
            let check = phone.find((y) => y.value == data.phoneNumber);
            if (!check) {
              phone.push({
                id: index + 1,
                value: data.phoneNumber,
              });
            }
          }
          if (data.address_line_1 != "" && data.address_line_1 != null) {
            address.push(data.address_line_1);
          }
          if (data.address_line_2 != "" && data.address_line_2 != null) {
            addressline2.push(data.address_line_2);
          }
          if (data.city != "" && data.city != null) {
            city.push(data.city);
          }
          if (data.state != "" && data.state != null) {
            state.push(data.state);
          }
          if (data.country != "" && data.country != null) {
            country.push({
              value: data.country,
              full_name: data.country_name ? data.country_name : "",
            });
          }
          if (data.pincode != "" && data.pincode != null) {
            pincode.push(data.pincode);
          }
          if (data.user_image_url != "" && data.user_image_url != null) {
            images.push({
              user_image_url: data.user_image_url,
              user_image: data.user_image,
            });
          }
        });
      }
      if (images.length > 0) {
        images.push({
          user_image_url: "/images/appfemale.png",
          user_image: "/images/appfemale.png",
        });
      }
      returnState.profileDropDowns = {
        firstname: firstname,
        lastname: lastname,
        dob: dob,
        email: email,
        phone: phone,
        address: address,
        city: city,
        state: state,
        country: country,
        pincode: pincode,
        images: images,
        addressline2: addressline2,
        gender: gender,
        creditCards: unwrapOr(
          () => profileData.reduce((r, p) => [...r, ...p.card_on_files], []),
          [],
        ),
      };

      returnState.defaultProfileData = defaultProfileData;
      returnState.selectedHeader = 1;
    }
    // ************PROCEDURE DATA FETCH AND SEND DATA *****************************
    if (
      nextProps.procedureData != undefined &&
      nextProps.procedureDataTime != prevState.procedureDataTime
    ) {
      returnState.procedureDataTime = nextProps.procedureDataTime;
      returnState.showLoader = false;
      const procedureData = nextProps.procedureData.map((p) => ({
        name: p.procedure_name,
        date: p.procedure_date,
        provider_name: p.doctor_name,
        id: p.id,
        procedure_image_45_thumb_url: p.procedure_image_45_thumb_url || "",
        patient_image_left_45_thumb_url: p.procedure_image_data
          ? p.procedure_image_data.patient_image_left_45_thumb_url
          : "",
        patient_image_right_45_thumb_url: p.procedure_image_data
          ? p.procedure_image_data.patient_image_right_45_thumb_url
          : "",
        selected_status: true,
      }));
      returnState.procedureData = procedureData;
    }
    // ***************WALLET DATA FETCH AND SEND DATA********************************
    if (
      nextProps.walletData != undefined &&
      nextProps.walletDataTime != prevState.walletDataTime
    ) {
      returnState.walletDataTime = nextProps.walletDataTime;
      returnState.showLoader = false;
      let walletData = {
        dollarCredit: 0,
        bdCredit: 0,
        xperienceCredit: 0,
        aspireCredit: 0,
        collectiveData: false,
      };
      let productsData = [];
      if (nextProps.walletData.length > 0) {
        nextProps.walletData.map((obj) => {
          obj.data.map((data) => {
            if (data.row_type == "product") {
              productsData.push({
                balance: data.balance,
                balance_units: data.balance_units,
                credit_type: data.credit_type,
                date: data.date,
                discount_package_name: data.discount_package_name,
                discount_package_type: data.discount_package_type,
                product_name: data.product_name,
                row_type: data.row_type,
                total_units: data.total_units,
                selected_status: true,
                id: data.patient_package_id,
              });
            }
          });
          walletData.dollarCredit =
            walletData.dollarCredit +
            obj.patient_data.client_wallet.dollar_credit;
          walletData.bdCredit =
            walletData.bdCredit + obj.patient_data.client_wallet.bd_credit;
          walletData.xperienceCredit =
            walletData.xperienceCredit +
            obj.patient_data.client_wallet.xperience_credit;
          walletData.aspireCredit =
            walletData.aspireCredit +
            obj.patient_data.client_wallet.aspire_credit;
        });
      }
      returnState.walletData = walletData;
      returnState.productsData = productsData;
    }

    // *****************MEMBERSHIP DATA FETCH AND SEND DATA*********************
    if (
      nextProps.membershipData != undefined &&
      nextProps.membershipDataTime != prevState.membershipDataTime
    ) {
      returnState.membershipDataTime = nextProps.membershipDataTime;
      returnState.showLoader = false;
      let membershipData = [];
      let treatmentPlans = [];
      if (nextProps.membershipData.length > 0) {
        nextProps.membershipData.map((data) => {
          data.membership.memberships.map((obj) => {
            if (obj && obj.type != "start") {
              membershipData.push({
                id: obj.membership_id,
                membership_tier: obj.membership_tier,
                card_details: obj.card_details,
                subscription_started_at: obj.subscription_started_at,
                mothly_membership_fees: obj.mothly_membership_fees,
                yearly_membership_fee: obj.yearly_membership_fees
                  ? data.yearly_membership_fees
                  : 0,
                payment_frequency: obj.payment_frequency,
                one_time_membership_setup: obj.one_time_membership_setup
                  ? obj.one_time_membership_setup
                  : 0,
                recurly_program_name: obj.recurly_program_name,
                selected: true,
                tier_name: obj.tier_name,
              });
            }
          });

          if (
            data.current_treatment_plan != null &&
            data.current_treatment_plan.plan_type == "monthly"
          ) {
            let prescribed_plans = [];
            if (data.priscribed_treatment_plans.length > 0) {
              data.priscribed_treatment_plans.map((data) => {
                prescribed_plans.push(data);
              });
            }
            treatmentPlans.push({
              plan_type: data.current_treatment_plan.plan_type,
              patient_treatment_plan_schedule:
                data.current_treatment_plan.patient_treatment_plan_schedule,
              monthly_amount: data.current_treatment_plan.monthly_amount,
              id: data.current_treatment_plan.id,
              prescribed_plans: prescribed_plans,
              skincare_goal: data.current_treatment_plan.skincare_goal,
              selected: treatmentPlans.length == 0 ? true : false,
              total_amount: data.current_treatment_plan.total_amount,
              provider: data.current_treatment_plan.user
                ? data.current_treatment_plan.user.firstname +
                  " " +
                  data.current_treatment_plan.user.lastname
                : "",
            });
          }
          if (
            data.current_treatment_plan != null &&
            data.current_treatment_plan.plan_type == "payg"
          ) {
            let prescribed_plans = [];
            if (data.priscribed_treatment_plans.length > 0) {
              data.priscribed_treatment_plans.map((data) => {
                prescribed_plans.push(data);
              });
            }
            treatmentPlans.push({
              plan_type: data.current_treatment_plan.plan_type,
              patient_treatment_plan_schedule:
                data.current_treatment_plan.pay_as_you_go,
              monthly_amount: 0,
              skincare_goal: data.current_treatment_plan.skincare_goal,
              id: data.current_treatment_plan.id,
              prescribed_plans: prescribed_plans,
              selected: treatmentPlans.length == 0 ? true : false,
              total_amount: data.current_treatment_plan.total_amount,
              provider: data.current_treatment_plan.user
                ? data.current_treatment_plan.user.firstname +
                  " " +
                  data.current_treatment_plan.user.lastname
                : "",
            });
          }
        });
      }

      returnState.membershipData = membershipData;
      returnState.treatmentPlans = treatmentPlans;
    }

    // ***************MEDICAL HISTORY DATA FETCH AND SEND DATA*****************
    if (
      nextProps.medicalHistory != undefined &&
      nextProps.medicalHistoryDataTime != prevState.medicalHistoryDataTime
    ) {
      returnState.medicalHistory = nextProps.medicalHistory;

      if (!prevState.nextMedicalHistory) {
        returnState.nextMedicalHistory = getPatientsHistoryIntersection(
          nextProps.medicalHistory?.map(
            (patient) => patient?.new_medical_history || {},
          ) || [],
        );
      }
    }

    // *************FETCH DATA FOR UPCOMING APPTS AND PAYMENT HISTORY****************
    if (
      nextProps.appointmentAndPaymentData != undefined &&
      nextProps.appointmentDataTime != prevState.appointmentDataTime
    ) {
      returnState.appointmentDataTime = nextProps.appointmentDataTime;
      returnState.showLoader = false;
      let appointmentData = [];
      let paymentsData = [];
      if (nextProps.appointmentAndPaymentData.length > 0) {
        nextProps.appointmentAndPaymentData.map((data) => {
          if (data.upcoming_appointments.length > 0) {
            data.upcoming_appointments.map((obj) => {
              if (obj.status == "booked") {
                appointmentData.push({
                  appointment_services: obj.appointment_services,
                  id: obj.id,
                  provider: obj.provider,
                  clinic: obj.clinic,
                  date: obj.appointment_datetime,
                  selected: true,
                  notes: obj.appointment_note,
                });
              }
            });
          }

          if (data.pos_invoice.length > 0) {
            data.pos_invoice.map((obj, i) => {
              paymentsData.push({
                id: obj.id,
                invoice_number: obj.invoice_number,
                procedure: obj.procedure,
                pos_transaction: obj.pos_transaction,
                selected: true,
                created: obj.system_created_datetime,
              });
            });
          }
        });
      }

      returnState.appointmentData = appointmentData;
      returnState.paymentData = paymentsData;
    }
    // *********FETCH CUSTOMER NOTES AND SEND DATA******************
    if (
      nextProps.customerNotesData != undefined &&
      nextProps.notesDataTime != prevState.notesDataTime
    ) {
      returnState.notesDataTime = nextProps.notesDataTime;
      returnState.showLoader = false;
      let notes = [];
      if (nextProps.customerNotesData.length > 0) {
        nextProps.customerNotesData.map((obj) => {
          if (obj.customer_notes.length > 0) {
            obj.customer_notes.map((data) => {
              notes.push({
                added_by: data.added_by,
                created: data.created,
                edited_by: data.edited_by,
                hashtags: data.hashtags,
                id: data.id,
                modified: data.modified,
                notes: data.notes,
                patient_id: data.patient_id,
                title: data.title,
                user_id: data.user_id,
                selected: true,
              });
            });
          }
        });
      }
      returnState.notes = notes;
    }
    // ***********FETCH PATIENT DOCUMENTS AND SEND DATA************
    if (
      nextProps.customerDocuments != undefined &&
      nextProps.documentDataTime != prevState.documentDataTime
    ) {
      returnState.documentDataTime = nextProps.documentDataTime;
      returnState.showLoader = false;
      let documents = [];
      if (nextProps.customerDocuments.length > 0) {
        nextProps.customerDocuments.map((data) => {
          data.documents.map((obj) => {
            documents.push({
              appointment_id: obj.appointment_id,
              created: obj.created,
              description: obj.description,
              document_name: obj.document_name,
              document_type: obj.document_type,
              download_path: obj.download_path,
              filename: obj.filename,
              id: obj.id,
              is_deleted: obj.is_deleted,
              modified: obj.modified,
              patient_id: obj.patient_id,
              selected: true,
              appointmentData: obj.appointment,
            });
          });
        });
      }
      returnState.documents = documents;
    }
    if (
      nextProps.redirect != undefined &&
      nextProps.redirectDataTime != prevState.redirectDataTime
    ) {
      returnState.redirectDataTime = nextProps.redirectDataTime;
      returnState.mergeClientPopup = false;
      nextProps.history.push("/clients");
    }
    return returnState;
  }

  nextStep = () => {
    let formData = {
      ids: JSON.parse(localStorage.getItem("profiles_id")),
    };
    let selectedVar = this.state.selectedHeader;
    selectedVar = selectedVar + 1;
    if (selectedVar !== 6) {
      this.setState({ showLoader: true });
    }
    switch (selectedVar) {
      case 2: {
        this.props
          .getProcedureData(formData, "procedures")
          .then(() => this.setState({ selectedHeader: 2 }));
        break;
      }
      case 3: {
        this.props
          .getWalletData(formData, "wallets")
          .then(() => this.setState({ selectedHeader: 3 }));
        break;
      }
      case 4: {
        this.props
          .getTreatmentPlans(formData, "membeshipAndTreatmentPlan")
          .then(() => this.setState({ selectedHeader: 4 }));
        break;
      }
      case 5: {
        this.props
          .getMedicalHistory(formData, "newMedicalHistory")
          .then(() => this.setState({ selectedHeader: 5 }));
        break;
      }
      case 6: {
        const visibilityStatuses = getSectionVisibilityStatuses(
          this.state.medicalHistory?.map(
            (patient) => patient?.new_medical_history || {},
          ) || [],
        );
        const isNothingVisible = Object.values(visibilityStatuses).every(
          (v) => !v,
        );
        if (isNothingVisible) {
          this.props
            .getUpcomingAppointment(
              formData,
              "upcomingAppointmentsAndPaymentHistory",
            )
            .then(() => this.setState({ selectedHeader: 6 }));
        } else {
          this.setState({
            isMedicalHistorySummaryModalOpen: true,
          });
        }
        break;
      }
      case 7: {
        this.props
          .getCustomerNotes(formData, "customerNotes")
          .then(() => this.setState({ selectedHeader: 7 }));
        break;
      }
      case 8: {
        this.props
          .getPatientDocuments(formData, "patientDocuments")
          .then(() => this.setState({ selectedHeader: 8 }));
        break;
      }
      case 9: {
        this.setState({ selectedHeader: 9, showLoader: false });
        break;
      }
      case 10: {
        this.setState({ showLoader: false, selectedHeader: 10 });
      }
    }
  };

  setHeader = (number) => {
    let selectedHeader = this.state.selectedHeader;
    if (selectedHeader > number) {
      this.setState({ selectedHeader: number });
    }
  };

  donotClick = () => {
    return false;
  };

  handleChildState = (childState) => {
    this.setState(childState, () => {});
  };

  cancelMerge = () => {
    this.setState({ cancelMergePopup: true });
  };

  dismissModal = () => {
    this.setState({ cancelMergePopup: false });
  };

  cancelMergeOfClients = () => {
    this.setState({ cancelMergePopup: false });
    this.props.history.push("/clients/scan");
  };

  // **********FINAL MERGE CALL***************
  mergeData = () => {
    this.setState({ showLoader: true });
    let patientData = {};
    let procedures = [];
    let package_ids = [];
    let treatmentPlanId = "";
    let appointment_ids = [];
    let pos_invoice_ids = [];
    let patient_note_ids = [];
    let documents = [];
    let invalid_package_id = [];
    let membership_data = [];
    // ****STEP 1 DATA***************
    if (this.state.step1Data) {
      let data = this.state.step1Data;
      let emailSelected;
      let phoneSelected;
      if (data.selected_emailObject && data.selected_emailObject.length > 0) {
        emailSelected = data.selected_emailObject.findIndex(
          (x) => x.label == data.default_email,
        );
        if (emailSelected > -1) {
          data.selected_emailObject.splice(emailSelected, 1);
        }
      }
      if (data.selected_phonesObject && data.selected_phonesObject.length > 0) {
        phoneSelected = data.selected_phonesObject.findIndex(
          (x) => x.label == data.default_phone,
        );
        if (phoneSelected > -1) {
          data.selected_phonesObject.splice(phoneSelected, 1);
        }
      }
      patientData.firstname = data.firstname;
      patientData.lastname = data.lastname;
      patientData.email = data.default_email ? data.default_email : "";
      patientData.email_2 =
        data.selected_emailObject.length >= 1
          ? data.selected_emailObject[0].label
          : "";
      patientData.email_3 =
        data.selected_emailObject.length >= 2
          ? data.selected_emailObject[1].label
          : "";
      patientData.email_4 =
        data.selected_emailObject.length >= 3
          ? data.selected_emailObject[2].label
          : "";
      patientData.user_image_url =
        data.user_image_url == "/images/appfemale.png"
          ? ""
          : data.user_image_url;
      patientData.user_image = data.user_image ? data.user_image : "";
      patientData.date_of_birth =
        data.date_of_birth == "0000-00-00" ? "" : data.date_of_birth;
      patientData.gender = data.gender ? data.gender : 2;
      patientData.member_type = this.state.defaultProfileData.member_type;
      patientData.phoneNumber = data.default_phone ? data.default_phone : "";
      patientData.phoneNumber_2 =
        data.selected_phonesObject && data.selected_phonesObject.length >= 1
          ? data.selected_phonesObject[0].label
          : "";
      patientData.phoneNumber_3 =
        data.selected_phonesObject && data.selected_phonesObject.length >= 2
          ? data.selected_phonesObject[1].label
          : "";
      patientData.phoneNumber_4 =
        data.selected_phonesObject && data.selected_phonesObject.length >= 3
          ? data.selected_phonesObject[2].label
          : "";
      patientData.address_line_1 = data.address_line_1;
      patientData.address_line_2 = data.address_line_2;
      patientData.city = data.city;
      patientData.state = data.state;
      patientData.country = data.country.value;
      patientData.pincode = data.pincode;
      patientData.cards_on_files_id = data.ccId;
      patientData.access_portal = this.state.defaultProfileData.access_portal;
    } else {
      let data = this.state.defaultProfileData;
      patientData.firstname = data.firstname;
      patientData.lastname = data.lastname;
      patientData.email = data.email;
      patientData.email_2 = data.email_2;
      patientData.email_3 = data.email_3;
      patientData.email_4 = data.email_4;
      patientData.user_image_url =
        data.user_image_url == "/images/appfemale.png"
          ? ""
          : data.user_image_url;
      patientData.user_image = data.user_image ? data.user_image : "";
      patientData.date_of_birth =
        data.date_of_birth == "0000-00-00" ? "" : data.date_of_birth;
      patientData.gender = data.gender ? data.gender : 2;
      patientData.member_type = data.member_type;
      patientData.phoneNumber = data.phoneNumber;
      patientData.phoneNumber_2 = data.phoneNumber_2;
      patientData.phoneNumber_3 = data.phoneNumber_3;
      patientData.phoneNumber_4 = data.phoneNumber_4;
      patientData.address_line_1 = data.address_line_1
        ? data.address_line_1
        : this.state.profileDropDowns.address &&
          this.state.profileDropDowns.address.length > 0
        ? this.state.profileDropDowns.address[0]
        : "";
      patientData.address_line_2 = data.address_line_2
        ? data.address_line_2
        : this.state.profileDropDowns.addressline2 &&
          this.state.profileDropDowns.addressline2.length > 0
        ? this.state.profileDropDowns.addressline2[0]
        : "";
      patientData.city = data.city
        ? data.city
        : this.state.profileDropDowns.city &&
          this.state.profileDropDowns.city.length > 0
        ? this.state.profileDropDowns.city[0]
        : "";
      patientData.state = data.state
        ? data.state
        : this.state.profileDropDowns.state &&
          this.state.profileDropDowns.state.length > 0
        ? this.state.profileDropDowns.state[0]
        : "";
      patientData.country = data.country
        ? data.country
        : this.state.profileDropDowns.country &&
          this.state.profileDropDowns.country.length > 0
        ? this.state.profileDropDowns.country[0].value
        : "";
      patientData.pincode = data.pincode
        ? data.pincode
        : this.state.profileDropDowns.pincode &&
          this.state.profileDropDowns.pincode.length > 0
        ? this.state.profileDropDowns.pincode[0]
        : "";
      patientData.cards_on_files_id = null;
      patientData.access_portal = data.access_portal;
    }
    // ********STEP2 DATA*****************
    if (this.state.step2Data) {
      this.state.step2Data.map((data) => {
        if (data.selected_status == true) {
          procedures.push(data.id);
        }
      });
    } else {
      this.state.procedureData.map((data) => {
        if (data.selected_status == true) {
          procedures.push(data.id);
        }
      });
    }
    // ******STEP 3 DATA********************
    if (this.state.step3Data) {
      this.state.step3Data.productsData.map((data) => {
        if (data.selected_status == true) {
          let checkData = package_ids.find((y) => y == data.id);
          if (!checkData) {
            package_ids.push(data.id);
          }
        }
        if (data.selected_status == false) {
          let checkData = invalid_package_id.find((y) => y == data.id);
          if (!checkData) {
            invalid_package_id.push(data.id);
          }
        }
      });
    } else {
      this.state.productsData.map((data) => {
        if (data.selected_status == true) {
          let checkData = package_ids.find((y) => y == data.id);
          if (!checkData) {
            package_ids.push(data.id);
          }
        }
        if (data.selected_status == false) {
          let checkData = invalid_package_id.find((y) => y == data.id);
          if (!checkData) {
            invalid_package_id.push(data.id);
          }
        }
      });
    }

    // *******STEP4 DATA********************
    if (this.state.step4Data) {
      this.state.step4Data.membershipData.map((data) => {
        if (data.selected == true) {
          membership_data.push(data.id);
        }
      });
      this.state.step4Data.treatmentPlans.map((data) => {
        if (data.selected == true) {
          treatmentPlanId = data.id;
        }
      });
    } else {
      this.state.membershipData.map((data) => {
        if (data.selected == true) {
          membership_data.push(data.id);
        }
      });
      this.state.treatmentPlans.map((data) => {
        if (data.selected == true) {
          treatmentPlanId = data.id;
        }
      });
    }

    // ******STEP 6 DATA*************************
    if (this.state.step6Data) {
      this.state.step6Data.appointmentData.map((data) => {
        if (data.selected == true) {
          appointment_ids.push(data.id);
        }
      });

      this.state.step6Data.paymentData.map((data) => {
        if (data.selected == true) {
          pos_invoice_ids.push(data.id);
        }
      });
    } else {
      this.state.appointmentData.map((data) => {
        if (data.selected == true) {
          appointment_ids.push(data.id);
        }
      });

      this.state.paymentData.map((data) => {
        if (data.selected == true) {
          pos_invoice_ids.push(data.id);
        }
      });
    }
    // ****STEP 7 DATA****************************
    if (this.state.step7Data) {
      this.state.step7Data.notes.map((data) => {
        if (data.selected == true) {
          patient_note_ids.push(data.id);
        }
      });
    } else {
      this.state.notes.map((data) => {
        if (data.selected == true) {
          patient_note_ids.push(data.id);
        }
      });
    }
    // *****STEP 8 DATA*****************************
    if (this.state.step8Data) {
      this.state.step8Data.documents.map((data) => {
        if (data.selected == true) {
          documents.push(data.id);
        }
      });
    } else {
      this.state.documents.map((data) => {
        if (data.selected == true) {
          documents.push(data.id);
        }
      });
    }
    let formData = {
      patients_ids: JSON.parse(localStorage.getItem("profiles_id")),
      patient_data: patientData,
      procedures_ids: procedures,
      patient_package_ids: package_ids,
      membership_id: membership_data,
      treatment_plan_id: treatmentPlanId,
      new_medical_history_data: this.state.nextMedicalHistory,
      appointment_ids: appointment_ids,
      pos_invoice_ids: pos_invoice_ids,
      patient_note_ids: patient_note_ids,
      document_ids: documents,
      patient_package_ids_to_be_deleted: invalid_package_id,
      efax_ids: this.state.selectedOrders.map((order) => order.id),
    };

    this.props
      .mergeClient(formData)
      .then(() => {
        uiNotification.success("Profiles have been successfully merged");
      })
      .catch((error) => {
        const errorMessages = {
          merge_aborted:
            "Unable to merge accounts due to active recurring services",
          default: "Unable to merge profiles. Try again later",
        };
        const msg = error.message;

        uiNotification.error(errorMessages[msg] || errorMessages.default);
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  dismissMergeClientModal = () => {
    this.setState({ mergeClientPopup: false });
  };

  openMergePopup = () => {
    this.setState({ mergeClientPopup: true });
  };

  render() {
    return (
      <div id="content">
        <div className="container-fluid content">
          <div className="setting-setion merge-title full-width">
            <div className="membership-title">
              <span>{this.state.settingsLang.sidebar_patients_menu}</span>{" "}
              <i className="fa fa-chevron-right"></i>
              <span>
                Merge {this.state.settingsLang.sidebar_patients_menu}
              </span>{" "}
              <i className="fa fa-chevron-right"></i>
              <span className="currentPageName">
                {this.state.defaultProfileData
                  ? this.state.defaultProfileData.firstname
                  : ""}{" "}
                {this.state.defaultProfileData
                  ? this.state.defaultProfileData.lastname
                  : ""}
              </span>
              <div className="mainRightActions">
                <a className="new-line-btn no-width" onClick={this.cancelMerge}>
                  Cancel
                </a>
                {this.state.selectedHeader < 10 && (
                  <a className="new-blue-btn" onClick={this.nextStep}>
                    Next Step
                  </a>
                )}
                {this.state.selectedHeader == 10 && (
                  <a className="new-blue-btn" onClick={this.openMergePopup}>
                    Merge
                  </a>
                )}
              </div>
            </div>
          </div>
          <ul className="mergeSteps">
            <li
              className={
                this.state.selectedHeader == 1
                  ? "active"
                  : this.state.selectedHeader > 1
                  ? "activated"
                  : ""
              }
              onClick={() => this.setHeader(1)}
            >
              <a>{this.state.clientLang.merge_profile_information}</a>
            </li>
            <li
              className={
                this.state.selectedHeader == 2
                  ? "active"
                  : this.state.selectedHeader > 2
                  ? "activated"
                  : ""
              }
              onClick={() => this.setHeader(2)}
            >
              <a>{this.state.clientLang.merge_procedures}</a>
            </li>
            <li
              className={
                this.state.selectedHeader == 3
                  ? "active"
                  : this.state.selectedHeader > 3
                  ? "activated"
                  : ""
              }
              onClick={() => this.setHeader(3)}
            >
              <a>{this.state.globalLang.inv_wallet}</a>
            </li>
            <li
              className={
                this.state.selectedHeader == 4
                  ? "active"
                  : this.state.selectedHeader > 4
                  ? "activated"
                  : ""
              }
              onClick={() => this.setHeader(4)}
            >
              <a>
                {this.state.clientLang.merge_membership_and_treatment_plans}
              </a>
            </li>
            <li
              className={
                this.state.selectedHeader == 5
                  ? "active"
                  : this.state.selectedHeader > 5
                  ? "activated"
                  : ""
              }
              onClick={() => this.setHeader(5)}
            >
              <a>{this.state.clientLang.clientprofile_medical_history}</a>
            </li>
            <li
              className={
                this.state.selectedHeader == 6
                  ? "active"
                  : this.state.selectedHeader > 6
                  ? "activated"
                  : ""
              }
              onClick={() => this.setHeader(6)}
            >
              <a>
                {this.state.clientLang.merge_upcoming_appts_payment_history}
              </a>
            </li>
            <li
              className={
                this.state.selectedHeader == 7
                  ? "active"
                  : this.state.selectedHeader > 7
                  ? "activated"
                  : ""
              }
              onClick={() => this.setHeader(7)}
            >
              <a>{this.state.clientLang.clientprofile_customer_notes}</a>
            </li>
            <li
              className={
                this.state.selectedHeader == 8
                  ? "active"
                  : this.state.selectedHeader > 8
                  ? "activated"
                  : ""
              }
              onClick={() => this.setHeader(8)}
            >
              <a>{this.state.clientLang.clientprofile_documents}</a>
            </li>
            <li
              className={
                this.state.selectedHeader == 9
                  ? "active"
                  : this.state.selectedHeader > 9
                  ? "activated"
                  : ""
              }
              onClick={() => this.setHeader(9)}
            >
              <a>Orders</a>
            </li>
            <li
              className={
                this.state.selectedHeader == 10
                  ? "active"
                  : this.state.selectedHeader > 10
                  ? "activated"
                  : ""
              }
              onClick={() => this.setHeader(10)}
            >
              <a>{this.state.clientLang.merge_review_merge}</a>
            </li>
          </ul>
          {this.state.selectedHeader == 1 && (
            <ProfileInformation
              profileDropDowns={this.state.profileDropDowns}
              mergeDataTime={this.state.mergeDataTime}
              defaultProfileData={
                this.state.step1Data
                  ? this.state.step1Data
                  : this.state.defaultProfileData
              }
              profileInformation={this.state.profileInformation}
              handleChildState={this.handleChildState}
            />
          )}
          {this.state.selectedHeader == 2 && (
            <Procedures
              procedureData={
                this.state.step2Data
                  ? this.state.step2Data
                  : this.state.procedureData
              }
              procedureDataTime={this.state.procedureDataTime}
              handleChildState={this.handleChildState}
            />
          )}
          {this.state.selectedHeader == 3 && (
            <Wallet
              productsData={
                this.state.step3Data
                  ? this.state.step3Data.productsData
                  : this.state.productsData
              }
              walletData={
                this.state.step3Data
                  ? this.state.step3Data.walletData
                  : this.state.walletData
              }
              walletDataTime={this.state.walletDataTime}
              handleChildState={this.handleChildState}
            />
          )}
          {this.state.selectedHeader == 4 && (
            <MemberShip
              membershipData={
                this.state.step4Data
                  ? this.state.step4Data.membershipData
                  : this.state.membershipData
              }
              treatmentPlans={
                this.state.step4Data
                  ? this.state.step4Data.treatmentPlans
                  : this.state.treatmentPlans
              }
              membershipDataTime={this.state.membershipDataTime}
              handleChildState={this.handleChildState}
            />
          )}
          {this.state.selectedHeader == 5 && (
            <MedicalHistory
              patients={this.state.medicalHistory || []}
              intersection={this.state.nextMedicalHistory || {}}
              onChange={(nextMedicalHistory) => {
                this.setState({
                  nextMedicalHistory,
                });
              }}
            />
          )}
          {this.state.selectedHeader == 6 && (
            <UpcomingAppointments
              appointmentData={
                this.state.step6Data
                  ? this.state.step6Data.appointmentData
                  : this.state.appointmentData
              }
              paymentData={
                this.state.step6Data
                  ? this.state.step6Data.paymentData
                  : this.state.paymentData
              }
              appointmentDataTime={this.state.appointmentDataTime}
              handleChildState={this.handleChildState}
            />
          )}
          {this.state.selectedHeader == 7 && (
            <CustomerNotes
              notes={
                this.state.step7Data
                  ? this.state.step7Data.notes
                  : this.state.notes
              }
              notesDataTime={this.state.notesDataTime}
              handleChildState={this.handleChildState}
            />
          )}
          {this.state.selectedHeader == 8 && (
            <Documents
              documents={
                this.state.step8Data
                  ? this.state.step8Data.documents
                  : this.state.documents
              }
              documentDataTime={this.state.documentDataTime}
              handleChildState={this.handleChildState}
            />
          )}
          {this.state.selectedHeader === 9 && (
            <EfaxOrders
              selectedOrders={this.state.selectedOrders}
              setSelectedOrders={(orders) =>
                this.setState({ selectedOrders: orders })
              }
            />
          )}
          {this.state.selectedHeader == 10 && (
            <ReviewMerge
              defaultProfileData={
                this.state.step1Data
                  ? this.state.step1Data
                  : this.state.defaultProfileData
              }
              procedureData={
                this.state.step2Data
                  ? this.state.step2Data
                  : this.state.procedureData
              }
              walletData={
                this.state.step3Data
                  ? this.state.step3Data.walletData
                  : this.state.walletData
              }
              productsData={
                this.state.step3Data
                  ? this.state.step3Data.productsData
                  : this.state.productsData
              }
              membershipData={
                this.state.step4Data
                  ? this.state.step4Data.membershipData
                  : this.state.membershipData
              }
              treatmentPlans={
                this.state.step4Data
                  ? this.state.step4Data.treatmentPlans
                  : this.state.treatmentPlans
              }
              profileDropDowns={this.state.profileDropDowns}
              selectedOrders={this.state.selectedOrders}
            />
          )}
          <div className={this.state.cancelMergePopup ? "overlay" : ""}></div>
          <div
            role="dialog"
            className={
              this.state.cancelMergePopup
                ? "modal fade in displayBlock"
                : "modal fade"
            }
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.dismissModal}
                  >
                    x
                  </button>
                  <h4 className="modal-title" id="model_title">
                    {this.state.clientLang.label_cancel_merge}
                    {this.state.showModal}
                  </h4>
                </div>
                <div
                  id="errorwindow"
                  className="modal-body add-patient-form filter-patient"
                >
                  {
                    "Are you sure you want to exit merge profile mode? All unsaved data will be deleted."
                  }
                </div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button
                      type="button"
                      className="btn  logout pull-right"
                      data-dismiss="modal"
                      onClick={this.dismissModal}
                    >
                      {this.state.settingsLang.no_option}
                    </button>
                    <button
                      type="button"
                      className="btn btn-success pull-right m-r-10"
                      data-dismiss="modal"
                      onClick={this.cancelMergeOfClients}
                    >
                      {this.state.settingsLang.yes_option}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={this.state.mergeClientPopup ? "overlay" : ""}></div>
          <div
            id="filterModal"
            role="dialog"
            className={
              this.state.mergeClientPopup
                ? "modal fade in displayBlock"
                : "modal fade"
            }
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.dismissMergeClientModal}
                  >
                    x
                  </button>
                  <h4 className="modal-title" id="model_title">
                    {this.state.settingsLang.settings_Confirmation_required}
                    {this.state.showModal}
                  </h4>
                </div>
                <div
                  id="errorwindow"
                  className="modal-body add-patient-form filter-patient"
                >
                  {this.state.clientLang.message_merge_profile}
                </div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button
                      type="button"
                      className="btn  logout pull-right"
                      data-dismiss="modal"
                      onClick={this.dismissMergeClientModal}
                    >
                      {this.state.settingsLang.no_option}
                    </button>
                    <button
                      type="button"
                      className="btn btn-success pull-right m-r-10"
                      data-dismiss="modal"
                      onClick={this.mergeData}
                    >
                      {this.state.settingsLang.yes_option}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
        {this.state.isMedicalHistorySummaryModalOpen && (
          <MedicalHistory.ModalSummary
            isOpen
            intersection={this.state.nextMedicalHistory}
            onClose={() =>
              this.setState({
                isMedicalHistorySummaryModalOpen: false,
              })
            }
            onConfirm={() => {
              this.setState({
                isMedicalHistorySummaryModalOpen: false,
              });
              this.props.getUpcomingAppointment(
                {
                  ids: JSON.parse(localStorage.getItem("profiles_id")),
                },
                "upcomingAppointmentsAndPaymentHistory",
              );
            }}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  let returnState = {};

  if (state.ClientsReducer.action == "GET_MERGE_PROFILES_DATA") {
    if (state.ClientsReducer.data.status == 200) {
      returnState.mergeData = state.ClientsReducer.data.data;
      returnState.mergeDataTime = new Date();
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.ClientsReducer.data.message]);
    }
  }

  if (state.ClientsReducer.action == "GET_PROCEDURE_DATA") {
    if (state.ClientsReducer.data.status == 200) {
      returnState.procedureData = state.ClientsReducer.data.data;
      returnState.procedureDataTime = new Date();
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.ClientsReducer.data.message]);
    }
  }

  if (state.ClientsReducer.action == "GET_WALLET_DATA") {
    if (state.ClientsReducer.data.status == 200) {
      returnState.walletData = state.ClientsReducer.data.data;
      returnState.walletDataTime = new Date();
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.ClientsReducer.data.message]);
    }
  }

  if (state.ClientsReducer.action == "GET_TREATMENT_PLANS") {
    if (state.ClientsReducer.data.status == 200) {
      returnState.membershipData = state.ClientsReducer.data.data;
      returnState.membershipDataTime = new Date();
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.ClientsReducer.data.message]);
    }
  }

  if (state.ClientsReducer.action == "GET_MEDICAL_HISTORY") {
    if (state.ClientsReducer.data.status == 200) {
      returnState.medicalHistory = state.ClientsReducer.data.data;
      returnState.medicalHistoryDataTime = new Date();
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.ClientsReducer.data.message]);
    }
  }

  if (
    state.ClientsReducer.action ==
    "GET_UPCOMING_APPOINTMENT_AND_PAYMENT_HISTORY"
  ) {
    if (state.ClientsReducer.data.status == 200) {
      returnState.appointmentAndPaymentData = state.ClientsReducer.data.data;
      returnState.appointmentDataTime = new Date();
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.ClientsReducer.data.message]);
    }
  }

  if (state.ClientsReducer.action == "GET_CUSTOMER_NOTES") {
    if (state.ClientsReducer.data.status == 200) {
      returnState.customerNotesData = state.ClientsReducer.data.data;
      returnState.notesDataTime = new Date();
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.ClientsReducer.data.message]);
    }
  }

  if (state.ClientsReducer.action == "GET_PATIENT_DOCUMENTS") {
    if (state.ClientsReducer.data.status == 200) {
      returnState.customerDocuments = state.ClientsReducer.data.data;
      returnState.documentDataTime = new Date();
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.ClientsReducer.data.message]);
    }
  }

  if (state.ClientsReducer.action == "MERGE_PATIENT_DATA") {
    if (state.ClientsReducer.data.status == 200) {
      returnState.redirect = true;
      returnState.redirectDataTime = new Date();
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMergeData: getMergeData,
      exportEmptyData: exportEmptyData,
      getProcedureData: getProcedureData,
      getWalletData: getWalletData,
      getTreatmentPlans: getTreatmentPlans,
      getMedicalHistory: getMedicalHistory,
      getUpcomingAppointment: getUpcomingAppointment,
      getCustomerNotes: getCustomerNotes,
      getPatientDocuments: getPatientDocuments,
      mergeClient: mergeClient,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MergeProfile);
