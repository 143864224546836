import React, { useState } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { useConfirmPayment } from "../hooks/useConfirmPayment";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import ViewTipsModal from "../../../ViewTipsModal/ViewTipsModal";
import { ModalCharge } from "../../../../shared/ModalCharge/ModalCharge";
import { Footer } from "../../../../shared/ModalCharge/Footer";
import { checkoutInvoice } from "../../../../../../../store/checkoutInvoice";
import { usePaymentFlow } from "../../../../hooks/usePaymentFlow";

const StripeCheckoutPaymentForm = ({
  saveCard,
  paymentAmount,
  setShowPaymentForm,
  stripeData,
  generateIntent,
  isGeneratingIntent,
  isTipsApplying,
}) => {
  const { invoice, isStartStage, isTipsEnabled } = useInvoice();
  const { tipsOnlyFlow } = usePaymentFlow();
  const [showTipsModal, setShowTipsModal] = useState(false);
  const [formCompleted, setFormCompleted] = useState(false);

  const isTipsAvailable = isStartStage && !showTipsModal && isTipsEnabled();

  const { confirmPayment, isLoading } = useConfirmPayment({
    paymentIntentId: stripeData.paymentIntentId,
    saveCard: saveCard,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isTipsAvailable && !tipsOnlyFlow) {
      setShowTipsModal(true);
      return;
    }

    if (showTipsModal) {
      await generateIntent({
        invoice_id: invoice.id,
        amount: parseFloat(paymentAmount) + parseFloat(invoice.tipAmount),
      });
    }

    confirmPayment();
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement onChange={(event) => setFormCompleted(event.complete)} />
      {showTipsModal && (
        <ModalCharge
          isOpen
          title={null}
          imageSrc={null}
          onClose={() => {
            setShowPaymentForm(false);
            setShowTipsModal(false);
          }}
          onCharge={handleSubmit}
          isCharging={isLoading || isGeneratingIntent}
          isChargeDisabled={isTipsApplying}
        >
          <ViewTipsModal amountToPay={paymentAmount} />
        </ModalCharge>
      )}

      <Footer
        className={"m-t-0"}
        onClose={(e) => {
          e.preventDefault();
          setShowPaymentForm(false);
        }}
        isCharging={isLoading}
        onCharge={handleSubmit}
        isChargeDisabled={!formCompleted}
        isCancelDisabled={isLoading}
        chargeLabel={"Charge"}
      />
    </form>
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default connect(mapStateToProps)(StripeCheckoutPaymentForm);
