import React from "react";
import cx from "clsx";
import Pagination from "react-js-pagination";
import { WidgetDateRange } from "../../components/WidgetDateRange";
import { WidgetLoader } from "../../components/WidgetLoader";
import { MonthRangeSwitch } from "../../../../shared/MonthRangeSwitch/MonthRangeSwitch";

/**
 * @param {object} param0
 *
 * @param {string} param0.label
 *
 * @param {{
 *  dates: { start: string; end: string };
 *  onSelect: Function;
 * }?} param0.dateRange;
 *
 * @param {{
 *  activePage: number;
 *  itemsCountPerPage: number;
 *  total: number;
 *  range: number;
 *  setActivePage: Function;
 * }?} param0.pagination
 *
 * @param {boolean} param0.isLoading
 *
 * @param {boolean} param0.isEmpty
 *
 * @param {React.ReactNode} param0.children
 *
 * @param {string?} param0.contentClassName
 */
export const WidgetBox = ({
  label,
  dateRange,
  monthlyRange,
  pagination,
  isLoading,
  isEmpty,
  children,
  headerClassName,
  contentClassName,
}) => {
  return (
    <div className="setting-setion m-b-15">
      <div className={cx("dashboardSectionTitle", headerClassName)}>
        <b>{label}</b>
        {dateRange && (
          <WidgetDateRange
            ranges={{
              startDate: dateRange.dates.start,
              endDate: dateRange.dates.end,
            }}
            onChange={dateRange.onSelect}
            dates={dateRange.dates}
          />
        )}
        {monthlyRange && (
          <MonthRangeSwitch
            startDate={monthlyRange.dates.start}
            endDate={monthlyRange.dates.end}
            onChange={({ startDate, endDate }) => {
              monthlyRange.update({
                from: startDate,
                to: endDate,
              });
            }}
          />
        )}
      </div>
      <div
        data-highcharts-chart="0"
        className={cx(
          "dash-box-content egiftCardGraph businessGraphOuter dashBarGraph",
          contentClassName,
        )}
      >
        {isEmpty ? (
          <div className="dashboardSectionContent">
            <div className="no-record">Sorry, No Record Found</div>
          </div>
        ) : (
          children
        )}
      </div>
      {Boolean(pagination?.total) && (
        <div className="dashboardSectionFooter">
          <div className="dashboardPagination">
            {pagination.total > 0 && (
              <Pagination
                prevPageText="Prev"
                nextPageText="Next"
                firstPageText={<i className="fa fa-angle-double-left" />}
                lastPageText={<i className="fa fa-angle-double-right" />}
                activePage={pagination.activePage}
                itemsCountPerPage={pagination.itemsCountPerPage}
                totalItemsCount={pagination.total}
                pageRangeDisplayed={pagination.range}
                onChange={pagination.setActivePage}
              />
            )}
          </div>
        </div>
      )}
      {isLoading && <WidgetLoader />}
    </div>
  );
};
