import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";
import {
  paginationSchema,
  getNextPaginationPage,
  composePaginationSchema,
} from "../../schemas/pagination";

export const CLIENTS_SORT_FIELDS = {
  firstName: "firstname",
  lastName: "lastname",
  email: "email",
  dateOfBirth: "date_of_birth",
};

const requestSchema = yup.object({
  pageSize: yup.number().required(),
  sortBy: yup.string().nullable(),
  sortOrder: yup.string().nullable(),
  searchTerm: yup.string().nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    clients: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          firsName: yup.string().nullable(),
          lastName: yup.string().nullable(),
          fullName: yup.string().nullable(),
          phoneNumber: yup.string().nullable(),
          email: yup.string().nullable(),
        }),
      )
      .required(),
  }),
);

const composeResponse = (res, req) => {
  const clients = res?.data?.data?.data || [];

  return {
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: req.pageSize,
    }),
    clients: clients.map((c) => ({
      id: c.id,
      firstName: c.firstname,
      lastName: c.lastname,
      fullName: c.full_name,
      phoneNumber: c.phoneNumber,
      email: c.email,
    })),
  };
};

const createClientsQueryKey = (...args) => [QUERY_KEYS.clients, ...args];

export const useClientsQuery = ({ payload = {}, options = {} } = {}) => {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  return useInfiniteQuery({
    queryKey: createClientsQueryKey(
      req.pageSize,
      req.sortBy,
      req.sortOrder,
      req.searchTerm,
      req.context,
    ),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.getClients(), {
        params: removeNullishFromShape({
          pagesize: req.pageSize,
          sortby: req.sortBy,
          sortorder: req.sortOrder,
          term: req.searchTerm || undefined,
          page: pageParam,
          context: req.context,
        }),
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
};
