/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { InView } from "react-intersection-observer";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Sidebar from "../InventorySidebar";
import { getAllPurchaseOrders } from "../../../Actions/Inventory/inventoryActions.js";
import { autoScrolling } from "../../../Utils/services.js";
import Loader from "../../Common/Loader.js";
import { unwrapOr } from "../../../utilities/general";
import { shortenClinicName } from "../../../helpers/general";

class PurchaseOrder extends Component {
  constructor(props) {
    super(props);
    let languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      term: "",
      purchaseOrdersData: [],
      showLoader: false,
      page: 1,
      pagesize: 20,
      showLoadingText: false,
      statusName: "All Purchases",
      globalLang: languageData.global,
      inventoryLang: languageData.inventory,
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value,
    });
  };

  componentDidMount() {
    autoScrolling(true);
    this.setState({ showLoader: true });
    this.props.getAllPurchaseOrders({});
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {
      next_page_url: unwrapOr(
        () => nextProps.purchaseOrdersData.next_page_url,
        null,
      ),
    };
    if (
      nextProps.purchaseOrdersData != undefined &&
      nextProps.purchaseOrdersDataTime != prevState.purchaseOrdersDataTime
    ) {
      returnState.purchaseOrdersData = nextProps.purchaseOrdersData;
      returnState.purchaseOrdersDataTime = nextProps.purchaseOrdersDataTime;
      autoScrolling(false);
      if (prevState.purchaseOrdersData.length == 0) {
        if (nextProps.purchaseOrdersData.next_page_url != null) {
          returnState.page = prevState.page + 1;
        }
        returnState.purchaseOrdersData = nextProps.purchaseOrdersData.data
          ? nextProps.purchaseOrdersData.data
          : [];
      } else {
        returnState.purchaseOrdersData = [
          ...prevState.purchaseOrdersData,
          ...nextProps.purchaseOrdersData.data,
        ];
        returnState.page = prevState.page + 1;
      }
      returnState.showLoader = false;
      returnState.showLoadingText = false;
    }
    return returnState;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        term: this.state.term,
        order_status:
          this.state.orderStatus == "all" ? "" : this.state.orderStatus,
      },
    };

    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      next_page_url: "",
      showLoader: true,
      purchaseOrdersData: [],
    });
    autoScrolling(true);
    this.props.getAllPurchaseOrders(formData);
  };

  loadMore = (inView) => {
    if (!autoScrolling() && inView && this.state.next_page_url) {
      this.setState({ startFresh: true, showLoadingText: true });
      let formData = {};
      formData = {
        params: {
          page: this.state.page,
          pagesize: this.state.pagesize,
          term: this.state.term,
          order_status:
            this.state.orderStatus == "all" ? "" : this.state.orderStatus,
        },
      };
      autoScrolling(true);
      this.props.getAllPurchaseOrders(formData);
    }
  };
  changeStatus = (status) => {
    let returnState = {};
    if (status == "all") {
      returnState.statusName = "All Purchases";
    } else if (status == "fully_received") {
      returnState.statusName = "Received";
    } else if (status == "not_received") {
      returnState.statusName = "Not Received";
    } else {
      returnState.statusName = "Partially Received";
    }

    returnState.orderStatus = status;
    returnState.showStatus = false;
    returnState.showLoader = true;
    returnState.purchaseOrdersData = [];
    returnState.page = 1;
    this.setState(returnState);
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        term: this.state.term,
        order_status: status == "all" ? "" : status,
      },
    };
    autoScrolling(true);
    this.props.getAllPurchaseOrders(formData);
  };

  toggleStatus = () => {
    this.setState({ showStatus: !this.state.showStatus });
  };

  render() {
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <Sidebar />
          <div className="memberWalletOuter business-section">
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                <span className="titleDropDown" onClick={this.toggleStatus}>
                  {this.state.statusName}
                  <i className="fa fa-angle-down"></i>
                  <div
                    className={
                      this.state.showStatus
                        ? "actionDropdownOuter clinicDropdown"
                        : "no-display"
                    }
                    ref={(statusNode) => {
                      this.statusNode = statusNode;
                    }}
                  >
                    <ul className="actionDropdown">
                      <li key="statusName-all">
                        <a
                          onClick={this.changeStatus.bind(this, "all")}
                          className={
                            this.state.mainStatus == "all" ? "selected" : ""
                          }
                        >
                          All Purchases
                        </a>
                      </li>
                      <li key="statusName-fully_received">
                        <a
                          onClick={this.changeStatus.bind(
                            this,
                            "fully_received",
                          )}
                          className={
                            this.state.mainStatus == "fully_received"
                              ? "selected"
                              : ""
                          }
                        >
                          Received
                        </a>
                      </li>
                      <li key="statusName-not_received">
                        <a
                          onClick={this.changeStatus.bind(this, "not_received")}
                          className={
                            this.state.mainStatus == "not_received"
                              ? "selected"
                              : ""
                          }
                        >
                          Not Received
                        </a>
                      </li>
                      <li key="statusName-partial_received">
                        <a
                          onClick={this.changeStatus.bind(
                            this,
                            "partial_received",
                          )}
                          className={
                            this.state.mainStatus == "partial_received"
                              ? "selected"
                              : ""
                          }
                        >
                          Partially Received
                        </a>
                      </li>
                    </ul>
                  </div>
                </span>
                <form
                  onSubmit={this.handleSubmit}
                  className="searchForm settingAddProductOuter"
                >
                  <span className="searchOuter">
                    <i className="fa fa-search search-icon" />
                    <input
                      className="setting-search-input search-key"
                      placeholder={"Search by po#"}
                      name="term"
                      autoComplete="off"
                      value={this.state.term}
                      onChange={this.handleInputChange}
                    />
                  </span>
                </form>
                <div className="memberRightActions">
                  <Link
                    to="/inventory/purchase-order/add"
                    name="filter"
                    className="new-line-btn no-width"
                  >
                    {"Add Purchase Order"}
                  </Link>
                </div>
              </div>
            </div>
            <div className="juvly-section full-width">
              <div className="table-responsive">
                <table className="table-updated setting-table table-min-width no-td-border">
                  <thead className="table-updated-thead">
                    <tr>
                      <th className="col-xs-2 table-updated-th">PO #</th>
                      <th className="col-xs-2 table-updated-th">
                        Supplier Name
                      </th>
                      <th className="col-xs-2 table-updated-th">Clinic Name</th>
                      <th className="col-xs-3 table-updated-th">
                        Order Date & Time
                      </th>
                      <th className="col-xs-2 table-updated-th">Status</th>
                      <th className="col-xs-2 table-updated-th">Action</th>
                    </tr>
                  </thead>
                  <tbody className="ajax_body">
                    {this.state.purchaseOrdersData &&
                      this.state.purchaseOrdersData.length > 0 &&
                      this.state.purchaseOrdersData.map((obj, idx) => {
                        return (
                          <tr key={"purchaseOrdersData-" + idx}>
                            <td className="col-xs-2 table-updated-td">
                              {obj.po_number}
                            </td>
                            <td className="col-xs-2 table-updated-td">
                              {obj.supplier && obj.supplier.supplier_name}
                            </td>
                            <td className="col-xs-2 table-updated-td">
                              {obj.clinic &&
                                shortenClinicName(obj.clinic.clinic_name)}
                            </td>
                            <td className="col-xs-3 table-updated-td">
                              {obj.placed_on}
                            </td>
                            <td className="col-xs-2 table-updated-td">
                              {obj.order_status == "not_received"
                                ? "Not Received"
                                : obj.order_status == "fully_received"
                                ? "Received"
                                : "Partially Received"}
                            </td>
                            <td className="col-xs-2 table-updated-td">
                              <Link
                                to={"/inventory/purchase-order/view/" + obj.id}
                                className="line-btn p-l-0 p-r-0 text-center"
                              >
                                {" "}
                                View Details
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    {this.state.purchaseOrdersData &&
                      this.state.purchaseOrdersData.length == 0 && (
                        <tr>
                          <td
                            colSpan="6"
                            className="col-xs-12 table-updated-td text-center"
                          >
                            No Record Found
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
              {!this.state.showLoader && (
                <InView onChange={(inView) => this.loadMore(inView)}>
                  {({ ref }) => (
                    <div
                      ref={ref}
                      className="loading-please-wait no-margin-top"
                    >
                      {this.state.showLoadingText &&
                        this.state.globalLang.loading_please_wait_text}
                    </div>
                  )}
                </InView>
              )}
            </div>
          </div>
        </div>
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.InventoryReducer.action === "PURCHASE_ORDERS_LIST") {
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      returnState.showLoaderTime = new Date();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      returnState.purchaseOrdersData = state.InventoryReducer.data.data;
      returnState.purchaseOrdersDataTime = new Date();
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getAllPurchaseOrders: getAllPurchaseOrders },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PurchaseOrder));
