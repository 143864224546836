import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, PATIENT_DOC_TYPES } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";

/* SCHEMAS */

const composeTypeSchema = (typeValue) =>
  yup
    .string()
    .test({ test: (v) => v === typeValue })
    .required();

const commonSchema = yup.object({
  name: yup.string().required(),
});

const fileSchema = commonSchema.concat(
  yup.object({
    type: composeTypeSchema(PATIENT_DOC_TYPES.file),
    patient_id: yup.number().required(),
    document_id: yup.number().required(),
  }),
);

const consentSchema = commonSchema.concat(
  yup.object({
    type: composeTypeSchema(PATIENT_DOC_TYPES.consent),
    procedure_id: yup.number().required(),
    consent_id: yup.number().required(),
    patient_id: yup.number().required(),
    subType: yup.string().required(),
  }),
);

const procedureSchema = commonSchema.concat(
  yup.object({
    type: composeTypeSchema(PATIENT_DOC_TYPES.procedure),
    patient_id: yup.number().required(),
    procedure_id: yup.number().required(),
  }),
);

const agreementSchema = commonSchema.concat(
  yup.object({
    type: composeTypeSchema(PATIENT_DOC_TYPES.agreement),
    patient_id: yup.number().required(),
    is_expired: yup.number().required(),
    agreement_id: yup.number().required(),
  }),
);

const appointmentQuestionnaireSchema = commonSchema.concat(
  yup.object({
    type: composeTypeSchema(PATIENT_DOC_TYPES.appointmentQuestionnaire),
    appointment_id: yup.number().required(),
    consultation_id: yup.number().required(),
    patient_id: yup.number().required(),
    service_id: yup.number().required(),
  }),
);

const procedureQuestionnaireSchema = commonSchema.concat(
  yup.object({
    type: composeTypeSchema(PATIENT_DOC_TYPES.procedureQuestionnaire),
    procedure_id: yup.number().required(),
    questionnaire_id: yup.number().required(),
  }),
);

const healthQuestionnaireSchema = commonSchema.concat(
  yup.object({
    type: composeTypeSchema(PATIENT_DOC_TYPES.healthQuestionnaires),
    appointment_id: yup.number().required(),
    patient_id: yup.number().required(),
    procedure_template_id: yup.number().required(),
  }),
);

const procedureHealthQuestionnaireSchema = commonSchema.concat(
  yup.object({
    type: composeTypeSchema(PATIENT_DOC_TYPES.procedureHealthQuestionnaire),
    patient_id: yup.number().required(),
    procedure_template_id: yup.number().required(),
    procedure_id: yup.number().required(),
  }),
);

const ePrescriptionSchema = commonSchema.concat(
  yup.object({
    type: composeTypeSchema(PATIENT_DOC_TYPES.ePrescription),
    prescription_id: yup.number().required(),
    patient_id: yup.number().required(),
  }),
);

const gfeSchema = commonSchema.concat(
  yup.object({
    type: composeTypeSchema(PATIENT_DOC_TYPES.gfe),
    patient_id: yup.number().required(),
    document_id: yup.number().required(),
  }),
);

const efaxSchema = commonSchema.concat(
  yup.object({
    type: composeTypeSchema(PATIENT_DOC_TYPES.efax),
    patient_id: yup.number().required(),
    document_id: yup.number().required(),
  }),
);

const fileSchemas = [
  fileSchema,
  consentSchema,
  procedureSchema,
  agreementSchema,
  appointmentQuestionnaireSchema,
  procedureQuestionnaireSchema,
  healthQuestionnaireSchema,
  procedureHealthQuestionnaireSchema,
  ePrescriptionSchema,
  gfeSchema,
  efaxSchema,
];

const requestSchema = yup.object({
  docs: yup.array().test({
    test: (documents) =>
      documents.every((doc) =>
        fileSchemas.some((schema) =>
          schema.isValidSync(doc, {
            strict: true,
          }),
        ),
      ),
  }),
});

const responseSchema = yup.object({
  fileName: yup.string().required(),
  failedFiles: yup.array().of(yup.string()).required(),
});

/* COMPOSERS */

const composeResponse = (res) => {
  const data = unwrapOr(() => res.data.data, {});
  return {
    fileName: data.file,
    failedFiles: data.failedFiles || [],
  };
};

// ---------

export function useDownloadPatientDocsMutation(options = {}) {
  return useMutation(async (dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    const res = await http.post(HTTP_ENDPOINTS.postDownloadPatientDocs(), {
      documentsData: req.docs,
    });
    return responseSchema.validateSync(composeResponse(res), {
      strict: true,
    });
  }, options);
}
